import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as networksActions from './actions';
import * as publishersActions from '../publishers/actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

function* createNetworkSaga({ network }) {
  try {
    const networksRef = firebase.firestore().collection('networks');
    const countryId = yield select((state) => state.Dashboard.countryId);

    const newPublisher = yield call(rsf.firestore.addDocument, networksRef, {
      ...network,
      countryId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    yield put(
      publishersActions.updateNetworkId(
        network.publisherIds,
        [],
        newPublisher.id,
      ),
    );
    yield put(networksActions.createNetworkSuccess(network));
    toastr.success('Network created!', '');
  } catch (error) {
    yield put(networksActions.createNetworkFailure(error));
  }
}

function* updateNetworkSaga({ network, prevPublisherIds }) {
  try {
    const networkId = network.id;
    const networksRef = firebase
      .firestore()
      .collection('networks')
      .doc(network.id);
    delete network.id;

    yield call(
      rsf.firestore.setDocument,
      networksRef,
      {
        ...network,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(
      publishersActions.updateNetworkId(
        network.publisherIds,
        prevPublisherIds,
        networkId,
      ),
    );
    yield put(networksActions.updateNetworkSuccess());
    toastr.success('Network updated!', '');
  } catch (error) {
    yield put(networksActions.updateNetworkFailure(error));
  }
}

const networkTransformer = (payload) => {
  let networks = [];

  payload.forEach((network) => {
    const data = network.data();

    networks.push({
      id: network.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(network, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(network, data, 'updatedAt').toDate(),
      }),
    });
  });

  return networks;
};

function* syncNetworksSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const networksRef = firebase
    .firestore()
    .collection('networks')
    .where('countryId', '==', countryId)
    .orderBy('createdAt', 'desc');

  const task = yield fork(rsf.firestore.syncCollection, networksRef, {
    successActionCreator: networksActions.syncNetworksSuccess,
    failureActionCreator: networksActions.syncNetworksFailure,
    transform: (payload) => networkTransformer(payload),
  });

  yield take(types.RESET_NETWORK_STATE);
  yield cancel(task);
}

function* networkSaga() {
  yield all([
    takeLatest(types.SYNC_NETWORKS.REQUEST, syncNetworksSaga),
    takeLeading(types.CREATE_NETWORK.REQUEST, createNetworkSaga),
    takeLatest(types.UPDATE_NETWORK.REQUEST, updateNetworkSaga),
  ]);
}

export default networkSaga;
