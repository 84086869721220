import { all } from 'redux-saga/effects';

//public
import AuthSaga from './auth/saga';
import DashboardSaga from './dashboard/saga';
import LayoutSaga from './layout/saga';
import NetworkSaga from './networks/saga';
import NetworkInsightSaga from './networks/insights/saga';
import PublisherSaga from './publishers/saga';
import PublisherInsightSaga from './publishers/insights/saga';
import SiteSaga from './sites/saga';
import SiteInsightSaga from './sites/insights/saga';
import SiteRevenueSaga from './sites/revenues/saga';
import MonthlySiteRevenueSaga from './sites/monthlyRevenues/saga';
import RevenueSaga from './revenues/adUnits/saga';
import PodcastSaga from './podcasts/saga';
import PodcastAnalysisSaga from './podcasts/analyses/saga';
import PlaylistSaga from './playlists/saga';
import CustomerSaga from './customers/saga';
import InsightSaga from './insights/saga';
import ClusterSaga from './clusters/saga';
import CampaignSaga from './campaigns/saga';
import CampaignInsightSaga from './campaigns/insights/saga';
import CreativeSaga from './campaigns/creatives/saga';
import CreativeInsightSaga from './campaigns/creatives/insights/saga';
import TransactionSaga from './transactions/saga';
import UserSaga from './users/saga';
import CountrySaga from './countries/saga';
import CountryInsightSaga from './countries/insights/saga';

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    LayoutSaga(),
    NetworkSaga(),
    NetworkInsightSaga(),
    PublisherSaga(),
    PublisherInsightSaga(),
    SiteSaga(),
    SiteInsightSaga(),
    SiteRevenueSaga(),
    MonthlySiteRevenueSaga(),
    RevenueSaga(),
    PodcastSaga(),
    PodcastAnalysisSaga(),
    PlaylistSaga(),
    CustomerSaga(),
    InsightSaga(),
    ClusterSaga(),
    CampaignSaga(),
    CampaignInsightSaga(),
    CreativeSaga(),
    CreativeInsightSaga(),
    TransactionSaga(),
    UserSaga(),
    DashboardSaga(),
    CountrySaga(),
    CountryInsightSaga(),
  ]);
}
