import '../../lib/availity/yup';
import * as yup from 'yup';

const formValidation = (isFormCreate) =>
  yup.object().shape(
    isFormCreate
      ? {
          url: yup.string().url().required(),
          isPremium: yup
            .boolean()
            .transform((value, originalValue) =>
              originalValue ? true : false,
            ),
        }
      : {
          title: yup.string(),
          author: yup.string(),
          scraped: yup.string().required(),
          synthesized: yup.string().required(),
          text: yup.string().required(),
          articleSelector: yup.string(),
          synthesizerEngine: yup.string().required(),
          voiceType: yup.string().required(),
          voiceName: yup.string().required(),
          showPodcastImage: yup
            .boolean()
            .transform((value, originalValue) =>
              originalValue ? true : false,
            ),
          renderPlayerWithoutPodcast: yup
            .boolean()
            .transform((value, originalValue) =>
              originalValue ? true : false,
            ),
          renderPlaylist: yup
            .boolean()
            .transform((value, originalValue) =>
              originalValue ? true : false,
            ),
          forceHideStickyPlayer: yup
            .boolean()
            .transform((value, originalValue) =>
              originalValue ? true : false,
            ),
          hideStickyPlayerOnMobile: yup
            .boolean()
            .transform((value, originalValue) =>
              originalValue ? true : false,
            ),
          hideStickyPlayerOnDesktop: yup
            .boolean()
            .transform((value, originalValue) =>
              originalValue ? true : false,
            ),
          heightFixedFloatingPlayer: yup.number(),
        },
  );

export default formValidation;
