import { put, all, call, takeLeading, select } from 'redux-saga/effects';

import types from './actionTypes';
import * as revenuesActions from './actions';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
} from '../../../selectors/auth';
import firebase from 'firebase/app';
import rsf from '../../../helpers/firebase';

import { toDateFirebase, checkField } from '../../../helpers/sharedFunction';
import { MANDATORY_CSV_IMPORT_FIELDS } from '../../../config/revenue';

const revenueTransformer = (revenue, data) => ({
  id: revenue.id,
  ...data,
  averageSongDuration: data.averageSongDuration ?? 0,
  date: toDateFirebase(revenue, data, 'date').toDate(),
});

function* createMonthlySiteRevenueSaga({ insights, month, isFinalBalance }) {
  try {
    //Validate insights
    insights = insights.map((insight, index) => {
      let column = '';
      const check = MANDATORY_CSV_IMPORT_FIELDS.every(
        ({ name, type, required }) => {
          column = name;
          return checkField(insight[name], type, required);
        },
      );
      if (!check)
        throw new Error(
          `Check CSV at row ${index + 2}, column "${column}". Please retry!`,
        );

      //transform percentage to float number
      const { fillRate, revenueShare } = insight;
      insight = {
        ...insight,
        fillRate: parseFloat(fillRate.replace('%', '').replace(',', '.')),
        revenueShare: parseFloat(
          revenueShare.replace('%', '').replace(',', '.'),
        ),
      };

      return insight;
    });

    const createMonthlySiteRevenuesFunction = firebase
      .functions()
      .httpsCallable('createMonthlySiteRevenues-createMonthlySiteRevenues');
    const { data } = yield call(createMonthlySiteRevenuesFunction, {
      insights,
      month,
      isFinalBalance,
    });

    if (data.error) throw new Error(data.error.message);
    yield put(revenuesActions.createMonthlySiteRevenueSuccess(data.message));
  } catch (error) {
    yield put(revenuesActions.createMonthlySiteRevenueFailure(error));
  }
}

function* fetchMonthlyRevenuesSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);

    const isAdmin = yield select(isAdminSelector);
    const isManager = yield select(isManagerSelector);
    const isStaff = isAdmin || isManager;

    if (!isStaff) filters = { ...filters, onlyFinalBalance: true };

    const revenuesRef = rsf.firestore.createCollectionRefWithFilters(
      'monthlySiteRevenues',
      countryId,
      startDate,
      endDate,
      filters,
      null,
      null,
      'date',
    );

    const revenuesSnap = yield call(rsf.firestore.getCollection, revenuesRef);

    const revenues = revenuesSnap.docs.map((revenue) =>
      revenueTransformer(revenue, revenue.data()),
    );

    yield put(
      revenuesActions.fetchMonthlyRevenuesSuccess(
        revenues,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(revenuesActions.fetchMonthlyRevenuesFailure(error));
  }
}

function* revenueSaga() {
  yield all([
    takeLeading(
      types.CREATE_MONTHLY_SITE_REVENUES.REQUEST,
      createMonthlySiteRevenueSaga,
    ),
    takeLeading(
      types.FETCH_MONTHLY_SITE_REVENUES.REQUEST,
      fetchMonthlyRevenuesSaga,
    ),
  ]);
}

export default revenueSaga;
