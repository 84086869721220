import { REVENUE_LEVEL } from '../config/revenue';
import { createSelector } from 'reselect';

export const selectSitesByPublisher = (publisherId, onlyActive = false) =>
  createSelector(
    (state) => state.Site.sites,
    (sites) => {
      if (!publisherId) return [];
      const selectedSites = sites.filter(
        (site) => site.publisherId === publisherId,
      );
      return onlyActive
        ? selectedSites.filter((site) => site.active)
        : selectedSites;
    },
  );

export const selectSeedingSitesByPublisher = (
  publisherId,
  onlyActive = false,
) =>
  createSelector(selectSitesByPublisher(publisherId, onlyActive), (sites) =>
    sites.filter((site) => !!site.gamAdUnitSeedingId),
  );

export const selectSeedingSites = createSelector(
  (state) => state.Site.sites,
  (sites) => sites.filter((site) => !!site.gamAdUnitSeedingId),
);

export const getRevenue = (level) =>
  createSelector(
    (state) => state.SiteRevenue.revenues,
    (state) => state.MonthlySiteRevenue.revenues,
    (daily, monthly) => {
      if (level === REVENUE_LEVEL.DAILY) return daily;
      if (level === REVENUE_LEVEL.MONTHLY) return monthly;
      return daily;
    },
  );

export const totalMetric = (metric, level) =>
  createSelector(getRevenue(level), (revenues) =>
    revenues.map((revenue) => revenue[metric] || 0).reduce((a, b) => a + b, 0),
  );
