import types from './actionTypes';

const INIT_STATE = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'dark',
  leftSideBarType: 'condensed',
  topbarTheme: 'light',
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  showSensitiveData: true,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case types.CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };
    case types.CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case types.CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case types.CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case types.CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case types.SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      };
    case types.SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      };
    case types.TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      };
    case types.TOGGLE_SENSITIVE_DATA:
      return {
        ...state,
        showSensitiveData: action.payload,
      };
    default:
      return state;
  }
};

export default Layout;
