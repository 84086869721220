import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_USER_STATE,
});

export const createUser = (user) => ({
  type: types.CREATE_USER.REQUEST,
  user,
});

export const createUserSuccess = (user) => ({
  type: types.CREATE_USER.SUCCESS,
  user,
});

export const createUserFailure = (error) => ({
  type: types.CREATE_USER.FAILURE,
  error,
});

export const updateUser = (user) => ({
  type: types.UPDATE_USER.REQUEST,
  user,
});

export const updateUserSuccess = () => ({
  type: types.UPDATE_USER.SUCCESS,
});

export const updateUserFailure = (error) => ({
  type: types.UPDATE_USER.FAILURE,
  error,
});

export const syncUsers = () => ({
  type: types.SYNC_USERS.REQUEST,
});

export const syncUsersSuccess = (users) => ({
  type: types.SYNC_USERS.SUCCESS,
  users,
});

export const syncUsersFailure = (error) => ({
  type: types.SYNC_USERS.FAILURE,
  error,
});

export const fetchUser = (userId) => ({
  type: types.FETCH_USER.REQUEST,
  userId,
});

export const fetchUserSuccess = (user) => ({
  type: types.FETCH_USER.SUCCESS,
  user,
});

export const fetchUserFailure = (error) => ({
  type: types.FETCH_USER.FAILURE,
  error,
});

export const userActivation = (user) => ({
  type: types.USER_ACTIVATION.REQUEST,
  user,
});

export const userActivationSuccess = () => ({
  type: types.USER_ACTIVATION.SUCCESS,
});

export const userActivationFailure = (error) => ({
  type: types.USER_ACTIVATION.FAILURE,
  error,
});
