import moment from 'moment';

const yesterdayMoment = () => moment.utc().subtract(1, 'days');
const todayMoment = () => moment.utc();

const datePresets = [
  {
    name: 'today',
    label: 'Today',
    startDate: todayMoment(),
    endDate: todayMoment(),
  },
  {
    name: 'yesterday',
    label: 'Yesterday',
    startDate: yesterdayMoment(),
    endDate: yesterdayMoment(),
  },
  {
    name: 'last7days',
    label: 'Last 7 days',
    startDate: (() => moment.utc().subtract(6, 'days'))(),
    endDate: todayMoment(),
  },
  {
    name: 'thisMonth',
    label: 'This month',
    startDate: (() => moment.utc().startOf('month'))(),
    endDate: todayMoment(),
  },
  {
    name: 'last30days',
    label: 'Last 30 days',
    startDate: (() => moment.utc().subtract(29, 'days'))(),
    endDate: todayMoment(),
  },
  {
    name: 'lastMonth',
    label: 'Last month',
    startDate: (() => moment.utc().subtract(1, 'months').startOf('month'))(),
    endDate: (() => moment.utc().subtract(1, 'months').endOf('month'))(),
  },
];

export { todayMoment, yesterdayMoment, datePresets };
