import {
  takeEvery,
  put,
  all,
  call,
  takeLeading,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as insightsActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../../helpers/firebase';

import { toDateFirebase } from '../../../helpers/sharedFunction';

const insightTransformer = (insight, data) => ({
  id: insight.id,
  ...data,
  averageSongDuration: data.averageSongDuration ?? 0,
  date: toDateFirebase(insight, data, 'date').toDate(),
});

function* fetchInsightsSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const insightsRef = rsf.firestore.createCollectionRefWithFilters(
      'dailyCampaignInsights',
      countryId,
      startDate,
      endDate,
      filters,
      null,
      null,
      'date',
    );

    const insightsSnap = yield call(rsf.firestore.getCollection, insightsRef);

    let insights = [];

    insightsSnap.forEach((insight) => {
      const data = insight.data();
      insights.push(insightTransformer(insight, data));
    });

    yield put(
      insightsActions.fetchInsightsSuccess(
        insights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(insightsActions.fetchInsightsFailure(error));
  }
}

function* fetchInsightsByCampaignSaga({ campaign, startDate, endDate }) {
  try {
    const insightsRef = firebase
      .firestore()
      .collection('dailyCampaignInsights')
      .where('campaignId', '==', campaign.id)
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)
      .orderBy('date', 'desc');

    const insightsSnap = yield call(rsf.firestore.getCollection, insightsRef);

    let insights = [];
    insightsSnap.forEach((insight) => {
      const data = insight.data();
      insights.push(insightTransformer(insight, data));
    });
    yield put(insightsActions.fetchInsightsByCampaignSuccess(insights));
  } catch (error) {
    yield put(insightsActions.fetchInsightsByCampaignFailure(error));
  }
}

function* insightSaga() {
  yield all([
    takeEvery(types.FETCH_CAMPAIGN_INSIGHTS.REQUEST, fetchInsightsSaga),
    takeLeading(
      types.FETCH_CAMPAIGN_INSIGHTS_BY_CAMPAIGN.REQUEST,
      fetchInsightsByCampaignSaga,
    ),
  ]);
}

export default insightSaga;
