const statsTypes = {
  RESET_PLAYLIST_STATE: 'RESET_PLAYLIST_STATE',
  CREATE_PLAYLIST: {
    REQUEST: 'CREATE_PLAYLIST.REQUEST',
    SUCCESS: 'CREATE_PLAYLIST.SUCCESS',
    FAILURE: 'CREATE_PLAYLIST.FAILURE',
  },
  UPDATE_PLAYLIST: {
    REQUEST: 'UPDATE_PLAYLIST.REQUEST',
    SUCCESS: 'UPDATE_PLAYLIST.SUCCESS',
    FAILURE: 'UPDATE_PLAYLIST.FAILURE',
  },
  SYNC_PLAYLISTS: {
    REQUEST: 'SYNC_PLAYLISTS.REQUEST',
    SUCCESS: 'SYNC_PLAYLISTS.SUCCESS',
    FAILURE: 'SYNC_PLAYLISTS.FAILURE',
  },
};

export default statsTypes;
