const statsTypes = {
  RESET_DASHBOARD_STATE: 'RESET_DASHBOARD_STATE',
  SET_DASHBOARD_FILTERS: {
    REQUEST: 'SET_DASHBOARD_FILTERS.REQUEST',
    SUCCESS: 'SET_DASHBOARD_FILTERS.SUCCESS',
    FAILURE: 'SET_DASHBOARD_FILTERS.FAILURE',
  },
  SET_COUNTRY: {
    REQUEST: 'SET_COUNTRY.REQUEST',
    SUCCESS: 'SET_COUNTRY.SUCCESS',
    FAILURE: 'SET_COUNTRY.FAILURE',
  },
  FETCH_DASHBOARD_INSIGHTS: {
    REQUEST: 'FETCH_DASHBOARD_INSIGHTS.REQUEST',
    SUCCESS: 'FETCH_DASHBOARD_INSIGHTS.SUCCESS',
    FAILURE: 'FETCH_DASHBOARD_INSIGHTS.FAILURE',
  },
};

export default statsTypes;
