import React, { memo } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import {
  getInsight,
  totalMetric,
  listenThroughRate,
  percentageFirstQuartile,
  percentageMidpoint,
  percentageThirdQuartile,
  averageListeningTime as averageListeningTimeSelector,
  averageDurationTime as averageDurationTimeSelector,
} from '../../selectors/insight';

import ApexRadial from '../../components/Common/ApexRadial';
import { Spinner } from 'react-activity';
import colors from '../../components/CommonStyle/colors';
import { secondsToHms } from '../../helpers/sharedFunction';
import { useTranslation } from 'react-i18next';

const SiteInsightMetrics = ({ insightLevel, onPressMostPlayed, loading }) => {
  const insights = useSelector(getInsight(insightLevel));

  const play = useSelector(totalMetric('unique-play', insightLevel));
  const completed = useSelector(totalMetric('completed', insightLevel));
  const LTR = useSelector(listenThroughRate(insightLevel));
  const firstQuartile = useSelector(percentageFirstQuartile(insightLevel));
  const midpoint = useSelector(percentageMidpoint(insightLevel));
  const thirdQuartile = useSelector(percentageThirdQuartile(insightLevel));
  const listeningTime = useSelector(totalMetric('listeningTime', insightLevel));
  const averageListeningTime = useSelector(
    averageListeningTimeSelector(insightLevel),
  );
  const averageDurationTime = useSelector(
    averageDurationTimeSelector(insightLevel),
  );

  const { t } = useTranslation();

  return (
    <Card className="flex-fill">
      <CardBody>
        <Row>
          <Col>
            <div className="font-size-16 text-muted">
              Listen Through Rate:{' '}
              <span className="font-size-22 text-dark mr-2 font-weight-bold">
                {!loading ? (
                  `${isFinite(LTR) ? LTR.toFixed(2) : 0}%`
                ) : (
                  <Spinner
                    color={colors.dark}
                    size={16}
                    speed={1}
                    animating={true}
                  />
                )}
              </span>
            </div>
            <div className="font-size-16 text-muted mt-2">
              <span className="font-size-18 text-warning mr-2 font-weight-bold">
                {!loading ? (
                  play
                ) : (
                  <Spinner
                    color={colors.warning}
                    size={14}
                    speed={1}
                    animating={true}
                  />
                )}
              </span>{' '}
              streaming
            </div>
            <div className="font-size-16 text-muted mt-1">
              <span className="font-size-18 text-success mr-2 font-weight-bold">
                {!loading ? (
                  completed
                ) : (
                  <Spinner
                    color={colors.success}
                    size={14}
                    speed={1}
                    animating={true}
                  />
                )}
              </span>{' '}
              completed
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ApexRadial
              colors={[colors.blue]}
              labels={['First quartile']}
              series={[isFinite(firstQuartile) ? firstQuartile.toFixed(0) : 0]}
            />
          </Col>
          <Col>
            <ApexRadial
              colors={[colors.purple]}
              labels={['Midpoint']}
              series={[isFinite(midpoint) ? midpoint.toFixed(0) : 0]}
            />
          </Col>
          <Col>
            <ApexRadial
              colors={[colors.teal]}
              labels={['Third quartile']}
              series={[isFinite(thirdQuartile) ? thirdQuartile.toFixed(0) : 0]}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <div className="font-size-14 text-muted mt-2">
              {t('Total listening time')}:{' '}
              <span className="font-size-16 text-dark mr-2 font-weight-bold">
                {!loading ? (
                  `${
                    isFinite(listeningTime)
                      ? secondsToHms(listeningTime, true)
                      : 0
                  }`
                ) : (
                  <Spinner
                    color={colors.dark}
                    size={14}
                    speed={1}
                    animating={true}
                  />
                )}
              </span>
            </div>
            <div className="font-size-14 text-muted mt-2">
              {t('Average listening time per spoken articles')}:{' '}
              <span className="font-size-16 text-dark mr-2 font-weight-bold">
                {!loading ? (
                  `${
                    isFinite(averageListeningTime)
                      ? secondsToHms(averageListeningTime)
                      : 0
                  }`
                ) : (
                  <Spinner
                    color={colors.dark}
                    size={14}
                    speed={1}
                    animating={true}
                  />
                )}
              </span>
            </div>
            {averageDurationTime > 0 && (
              <div className="font-size-14 text-muted mt-2">
                {t('Spoken article average length')}:{' '}
                <span className="font-size-16 text-dark mr-2 font-weight-bold">
                  {!loading ? (
                    `${
                      isFinite(averageDurationTime)
                        ? secondsToHms(averageDurationTime)
                        : 0
                    }`
                  ) : (
                    <Spinner
                      color={colors.dark}
                      size={14}
                      speed={1}
                      animating={true}
                    />
                  )}
                </span>
              </div>
            )}
          </Col>
        </Row>
        {onPressMostPlayed && (
          <Row className="mt-3">
            <Col>
              <div className="font-size-14 text-muted mt-2">
                {t('Most listened to spoken articles')}:{' '}
                {!loading ? (
                  <button
                    className="btn btn-info btn-sm waves-effect waves-light ml-3"
                    type="button"
                    disabled={!insights.length}
                    onClick={onPressMostPlayed}>
                    <i className="bx bx bx-stats font-size-16 align-middle mr-2"></i>
                    {t('Show')}
                  </button>
                ) : (
                  <Spinner
                    color={colors.dark}
                    size={14}
                    speed={1}
                    animating={true}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default memo(SiteInsightMetrics);
