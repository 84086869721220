const statsTypes = {
  RESET_COUNTRY_STATE: 'RESET_COUNTRY_STATE',
  CREATE_COUNTRY: {
    REQUEST: 'CREATE_COUNTRY.REQUEST',
    SUCCESS: 'CREATE_COUNTRY.SUCCESS',
    FAILURE: 'CREATE_COUNTRY.FAILURE',
  },
  UPDATE_COUNTRY: {
    REQUEST: 'UPDATE_COUNTRY.REQUEST',
    SUCCESS: 'UPDATE_COUNTRY.SUCCESS',
    FAILURE: 'UPDATE_COUNTRY.FAILURE',
  },
  SYNC_COUNTRIES: {
    REQUEST: 'SYNC_COUNTRIES.REQUEST',
    SUCCESS: 'SYNC_COUNTRIES.SUCCESS',
    FAILURE: 'SYNC_COUNTRIES.FAILURE',
  },
  FETCH_COUNTRY: {
    REQUEST: 'FETCH_COUNTRY.REQUEST',
    SUCCESS: 'FETCH_COUNTRY.SUCCESS',
    FAILURE: 'FETCH_COUNTRY.FAILURE',
  },
};

export default statsTypes;
