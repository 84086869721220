import React, { memo } from 'react';
import { Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import { totalMetric } from '../../selectors/insight';
import { isAdmin as isAdminSelector } from '../../selectors/auth';

import MiniCard from '../../components/Common/MiniCard';
import { useTranslation } from 'react-i18next';

const PodcastMetrics = ({ loading, insightLevel }) => {
  const isAdmin = useSelector(isAdminSelector);

  const podcasts = useSelector(totalMetric('podcasts', insightLevel));
  const synthesized = useSelector(totalMetric('synthesized', insightLevel));
  const percentageSynthesized = (synthesized / podcasts) * 100;

  const published = useSelector(totalMetric('published', insightLevel));
  const percentagePublished = (published / podcasts) * 100;

  const errors = useSelector(totalMetric('errors', insightLevel));
  const percentageErrors = (errors / podcasts) * 100;

  const synthesisCost = useSelector(totalMetric('synthesisCost', insightLevel));

  const { t } = useTranslation();

  let podcastMetrics = [
    {
      title: t('Articles'),
      text: podcasts,
      color: 'info',
      icon: 'bx bx-headphone',
    },
    /*{
      title: t('Managed podcast'),
      text: scrapedPodcasts,
      color: 'dark',
      icon: 'bx bx-headphone',
      percentage: `${
        isFinite(percentageScrapedPodcasts)
          ? percentageScrapedPodcasts.toFixed(0)
          : 0
      }%`,
    },*/
    {
      title: t('Spoken Articles'),
      text: synthesized,
      color: 'warning',
      icon: 'bx bx-headphone',
      percentage: `${
        isFinite(percentageSynthesized) ? percentageSynthesized.toFixed(0) : 0
      }%`,
    },
    {
      title: t('Published'),
      text: published,
      color: 'success',
      icon: 'mdi mdi-upload-outline',
      percentage: `${
        isFinite(percentagePublished) ? percentagePublished.toFixed(0) : 0
      }%`,
    },
  ];

  const errorsAndCostPodcastMetric = [
    {
      title: t('Errors'),
      text: errors,
      color: 'danger',
      icon: 'bx bx-error-circle',
      percentage: `${
        isFinite(percentageErrors) ? percentageErrors.toFixed(0) : 0
      }%`,
    },
    {
      title: t('Costs'),
      text: `${isFinite(synthesisCost) ? synthesisCost.toFixed(2) : 0} €`,
      color: 'warning',
      icon: 'fas fa-euro-sign',
      isSensitiveData: true,
    },
  ];

  if (isAdmin)
    podcastMetrics = [...podcastMetrics, ...errorsAndCostPodcastMetric];

  return (
    <>
      {podcastMetrics.map((metric, index) => (
        <Col key={'podcastMetric_' + index}>
          <MiniCard {...metric} loading={loading} />
        </Col>
      ))}
    </>
  );
};

export default memo(PodcastMetrics);
