import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_NETWORK_INSIGHT_STATE,
});

export const fetchInsights = (
  startDate,
  endDate,
  filters = null,
  withPodcasts = false,
  withPodcastsLimit = 10,
) => ({
  type: types.FETCH_NETWORK_INSIGHTS.REQUEST,
  startDate,
  endDate,
  filters,
  withPodcasts,
  withPodcastsLimit,
});

export const fetchInsightsSuccess = (
  insights,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_NETWORK_INSIGHTS.SUCCESS,
  insights,

  startDate,
  endDate,
  filters,
});

export const fetchInsightsFailure = (error) => ({
  type: types.FETCH_NETWORK_INSIGHTS.FAILURE,
  error,
});

export const fetchInsightsByNetwork = (network) => ({
  type: types.FETCH_NETWORK_INSIGHTS_BY_PODCAST.REQUEST,
  network,
});

export const fetchInsightsByNetworkSuccess = (insights) => ({
  type: types.FETCH_NETWORK_INSIGHTS_BY_PODCAST.SUCCESS,
  insights,
});

export const fetchInsightsByNetworkFailure = (error) => ({
  type: types.FETCH_NETWORK_INSIGHTS_BY_PODCAST.FAILURE,
  error,
});
