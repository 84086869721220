import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SITE_STATE,
});

export const resetMessage = () => ({
  type: types.RESET_SITE_MESSAGE,
});

export const createSite = (site) => ({
  type: types.CREATE_SITE.REQUEST,
  site,
});

export const createSiteSuccess = (site) => ({
  type: types.CREATE_SITE.SUCCESS,
  site,
});

export const createSiteFailure = (error) => ({
  type: types.CREATE_SITE.FAILURE,
  error,
});

export const updateSite = (site) => ({
  type: types.UPDATE_SITE.REQUEST,
  site,
});

export const updateSiteSuccess = () => ({
  type: types.UPDATE_SITE.SUCCESS,
});

export const updateSiteFailure = (error) => ({
  type: types.UPDATE_SITE.FAILURE,
  error,
});

export const syncSites = () => ({
  type: types.SYNC_SITES.REQUEST,
});

export const syncSitesSuccess = (sites) => ({
  type: types.SYNC_SITES.SUCCESS,
  sites,
});

export const syncSitesFailure = (error) => ({
  type: types.SYNC_SITES.FAILURE,
  error,
});

export const importVast = (sites) => ({
  type: types.IMPORT_VAST.REQUEST,
  sites,
});

export const importVastSuccess = (message) => ({
  type: types.IMPORT_VAST.SUCCESS,
  message,
});

export const importVastFailure = (error) => ({
  type: types.IMPORT_VAST.FAILURE,
  error,
});

export const createAdUnits = (site) => ({
  type: types.CREATE_AD_UNITS.REQUEST,
  site,
});

export const createAdUnitsSuccess = (message) => ({
  type: types.CREATE_AD_UNITS.SUCCESS,
  message,
});

export const createAdUnitsFailure = (error) => ({
  type: types.CREATE_AD_UNITS.FAILURE,
  error,
});
