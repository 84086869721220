import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_COUNTRY_INSIGHT_STATE,
});

export const fetchInsights = (
  startDate,
  endDate,
  withPodcasts = false,
  withPodcastsLimit = 10,
) => ({
  type: types.FETCH_COUNTRY_INSIGHTS.REQUEST,
  startDate,
  endDate,
  withPodcasts,
  withPodcastsLimit,
});

export const fetchInsightsSuccess = (insights, startDate, endDate) => ({
  type: types.FETCH_COUNTRY_INSIGHTS.SUCCESS,
  insights,
  startDate,
  endDate,
});

export const fetchInsightsFailure = (error) => ({
  type: types.FETCH_COUNTRY_INSIGHTS.FAILURE,
  error,
});

export const fetchInsightsByCountry = (country) => ({
  type: types.FETCH_COUNTRY_INSIGHTS_BY_PODCAST.REQUEST,
  country,
});

export const fetchInsightsByCountrySuccess = (insights) => ({
  type: types.FETCH_COUNTRY_INSIGHTS_BY_PODCAST.SUCCESS,
  insights,
});

export const fetchInsightsByCountryFailure = (error) => ({
  type: types.FETCH_COUNTRY_INSIGHTS_BY_PODCAST.FAILURE,
  error,
});
