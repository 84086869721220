const statsTypes = {
  RESET_INSIGHT_STATE: 'RESET_INSIGHT_STATE',
  FETCH_INSIGHTS: {
    REQUEST: 'FETCH_INSIGHTS.REQUEST',
    SUCCESS: 'FETCH_INSIGHTS.SUCCESS',
    FAILURE: 'FETCH_INSIGHTS.FAILURE',
  },
  FETCH_INSIGHTS_BY_PODCAST: {
    REQUEST: 'FETCH_INSIGHTS_BY_PODCAST.REQUEST',
    SUCCESS: 'FETCH_INSIGHTS_BY_PODCAST.SUCCESS',
    FAILURE: 'FETCH_INSIGHTS_BY_PODCAST.FAILURE',
  },
  FETCH_MOST_PLAYED_PODCASTS: {
    REQUEST: 'FETCH_MOST_PLAYED_PODCASTS.REQUEST',
    SUCCESS: 'FETCH_MOST_PLAYED_PODCASTS.SUCCESS',
    FAILURE: 'FETCH_MOST_PLAYED_PODCASTS.FAILURE',
  },
};

export default statsTypes;
