import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as playlistsActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

function* createPlaylistSaga({ playlist }) {
  try {
    const playlistsRef = firebase.firestore().collection('playlists');
    const countryId = yield select((state) => state.Dashboard.countryId);
    const userId = yield select((state) => state.Auth.admin.id);

    yield call(rsf.firestore.addDocument, playlistsRef, {
      ...playlist,
      userId,
      countryId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    yield put(playlistsActions.createPlaylistSuccess(playlist));
    toastr.success('Playlist created!', '');
  } catch (error) {
    yield put(playlistsActions.createPlaylistFailure(error));
  }
}

function* updatePlaylistSaga({ playlist }) {
  try {
    const playlistsRef = firebase
      .firestore()
      .collection('playlists')
      .doc(playlist.id);
    delete playlist.id;
    delete playlist.createdAt;

    yield call(
      rsf.firestore.setDocument,
      playlistsRef,
      {
        ...playlist,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(playlistsActions.updatePlaylistSuccess());
    toastr.success('Playlist updated!', '');
  } catch (error) {
    yield put(playlistsActions.updatePlaylistFailure(error));
  }
}

const playlistTransformer = (payload) => {
  let playlists = [];

  payload.forEach((playlist) => {
    const data = playlist.data();

    playlists.push({
      id: playlist.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(playlist, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(playlist, data, 'updatedAt').toDate(),
      }),
    });
  });

  return playlists;
};

function* syncPlaylistsSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const playlistsRef = firebase
    .firestore()
    .collection('playlists')
    .where('countryId', '==', countryId)
    .orderBy('createdAt', 'desc');

  const task = yield fork(rsf.firestore.syncCollection, playlistsRef, {
    successActionCreator: playlistsActions.syncPlaylistsSuccess,
    failureActionCreator: playlistsActions.syncPlaylistsFailure,
    transform: (payload) => playlistTransformer(payload),
  });

  yield take(types.RESET_PLAYLIST_STATE);
  yield cancel(task);
}

function* playlistSaga() {
  yield all([
    takeLatest(types.SYNC_PLAYLISTS.REQUEST, syncPlaylistsSaga),
    takeLeading(types.CREATE_PLAYLIST.REQUEST, createPlaylistSaga),
    takeLatest(types.UPDATE_PLAYLIST.REQUEST, updatePlaylistSaga),
  ]);
}

export default playlistSaga;
