import React, { useState, useEffect, useMemo, memo } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';

import * as insightsActions from '../../store/campaigns/insights/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
} from '../../selectors/auth';
import {
  totalMetric,
  amountSpent as amountSpentSelector,
} from '../../selectors/campaignInsight';

import Modal from '../../components/Common/Modal/Modal';
import DateRangeFilter from '../Common/DateRangeFilter/DateRangeFilter';
import CampaignGraph from './CampaignGraph';
import CreativeInsights from './Creatives/CreativeInsights';
import MiniCard from '../../components/Common/MiniCard';
import RelatedPodcasts from '../Podcasts/RelatedPodcasts';
import colors from '../CommonStyle/colors';
import { INSIGHT_LEVEL } from '../../config/insight';
import { datePresets } from '../../config/datePresets';
import {
  GAM_DEVICE_CATEGORY_NAME_ALLOWED,
  campaignStatusColor,
} from '../../config/campaign';

import { isNumeric, getDates, capitalize } from '../../helpers/sharedFunction';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const DEFAULT_DATE_PRESETS = datePresets.find(
  ({ name }) => name === 'last7days',
);
const MAX_DATE = moment.utc();
const MIN_DATE = moment.utc('01/06/2023', 'DD/MM/YYYY');

const CampaignInsights = ({ campaign }) => {
  const {
    impressions: lifetimeImpressions,
    deliveryIndicator,
    amountSpent: lifetimeAmountSpent,
  } = campaign;

  const loading = useSelector((state) => state.CampaignInsight.loading);

  const [startDate, setStartDate] = useState(DEFAULT_DATE_PRESETS.startDate);
  const [endDate, setEndDate] = useState(DEFAULT_DATE_PRESETS.endDate);
  const [relatedPodcastsModal, setRelatedPodcastsModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState('all');

  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const showRelatedPodcastsBtn = isAdmin || isManager;
  const impressions = useSelector(
    totalMetric('impressions', INSIGHT_LEVEL.CAMPAIGN, selectedDevice),
  );
  const amountSpent = useSelector(amountSpentSelector);

  const dispatch = useDispatch();

  const refresh = ({ startDate, endDate }) => {
    const selectedStartDate = startDate.utc().startOf('day');
    const selectedEndDate = endDate.utc().endOf('day');
    dispatch(
      insightsActions.fetchInsightsByCampaign(
        campaign,
        selectedStartDate.toDate(),
        selectedEndDate.toDate(),
      ),
    );
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  };

  useEffect(() => {
    dispatch(
      insightsActions.fetchInsightsByCampaign(
        campaign,
        startDate.utc().startOf('day').toDate(),
        endDate.utc().endOf('day').toDate(),
      ),
    );
  }, [campaign, startDate, endDate]);

  const { t } = useTranslation();

  const totalCampaignMetrics = useMemo(
    () => [
      {
        title: t('Lifetime streaming'),
        text: lifetimeImpressions,
        color: 'primary',
        icon: 'bx bx-headphone',
      },
      {
        title: t('Pacing'),
        text: isNumeric(deliveryIndicator)
          ? `${Math.round(deliveryIndicator * 100)}%`
          : '-',
        color:
          deliveryIndicator <= 0.75
            ? 'danger'
            : deliveryIndicator <= 0.92
            ? 'warning'
            : 'success',
        icon: 'dripicons-graph-line',
      },
      {
        title: t('Total Spent'),
        text: `€ ${lifetimeAmountSpent ? lifetimeAmountSpent.toFixed(2) : 0}`,
        color: 'dark',
        icon: 'fas fa-euro-sign',
      },
    ],
    [lifetimeImpressions, deliveryIndicator, lifetimeAmountSpent],
  );

  const days = useMemo(
    () => getDates(startDate.toDate(), endDate.toDate()).length,
    [startDate, endDate],
  );

  return (
    <Card>
      <CardBody>
        <Row className="m-2 align-items-center">
          <h3 className="m-0" style={{ color: colors.indigo }}>
            {campaign.name}
          </h3>
          <span
            className={`badge badge-${campaignStatusColor(
              campaign.status,
            )} font-size-12 m-3`}>
            {campaign.status.toUpperCase()}
          </span>
          <span className="badge badge-success font-size-12">
            {campaign.free ? 'FREE' : 'PAID'}
          </span>
        </Row>
        <Row>
          {totalCampaignMetrics.map((metric, index) => (
            <Col key={'campaignMetric_' + index}>
              <MiniCard {...metric} loading={loading} />
            </Col>
          ))}
        </Row>
        <DateRangeFilter
          onUpdate={refresh}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          startDate={startDate}
          endDate={endDate}
          loading={loading}
        />
        <Row>
          {showRelatedPodcastsBtn && (
            <Col md="4">
              <button
                type="button"
                className="waves-effect waves-light btn btn-sm btn-warning"
                onClick={() => setRelatedPodcastsModal(true)}>
                <i className="bx bx-headphone align-middle mr-1"></i>
                {t('Related Podcasts')}
              </button>
            </Col>
          )}
          <Col md={showRelatedPodcastsBtn ? 8 : 12} className="text-right">
            <ButtonGroup size="sm">
              <Button
                color="dark"
                outline
                onClick={() => setSelectedDevice('all')}
                active={selectedDevice === 'all'}>
                All
              </Button>
              {GAM_DEVICE_CATEGORY_NAME_ALLOWED.map((deviceName) => (
                <Button
                  key={deviceName}
                  color="dark"
                  outline
                  onClick={() =>
                    setSelectedDevice(deviceName.toLocaleLowerCase())
                  }
                  active={selectedDevice === deviceName.toLocaleLowerCase()}>
                  {deviceName}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div>
                    <p className="text-muted font-weight-medium mb-3">
                      {t(`${capitalize(selectedDevice)} Streaming`)}
                    </p>
                    <h4 className="text-primary mb-1">{impressions}</h4>
                    <p className="text-muted mb-3">
                      in {days === 1 ? `${days} day` : `${days} days`}
                    </p>
                  </div>
                  <div className="ml-auto align-self-end">
                    <i className="bx bx-headphone display-4 text-light"></i>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div>
                    <p className="text-muted font-weight-medium mb-2">
                      {t('Spent')}
                    </p>
                    <h5 className="mb-2">
                      &asymp; {`€ ${amountSpent ? amountSpent.toFixed(2) : 0}`}
                    </h5>
                  </div>
                  <div className="ml-auto align-self-end">
                    <i
                      className="fas fa-euro-sign display-4 text-light"
                      style={{ fontWeight: 700 }}></i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="9" className="d-flex">
            <CampaignGraph
              dateRange={{ startDate, endDate }}
              insightLevel={INSIGHT_LEVEL.CAMPAIGN}
              device={selectedDevice}
              loading={loading}
            />
          </Col>
        </Row>
        <CreativeInsights
          campaign={campaign}
          startDate={startDate}
          endDate={endDate}
          days={days}
        />
      </CardBody>
      <Modal
        size="lg"
        title="Last 50 related spoken articles"
        isOpen={relatedPodcastsModal}
        toggle={() => setRelatedPodcastsModal(!relatedPodcastsModal)}
        showClose
        scrollable={false}>
        <RelatedPodcasts
          siteIds={campaign.siteIds}
          clusterId={campaign.clusterId}
        />
      </Modal>
    </Card>
  );
};

export default memo(CampaignInsights);
