import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as customersActions from './actions';
import * as publishersActions from '../publishers/actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

function* createCustomerSaga({ customer }) {
  try {
    const customersRef = firebase.firestore().collection('customers');
    const countryId = yield select((state) => state.Dashboard.countryId);

    const newCustomer = yield call(rsf.firestore.addDocument, customersRef, {
      ...customer,
      stripeSetup: 'new',
      countryId,
      active: true,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    if (customer.publisherId)
      yield put(
        publishersActions.updatePublisher({
          id: customer.publisherId,
          customerId: newCustomer.id,
        }),
      );
    yield put(customersActions.createCustomerSuccess(customer));
    toastr.success('Customer created!', '');
  } catch (error) {
    yield put(customersActions.createCustomerFailure(error));
  }
}

function* updateCustomerSaga({ customer, prevPublisherId }) {
  try {
    const customerRef = firebase
      .firestore()
      .collection('customers')
      .doc(customer.id);

    const { publisherId } = customer;

    if (prevPublisherId)
      yield put(
        publishersActions.updatePublisher({
          id: prevPublisherId,
          customerId: firebase.firestore.FieldValue.delete(),
        }),
      );

    if (publisherId && publisherId !== firebase.firestore.FieldValue.delete())
      yield put(
        publishersActions.updatePublisher({
          id: publisherId,
          customerId: customer.id,
        }),
      );

    delete customer.id;
    yield call(
      rsf.firestore.setDocument,
      customerRef,
      {
        ...customer,
        stripeSetup: 'new',
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(customersActions.updateCustomerSuccess(customer));
    toastr.success('Customer updated!', '');
  } catch (error) {
    yield put(customersActions.updateCustomerFailure(error));
  }
}

const customerTransformer = (payload) => {
  let customers = [];

  payload.forEach((customer) => {
    const data = customer.data();

    customers.push({
      id: customer.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(customer, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(customer, data, 'updatedAt').toDate(),
      }),
    });
  });

  return customers;
};

function* syncCustomersSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const customersRef = firebase
    .firestore()
    .collection('customers')
    .where('countryId', '==', countryId)
    .orderBy('name', 'asc');

  const task = yield fork(rsf.firestore.syncCollection, customersRef, {
    successActionCreator: customersActions.syncCustomersSuccess,
    failureActionCreator: customersActions.syncCustomersFailure,
    transform: (payload) => customerTransformer(payload),
  });

  yield take(types.RESET_CUSTOMER_STATE);
  yield cancel(task);
}

function* customerSaga() {
  yield all([
    takeLatest(types.SYNC_CUSTOMERS.REQUEST, syncCustomersSaga),
    takeLeading(types.CREATE_CUSTOMER.REQUEST, createCustomerSaga),
    takeLeading(types.UPDATE_CUSTOMER.REQUEST, updateCustomerSaga),
  ]);
}

export default customerSaga;
