import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as sitesActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import { toDateFirebase, checkField } from '../../helpers/sharedFunction';
import { MANDATORY_CSV_IMPORT_VAST_FIELDS } from '../../config/site';
import moment from 'moment';
import toastr from 'toastr';

function* createSiteSaga({ site }) {
  try {
    const sitesRef = firebase.firestore().collection('sites');
    const countryId = yield select((state) => state.Dashboard.countryId);
    const articlesPublishedSince = site.articlesPublishedSince
      ? moment(site.articlesPublishedSince).format('YYYY-MM-DD')
      : null;
    const ttsVoices = site.ttsVoices
      .filter((voice) => voice.split(',').length === 3)
      .map((voice) => ({
        synthesizerEngine: voice.split(',')[0],
        voiceType: voice.split(',')[1],
        voiceName: voice.split(',')[2],
      }));

    const duplicateRef = firebase
      .firestore()
      .collection('sites')
      .where('domain', 'in', [site.domain, `www.${site.domain}`]);
    const duplicateSnap = yield call(rsf.firestore.getDocument, duplicateRef);
    if (!duplicateSnap.empty)
      throw new Error(
        `There is already a site with this domain: ${site.domain}`,
      );

    yield call(rsf.firestore.addDocument, sitesRef, {
      ...site,
      ttsVoices,
      ...(articlesPublishedSince && {
        articlesPublishedSince: firebase.firestore.Timestamp.fromDate(
          new Date(`${articlesPublishedSince} 00:00:00`),
        ),
      }),
      countryId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    yield put(sitesActions.createSiteSuccess(site));
    toastr.success('Site created!', '');
  } catch (error) {
    yield put(sitesActions.createSiteFailure(error));
    toastr.error(error.message, 'Error');
  }
}

function* updateSiteSaga({ site }) {
  try {
    const sitesRef = firebase.firestore().collection('sites').doc(site.id);
    delete site.id;
    delete site.createdAt;

    const articlesPublishedSince = site.articlesPublishedSince
      ? firebase.firestore.Timestamp.fromDate(
          new Date(
            `${moment(site.articlesPublishedSince).format(
              'YYYY-MM-DD',
            )} 00:00:00`,
          ),
        )
      : firebase.firestore.FieldValue.delete();
    const ttsVoices = site.ttsVoices
      .filter((voice) => voice.split(',').length === 3)
      .map((voice) => ({
        synthesizerEngine: voice.split(',')[0],
        voiceType: voice.split(',')[1],
        voiceName: voice.split(',')[2],
      }));

    yield call(
      rsf.firestore.setDocument,
      sitesRef,
      {
        ...site,
        ttsVoices,
        articlesPublishedSince,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(sitesActions.updateSiteSuccess());
    toastr.success('Site updated!', '');
  } catch (error) {
    yield put(sitesActions.updateSiteFailure(error));
  }
}

const siteTransformer = (payload) => {
  let sites = [];

  payload.forEach((site) => {
    const data = site.data();
    const ttsVoices = Array.isArray(data.ttsVoices)
      ? data.ttsVoices.map(
          ({ synthesizerEngine, voiceType, voiceName }) =>
            `${synthesizerEngine},${voiceType},${voiceName}`,
        )
      : null;

    sites.push({
      id: site.id,
      ...data,
      ttsVoices,
      ...(data.articlesPublishedSince && {
        articlesPublishedSince: toDateFirebase(
          site,
          data,
          'articlesPublishedSince',
        ).toDate(),
      }),
      ...(data.createdAt && {
        createdAt: toDateFirebase(site, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(site, data, 'updatedAt').toDate(),
      }),
    });
  });

  return sites;
};

function* syncSitesSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const sitesRef = firebase
    .firestore()
    .collection('sites')
    .where('countryId', '==', countryId)
    .orderBy('createdAt', 'desc');

  const task = yield fork(rsf.firestore.syncCollection, sitesRef, {
    successActionCreator: sitesActions.syncSitesSuccess,
    failureActionCreator: sitesActions.syncSitesFailure,
    transform: (payload) => siteTransformer(payload),
  });

  yield take(types.RESET_SITE_STATE);
  yield cancel(task);
}

// Added for import vast tags in file .csv
function* importVastTagsSaga({ sites }) {
  try {
    //Validate fields
    sites = sites.map((site, index) => {
      const check = MANDATORY_CSV_IMPORT_VAST_FIELDS.every(
        ({ name, type, required }) => checkField(site[name], type, required),
      );
      if (!check)
        throw new Error(`Check CSV at row ${index + 2}. Please retry!`);
      return site;
    });

    const updateVastUrlsFunction = firebase
      .functions()
      .httpsCallable('updateVastUrls-updateVastUrls');
    const { data } = yield call(updateVastUrlsFunction, {
      sites,
    });

    if (data.error) throw new Error(data.error.message);
    yield put(sitesActions.importVastSuccess(data.message));
  } catch (error) {
    yield put(sitesActions.importVastFailure(error));
  }
}

function* createAdUnitsSaga({ site }) {
  try {
    const createAdUnitFunction = firebase
      .functions()
      .httpsCallable('createAdUnit-createAdUnit');
    const { data } = yield call(createAdUnitFunction, {
      siteId: site.id,
    });

    if (data.error) throw new Error(data.error.message);

    yield put(sitesActions.createAdUnitsSuccess(data.message));
  } catch (error) {
    yield put(sitesActions.createAdUnitsFailure(error));
  }
}

function* siteSaga() {
  yield all([
    takeLatest(types.SYNC_SITES.REQUEST, syncSitesSaga),
    takeLeading(types.CREATE_SITE.REQUEST, createSiteSaga),
    takeLatest(types.UPDATE_SITE.REQUEST, updateSiteSaga),
    takeLeading(types.IMPORT_VAST.REQUEST, importVastTagsSaga),
    takeLeading(types.CREATE_AD_UNITS.REQUEST, createAdUnitsSaga),
  ]);
}

export default siteSaga;
