import React from 'react';
import { Link } from 'react-router-dom';
import { toggleLeftMenu, changeSidebarType } from '../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { isAdvertiser as isAdvertiserSelector } from '../../selectors/auth';

import CountryBox from '../Common/CountryBox';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import CreditAmount from '../Common/PaymentStripe/CreditAmount';
import ToggleSensitiveData from '../Common/ToggleSensitiveData';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

import logo from '../../assets/images/logo-sm-light.png';
import logoLightPng from '../../assets/images/logo-light.png';
import logoDark from '../../assets/images/logo-dark.png';

const Header = (props) => {
  const countryId = useSelector((state) => state.Dashboard.countryId);
  const isAdvertiser = useSelector(isAdvertiserSelector);
  const { leftMenu, leftSideBarType } = useSelector((state) => state.Layout);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const dispatch = useDispatch();

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    dispatch(toggleLeftMenu(!leftMenu));
    if (leftSideBarType === 'default') {
      dispatch(changeSidebarType('condensed', isMobile));
    } else if (leftSideBarType === 'condensed') {
      dispatch(changeSidebarType('default', isMobile));
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="50" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {countryId && <CountryBox countryId={countryId} />}
          </div>
          <div className="d-flex">
            {countryId && isAdvertiser && <CreditAmount />}
            {!isMobile && (
              <>
                <div className="dropdown d-none d-lg-inline-block ml-1">
                  <button
                    type="button"
                    onClick={() => {
                      toggleFullscreen();
                    }}
                    className="btn header-item noti-icon waves-effect"
                    data-toggle="fullscreen">
                    <i className="bx bx-fullscreen"></i>
                  </button>
                </div>
                <LanguageDropdown />
              </>
            )}

            <ToggleSensitiveData />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
