import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexRadial = ({
  colors,
  labels,
  series,
  height = 160,
  nameFontSize = 14,
  valueFontSize = 16,
}) => {
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: `${nameFontSize}px`,
            color: void 0,
            offsetY: 60,
          },
          value: {
            offsetY: 22,
            fontSize: `${valueFontSize}px`,
            color: void 0,
            formatter: (value) => value + '%',
          },
        },
      },
    },
    colors: colors,
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: labels,
    chart: {
      fontFamily: '"Poppins", sans-serif',
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height={height}
    />
  );
};

export default ApexRadial;
