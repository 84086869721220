import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as countriesActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

function* createCountrySaga({ country }) {
  try {
    const countriesRef = firebase.firestore().collection('countries');

    yield call(rsf.firestore.addDocument, countriesRef, {
      ...country,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    yield put(countriesActions.createCountrySuccess(country));
    toastr.success('Country created!', '');
  } catch (error) {
    yield put(countriesActions.createCountryFailure(error));
  }
}

function* updateCountrySaga({ country }) {
  try {
    const countriesRef = firebase
      .firestore()
      .collection('countries')
      .doc(country.id);
    delete country.id;
    delete country.createdAt;

    yield call(
      rsf.firestore.setDocument,
      countriesRef,
      {
        ...country,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(countriesActions.updateCountrySuccess(country));
    toastr.success('Country updated!', '');
  } catch (error) {
    yield put(countriesActions.updateCountryFailure(error));
  }
}

const countryTransformer = (payload) => {
  let countries = [];

  payload.forEach((country) => {
    const data = country.data();
    countries.push({
      id: country.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(country, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(country, data, 'updatedAt').toDate(),
      }),
    });
  });

  return countries;
};

function* syncCountriesSaga() {
  const countriesRef = firebase
    .firestore()
    .collection('countries')
    .orderBy('createdAt', 'desc');

  const task = yield fork(rsf.firestore.syncCollection, countriesRef, {
    successActionCreator: countriesActions.syncCountriesSuccess,
    failureActionCreator: countriesActions.syncCountriesFailure,
    transform: (payload) => countryTransformer(payload),
  });

  yield take(types.RESET_COUNTRY_STATE);
  yield cancel(task);
}

function* countrySaga() {
  yield all([
    takeLatest(types.SYNC_COUNTRIES.REQUEST, syncCountriesSaga),
    takeLeading(types.CREATE_COUNTRY.REQUEST, createCountrySaga),
    takeLeading(types.UPDATE_COUNTRY.REQUEST, updateCountrySaga),
  ]);
}

export default countrySaga;
