const statsTypes = {
  RESET_PODCAST_STATE: 'RESET_PODCAST_STATE',
  CREATE_PODCAST: {
    REQUEST: 'CREATE_PODCAST.REQUEST',
    SUCCESS: 'CREATE_PODCAST.SUCCESS',
    FAILURE: 'CREATE_PODCAST.FAILURE',
  },
  UPDATE_PODCAST: {
    REQUEST: 'UPDATE_PODCAST.REQUEST',
    SUCCESS: 'UPDATE_PODCAST.SUCCESS',
    FAILURE: 'UPDATE_PODCAST.FAILURE',
  },
  FETCH_PODCASTS: {
    REQUEST: 'FETCH_PODCASTS.REQUEST',
    SUCCESS: 'FETCH_PODCASTS.SUCCESS',
    FAILURE: 'FETCH_PODCASTS.FAILURE',
  },
  FETCH_PODCASTS_BY_IDS: {
    REQUEST: 'FETCH_PODCASTS_BY_IDS.REQUEST',
    SUCCESS: 'FETCH_PODCASTS_BY_IDS.SUCCESS',
    FAILURE: 'FETCH_PODCASTS_BY_IDS.FAILURE',
  },
  FETCH_RELATED_PODCASTS: {
    REQUEST: 'FETCH_RELATED_PODCASTS.REQUEST',
    SUCCESS: 'FETCH_RELATED_PODCASTS.SUCCESS',
    FAILURE: 'FETCH_RELATED_PODCASTS.FAILURE',
  },
};

export default statsTypes;
