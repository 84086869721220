import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CLUSTER_STATE,
});

export const createCluster = (cluster) => ({
  type: types.CREATE_CLUSTER.REQUEST,
  cluster,
});

export const createClusterSuccess = (cluster) => ({
  type: types.CREATE_CLUSTER.SUCCESS,
  cluster,
});

export const createClusterFailure = (error) => ({
  type: types.CREATE_CLUSTER.FAILURE,
  error,
});

export const updateCluster = (cluster) => ({
  type: types.UPDATE_CLUSTER.REQUEST,
  cluster,
});

export const updateClusterSuccess = () => ({
  type: types.UPDATE_CLUSTER.SUCCESS,
});

export const updateClusterFailure = (error) => ({
  type: types.UPDATE_CLUSTER.FAILURE,
  error,
});

export const createDraftCluster = (cluster) => ({
  type: types.CREATE_DRAFT_CLUSTER.REQUEST,
  cluster,
});

export const createDraftClusterSuccess = (cluster) => ({
  type: types.CREATE_DRAFT_CLUSTER.SUCCESS,
  cluster,
});

export const createDraftClusterFailure = (error) => ({
  type: types.CREATE_DRAFT_CLUSTER.FAILURE,
  error,
});

export const updateDraftCluster = (cluster) => ({
  type: types.UPDATE_DRAFT_CLUSTER.REQUEST,
  cluster,
});

export const updateDraftClusterSuccess = (cluster) => ({
  type: types.UPDATE_DRAFT_CLUSTER.SUCCESS,
  cluster,
});

export const updateDraftClusterFailure = (error) => ({
  type: types.UPDATE_DRAFT_CLUSTER.FAILURE,
  error,
});

export const deleteDraftCluster = (cluster) => ({
  type: types.DELETE_DRAFT_CLUSTER.REQUEST,
  cluster,
});

export const deleteDraftClusterSuccess = (cluster) => ({
  type: types.DELETE_DRAFT_CLUSTER.SUCCESS,
  cluster,
});

export const deleteDraftClusterFailure = (error) => ({
  type: types.DELETE_DRAFT_CLUSTER.FAILURE,
  error,
});

export const syncClusters = () => ({
  type: types.SYNC_CLUSTERS.REQUEST,
});

export const syncClustersSuccess = (clusters) => ({
  type: types.SYNC_CLUSTERS.SUCCESS,
  clusters,
});

export const syncClustersFailure = (error) => ({
  type: types.SYNC_CLUSTERS.FAILURE,
  error,
});
