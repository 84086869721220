import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CUSTOMER_STATE,
});

export const createCustomer = (customer) => ({
  type: types.CREATE_CUSTOMER.REQUEST,
  customer,
});

export const createCustomerSuccess = (customer) => ({
  type: types.CREATE_CUSTOMER.SUCCESS,
  customer,
});

export const createCustomerFailure = (error) => ({
  type: types.CREATE_CUSTOMER.FAILURE,
  error,
});

export const updateCustomer = (customer, prevPublisherId = null) => ({
  type: types.UPDATE_CUSTOMER.REQUEST,
  customer,
  prevPublisherId,
});

export const updateCustomerSuccess = (customer) => ({
  type: types.UPDATE_CUSTOMER.SUCCESS,
  customer,
});

export const updateCustomerFailure = (error) => ({
  type: types.UPDATE_CUSTOMER.FAILURE,
  error,
});

export const syncCustomers = () => ({
  type: types.SYNC_CUSTOMERS.REQUEST,
});

export const syncCustomersSuccess = (customers) => ({
  type: types.SYNC_CUSTOMERS.SUCCESS,
  customers,
});

export const syncCustomersFailure = (error) => ({
  type: types.SYNC_CUSTOMERS.FAILURE,
  error,
});
