import React, { useState, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Collapse,
  InputGroup,
  InputGroupAddon,
  Alert,
  Spinner,
} from 'reactstrap';
import * as creativesActions from '../../../store/campaigns/creatives/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  isSuperAdmin as isSuperAdminSelector,
  hasRole,
} from '../../../selectors/auth';
import { actualCustomer } from '../../../selectors/customer';
import { selectCampaign } from '../../../selectors/campaign';
import * as campaignsActions from '../../../store/campaigns/actions';
import { Form, Input } from '../../../lib/availity/form';
import { DateRange } from '../../../lib/availity/date';
import { Select } from '../../../lib/availity/select';
import { FieldArray } from 'formik';
import FormErrorMessage from '../../../components/Common/FormErrorMessage';
import '../../../lib/availity/yup';
import FileUpload from '../../Common/FileUpload';
import SpeakUpArticlePreview from '../../Common/SpeakUpArticlePreview';
import formValidation from './formValidation';
import {
  CancelButton,
  SubmitButton,
} from '../../Common/Modal/modalActionButtons';
import roles from '../../../config/roles';
import { autoId } from '../../../helpers/sharedFunction';
import { THIRD_PARTY_TRACKING_TYPES } from '../../../config/campaign';
import axios from 'axios';
import moment from 'moment';

const FormCreative = ({
  creative,
  campaign,
  user,
  closeModal,
  loading,
  ...props
}) => {
  const editingCreative = creative || {};
  const { image, audioFile } = editingCreative;

  const [formValues, setFormValues] = useState(editingCreative);

  const [files, setFiles] = useState(null);
  const [duration, setDuration] = useState(0);
  const [errorFile, setErrorFile] = useState('');

  const [spreakerIdLoading, setSpreakerIdLoading] = useState(false);

  campaign = useSelector(selectCampaign(creative?.campaignId)) || campaign;
  const {
    startDate = moment().startOf('day'),
    endDate = moment().endOf('day'),
  } = campaign || {};

  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isManager = useSelector(
    hasRole([roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER]),
  );
  const customer = useSelector(actualCustomer);

  const creativeId = useMemo(() => creative?.id || autoId(), [creative]);

  const dispatch = useDispatch();

  useEffect(() => {
    !campaign &&
      creative &&
      dispatch(campaignsActions.fetchCampaignById(creative.campaignId));
  }, [creative]);

  const handleSubmit = (values) => {
    if (!image && !files?.image) return setErrorFile('Image file, please!');
    if (!audioFile && !files?.audioFile)
      return setErrorFile('Upload audio Mp3 file, please!');

    const { startDate, endDate } = values.dates;
    delete values.dates;

    dispatch(
      !creative
        ? creativesActions.createCreative({
            id: creativeId,
            campaignId: campaign.id,
            customerId: campaign.customerId,
            ...values,
            startDate,
            endDate,
            files,
            audioDuration: duration,
            userId: user.id,
          })
        : creativesActions.updateCreative({
            id: creativeId,
            ...values,
            startDate,
            endDate,
            ...(files && { files }),
            audioDuration: duration || values.audioDuration,
          }),
    );
    closeModal();
  };

  const handleFiles = (name, files) => {
    setFiles((state) => ({ ...state, [name]: files[0] }));
    setErrorFile('');
  };

  const handleRemoveFiles = (name) => {
    let newFiles = { ...files };
    delete newFiles[name];
    setFiles(newFiles);
  };

  const getSpreakerId = (url, setFieldValue) => {
    setSpreakerIdLoading(true);
    axios
      .post(
        `https://us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net/ws-ws/get-spreaker-id`,
        { url },
      )
      .then((response) => {
        const { episodeId } = response.data;
        setFieldValue('spreakerId', episodeId);
      })
      .catch(async (error) => {
        //const { message } = JSON.parse(await error.response.data.text());
        console.log(error);
      })
      .finally(() => {
        setSpreakerIdLoading(false);
      });
  };

  const getMegaphonePath = (url, setFieldValue) => {
    setFieldValue(
      'megaphonePath',
      url.replace('https://traffic.megaphone.fm/', ''),
    );
  };

  return (
    <Row {...props}>
      <Col md="8" style={{ borderRight: '1px solid #eff2f7' }}>
        <Form
          initialValues={{
            ...editingCreative,
            dates: {
              startDate: editingCreative.startDate
                ? new Date(editingCreative.startDate)
                : undefined,
              endDate: editingCreative.endDate
                ? new Date(editingCreative.endDate)
                : undefined,
            },
          }}
          validationSchema={formValidation(creative)}
          onSubmit={handleSubmit}>
          {({ errors, isValid, setFieldValue, values, handleChange }) => {
            const onChange = (e) => {
              const targetEl = e.target;
              const fieldName = targetEl.name;
              setFormValues({
                ...formValues,
                [fieldName]: targetEl.value,
              });
              return handleChange(e);
            };
            return (
              <>
                {editingCreative.rejectionMessage && (
                  <Alert color="danger">
                    {editingCreative.rejectionMessage}
                  </Alert>
                )}
                {editingCreative.error && (
                  <Alert color="danger">{editingCreative.error}</Alert>
                )}
                <Collapse isOpen={!!errorFile}>
                  <Alert color="danger">{errorFile}</Alert>
                </Collapse>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="name">Name</Label>
                      <Input id="name" name="name" />
                      <FormErrorMessage name="name" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="dates">Date</Label>
                      <DateRange
                        id="dates"
                        name="dates"
                        datepickerProps={{
                          displayFormat: 'DD/MM/YYYY',
                        }}
                        min={
                          creative
                            ? moment(startDate).startOf('day')
                            : moment().startOf('day')
                        }
                        max={moment(endDate).endOf('day')}
                      />
                      <FormErrorMessage name="dates" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <Input id="title" name="title" onChange={onChange} />
                      <FormErrorMessage name="title" />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="brand">Brand</Label>
                      <Input id="brand" name="brand" onChange={onChange} />
                      <FormErrorMessage name="brand" />
                    </FormGroup>
                  </Col>
                  {false && (
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="brandImage">Brand Logo</Label>
                        <FileUpload
                          name="brandImage"
                          accept={{
                            'image/*': ['.png', '.jpeg', '.jpg'],
                          }}
                          maxFiles={1}
                          handleFiles={handleFiles}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="image">Image</Label>
                      <FileUpload
                        name="image"
                        accept={{
                          'image/*': ['.png', '.jpeg', '.jpg'],
                        }}
                        maxFiles={1}
                        uploadMessage="Drop image here or click to upload."
                        handleFiles={handleFiles}
                        handleRemoveFiles={handleRemoveFiles}
                        showPreview
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="audioFile">Audio MP3</Label>
                      <FileUpload
                        name="audioFile"
                        accept={{
                          'audio/mpeg': ['.mp3'],
                        }}
                        maxFiles={1}
                        uploadMessage="Drop audio MP3 here or click to upload."
                        handleFiles={handleFiles}
                        handleRemoveFiles={handleRemoveFiles}
                        showPreview
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="link">Link</Label>
                      <Input id="link" name="link" onChange={onChange} />
                      <FormErrorMessage name="link" />
                    </FormGroup>
                  </Col>
                </Row>
                {(customer?.showSpreakerEpisodeURL || isManager) && (
                  <Row>
                    <Col md="9">
                      <FormGroup>
                        <Label htmlFor="spreakerEpisodeURL" className="mr-2">
                          Spreaker.com Episode URL
                        </Label>
                        {spreakerIdLoading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        <InputGroup>
                          <Input
                            id="spreakerEpisodeURL"
                            name="spreakerEpisodeURL"
                            onChange={onChange}
                          />
                          {isManager && (
                            <InputGroupAddon addonType="append">
                              <button
                                type="button"
                                disabled={
                                  !values.spreakerEpisodeURL ||
                                  errors.spreakerEpisodeURL
                                }
                                className="btn btn-success waves-effect waves-light mb-2 mr-2"
                                onClick={() =>
                                  getSpreakerId(
                                    values.spreakerEpisodeURL,
                                    setFieldValue,
                                  )
                                }>
                                <i className="bx bx-key mr-1"></i>
                                Get ID
                              </button>
                            </InputGroupAddon>
                          )}
                        </InputGroup>
                        <FormErrorMessage name="spreakerEpisodeURL" />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <Collapse isOpen={isManager && !!values.spreakerId}>
                        <FormGroup>
                          <Label htmlFor="spreakerId">Spreaker ID</Label>
                          <Input id="spreakerId" name="spreakerId" />
                          <FormErrorMessage name="spreakerId" />
                        </FormGroup>
                      </Collapse>
                    </Col>
                  </Row>
                )}
                {(customer?.showMegaphoneEpisodeURL || isManager) && (
                  <Row>
                    <Col md="9">
                      <FormGroup>
                        <Label htmlFor="megaphoneEpisodeURL" className="mr-2">
                          Megaphone Episode URL
                        </Label>
                        <InputGroup>
                          <Input
                            id="megaphoneEpisodeURL"
                            name="megaphoneEpisodeURL"
                            onChange={onChange}
                          />
                          {isManager && (
                            <InputGroupAddon addonType="append">
                              <button
                                type="button"
                                disabled={
                                  !values.megaphoneEpisodeURL ||
                                  errors.megaphoneEpisodeURL
                                }
                                className="btn btn-success waves-effect waves-light mb-2 mr-2"
                                onClick={() =>
                                  getMegaphonePath(
                                    values.megaphoneEpisodeURL,
                                    setFieldValue,
                                  )
                                }>
                                <i className="bx bx-key mr-1"></i>
                                Get Path
                              </button>
                            </InputGroupAddon>
                          )}
                        </InputGroup>
                        {isManager && (
                          <p className="font-size-11 text-muted">
                            {`Insert Megaphone URL like:
                          https://traffic.megaphone.fm/{ID}.mp3?updated={DATE}`}
                          </p>
                        )}
                        <FormErrorMessage name="megaphoneEpisodeURL" />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <Collapse isOpen={isManager && !!values.megaphonePath}>
                        <FormGroup>
                          <Label htmlFor="megaphonePath">Megaphone Path</Label>
                          <Input id="megaphonePath" name="megaphonePath" />
                          <FormErrorMessage name="megaphonePath" />
                        </FormGroup>
                      </Collapse>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="12" className="mt-2">
                    <FieldArray
                      name="thirdPartyTrackingURLs"
                      render={(arrayHelpers) => {
                        const { thirdPartyTrackingURLs } = values;
                        return (
                          <FormGroup>
                            <Label htmlFor="thirdPartyTrackingURLs">
                              Third-party tracking URLs
                            </Label>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline btn-light ml-4"
                              onClick={() =>
                                arrayHelpers.push({
                                  type: THIRD_PARTY_TRACKING_TYPES[0].value,
                                  trackingURL: '',
                                })
                              }>
                              <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                              Add
                            </button>
                            {Array.isArray(thirdPartyTrackingURLs) &&
                              thirdPartyTrackingURLs.map(
                                (thirdPartyTrackingURL, index) => (
                                  <Row key={index} className="mb-2">
                                    <Col md="2">
                                      <Label
                                        htmlFor={`thirdPartyTrackingURLs.${index}.type`}>
                                        Type
                                      </Label>
                                      <FormGroup>
                                        <Select
                                          name={`thirdPartyTrackingURLs.${index}.type`}
                                          id={`thirdPartyTrackingURLs.${index}.type`}
                                          options={THIRD_PARTY_TRACKING_TYPES}
                                        />
                                        <FormErrorMessage
                                          name={`thirdPartyTrackingURLs.${index}.type`}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="9">
                                      <FormGroup>
                                        <Label
                                          htmlFor={`thirdPartyTrackingURLs.${index}.trackingURL`}>
                                          URL
                                        </Label>
                                        <InputGroup>
                                          <Input
                                            type="text"
                                            name={`thirdPartyTrackingURLs.${index}.trackingURL`}
                                            id={`thirdPartyTrackingURLs.${index}.trackingURL`}
                                          />
                                          <div className="input-group-prepend">
                                            <button
                                              className="btn btn-danger p-0"
                                              style={{ width: '31px' }}
                                              type="button"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }>
                                              <i className="bx bx-trash d-block font-size-15" />
                                            </button>
                                          </div>
                                          <FormErrorMessage
                                            name={`thirdPartyTrackingURLs.${index}.trackingURL`}
                                          />
                                        </InputGroup>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                ),
                              )}
                          </FormGroup>
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Collapse
                      isOpen={
                        isSuperAdmin &&
                        !!(values.spreakerId || values.megaphonePath)
                      }>
                      <FormGroup>
                        <Label htmlFor="booster">Booster</Label>
                        <Input
                          id="booster"
                          name="booster"
                          min={0}
                          max={1}
                          type="number"
                          step="0.01"
                        />
                        <FormErrorMessage name="booster" />
                        <p className="font-size-12 text-muted">
                          From 0 to 1 (1 = 100%)
                        </p>
                      </FormGroup>
                    </Collapse>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <CancelButton disabled={loading} onClick={closeModal} />
                  <SubmitButton
                    disabled={!isValid || loading}
                    create={!creative}
                  />
                </div>
              </>
            );
          }}
        </Form>
      </Col>
      <Col md="4">
        <h5 className="pb-3 text-center">Preview</h5>
        <SpeakUpArticlePreview
          title={formValues?.title}
          brand={formValues?.brand}
          image={files?.image?.preview || image}
          link={formValues?.link}
          audio={files?.audioFile?.preview || audioFile}
          setDuration={(duration) => setDuration(duration)}
        />
      </Col>
    </Row>
  );
};

export default FormCreative;
