import types from './actionTypes';
import { addOrReplaceObjectInArray } from '../../../helpers/reducerHelper';

const initialState = {
  loading: false,
  podcastAnalyses: [],
  error: '',
};

const podcastAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PODCAST_ANALYSIS_STATE:
      return initialState;
    case types.FETCH_PODCAST_ANALYSIS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.FETCH_PODCAST_ANALYSIS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        podcastAnalyses: addOrReplaceObjectInArray(
          state.podcastAnalyses,
          action.podcastAnalysis,
        ),
      };
    case types.FETCH_PODCAST_ANALYSIS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default podcastAnalysisReducer;
