import React from 'react';
import { ViewButton } from '../Common/DataTable/tableActionButtons';
import moment from 'moment';

const createTableColumns = (openViewModal = null) => [
  {
    title: 'Created At',
    name: 'createdAt',
    formatterComponent: ({ value }) =>
      `${moment(value).format('DD/MM/YYYY HH:mm')}`,
    sort: true,
    filter: 'disabled',
  },
  {
    title: 'Reason',
    name: 'reason',
    wordWrapEnabled: true,
    filter: 'disabled',
  },
  {
    title: 'Amount',
    name: 'amount',
    formatterComponent: ({ value }) => `€ ${value}`,
    filter: 'disabled',
  },
  {
    title: 'Status',
    name: 'status',
    width: 100,
    sort: true,
    formatterComponent: ({ value }) => (
      <span className="badge badge-success font-size-12">
        {value.toUpperCase()}
      </span>
    ),
    filter: 'disabled',
  },
  {
    title: 'Action',
    name: 'action',
    width: 100,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        {openViewModal && <ViewButton row={row} onPress={openViewModal} />}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
    hidden: true,
  },
];

export default createTableColumns;
