export default {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  CUSTOMER: 'CUSTOMER',
  NETWORKER: 'NETWORKER',
  PUBLISHER: 'PUBLISHER',
  QUALITY_CHECKER: 'QUALITY_CHECKER',
  OPERATOR: 'OPERATOR',
};
