import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as publishersActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import { toDateFirebase } from '../../helpers/sharedFunction';
import toastr from 'toastr';

function* createPublisherSaga({ publisher }) {
  try {
    const publishersRef = firebase.firestore().collection('publishers');
    const countryId = yield select((state) => state.Dashboard.countryId);

    yield call(rsf.firestore.addDocument, publishersRef, {
      ...publisher,
      countryId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    yield put(publishersActions.createPublisherSuccess(publisher));
    toastr.success('Publisher created!', '');
  } catch (error) {
    yield put(publishersActions.createPublisherFailure(error));
  }
}

function* updatePublisherSaga({ publisher }) {
  try {
    const publishersRef = firebase
      .firestore()
      .collection('publishers')
      .doc(publisher.id);
    delete publisher.id;
    delete publisher.createdAt;

    yield call(
      rsf.firestore.setDocument,
      publishersRef,
      {
        ...publisher,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(publishersActions.updatePublisherSuccess());
    toastr.success('Publisher updated!', '');
  } catch (error) {
    yield put(publishersActions.updatePublisherFailure(error));
  }
}

function* updateNetworkIdSaga({ networkIds, prevNetworkIds, networkId }) {
  try {
    const linkNetworkPublishers = networkIds.filter(
      (x) => !prevNetworkIds.includes(x),
    );
    const unlinkNetworkPublishers = prevNetworkIds.filter(
      (x) => !networkIds.includes(x),
    );

    const publishersCollection = firebase.firestore().collection('publishers');

    yield all(
      linkNetworkPublishers.map((publisherId) =>
        call(
          rsf.firestore.setDocument,
          publishersCollection.doc(publisherId),
          {
            networkId,
          },
          { merge: true },
        ),
      ),
    );

    yield all(
      unlinkNetworkPublishers.map((publisherId) =>
        call(
          rsf.firestore.setDocument,
          publishersCollection.doc(publisherId),
          {
            networkId: firebase.firestore.FieldValue.delete(),
          },
          { merge: true },
        ),
      ),
    );
    yield put(publishersActions.updateNetworkIdSuccess());
    toastr.success('Publisher updated!', '');
  } catch (error) {
    yield put(publishersActions.updateNetworkIdFailure(error));
  }
}

const publisherTransformer = (payload) => {
  let publishers = [];

  payload.forEach((publisher) => {
    const data = publisher.data();

    publishers.push({
      id: publisher.id,
      ...data,
      ...(data.createdAt && {
        createdAt: toDateFirebase(publisher, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(publisher, data, 'updatedAt').toDate(),
      }),
    });
  });

  return publishers;
};

function* syncPublishersSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const publishersRef = firebase
    .firestore()
    .collection('publishers')
    .where('countryId', '==', countryId)
    .orderBy('createdAt', 'desc');

  const task = yield fork(rsf.firestore.syncCollection, publishersRef, {
    successActionCreator: publishersActions.syncPublishersSuccess,
    failureActionCreator: publishersActions.syncPublishersFailure,
    transform: (payload) => publisherTransformer(payload),
  });

  yield take(types.RESET_PUBLISHER_STATE);
  yield cancel(task);
}

function* publisherSaga() {
  yield all([
    takeLatest(types.SYNC_PUBLISHERS.REQUEST, syncPublishersSaga),
    takeLeading(types.CREATE_PUBLISHER.REQUEST, createPublisherSaga),
    takeLatest(types.UPDATE_PUBLISHER.REQUEST, updatePublisherSaga),
    takeLeading(types.UPDATE_NETWORK_ID.REQUEST, updateNetworkIdSaga),
  ]);
}

export default publisherSaga;
