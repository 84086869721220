const statsTypes = {
  RESET_CREATIVE_INSIGHT_STATE: 'RESET_CREATIVE_INSIGHT_STATE',
  FETCH_CREATIVE_INSIGHTS: {
    REQUEST: 'FETCH_CREATIVE_INSIGHTS.REQUEST',
    SUCCESS: 'FETCH_CREATIVE_INSIGHTS.SUCCESS',
    FAILURE: 'FETCH_CREATIVE_INSIGHTS.FAILURE',
  },
  FETCH_CREATIVE_INSIGHTS_BY_CREATIVE: {
    REQUEST: 'FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.REQUEST',
    SUCCESS: 'FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.SUCCESS',
    FAILURE: 'FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.FAILURE',
  },
};

export default statsTypes;
