import React from 'react';
import { Col, Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  creditAmountEmpty as creditAmountEmptySelector,
  creditAmountLow as creditAmountLowSelector,
} from '../../selectors/customer';

import { useTranslation } from 'react-i18next';

const CreditAmountAlert = (props) => {
  const creditAmountEmpty = useSelector(creditAmountEmptySelector());
  const creditAmountLow = useSelector(creditAmountLowSelector());

  const { t } = useTranslation();

  return (
    <>
      <Col xl="12">
        {creditAmountEmpty && (
          <Alert color="danger">
            <i className="bx bxs-error font-size-15 text-danger align-middle mr-1"></i>
            {t(
              'Your credit is over! Top up your credit to create new campaigns or resume them. ',
            )}
          </Alert>
        )}
        {creditAmountLow && (
          <Alert color="warning">
            <i className="bx bxs-error font-size-15 text-warning align-middle mr-1"></i>
            {t(
              'Your credit is running out! Top up your credit your campaigns will be stopped soon. ',
            )}
          </Alert>
        )}
      </Col>
    </>
  );
};

export default CreditAmountAlert;
