const statsTypes = {
  RESET_CREATIVE_STATE: 'RESET_CREATIVE_STATE',
  RESET_CREATIVE_MESSAGE: 'RESET_CREATIVE_MESSAGE',
  CREATE_CREATIVE: {
    REQUEST: 'CREATE_CREATIVE.REQUEST',
    SUCCESS: 'CREATE_CREATIVE.SUCCESS',
    FAILURE: 'CREATE_CREATIVE.FAILURE',
  },
  UPDATE_CREATIVE: {
    REQUEST: 'UPDATE_CREATIVE.REQUEST',
    DRAFT_SUCCESS: 'UPDATE_CREATIVE.DRAFT_SUCCESS',
    SUCCESS: 'UPDATE_CREATIVE.SUCCESS',
    FAILURE: 'UPDATE_CREATIVE.FAILURE',
  },
  DELETE_DRAFT_CREATIVE: {
    REQUEST: 'DELETE_DRAFT_CREATIVE.REQUEST',
    SUCCESS: 'DELETE_DRAFT_CREATIVE.SUCCESS',
    FAILURE: 'DELETE_DRAFT_CREATIVE.FAILURE',
  },
  APPROVE_CREATIVE: {
    REQUEST: 'APPROVE_CREATIVE.REQUEST',
    SUCCESS: 'APPROVE_CREATIVE.SUCCESS',
    FAILURE: 'APPROVE_CREATIVE.FAILURE',
  },
  REJECT_CREATIVE: {
    REQUEST: 'REJECT_CREATIVE.REQUEST',
    SUCCESS: 'REJECT_CREATIVE.SUCCESS',
    FAILURE: 'REJECT_CREATIVE.FAILURE',
  },
  REPROCESS_CREATIVE: {
    REQUEST: 'REPROCESS_CREATIVE.REQUEST',
    SUCCESS: 'REPROCESS_CREATIVE.SUCCESS',
    FAILURE: 'REPROCESS_CREATIVE.FAILURE',
  },
  FETCH_CREATIVES: {
    REQUEST: 'FETCH_CREATIVES.REQUEST',
    SUCCESS: 'FETCH_CREATIVES.SUCCESS',
    FAILURE: 'FETCH_CREATIVES.FAILURE',
  },
  FETCH_CREATIVES_BY_CAMPAIGN_ID: {
    REQUEST: 'FETCH_CREATIVES_BY_CAMPAIGN_ID.REQUEST',
    SUCCESS: 'FETCH_CREATIVES_BY_CAMPAIGN_ID.SUCCESS',
    FAILURE: 'FETCH_CREATIVES_BY_CAMPAIGN_ID.FAILURE',
  },
  DISCARD_DRAFT_CREATIVES: {
    REQUEST: 'DISCARD_DRAFT_CREATIVES.REQUEST',
    SUCCESS: 'DISCARD_DRAFT_CREATIVES.SUCCESS',
    FAILURE: 'DISCARD_DRAFT_CREATIVES.FAILURE',
  },
  ACTION_CREATIVE: {
    REQUEST: 'ACTION_CREATIVE.REQUEST',
    SUCCESS: 'ACTION_CREATIVE.SUCCESS',
    FAILURE: 'ACTION_CREATIVE.FAILURE',
  },
};

export default statsTypes;
