import React from 'react';
import { ViewButton } from '../Common/DataTable/tableActionButtons';
import { getLabel, getFilter } from '../Common/DataTable/tableHelpers';

const createTableColumns = (publishers, openViewModal) => [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Publisher',
    name: 'publisherId',
    width: 180,
    formatterComponent: ({ value }) =>
      getLabel(value, publishers, 'id', 'name'),
    filter: (value, filter) =>
      getFilter(getLabel(value, publishers, 'id', 'name'), filter.value),
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
