import React, { useEffect } from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
  Spinner,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter, Link } from 'react-router-dom';
import * as authActions from '../../store/auth/actions';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';
import logoDark from '../../assets/images/logo-dark.png';

const EmailVerification = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const email = queryParameters.get('email');

  const loading = useSelector((state) => state.Auth.loading);
  const error = useSelector((state) => state.Auth.error);
  const success = useSelector((state) => state.Auth.success);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetMessages());
  }, []);

  const handleResend = () => {
    email && dispatch(authActions.sendEmailVerification(email));
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logoDark}
                    alt="Audioboost Logo"
                    height="80"
                    className="auth-logo-dark mx-auto"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Please verify your email</h4>
                        <p className="pt-2">
                          You’re almost there! We sent an email to{' '}
                          <span className="font-weight-medium">
                            {email || ''}
                          </span>
                        </p>
                        <p className="pt-0">
                          Just click on the link in that email to complete your
                          signup. If you don't see it, you may need to check
                          your spam folder.
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {error && <Alert color="danger">{error}</Alert>}
                {success && <Alert color="success">{success}</Alert>}
                {!error && !success && (
                  <p>
                    Didn&apos;t receive an email ?{' '}
                    {loading ? (
                      <Spinner color="secondary" animation="border" size="sm" />
                    ) : (
                      <a
                        href="#"
                        className="font-weight-medium text-primary"
                        onClick={handleResend}>
                        Resend
                      </a>
                    )}
                  </p>
                )}
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EmailVerification);
