import './helpers/ReactotronConfig.js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';

import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/lib/integration/react';

import CenterBox from './components/Common/CenterBox';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';

const app = (
  <Provider store={store}>
    <PersistGate
      loading={
        <CenterBox height={300}>
          <Dots size={21} />
        </CenterBox>
      }
      persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
