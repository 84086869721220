import React from 'react';
import { useTranslation } from 'react-i18next';

export const SubmitButton = ({ disabled, create, label }) => {
  const icon = create ? 'bx bx-plus' : 'bx bx-pencil';
  const { t } = useTranslation();
  return (
    <button
      type="submit"
      disabled={disabled}
      className="btn btn-dark waves-effect waves-light">
      <i className={`${icon} font-size-16 align-middle mr-2`}></i>
      {label || t(create ? 'Create' : 'Save')}
    </button>
  );
};

export const NextButton = ({ disabled, type = 'submit', label, ...props }) => {
  const { t } = useTranslation();
  return (
    <button
      {...props}
      type={type}
      disabled={disabled}
      className="btn btn-primary waves-effect waves-light">
      <i className="bx bx-caret-right font-size-16 align-middle mr-2"></i>
      {t(label || 'Next')}
    </button>
  );
};

export const PrevButton = ({ disabled, label, ...props }) => {
  const { t } = useTranslation();
  return (
    <button
      {...props}
      type="button"
      disabled={disabled}
      className="btn btn-secondary waves-effect waves-light">
      <i className="bx bx-caret-left font-size-16 align-middle mr-2"></i>
      {t(label || 'Prev')}
    </button>
  );
};

export const CancelButton = (props) => {
  const { t } = useTranslation();
  return (
    <button
      {...props}
      type="button"
      className="btn btn-danger waves-effect waves-light">
      {t('Cancel')}
    </button>
  );
};

export const CloseButton = (props) => (
  <button
    {...props}
    type="button"
    className="btn btn-sm btn-secondary waves-effect waves-light">
    <i className="mdi mdi-close-thick font-size-16 align-middle"></i>
  </button>
);
