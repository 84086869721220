import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_AD_UNIT_REVENUE_STATE,
});

export const fetchRevenues = (startDate, endDate, filters = null) => ({
  type: types.FETCH_AD_UNIT_REVENUES.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchRevenuesSuccess = (
  revenues,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_AD_UNIT_REVENUES.SUCCESS,
  revenues,
  startDate,
  endDate,
  filters,
});

export const fetchRevenuesFailure = (error) => ({
  type: types.FETCH_AD_UNIT_REVENUES.FAILURE,
  error,
});

export const importSpeakerRevenues = (insights, month) => ({
  type: types.IMPORT_SPREAKER_REVENUES.REQUEST,
  insights,
  month,
});

export const importSpeakerRevenuesSuccess = (message) => ({
  type: types.IMPORT_SPREAKER_REVENUES.SUCCESS,
  message,
});

export const importSpeakerRevenuesFailure = (error) => ({
  type: types.IMPORT_SPREAKER_REVENUES.FAILURE,
  error,
});
