import {
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  select,
  delay,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as podcastsActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import toastr from 'toastr';
import { toDateFirebase, interleave } from '../../helpers/sharedFunction';
import { DELETE_SENTINEL } from '../../helpers/reducerHelper';
import cloneDeep from 'lodash.clonedeep';

const podcastTransformer = (podcast, data) => ({
  id: podcast.id,
  ...data,
  ...(data.audioFileCreatedAt && {
    audioFileCreatedAt: toDateFirebase(
      podcast,
      data,
      'audioFileCreatedAt',
    ).toDate(),
  }),
  ...(data.articlePublishedAt && {
    articlePublishedAt: toDateFirebase(
      podcast,
      data,
      'articlePublishedAt',
    ).toDate(),
  }),
  ...(data.publishedAt && {
    publishedAt: toDateFirebase(podcast, data, 'publishedAt').toDate(),
  }),
  ...(data.createdAt && {
    createdAt: toDateFirebase(podcast, data).toDate(),
  }),
  ...(data.updatedAt && {
    updatedAt: toDateFirebase(podcast, data, 'updatedAt').toDate(),
  }),
});

function* createPodcastSaga({ url, isPremium }) {
  try {
    const createAddPodcastFunction = firebase
      .functions()
      .httpsCallable('addPodcast-addPodcast');
    const { data } = yield call(createAddPodcastFunction, {
      url,
      isPremium,
    });

    if (data.error) throw new Error(data.error.message);
    yield put(podcastsActions.createPodcastSuccess());
    toastr.success('Podcast created!', '');
  } catch (error) {
    yield put(podcastsActions.createPodcastFailure(error));
    toastr.error(error.message, 'Error');
  }
}

function* updatePodcastSaga({ podcast }) {
  const podcasts = Array.isArray(podcast)
    ? cloneDeep(podcast)
    : [{ ...podcast }];
  try {
    yield all(
      podcasts.map((podcast) => {
        const podcastRef = firebase
          .firestore()
          .collection('podcasts')
          .doc(podcast.id);
        delete podcast.id;

        return call(
          rsf.firestore.setDocument,
          podcastRef,
          {
            ...podcast,
            ...(podcast.audioFileCreatedAt && {
              audioFileCreatedAt: firebase.firestore.Timestamp.fromDate(
                new Date(podcast.audioFileCreatedAt),
              ),
            }),
            ...(podcast.articlePublishedAt && {
              articlePublishedAt: firebase.firestore.Timestamp.fromDate(
                new Date(podcast.articlePublishedAt),
              ),
            }),
            ...(podcast.publishedAt && {
              publishedAt:
                podcast.publishedAt === DELETE_SENTINEL
                  ? firebase.firestore.FieldValue.delete()
                  : firebase.firestore.Timestamp.fromDate(
                      podcast.publishedAt instanceof Date
                        ? podcast.publishedAt
                        : new Date(podcast.publishedAt),
                    ),
            }),
            ...(podcast.createdAt && {
              createdAt: firebase.firestore.Timestamp.fromDate(
                new Date(podcast.createdAt),
              ),
            }),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );
      }),
    );
    yield put(podcastsActions.updatePodcastSuccess(podcast));
    podcast.length && toastr.success('Podcast updated!', '');
  } catch (error) {
    yield put(podcastsActions.updatePodcastFailure(error));
  }
}

function* fetchPodcastsSaga({
  startDate,
  endDate,
  filters,
  startAfterId,
  limit,
}) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const startAfterSnap = startAfterId
      ? yield call(rsf.firestore.getDocument, `podcasts/${startAfterId}`)
      : null;

    const podcastsRef = rsf.firestore.createCollectionRefWithFilters(
      'podcasts',
      countryId,
      startDate,
      endDate,
      filters,
      startAfterSnap,
      limit,
    );

    const podcastsSnap = yield call(rsf.firestore.getCollection, podcastsRef);
    const podcasts = podcastsSnap.docs.map((podcast) =>
      podcastTransformer(podcast, podcast.data()),
    );

    const previousPodcasts = startAfterSnap
      ? yield select((state) => state.Podcast.podcasts)
      : [];
    const loadMore = limit && podcasts.length === limit ? true : false;

    yield put(
      podcastsActions.fetchPodcastsSuccess(
        [...previousPodcasts, ...podcasts],
        startDate,
        endDate,
        filters,
        loadMore,
      ),
    );
  } catch (error) {
    yield put(podcastsActions.fetchPodcastsFailure(error));
  }
}

function* fetchPodcastsByIdsSaga({ ids }) {
  try {
    const podcastsSnaps = ids.map((id) =>
      call(
        rsf.firestore.getCollection,
        firebase.firestore().collection('podcasts').doc(id),
      ),
    );
    const delays = Array(ids.length - 1).fill(delay(50));

    //delay 50 milliseconds each podcast fetch
    const podcastsSnap = yield all(interleave(podcastsSnaps, delays));

    let podcasts = [];

    podcastsSnap.forEach((podcast, index) => {
      if (index % 2 === 0) {
        const data = podcast.data();
        podcasts.push(podcastTransformer(podcast, data));
      }
    });

    yield put(podcastsActions.fetchPodcastsByIdsSuccess(podcasts));
  } catch (error) {
    yield put(podcastsActions.fetchPodcastsByIdsFailure(error));
  }
}

function* fetchRelatedPodcastsSaga({ siteId, clusterId }) {
  try {
    let podcastRef = firebase
      .firestore()
      .collection('podcasts')
      .orderBy('articlePublishedAt', 'desc')
      .limit(50);

    if (siteId) podcastRef = podcastRef.where('siteId', '==', siteId);
    if (clusterId)
      podcastRef = podcastRef.where('clusterIds', 'array-contains-any', [
        clusterId,
      ]);

    const podcastsSnap = yield call(rsf.firestore.getCollection, podcastRef);
    const podcasts = podcastsSnap.docs.map((podcast) =>
      podcastTransformer(podcast, podcast.data()),
    );

    yield put(podcastsActions.fetchRelatedPodcastsSuccess(podcasts));
  } catch (error) {
    yield put(podcastsActions.fetchRelatedPodcastsFailure(error));
  }
}

function* podcastSaga() {
  yield all([
    takeLatest(types.FETCH_PODCASTS.REQUEST, fetchPodcastsSaga),
    takeLeading(types.CREATE_PODCAST.REQUEST, createPodcastSaga),
    takeLeading(types.UPDATE_PODCAST.REQUEST, updatePodcastSaga),
    takeLeading(types.FETCH_PODCASTS_BY_IDS.REQUEST, fetchPodcastsByIdsSaga),
    takeLeading(types.FETCH_RELATED_PODCASTS.REQUEST, fetchRelatedPodcastsSaga),
  ]);
}

export default podcastSaga;
