import { buffers, eventChannel } from 'redux-saga';
import { call, fork } from 'redux-saga/effects';

import assert from './assert';
import { syncChannel } from './utils';

import { checkEmptyObject } from '../../helpers/sharedFunction';
import moment from 'moment';

export const getCollectionRef = (rsf, pathOrRef) => {
  assert(
    !!rsf.app.firestore,
    "Firestore isn't installed. " +
      "Did you forget to `import '@firebase/firestore'`? " +
      'See https://redux-saga-firebase.js.org/ for more information.',
  );

  return typeof pathOrRef === 'string'
    ? rsf.app.firestore().collection(pathOrRef)
    : pathOrRef;
};

export const getDocumentRef = (rsf, pathOrRef) => {
  assert(
    !!rsf.app.firestore,
    "Firestore isn't installed. " +
      "Did you forget to `import '@firebase/firestore'`? " +
      'See https://redux-saga-firebase.js.org/ for more information.',
  );

  return typeof pathOrRef === 'string'
    ? rsf.app.firestore().doc(pathOrRef)
    : pathOrRef;
};

function* addDocument(collectionRef, data) {
  const collection = getCollectionRef(this, collectionRef);
  return yield call([collection, collection.add], data);
}

function channel(pathOrRef, type = 'collection', buffer = buffers.none()) {
  const ref =
    type === 'collection'
      ? getCollectionRef(this, pathOrRef)
      : getDocumentRef(this, pathOrRef);

  const channel = eventChannel((emit) => {
    const unsubscribe = ref.onSnapshot(emit);

    // Returns unsubscribe function
    return unsubscribe;
  }, buffer);

  return channel;
}

function* deleteDocument(documentRef) {
  const doc = getDocumentRef(this, documentRef);
  return yield call([doc, doc.delete]);
}

function* getCollection(collectionRef) {
  const collection = getCollectionRef(this, collectionRef);
  return yield call([collection, collection.get]);
}

function* getDocument(documentRef) {
  const doc = getDocumentRef(this, documentRef);
  return yield call([doc, doc.get]);
}

function* setDocument(documentRef, data, options) {
  const doc = getDocumentRef(this, documentRef);
  return yield call([doc, doc.set], data, options);
}

function* updateDocument(documentRef, ...args) {
  const doc = getDocumentRef(this, documentRef);
  // @ts-ignore
  return yield call([doc, doc.update], ...args);
}

function* syncCollection(pathOrRef, options) {
  const channel = yield call(this.firestore.channel, pathOrRef, 'collection');
  yield fork(syncChannel, channel, options);
}

function* syncDocument(pathOrRef, options) {
  const channel = yield call(this.firestore.channel, pathOrRef, 'document');
  yield fork(syncChannel, channel, options);
}

function createCollectionRefWithFilters(
  collectionName,
  countryId,
  startDate,
  endDate,
  filters = null,
  startAfterSnap = null,
  limit = null,
  fieldPathDate = 'createdAt',
) {
  // const start = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  // const end = new Date(new Date(endDate).setHours(23, 59, 59, 999));
  const start = moment.utc(startDate).startOf('day').toDate();
  const end = moment.utc(endDate).endOf('day').toDate();

  let query = this.app
    .firestore()
    .collection(collectionName)
    .where(fieldPathDate, '>=', start)
    .where(fieldPathDate, '<=', end)
    .where('countryId', '==', countryId)
    .orderBy(fieldPathDate, 'desc');

  if (startAfterSnap) query = query.startAfter(startAfterSnap);
  if (limit) query = query.limit(limit);

  if (!filters || checkEmptyObject(filters)) return query;

  if (filters.onlyFinalBalance)
    query = query.where('isFinalBalance', '==', true);

  if (filters.networkId)
    return query.where('networkId', '==', filters.networkId);

  if (filters.publisherId && !filters.siteIds)
    return query.where('publisherId', '==', filters.publisherId);

  return query.where('siteId', 'in', filters.siteIds);
}

export default {
  addDocument,
  channel,
  deleteDocument,
  getCollection,
  getDocument,
  setDocument,
  syncCollection,
  syncDocument,
  updateDocument,
  createCollectionRefWithFilters,
};
