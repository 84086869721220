import {
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as transactionsActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

const transactionTransformer = (transaction, data) => ({
  id: transaction.id,
  ...data,
  ...(data.createdAt && {
    createdAt: toDateFirebase(transaction, data).toDate(),
  }),
  ...(data.updatedAt && {
    updatedAt: toDateFirebase(transaction, data, 'updatedAt').toDate(),
  }),
});

function* createTransactionSaga({ transaction }) {
  try {
    const transactionRef = firebase.firestore().collection('transactions');
    const countryId = yield select((state) => state.Dashboard.countryId);

    yield call(rsf.firestore.addDocument, transactionRef, {
      ...transaction,
      countryId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    yield put(transactionsActions.createTransactionSuccess(transaction));
    toastr.success('Transaction created!', '');
  } catch (error) {
    yield put(transactionsActions.createTransactionFailure(error));
  }
}

function* updateTransactionSaga({ transaction }) {
  try {
    const transactionRef = firebase
      .firestore()
      .collection('transactions')
      .doc(transaction.id);

    yield call(
      rsf.firestore.setDocument,
      transactionRef,
      {
        ...transaction,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(transactionsActions.updateTransactionSuccess(transaction));
    toastr.success('Transaction updated!', '');
  } catch (error) {
    yield put(transactionsActions.updateTransactionFailure(error));
  }
}

function* fetchTransactionsSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const transactionsRef = rsf.firestore.createCollectionRefWithFilters(
      'transactions',
      countryId,
      startDate,
      endDate,
      filters,
    );
    const transactionsSnap = yield call(
      rsf.firestore.getCollection,
      transactionsRef,
    );
    const transactions = transactionsSnap.docs.map((snap) =>
      transactionTransformer(snap, snap.data()),
    );
    yield put(
      transactionsActions.fetchTransactionsSuccess(
        transactions,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(transactionsActions.fetchTransactionsFailure(error));
  }
}

function* fetchTransactionsByCustomerSaga({ customer }) {
  try {
    const transactionsRef = firebase
      .firestore()
      .collection('transactions')
      .where('customerId', '==', customer.id)
      .orderBy('createdAt', 'desc');

    const transactionsSnap = yield call(
      rsf.firestore.getCollection,
      transactionsRef,
    );
    const transactions = transactionsSnap.docs.map((transaction) =>
      transactionTransformer(transaction, transaction.data()),
    );

    yield put(
      transactionsActions.fetchTransactionsByCustomerSuccess(transactions),
    );
  } catch (error) {
    yield put(transactionsActions.fetchTransactionsByCustomerFailure(error));
  }
}

function* transactionSaga() {
  yield all([
    takeLatest(types.FETCH_TRANSACTIONS.REQUEST, fetchTransactionsSaga),
    takeLeading(types.CREATE_TRANSACTION.REQUEST, createTransactionSaga),
    takeLeading(types.UPDATE_TRANSACTION.REQUEST, updateTransactionSaga),
    takeLeading(
      types.FETCH_TRANSACTIONS_BY_CUSTOMER.REQUEST,
      fetchTransactionsByCustomerSaga,
    ),
  ]);
}

export default transactionSaga;
