import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Form, Input } from '../../lib/availity/form';
import '../../lib/availity/yup';
import * as yup from 'yup';

import Alert from '../Common/Modal/Alert';
import toastr from 'toastr';
import { useTranslation } from 'react-i18next';

const RejectAlert = ({ isOpen, onConfirm, onCancel, labels }) => {
  const [reason, setReason] = useState('');
  const [step, setStep] = useState(1);

  const [disabledAlertBtn, setDisabledAlertBtn] = useState(false);

  const handleSteps = () => {
    if (reason === '') return toastr.error(t('Choose a reason!'), t('Error'));

    step === 1 ? setStep(2) : handleOnConfirm();
  };

  const handleOnConfirm = () => {
    setDisabledAlertBtn(true);
    onConfirm(reason);
  };

  const handleOnCancel = () => {
    setStep(1);
    onCancel();
  };

  const { t } = useTranslation();

  return (
    <Alert
      isOpen={isOpen}
      title={t(labels[step - 1].title)}
      confirmBtnLabel={labels[step - 1].btnLabel}
      onCancel={handleOnCancel}
      onConfirm={handleSteps}
      disabledBtn={disabledAlertBtn}
      disableButtonOnConfirm={false}>
      <>
        {step === 1 && (
          <Form
            initialValues={{ reason }}
            validationSchema={yup.object().shape({
              reason: yup.string().required(),
            })}>
            <FormGroup>
              <Label htmlFor="reason">{t('Choose a reason')}:</Label>
              <Input
                name="reason"
                id="reason"
                type="textarea"
                onChange={(e) => setReason(e.target.value)}
              />
            </FormGroup>
          </Form>
        )}
        {step === 2 && <p>{reason}</p>}
      </>
    </Alert>
  );
};

export default RejectAlert;
