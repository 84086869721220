import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import { getLabels, getFilter } from '../Common/DataTable/tableHelpers';

const createTableColumns = (publishers, openViewModal, openEditModal) => [
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Publishers',
    name: 'publisherIds',
    formatterComponent: ({ value }) =>
      getLabels(value, publishers, 'id', 'name'),
    filter: (value, filter) =>
      getFilter(getLabels(value, publishers, 'id', 'name'), filter.value),
    wordWrapEnabled: true,
  },
  {
    title: 'Active',
    name: 'active',
    width: 100,
    sort: true,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
  },
  {
    title: 'Action',
    name: 'action',
    width: 140,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
        <EditButton row={row} onPress={openEditModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
