import React from 'react';
import * as layoutActions from '../../store/layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isAdmin as isAdminSelector } from '../../selectors/auth';

const ToggleSensitiveData = (props) => {
  const isAdmin = useSelector(isAdminSelector);

  const showSensitiveData = useSelector(
    (state) => state.Layout.showSensitiveData,
  );

  const dispatch = useDispatch();

  const toggleSensitiveData = () => {
    dispatch(layoutActions.toggleSensitiveData(!showSensitiveData));
  };

  return isAdmin ? (
    <div className="dropdown d-lg-inline-block">
      <button
        type="button"
        onClick={toggleSensitiveData}
        className="btn header-item noti-icon waves-effect"
        data-toggle="showSensitiveData">
        <i
          className={`${
            showSensitiveData ? 'bx bx-show' : 'bx bx-hide'
          } font-size-26`}></i>
      </button>
    </div>
  ) : null;
};

export default ToggleSensitiveData;
