import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import * as customersActions from '../../store/customers/actions';
import { useDispatch, useSelector } from 'react-redux';

import FormCustomer from '../../components/Customers/FormCustomer';
import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import createTableColumns from '../../components/Customers/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
} from '../../components/Common/DataTable/tableActionButtons';

const List = (props) => {
  const loading = useSelector((state) => state.Customer.loading);
  const customers = useSelector((state) => state.Customer.customers);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customersActions.syncCustomers());
  }, []);

  const openEditModal = (customer = null) => {
    setSelectedCustomer(customer);
    setEditModal(true);
  };

  const openViewModal = (customer) => {
    setSelectedCustomer(customer);
    setViewModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(openViewModal, openEditModal),
    [],
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Customers" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Customer"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={customers}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    className="mt-2"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Customer"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedCustomer} />
          </Modal>
          <Modal
            size="xl"
            title="Customer"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormCustomer
              closeModal={() => setEditModal(false)}
              create={!selectedCustomer}
              loading={loading}
              customer={selectedCustomer}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
