const statsTypes = {
  RESET_AD_UNIT_REVENUE_STATE: 'RESET_AD_UNIT_REVENUE_STATE',
  FETCH_AD_UNIT_REVENUES: {
    REQUEST: 'FETCH_AD_UNIT_REVENUES.REQUEST',
    SUCCESS: 'FETCH_AD_UNIT_REVENUES.SUCCESS',
    FAILURE: 'FETCH_AD_UNIT_REVENUES.FAILURE',
  },
  IMPORT_SPREAKER_REVENUES: {
    REQUEST: 'IMPORT_SPREAKER_REVENUES.REQUEST',
    SUCCESS: 'IMPORT_SPREAKER_REVENUES.SUCCESS',
    FAILURE: 'IMPORT_SPREAKER_REVENUES.FAILURE',
  },
};

export default statsTypes;
