import React, { useState, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormGroup,
  Label,
  Alert as AlertBox,
  Spinner,
} from 'reactstrap';
import { Form } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import * as yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
  isAdvertiser as isAdvertiserSelector,
  isCustomer as isCustomerSelector,
} from '../../selectors/auth';
import { selectAdvertiserUsers } from '../../selectors/user';
import { creditAmountEmpty as creditAmountEmptySelector } from '../../selectors/customer';
import {
  countCampaignsWithDrafts,
  selectCampaignsReadyToBeApproved,
} from '../../selectors/campaign';
import * as campaignsActions from '../../store/campaigns/actions';
import * as sitesActions from '../../store/sites/actions';
import * as customersActions from '../../store/customers/actions';
import * as clustersActions from '../../store/clusters/actions';
import * as usersActions from '../../store/users/actions';

import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormCampaign from '../../components/Campaigns/FormCampaign';
import PublishCampaigns from '../../components/Campaigns/PublishCampaigns';
import CampaignInsights from '../../components/Campaigns/CampaignInsights';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import CenterBox from '../../components/Common/CenterBox';
import RejectAlert from '../../components/Campaigns/RejectAlert';
import CreditAmountAlert from '../../components/Campaigns/CreditAmountAlert';
import createTableColumns from '../../components/Campaigns/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  RemoteButton,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';

import { useTranslation } from 'react-i18next';
import { CAMPAIGN_STATUS } from '../../config/campaign';
import { DEFAULT_CONFIG_TABLE } from '../../components/Common/DataTable/tableConfig';
import { capitalize, addOrRemove } from '../../helpers/sharedFunction';

const REJECT_LABELS = [
  { title: 'Do you want to reject this Campaign?', btnLabel: 'Reject' },
  { title: 'Do you confirm this reason', btnLabel: 'Confirm' },
];

const List = (props) => {
  const loading = useSelector((state) => state.Campaign.loading);
  const loadingSite = useSelector((state) => state.Site.loading);
  const loadingCluster = useSelector((state) => state.Cluster.loading);
  const loadingUser = useSelector((state) => state.User.loading);
  const isLoading = loading || loadingSite || loadingCluster || loadingUser;

  const loadMore = useSelector((state) => state.Campaign.loadMore);

  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const draftCampaigns = useSelector((state) => state.Campaign.draftCampaigns);
  const campaignsWithDrafts = useSelector(countCampaignsWithDrafts);
  const campaignsToApprove = useSelector(selectCampaignsReadyToBeApproved);
  const creditAmountEmpty = useSelector(creditAmountEmptySelector());

  const error = useSelector((state) => state.Campaign.error);
  const success = useSelector((state) => state.Campaign.success);

  const sites = useSelector((state) => state.Site.sites);
  const customers = useSelector((state) => state.Customer.customers);

  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const canApproveCampaigns = isAdmin || isManager;
  const isAdvertiser = useSelector(isAdvertiserSelector);
  const showCampaigns = isAdmin || isManager || isAdvertiser;
  const advertisers = useSelector(selectAdvertiserUsers(true));
  const userId = useSelector((state) => state.Auth.admin.id);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [discardDraftsAlert, setDiscardDraftsAlert] = useState(false);
  const [approveAlert, setApproveAlert] = useState(false);
  const [rejectAlert, setRejectAlert] = useState(false);
  const [reprocessAlert, setReprocessAlert] = useState(false);
  const [actionAlert, setActionAlert] = useState(false);
  const [publishCampaignsModal, setPublishCampaignsModal] = useState(false);
  const [campaignInsightsModal, setCampaignInsightsModal] = useState(false);
  const [selectUserAlert, setSelectUserAlert] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [actionCampaign, setActionCampaign] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  const refresh = () => {
    dispatch(
      campaignsActions.fetchCampaigns(
        false,
        null,
        DEFAULT_CONFIG_TABLE.DATA_FETCH_LIMIT,
      ),
    );
    //setSelectedPodcasts([]);
    //tableFilters.forEach((filter) => filter('')); //reset filters
  };

  const handleFetchDataTable = () => {
    const startAfterId = campaigns.length
      ? campaigns[campaigns.length - 1].id
      : null;

    dispatch(
      campaignsActions.fetchCampaigns(
        false,
        startAfterId,
        DEFAULT_CONFIG_TABLE.DATA_FETCH_LIMIT,
      ),
    );
  };

  useEffect(() => {
    if (showCampaigns) {
      dispatch(sitesActions.syncSites());
      dispatch(customersActions.syncCustomers());
      dispatch(clustersActions.syncClusters());
      dispatch(usersActions.syncUsers());
      ![...campaigns, ...draftCampaigns].length && refresh();
    }
  }, []);

  const openViewModal = (campaign) => {
    setSelectedCampaign(campaign);
    setViewModal(true);
  };

  const openEditModal = (campaign) => {
    setSelectedCampaign(campaign);
    setEditModal(true);
  };

  const openDeleteAlert = (campaign) => {
    setSelectedCampaign(campaign);
    setDeleteAlert(true);
  };

  const handleDeleteCampaign = () => {
    if (selectedCampaign.status === CAMPAIGN_STATUS.NEW)
      dispatch(campaignsActions.deleteDraftCampaign(selectedCampaign));
    setDeleteAlert(false);
  };

  const handleConfirmAlert = () => {
    setSelectUserAlert(false);
    openEditModal();
  };

  const handleDiscardDrafts = () => {
    dispatch(campaignsActions.discardDraftCampaigns());
    setDiscardDraftsAlert(false);
  };

  const openApproveAlert = (campaign) => {
    setSelectedCampaign(campaign);
    setApproveAlert(true);
  };

  const handleApproveCampaign = () => {
    if (selectedCampaign.status === CAMPAIGN_STATUS.IN_REVIEW)
      dispatch(campaignsActions.approveCampaign(selectedCampaign));
    setApproveAlert(false);
  };

  const openRejectAlert = (campaign) => {
    setSelectedCampaign(campaign);
    setRejectAlert(true);
  };

  const handleRejectCampaign = (reason) => {
    if (selectedCampaign.status === CAMPAIGN_STATUS.IN_REVIEW)
      dispatch(campaignsActions.rejectCampaign(selectedCampaign, reason));
    setRejectAlert(false);
  };

  const openReprocessAlert = (creative) => {
    setSelectedCampaign(creative);
    setReprocessAlert(true);
  };

  const handleReprocessCampaign = () => {
    if (selectedCampaign.status === CAMPAIGN_STATUS.ERROR)
      dispatch(campaignsActions.reprocessCampaign(selectedCampaign));
    setReprocessAlert(false);
  };

  const openActionAlert = (campaign, action) => {
    dispatch(campaignsActions.resetMessage());
    setSelectedCampaign(campaign);
    setActionCampaign(action);
    setActionAlert(true);
  };

  const openCampaignInsightsModal = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignInsightsModal(true);
  };

  const handleActionCampaign = () => {
    dispatch(campaignsActions.actionCampaign(selectedCampaign, actionCampaign));
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        sites,
        customers,
        openViewModal,
        openEditModal,
        openDeleteAlert,
        openApproveAlert,
        openRejectAlert,
        openReprocessAlert,
        openActionAlert,
        openCampaignInsightsModal,
        isAdmin,
        isCustomer,
        canApproveCampaigns,
        campaignsToApprove,
        creditAmountEmpty,
      ),
    [
      sites,
      customers,
      isAdmin,
      canApproveCampaigns,
      campaignsToApprove,
      creditAmountEmpty,
    ],
  );

  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Breadcrumbs
              title="Campaigns"
              onRefresh={showCampaigns ? refresh : undefined}
              breadcrumbItem="List"
              size="8"
            />
            <Col sx="4" className="text-right">
              {!!campaignsWithDrafts && (
                <>
                  <button
                    type="button"
                    disabled={isLoading}
                    className="btn btn-outline-danger waves-effect"
                    onClick={() => setDiscardDraftsAlert(true)}>
                    {isLoading ? (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                    ) : (
                      <i className="bx bx-trash font-size-16 align-middle mr-2"></i>
                    )}
                    {t('Discard Drafts')}
                  </button>
                  <button
                    type="button"
                    disabled={isLoading}
                    className="btn btn-outline-success waves-effect"
                    style={{ marginLeft: '20px' }}
                    onClick={() => setPublishCampaignsModal(true)}>
                    {isLoading ? (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                    ) : (
                      <i className="bx bx-cloud-upload font-size-16 align-middle mr-2"></i>
                    )}
                    {t('Review and Publish')}
                  </button>
                </>
              )}
            </Col>
          </Row>
          {isAdvertiser && (
            <Row>
              <CreditAmountAlert />
            </Row>
          )}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!isLoading}
                    />
                    {showCampaigns && (
                      <>
                        <CreateButton
                          label="Create new Campaign"
                          disabled={loading || creditAmountEmpty}
                          onPress={() =>
                            isAdmin ? setSelectUserAlert(true) : openEditModal()
                          }
                        />
                        <RemoteButton
                          label="Load more"
                          disabled={!loadMore || loading}
                          onPress={handleFetchDataTable}
                        />
                        <ToggleColumnButton
                          columns={columns}
                          hiddenColumns={hiddenColumns}
                          onToggleColumn={handleToggleColumn}
                        />
                      </>
                    )}
                  </Row>
                  {showCampaigns ? (
                    <DataTableNext
                      loading={loading}
                      rows={[...draftCampaigns, ...campaigns]}
                      columns={columns}
                      filters={filters}
                      onFiltersChange={setFilters}
                      sorting={sorting}
                      onSortingChange={setSorting}
                      currentPage={currentPage}
                      onCurrentPageChange={setCurrentPage}
                      hiddenColumns={hiddenColumns}
                      onHiddenColumnsChange={setHiddenColumns}
                      pageSize={pageSize}
                      onPageSizeChange={setPageSize}
                    />
                  ) : (
                    <CenterBox height={200}>
                      <div>
                        <div className="avatar-md mx-auto mb-3 mt-1">
                          <span className="avatar-title rounded-circle bg-soft bg-primary primary text-white">
                            <i className="dripicons-broadcast font-size-26"></i>
                          </span>
                        </div>
                        <h3>
                          This feature is not available. Contact Audioboost
                          Staff to enable it!
                        </h3>
                      </div>
                    </CenterBox>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Campaign"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedCampaign}
              collection="campaigns"
              icon="dripicons-broadcast"
            />
          </Modal>
          <Modal
            size="xl"
            title={
              selectedCampaign
                ? `Campaign: ${selectedCampaign.name} (${selectedCampaign.status})`
                : 'Create new Campaign'
            }
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormCampaign
              closeModal={() => setEditModal(false)}
              create={!selectedCampaign}
              loading={isLoading}
              campaign={selectedCampaign}
              userId={selectedUserId || userId}
            />
          </Modal>
          <Alert
            isOpen={selectUserAlert}
            title="Advertisers"
            confirmBtnLabel="Confirm"
            onCancel={() => setSelectUserAlert(false)}
            onConfirm={handleConfirmAlert}
            disabledBtn={!selectedUserId}>
            <Form
              initialValues={{ selectedUserId }}
              validationSchema={yup.object().shape({
                selectedUserId: yup.string().required(),
              })}>
              <FormGroup>
                <Label htmlFor="selectedUser">Select Advertiser:</Label>
                <Select
                  name="selectedUserId"
                  id="selectedUserId"
                  isMulti={false}
                  onChange={(value) => setSelectedUserId(value)}
                  value={selectedUserId}
                  options={advertisers.map((user) => ({
                    label: user.displayName,
                    value: user.id,
                  }))}
                />
              </FormGroup>
            </Form>
          </Alert>
          <Alert
            isOpen={deleteAlert}
            title="Do you want to delete this Campaign?"
            confirmBtnLabel="Delete"
            onCancel={() => setDeleteAlert(false)}
            onConfirm={handleDeleteCampaign}>
            There's no coming back!
          </Alert>
          <Alert
            isOpen={discardDraftsAlert}
            title="Do you want to discard Draft Campaign?"
            confirmBtnLabel="Discard Drafts"
            onCancel={() => setDiscardDraftsAlert(false)}
            onConfirm={handleDiscardDrafts}>
            There's no coming back!
          </Alert>
          <Alert
            isOpen={approveAlert}
            title="Do you want to approve this Campaign?"
            confirmBtnLabel="Approve"
            onCancel={() => setApproveAlert(false)}
            onConfirm={handleApproveCampaign}>
            There's no coming back!
          </Alert>
          <RejectAlert
            isOpen={rejectAlert}
            labels={REJECT_LABELS}
            onCancel={() => setRejectAlert(false)}
            onConfirm={handleRejectCampaign}
          />
          <Alert
            isOpen={reprocessAlert}
            title="Do you want to reprocess this Campaign?"
            confirmBtnLabel="Reprocess"
            onCancel={() => setReprocessAlert(false)}
            onConfirm={handleReprocessCampaign}>
            There's no coming back!
          </Alert>
          <Modal
            size="xl"
            title={t('Review and Publish')}
            isOpen={publishCampaignsModal}
            toggle={() => setPublishCampaignsModal(!publishCampaignsModal)}
            showClose
            scrollable={false}>
            <PublishCampaigns />
          </Modal>
          <Alert
            isOpen={actionAlert}
            type={error ? 'danger' : success ? 'success' : 'custom'}
            title={`Do you want to ${actionCampaign} campaign?`}
            loading={loading}
            confirmBtnLabel={capitalize(actionCampaign || 'waiting')}
            cancelBtnLabel={error || success ? 'Close' : 'Cancel'}
            onCancel={() => setActionAlert(false)}
            onConfirm={handleActionCampaign}
            disabledBtn={loading}
            disableButtonOnConfirm={true}
            dependencies={[selectedCampaign]}>
            <h5>{selectedCampaign?.name}</h5>
            {error && <AlertBox color="danger">{error}</AlertBox>}
            {success && <AlertBox color="success">{success}</AlertBox>}
          </Alert>
          <Modal
            size="xl"
            title={t('Campaign Insights')}
            isOpen={campaignInsightsModal}
            toggle={() => setCampaignInsightsModal(!campaignInsightsModal)}
            showClose
            scrollable={false}>
            <CampaignInsights campaign={selectedCampaign} />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
