import React, { useEffect } from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import * as authActions from '../../store/auth/actions';

import logotype from '../../assets/images/logo-dark.png';
import logo from '../../assets/images/logo-sm-light-square.png';

import DisclaimerFooter from '../../components/Common/DisclaimerFooter';

const Login = (props) => {
  const loading = useSelector((state) => state.Auth.loading);
  const error = useSelector((state) => state.Auth.error);
  const success = useSelector((state) => state.Auth.success);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetMessages());
  }, []);

  const handleValidSubmit = (event, values) => {
    const { email, password } = values;

    dispatch(authActions.loginWithEmail(email, password, props.history));
  };

  const handleLoginWithGoogle = () => {
    dispatch(authActions.loginWithGoogle(props.history));
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back!</h5>
                        <p>Sign in to continue to SpeakUp CMS.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logotype} alt="" className="img-fluid p-2" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
                      {error && <Alert color="danger">{error}</Alert>}
                      {success && <Alert color="success">{success}</Alert>}
                      <>
                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            disabled={loading}
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                            disabled={loading}
                          />
                        </div>
                      </>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline">
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loading}>
                          Login
                        </button>
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-outline-dark btn-block waves-effect waves-light"
                          type="button"
                          onClick={handleLoginWithGoogle}
                          disabled={loading}>
                          <i className="bx bxl-google font-size-18 align-middle mr-2"></i>
                          Login with Google
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i> Forgot your
                          password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don't have an account ?{' '}
                  <Link
                    to="register"
                    className="font-weight-medium text-primary">
                    {' '}
                    Signup now{' '}
                  </Link>{' '}
                </p>
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
