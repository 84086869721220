import React, { useState, useCallback, memo } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import tree from '../../lib/iabContentTaxonomyV2Tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';

const IABContentTaxonomySelector = ({
  selected,
  onCheck,
  disabled = false,
}) => {
  const [checked, setChecked] = useState(selected || []);
  const [expanded, setExpanded] = useState([]);

  const handleOnCheck = useCallback(
    (checked) => {
      setChecked(checked);
      onCheck(checked);
    },
    [onCheck],
  );

  return (
    <CheckboxTree
      nodes={tree}
      checkModel="all"
      checked={checked}
      expanded={expanded}
      iconsClass="fa5"
      expandOnClick
      showExpandAll
      onCheck={handleOnCheck}
      onExpand={(expanded) => setExpanded(expanded)}
      disabled={disabled}
    />
  );
};

export default memo(IABContentTaxonomySelector);
