import React, { useEffect, useState, memo } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '../../../../lib/availity/form';
import { Select } from '../../../../lib/availity/select';
import '../../../../lib/availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import { userRole, userNetworkId } from '../../../../selectors/auth';
import { selectNetworksByRole } from '../../../../selectors/network';
import * as networksActions from '../../../../store/networks/actions';
import { showAllSelect } from '../../../../config/filters';

import { useTranslation } from 'react-i18next';

const FilterNetworkBox = ({ initialFilters, onUpdate, showAllFilter }) => {
  const role = useSelector(userRole);
  const adminNetworkId = useSelector(userNetworkId);

  const [networkId, setNetworkId] = useState(initialFilters?.networkId);

  const loading = useSelector((state) => state.Network.loading);

  const selectedNetworks = useSelector(
    selectNetworksByRole(role, adminNetworkId || ''),
  );
  const networksForSelect = [
    ...(showAllFilter ? [showAllSelect] : []),
    ...selectedNetworks,
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(networksActions.syncNetworks());
  }, []);

  const handleFilters = (values) => {
    setNetworkId(values);
    onUpdate(values);
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ networkId }}
        validationSchema={yup.object().shape({
          networkId: yup.string().nullable(),
        })}
        className="d-flex">
        <Col md="12" className="d-flex align-items-center px-0">
          <h5 className="text-muted my-0 ml-5 mr-2">{t('Networks')}:</h5>
          <Select
            name="networkId"
            id="networkId"
            value={networkId}
            onChange={handleFilters}
            options={networksForSelect.map(({ id, name }) => ({
              label: t(name),
              value: id,
            }))}
            isDisabled={loading}
            closeMenuOnSelect={false}
            blurInputOnSelect={true}
          />
        </Col>
      </Form>
    </CardBody>
  );
};

export default memo(FilterNetworkBox);
