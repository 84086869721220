import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as transactionsActions from '../../store/transactions/actions';

import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Transactions/tableColumns';

const CustomerTransaction = ({ customer }) => {
  const loading = useSelector((state) => state.Transaction.loading);
  const transactions = useSelector((state) => state.Transaction.transactions);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(transactionsActions.fetchTransactionsByCustomer(customer));
  }, []);

  const columns = useMemo(() => createTableColumns(), []);

  const [hiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );

  return (
    <>
      <Card>
        <CardBody>
          <h5 className="mb-4">Transactions</h5>
          <DataTableNext
            loading={loading}
            rows={transactions}
            columns={columns}
            filters={filters}
            onFiltersChange={setFilters}
            sorting={sorting}
            onSortingChange={setSorting}
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            hiddenColumns={hiddenColumns}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default CustomerTransaction;
