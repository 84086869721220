import React from 'react';
import { Row, Col, FormGroup, Label, Card, CardBody } from 'reactstrap';
import { Form, Input } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import FormErrorMessage from '../../components/Common/FormErrorMessage';
import '../../lib/availity/yup';
import * as yup from 'yup';

import * as customersActions from '../../store/customers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitButton } from '../../components/Common/Modal/modalActionButtons';
import countryCodes from '../../lib/countryCodes.json';

const formValidation = yup.object().shape({
  companyName: yup.string().required(),
  email: yup.string().email().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  zip: yup.string().required(),
  country: yup.string().required(),
});

const CompanyInfo = ({ customer }) => {
  const loading = useSelector((state) => state.Customer.loading);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      customersActions.updateCustomer({
        id: customer.id,
        ...values,
      }),
    );
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={customer}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid }) => (
            <>
              <Row>
                <Col className="mb-2">
                  <h5>Company details</h5>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <Label htmlFor="companyName">Company Name</Label>
                    <Input type="text" name="companyName" id="companyName" />
                    <FormErrorMessage name="companyName" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <Label htmlFor="email">E-mail</Label>
                    <Input type="email" name="email" id="customerEmail" />
                    <FormErrorMessage name="email" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="taxId">Tax ID</Label>
                    <Input type="text" name="taxId" id="taxId" disabled />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <Label htmlFor="address">Address</Label>
                    <Input type="text" name="address" id="address" />
                    <FormErrorMessage name="address" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="city">City</Label>
                    <Input type="text" name="city" id="city" />
                    <FormErrorMessage name="city" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="zip">Zip Code</Label>
                    <Input type="text" name="zip" id="zip" />
                    <FormErrorMessage name="zip" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="country">Country</Label>
                    <Select
                      name="country"
                      id="country"
                      options={Object.keys(countryCodes).map((key) => ({
                        value: key,
                        label: countryCodes[key],
                      }))}
                    />
                    <FormErrorMessage name="country" />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={false} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default CompanyInfo;
