import React, { useRef, useState, memo } from 'react';
import { cdnUrl } from '../../../helpers/sharedFunction';

import './player-control.scss';
import './main-player-style.scss';
import './time-progress.scss';

import defaultImg from '../../../assets/images/default-player-image.png';

const SpeakUpArticlePreview = ({
  title,
  brand,
  image,
  link,
  audio,
  setDuration,
}) => {
  const audioPlayer = useRef();
  const [playing, setPlaying] = useState(false);

  const play = () => {
    if (!audio) return;
    setPlaying(true);
    audioPlayer.current.play();
  };

  const pause = () => {
    if (!audio) return;
    audioPlayer.current.pause();
  };

  const onPlaying = () => {
    if (audioPlayer.current.paused) setPlaying(false);
  };

  const handleLoadedMetadata = (event) => {
    setDuration && setDuration(event.currentTarget.duration);
  };

  return (
    <div className="speakup-article">
      <div className="center">
        <div id="speakup-song-image">
          <img
            alt=""
            data-amplitude-song-info="cover_art_url"
            data-amplitude-playlist="default"
            className="main-album-art speakup-fade-in"
            src={image ? cdnUrl(image) : defaultImg}
          />
        </div>
        <div className="song-meta-data">
          <span
            data-amplitude-song-info="artist"
            data-amplitude-playlist="default"
            className="song-artist speakup-fade-in promoted">
            {brand || 'BRAND'}
          </span>
          <div className="song-name-container">
            <span
              data-amplitude-song-info="name"
              data-amplitude-playlist="default"
              className="song-name">
              {title || 'TITLE'}
            </span>
            {link && (
              <a
                href={link}
                className="song-link"
                target="_blank"
                rel="noreferrer"></a>
            )}
          </div>
          <div className="song-promoted"></div>
        </div>
        <div className="time-progress speakup-fade-in">
          <div id="progress-container">
            <input
              type="range"
              className="amplitude-song-slider"
              data-amplitude-playlist="default"
            />
            <progress
              id="song-played-progress"
              className="amplitude-song-played-progress"
              data-amplitude-playlist="default"
              value="0.0026177023217255763"></progress>
            <progress
              id="song-buffered-progress"
              className="amplitude-buffered-progress"
              value="0.08569221231298073"></progress>
          </div>
          <div className="time-container">
            <span className="current-time">
              <span
                className="amplitude-current-minutes"
                data-amplitude-playlist="default">
                00
              </span>
              :
              <span
                className="amplitude-current-seconds"
                data-amplitude-playlist="default">
                00
              </span>
            </span>
          </div>
          <span className="duration">
            <span
              className="amplitude-duration-minutes"
              data-amplitude-playlist="default">
              10
            </span>
            :
            <span
              className="amplitude-duration-seconds"
              data-amplitude-playlist="default">
              00
            </span>
          </span>
        </div>
      </div>
      <div id="player-controls" className="speakup-fade-in">
        <div id="previous"></div>
        <div className="skip-seconds" id="prev-10"></div>
        <div
          id="play-pause"
          className={`amplitude-play-pause amplitude-${
            playing ? 'playing' : 'paused'
          }`}
          onClick={!playing ? play : pause}></div>
        <div className="skip-seconds" id="next-10"></div>
        <div id="next"></div>
      </div>
      {audio && (
        <audio
          ref={audioPlayer}
          src={cdnUrl(audio)}
          onTimeUpdate={onPlaying}
          preload="metadata"
          loop
          style={{ display: 'none' }}
          onLoadedMetadata={handleLoadedMetadata}
        />
      )}
    </div>
  );
};

export default memo(SpeakUpArticlePreview);
