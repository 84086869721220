const statsTypes = {
  RESET_CAMPAIGN_INSIGHT_STATE: 'RESET_CAMPAIGN_INSIGHT_STATE',
  FETCH_CAMPAIGN_INSIGHTS: {
    REQUEST: 'FETCH_CAMPAIGN_INSIGHTS.REQUEST',
    SUCCESS: 'FETCH_CAMPAIGN_INSIGHTS.SUCCESS',
    FAILURE: 'FETCH_CAMPAIGN_INSIGHTS.FAILURE',
  },
  FETCH_CAMPAIGN_INSIGHTS_BY_CAMPAIGN: {
    REQUEST: 'FETCH_CAMPAIGN_INSIGHTS_BY_CAMPAIGN.REQUEST',
    SUCCESS: 'FETCH_CAMPAIGN_INSIGHTS_BY_CAMPAIGN.SUCCESS',
    FAILURE: 'FETCH_CAMPAIGN_INSIGHTS_BY_CAMPAIGN.FAILURE',
  },
};

export default statsTypes;
