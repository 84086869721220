import {
  put,
  all,
  takeLatest,
  takeLeading,
  select,
  call,
} from 'redux-saga/effects';
import firebase from 'firebase/app';
import types from './actionTypes';
import { actualCountry } from '../../selectors/country';
import { userNetworkId } from '../../selectors/auth';
import * as dashboardActions from './actions';
import * as countryInsightsActions from '../countries/insights/actions';
import * as networkInsightsActions from '../networks/insights/actions';
import * as siteInsightsActions from '../sites/insights/actions';
import * as siteRevenuesActions from '../sites/revenues/actions';
import * as publisherInsightsActions from '../publishers/insights/actions';
import * as insightsActions from '../insights/actions';

function* setFiltersSaga({ startDate, endDate, filters }) {
  try {
    const networkId = yield select(userNetworkId);

    yield put(insightsActions.resetState());
    yield put(
      filters.publisherId === '' && networkId
        ? networkInsightsActions.fetchInsights(startDate, endDate, {
            networkId,
          })
        : filters.publisherId === ''
        ? countryInsightsActions.fetchInsights(startDate, endDate)
        : Array.isArray(filters.siteIds)
        ? siteInsightsActions.fetchInsights(startDate, endDate, filters)
        : publisherInsightsActions.fetchInsights(startDate, endDate, filters),
    );

    yield put(
      siteRevenuesActions.fetchRevenues(
        startDate,
        endDate,
        filters.publisherId === '' && networkId ? { networkId } : filters,
      ),
    );
    yield put(dashboardActions.setFiltersSuccess(startDate, endDate, filters));
  } catch (error) {
    yield put(dashboardActions.setFiltersFailure(error));
  }
}

function* setCountrySaga({ countryId }) {
  try {
    yield put(dashboardActions.setCountrySuccess(countryId));
  } catch (error) {
    yield put(dashboardActions.setCountryFailure(error));
  }
}

function* fetchInsightsSaga({ publisher }) {
  const country = yield select(actualCountry);
  let insights = null;

  try {
    const fetchInsightsFunction = firebase
      .functions()
      .httpsCallable('fetchInsights-fetchInsights');
    const { data } = yield call(fetchInsightsFunction, {
      countryId: country.id,
      publisher,
    });

    if (data.error) throw new Error(data.error.message);

    insights = data.data.insights;
    yield put(dashboardActions.fetchInsightsSuccess(insights));
  } catch (error) {
    yield put(dashboardActions.fetchInsightsFailure(error));
  }
}

function* dashboardSaga() {
  yield all([
    takeLatest(types.SET_DASHBOARD_FILTERS.REQUEST, setFiltersSaga),
    takeLatest(types.SET_COUNTRY.REQUEST, setCountrySaga),
    takeLeading(types.FETCH_DASHBOARD_INSIGHTS.REQUEST, fetchInsightsSaga),
  ]);
}

export default dashboardSaga;
