import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import logo from '../../../assets/images/logo-sm-light.png';

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);

  const admin = useSelector((state) => state.Auth.admin);

  const { t } = useTranslation();

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block">
      <DropdownToggle
        className="btn header-item waves-effect"
        id="page-header-user-dropdown"
        tag="button">
        <img
          className="rounded-circle header-profile-user"
          src={admin.photoURL || logo}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ml-2 mr-1">
          {admin.displayName}
        </span>
        <i
          className={`mdi mdi-chevron-${
            menu ? 'up' : 'down'
          } d-none d-xl-inline-block`}></i>
      </DropdownToggle>
      <DropdownMenu right>
        <Link to="/settings" className="dropdown-item">
          <i className="bx bx-cog font-size-16 align-middle mr-1"></i>
          <span>{t('Settings')}</span>
        </Link>
        <Link to="/logout" className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
          <span>{t('Logout')}</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withRouter(ProfileMenu);
