export const stringFilter = (value, filter) => {
  const toLowerCase = (value) => String(value).toLowerCase();
  return toLowerCase(value).startsWith(toLowerCase(filter.value));
};

export const getLabels = (cell, collection, key = 'id', label = 'name') =>
  Array.isArray(cell) && cell.length
    ? collection
        .filter((doc) => cell.includes(doc[key]))
        .map((doc) => doc[label])
        .join(', ')
    : '';

export const getLabel = (cell, collection, key = 'id', label = 'name') => {
  const doc = collection.find((doc) => doc[key] === cell);
  return doc ? doc[label] : '';
};

export const getFilter = (text, string) =>
  text.toLowerCase().includes(string.toLowerCase());

export const getSort = (a, b) => {
  if (a === b) return 0;
  return a < b ? -1 : 1;
};

export const roundTo = (number, precision = 2) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};
