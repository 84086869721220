import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_NETWORK_STATE,
});

export const createNetwork = (network) => ({
  type: types.CREATE_NETWORK.REQUEST,
  network,
});

export const createNetworkSuccess = (network) => ({
  type: types.CREATE_NETWORK.SUCCESS,
  network,
});

export const createNetworkFailure = (error) => ({
  type: types.CREATE_NETWORK.FAILURE,
  error,
});

export const updateNetwork = (network, prevPublisherIds) => ({
  type: types.UPDATE_NETWORK.REQUEST,
  network,
  prevPublisherIds,
});

export const updateNetworkSuccess = () => ({
  type: types.UPDATE_NETWORK.SUCCESS,
});

export const updateNetworkFailure = (error) => ({
  type: types.UPDATE_NETWORK.FAILURE,
  error,
});

export const syncNetworks = () => ({
  type: types.SYNC_NETWORKS.REQUEST,
});

export const syncNetworksSuccess = (networks) => ({
  type: types.SYNC_NETWORKS.SUCCESS,
  networks,
});

export const syncNetworksFailure = (error) => ({
  type: types.SYNC_NETWORKS.FAILURE,
  error,
});
