import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import Modal from '../Modal/Modal';
import { loadStripe } from '@stripe/stripe-js';
import { Form } from '../../../lib/availity/form';
import { Select } from '../../../lib/availity/select';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import * as customersActions from '../../../store/customers/actions';
import { actualCustomer } from '../../../selectors/customer';
import { useTranslation } from 'react-i18next';
import CheckoutForm from './CheckoutForm';

import { DEFAULT_AMOUNTS } from '../../../config/campaign';
import colors from '../../CommonStyle/colors';

const CreditAmount = (props) => {
  const customer = useSelector(actualCustomer);
  const creditAmount = customer?.creditAmount || 0;

  const location = useLocation();
  const [paymentSuccess, setPaymentSuccess] = useState(
    location.search.includes('payment_success'),
  );

  const [stripePromise, setStripePromise] = useState(null);
  const [showModal, setShowModal] = useState(paymentSuccess);
  const [amount, setAmount] = useState(200);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customersActions.syncCustomers());
  }, []);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
  }, []);

  const handleOnClosed = () => {
    window.history.replaceState(
      null,
      '',
      window.location.origin + window.location.pathname,
    );
    setPaymentSuccess(false);
  };

  const { t } = useTranslation();

  const options = {
    mode: 'payment',
    currency: 'eur',
    amount: parseFloat(amount) * 100,
    appearance: {
      theme: 'stripe',
      variables: {
        colorText: colors.text,
        colorPrimary: colors.primary,
        colorBackground: colors.white,
        colorWarningText: colors.warning,
        colorDanger: colors.danger,
        colorSuccess: colors.success,
        fontFamily: "'Noto Sans JP', sans-serif",
      },
    },
  };

  return (
    <div className="d-lg-inline-block align-self-center mr-2">
      <button
        type="button"
        className="btn btn-success waves-effect waves-light w-md"
        onClick={() => setShowModal(true)}>
        <i className="bx bx-wallet font-size-18 align-middle mr-3"></i>
        {creditAmount.toFixed(0)} €
      </button>
      <Modal
        size="lg"
        title={t(`Top-up your advertising amount`)}
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        onClosed={handleOnClosed}
        showclose="true"
        scrollable={false}>
        <Card>
          <CardBody>
            <Row>
              <Col md="6">
                <div className="d-flex">
                  <div className="mr-4">
                    <i className="mdi mdi-account-circle text-primary h1" />
                  </div>
                  <div className="flex-grow-1">
                    <div className="text-muted">
                      <h5>{customer?.name}</h5>
                      <p className="mb-1">{customer?.email}</p>
                      <p className="mb-0">Tax ID: {customer?.taxId}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <p className="text-muted mb-2">Available Credit</p>
                <h4>{creditAmount.toFixed(2)} €</h4>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <Form initialValues={{ amount }}>
                  <FormGroup>
                    <Label htmlFor="amount">Amount</Label>
                    <Select
                      name="amount"
                      id="amount"
                      options={DEFAULT_AMOUNTS.map((amount) => ({
                        value: amount,
                        label: `${amount} €`,
                      }))}
                      onChange={(value) => setAmount(value)}
                    />
                  </FormGroup>
                </Form>
              </Col>
              <Col md="2"></Col>
              <Col md="4">
                <FormGroup>
                  <Label>New Available Credit</Label>
                  <h5>{parseFloat(creditAmount + amount).toFixed(2)} €</h5>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="p-4">
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm
                    amount={amount}
                    customer={customer}
                    paymentSuccess={paymentSuccess}
                  />
                </Elements>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Modal>
    </div>
  );
};

export default CreditAmount;
