import { createSelector } from 'reselect';
import roles from '../config/roles';

export const hasRole = (roles) =>
  createSelector(
    (state) => state.Auth.admin,
    (admin) => roles.includes(admin.role),
  );

export const userRole = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role,
);

export const isSuperAdmin = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.SUPER_ADMIN,
);

export const isAdmin = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.ADMIN || admin.role === roles.SUPER_ADMIN,
);

export const isManager = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.MANAGER,
);

export const isCustomer = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.CUSTOMER,
);

export const userCustomerId = createSelector(
  (state) => state.Auth.admin,
  isCustomer,
  (admin, isCustomer) => (isCustomer ? admin.customerId : ''),
);

export const isPublisher = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.PUBLISHER,
);

export const userPublisherId = createSelector(
  (state) => state.Auth.admin,
  isPublisher,
  (admin, isPublisher) => (isPublisher ? admin.publisherId : ''),
);

export const isNetworker = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.NETWORKER,
);

export const userNetworkId = createSelector(
  (state) => state.Auth.admin,
  isNetworker,
  (admin, isNetworker) => (isNetworker ? admin.networkId : ''),
);

export const isAdvertiser = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.isAdvertiser,
);

export const isQualityChecker = createSelector(
  (state) => state.Auth.admin,
  (admin) => admin.role === roles.QUALITY_CHECKER,
);
