const statsTypes = {
  RESET_TRANSACTION_STATE: 'RESET_TRANSACTION_STATE',
  CREATE_TRANSACTION: {
    REQUEST: 'CREATE_TRANSACTION.REQUEST',
    SUCCESS: 'CREATE_TRANSACTION.SUCCESS',
    FAILURE: 'CREATE_TRANSACTION.FAILURE',
  },
  UPDATE_TRANSACTION: {
    REQUEST: 'UPDATE_TRANSACTION.REQUEST',
    SUCCESS: 'UPDATE_TRANSACTION.SUCCESS',
    FAILURE: 'UPDATE_TRANSACTION.FAILURE',
  },
  FETCH_TRANSACTIONS: {
    REQUEST: 'FETCH_TRANSACTIONS.REQUEST',
    SUCCESS: 'FETCH_TRANSACTIONS.SUCCESS',
    FAILURE: 'FETCH_TRANSACTIONS.FAILURE',
  },
  FETCH_TRANSACTIONS_BY_CUSTOMER: {
    REQUEST: 'FETCH_TRANSACTIONS_BY_CUSTOMER.REQUEST',
    SUCCESS: 'FETCH_TRANSACTIONS_BY_CUSTOMER.SUCCESS',
    FAILURE: 'FETCH_TRANSACTIONS_BY_CUSTOMER.FAILURE',
  },
};

export default statsTypes;
