import types from './actionTypes';
import initialFilters from '../../config/filters';
import { addOrReplaceObjectInArray } from '../../helpers/reducerHelper';

const initialState = {
  loading: false,
  transactions: [],
  startDate: null,
  endDate: null,
  filters: Object.assign({}, initialFilters),
  error: '',
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_TRANSACTION_STATE:
      return initialState;
    case types.CREATE_TRANSACTION.REQUEST:
    case types.UPDATE_TRANSACTION.REQUEST:
    case types.FETCH_TRANSACTIONS.REQUEST:
    case types.FETCH_TRANSACTIONS_BY_CUSTOMER.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_TRANSACTION.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.UPDATE_TRANSACTION.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        transactions: addOrReplaceObjectInArray(
          state.transactions,
          action.transaction,
          'id',
          'merge',
        ),
      };
    case types.FETCH_TRANSACTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        transactions: action.transactions,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: action.filters,
      };
    case types.FETCH_TRANSACTIONS_BY_CUSTOMER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        transactions: action.transactions,
      };
    case types.CREATE_TRANSACTION.FAILURE:
    case types.UPDATE_TRANSACTION.FAILURE:
    case types.FETCH_TRANSACTIONS.FAILURE:
    case types.FETCH_TRANSACTIONS_BY_CUSTOMER.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default transactionReducer;
