import React from 'react';
import { Row, Col, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { isLoading } from '../../selectors/dashboard';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { useTranslation } from 'react-i18next';

const QualityCheckerDashboard = (props) => {
  const loading = useSelector(isLoading);

  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs title={t('Dashboard')} loading={loading} />
      <Row>
        <Col md="4">
          <Card>
            <div className="text-center p-4 border-end">
              <div className="avatar-sm mx-auto mb-3 mt-1">
                <span className="avatar-title rounded-circle bg-soft bg-primary primary text-white font-size-16">
                  <i className="dripicons-broadcast font-size-20"></i>
                </span>
              </div>
              <h4 className="text-truncate pb-1">Spoken Article</h4>
            </div>
            <div className="text-center text-xl-start mb-2">
              <Link
                to="/podcasts/list"
                className="text-decoration-underline text-reset font-size-14">
                See all Spoken Article{' '}
                <i className="mdi mdi-arrow-right font-size-14"></i>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default QualityCheckerDashboard;
