import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_PLAYLIST_STATE,
});

export const createPlaylist = (playlist) => ({
  type: types.CREATE_PLAYLIST.REQUEST,
  playlist,
});

export const createPlaylistSuccess = (playlist) => ({
  type: types.CREATE_PLAYLIST.SUCCESS,
  playlist,
});

export const createPlaylistFailure = (error) => ({
  type: types.CREATE_PLAYLIST.FAILURE,
  error,
});

export const updatePlaylist = (playlist) => ({
  type: types.UPDATE_PLAYLIST.REQUEST,
  playlist,
});

export const updatePlaylistSuccess = () => ({
  type: types.UPDATE_PLAYLIST.SUCCESS,
});

export const updatePlaylistFailure = (error) => ({
  type: types.UPDATE_PLAYLIST.FAILURE,
  error,
});

export const syncPlaylists = () => ({
  type: types.SYNC_PLAYLISTS.REQUEST,
});

export const syncPlaylistsSuccess = (playlists) => ({
  type: types.SYNC_PLAYLISTS.SUCCESS,
  playlists,
});

export const syncPlaylistsFailure = (error) => ({
  type: types.SYNC_PLAYLISTS.FAILURE,
  error,
});
