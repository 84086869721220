import { createSelector } from 'reselect';

export const selectPodcast = (id) =>
  createSelector(
    (state) => state.Podcast.podcasts,
    (podcasts) => podcasts.find((podcast) => podcast.id === id),
  );

export const selectPodcastAnalysis = (id) =>
  createSelector(
    (state) => state.PodcastAnalysis.podcastAnalyses,
    (podcasts) => {
      if (!id) return null;
      return podcasts.find((podcast) => podcast.id === id);
    },
  );

export const countPodcasts = createSelector(
  (state) => state.Podcast.podcasts,
  (podcasts) => podcasts.length,
);

export const countScrapedPodcasts = createSelector(
  (state) => state.Podcast.podcasts,
  (podcasts) =>
    podcasts.filter((podcast) => podcast.scraped === 'completed').length,
);

export const countSynthesizedPodcasts = createSelector(
  (state) => state.Podcast.podcasts,
  (podcasts) =>
    podcasts.filter((podcast) => podcast.synthesized === 'completed').length,
);

export const countPublishedPodcasts = createSelector(
  (state) => state.Podcast.podcasts,
  (podcasts) => podcasts.filter((podcast) => podcast.published).length,
);

export const countPodcastsInError = createSelector(
  (state) => state.Podcast.podcasts,
  (podcasts) =>
    podcasts.filter((podcast) => podcast.error && podcast.error !== '').length,
);

export const totalSynthesisCosts = createSelector(
  (state) => state.Podcast.podcasts,
  (podcasts) =>
    podcasts
      .map((podcast) => podcast.synthesisCost || 0)
      .reduce((a, b) => a + b, 0),
);
