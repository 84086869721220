import {
  takeEvery,
  put,
  all,
  call,
  takeLeading,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as insightsActions from './actions';
import * as podcastsActions from '../podcasts/actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import { toDateFirebase } from '../../helpers/sharedFunction';

const insightTransformer = (insight, data) => ({
  id: insight.id,
  ...data,
  date: toDateFirebase(insight, data, 'date').toDate(),
});

function* fetchInsightsSaga({ startDate, endDate, filters, withPodcasts }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const insightsRef = rsf.firestore.createCollectionRefWithFilters(
      'insights',
      countryId,
      startDate,
      endDate,
      filters,
      null,
      null,
      'date',
    );

    const insightsSnap = yield call(rsf.firestore.getCollection, insightsRef);

    let insights = [];

    insightsSnap.forEach((insight) => {
      const data = insight.data();
      insights.push(insightTransformer(insight, data));
    });

    if (withPodcasts) {
      const podcastIds = insights.map((insight) => insight.podcastId);
      const uniquePodcastIds = [...new Set(podcastIds)];
      yield put(podcastsActions.fetchPodcastsByIds(uniquePodcastIds));
    }

    yield put(
      insightsActions.fetchInsightsSuccess(
        insights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(insightsActions.fetchInsightsFailure(error));
  }
}

function* fetchInsightsByPodcastSaga({ podcast }) {
  try {
    const insightsRef = firebase
      .firestore()
      .collection('insights')
      .where('podcastId', '==', podcast.id)
      .orderBy('date', 'asc');

    const insightsSnap = yield call(rsf.firestore.getCollection, insightsRef);

    let insights = [];

    insightsSnap.forEach((insight) => {
      const data = insight.data();
      insights.push(insightTransformer(insight, data));
    });

    yield put(insightsActions.fetchInsightsByPodcastSuccess(insights));
  } catch (error) {
    yield put(insightsActions.fetchInsightsByPodcastFailure(error));
  }
}

function* mostPlayedPodcastsSaga({ startDate, endDate, filters, limit }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);

    const mostPlayedPodcastsFunction = firebase
      .functions()
      .httpsCallable('mostPlayedPodcasts-mostPlayedPodcasts');
    const { data } = yield call(mostPlayedPodcastsFunction, {
      startDate,
      endDate,
      filters,
      limit,
      countryId,
    });

    if (data.error) throw new Error(data.error.message);
    yield put(
      insightsActions.fetchMostPlayedPodcastsSuccess(
        data.data?.playedPodcasts || [],
      ),
    );
  } catch (error) {
    yield put(insightsActions.fetchMostPlayedPodcastsFailure(error));
  }
}

function* insightSaga() {
  yield all([
    takeEvery(types.FETCH_INSIGHTS.REQUEST, fetchInsightsSaga),
    takeLeading(
      types.FETCH_INSIGHTS_BY_PODCAST.REQUEST,
      fetchInsightsByPodcastSaga,
    ),
    takeLeading(
      types.FETCH_MOST_PLAYED_PODCASTS.REQUEST,
      mostPlayedPodcastsSaga,
    ),
  ]);
}

export default insightSaga;
