import React from 'react';
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Collapse,
  Label,
  Alert,
} from 'reactstrap';
import * as campaignsActions from '../../../store/campaigns/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCampaign } from '../../../selectors/campaign';
import { creditAmountEmpty as creditAmountEmptySelector } from '../../../selectors/customer';
import { selectSeedingSitesByPublisher } from '../../../selectors/site';
import { hasRole } from '../../../selectors/auth';
import { Form, Input } from '../../../lib/availity/form';
import { Select } from '../../../lib/availity/select';
import { DateRange } from '../../../lib/availity/date';
import { START_DATE } from 'react-dates/constants';
import FormErrorMessage from '../../Common/FormErrorMessage';
import '../../../lib/availity/yup';
import formValidation from '../formValidation';
import {
  SubmitButton,
  CancelButton,
  NextButton,
} from '../../Common/Modal/modalActionButtons';
import { checkEmptyObject } from '../../../helpers/sharedFunction';
import roles from '../../../config/roles';
import { DEFAULT_PPL } from '../../../config/campaign';
import moment from 'moment';

const MAX_SELECTED_SITES = 10;

const FormCampaign = ({ campaignId, user, onNext }) => {
  const campaign = useSelector(selectCampaign(campaignId));
  const sites = useSelector(
    selectSeedingSitesByPublisher(user.publisherId, true),
  );
  const free = campaign?.free ?? (!user.publisherId ? false : undefined);
  const loading = useSelector((state) => state.Campaign.loading);

  const creditAmountEmpty = useSelector(creditAmountEmptySelector(false));
  const freeSelect = creditAmountEmpty
    ? [{ label: 'Yes', value: true }]
    : [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ];
  const isManager = useSelector(
    hasRole([roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER]),
  );

  const dispatch = useDispatch();

  const handleSubmit = (values, resetForm) => {
    const newCampaign = { ...values };
    const { startDate, endDate } = newCampaign.dates;
    delete newCampaign.dates;

    const free = newCampaign.free ?? false;
    if (free) delete newCampaign.dailyBudget;

    dispatch(
      !campaign
        ? campaignsActions.createCampaign({
            id: campaignId,
            ...newCampaign,
            startDate,
            endDate,
            user,
            free,
          })
        : campaignsActions.updateCampaign({
            id: campaignId,
            ...newCampaign,
            startDate,
            endDate,
            free,
          }),
    );
    resetForm({ values });
  };

  return (
    <>
      {!!(
        Array.isArray(campaign?.rejectionMessages) &&
        campaign?.rejectionMessages.length
      ) && (
        <Alert color="danger">
          {campaign?.rejectionMessages.map(({ id, ref, name, reason }) => (
            <li key={id}>
              {ref} "{name}": {reason}
            </li>
          ))}
        </Alert>
      )}
      <Form
        initialValues={{
          ...campaign,
          dates: {
            startDate: campaign ? new Date(campaign.startDate) : undefined,
            endDate: campaign ? new Date(campaign.endDate) : undefined,
          },
          free,
        }}
        validationSchema={formValidation(1, campaign)}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}>
        {({ isValid, values, dirty, resetForm, touched }) => (
          <>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <Input id="name" name="name" />
                  <FormErrorMessage name="name" />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="dates">Date</Label>
                  <DateRange
                    id="dates"
                    name="dates"
                    disabled={campaign?.gamLineItemId ? START_DATE : undefined}
                    datepickerProps={{
                      displayFormat: 'DD/MM/YYYY',
                    }}
                    min={campaign ? undefined : moment().startOf('day')}
                  />
                  <FormErrorMessage name="dates" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {user.publisherId && (
                <>
                  <Col md="3">
                    <FormGroup>
                      <Label htmlFor="free">Limited to own sites</Label>
                      <Select
                        name="free"
                        id="free"
                        isDisabled={!!campaign}
                        options={freeSelect}
                      />
                      <FormErrorMessage name="free" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <Collapse isOpen={values.free}>
                      <FormGroup>
                        <Label htmlFor="siteIds">Sites</Label>
                        <Select
                          name="siteIds"
                          id="siteIds"
                          isMulti
                          maxLength={MAX_SELECTED_SITES}
                          options={sites.map((site) => ({
                            label: site.name,
                            value: site.id,
                          }))}
                        />
                      </FormGroup>
                      <FormErrorMessage name="siteIds" />
                    </Collapse>
                  </Col>
                </>
              )}
              <Col md="3">
                <Collapse isOpen={values.free === false}>
                  <FormGroup>
                    <Label htmlFor="dailyBudget">Daily Budget</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      <Input
                        id="dailyBudget"
                        name="dailyBudget"
                        min={20}
                        type="number"
                        step="1"
                      />
                    </InputGroup>
                    <FormErrorMessage name="dailyBudget" />
                    <p className="font-size-12 text-muted">
                      Minimum daily budget: &euro; 20
                    </p>
                  </FormGroup>
                </Collapse>
              </Col>
              <Col md="2">
                <Collapse isOpen={isManager && values.free === false}>
                  <FormGroup>
                    <Label htmlFor="ppl">PPL</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      <Input
                        id="ppl"
                        name="ppl"
                        min={0.01}
                        max={10}
                        defaultValue={DEFAULT_PPL}
                        type="number"
                        step="0.01"
                      />
                    </InputGroup>
                    <FormErrorMessage name="ppl" />
                  </FormGroup>
                </Collapse>
              </Col>
            </Row>
            <div className="modal-footer">
              {dirty ? (
                <>
                  <SubmitButton
                    disabled={!isValid || loading}
                    create={!campaign}
                  />
                  <CancelButton
                    disabled={loading}
                    onClick={() => resetForm({ ...campaign, free })}
                  />
                </>
              ) : !checkEmptyObject(touched) || campaign ? (
                <NextButton
                  type="button"
                  disabled={!isValid || loading}
                  onClick={() => onNext(free ? 2 : 1)}
                />
              ) : null}
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default FormCampaign;
