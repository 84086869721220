import React, { useEffect } from 'react';
import { Row, Col, FormGroup, Label, Card, CardBody } from 'reactstrap';
import { Form, Input } from '../../lib/availity/form';
import FormErrorMessage from '../../components/Common/FormErrorMessage';
import '../../lib/availity/yup';
import * as yup from 'yup';

import * as usersActions from '../../store/users/actions';
import * as authActions from '../../store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../selectors/user';
import { SubmitButton } from '../../components/Common/Modal/modalActionButtons';
import CenterBox from '../../components/Common/CenterBox';
import logo from '../../assets/images/logo-sm-light.png';
import { Dots } from 'react-activity';

const userFormValidation = yup.object().shape({
  firstName: yup.string().label('first name').required(),
  lastName: yup.string().label('last name').required(),
});

const credentialFormValidation = yup.object().shape({
  currentPassword: yup.string().label('current password').required(),
  newPassword: yup.string().label('new password').required(),
  confirmPassword: yup
    .string()
    .label('confirm password')
    .required()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const UserInfo = ({ admin }) => {
  const userLoading = useSelector((state) => state.User.loading);
  const authLoading = useSelector((state) => state.Auth.loading);
  const user = useSelector(selectUser(admin.id));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.syncUsers());
  }, []);

  const handleUserSubmit = ({ firstName, lastName }) => {
    dispatch(
      usersActions.updateUser({
        id: user.id,
        firstName,
        lastName,
        displayName: `${firstName} ${lastName}`,
      }),
    );
  };

  const handleCredentialSubmit = ({
    email,
    currentPassword,
    newPassword,
    confirmPassword,
  }) => {
    newPassword === confirmPassword &&
      dispatch(authActions.passwordChange(email, currentPassword, newPassword));
  };

  return (
    <>
      <div className="d-flex mb-4">
        <div className="mr-3">
          <img
            src={admin.photoURL || logo}
            alt=""
            className="avatar-md rounded-circle img-thumbnail"
          />
        </div>
        <div className="align-self-center">
          <div className="text-muted">
            <h5>{admin.displayName}</h5>
            <p className="mb-1">{admin.email}</p>
          </div>
        </div>
        <div className="align-self-center ml-4">
          <span className="badge badge-secondary font-size-12">
            {admin.role}
          </span>
        </div>
      </div>
      <Card>
        <CardBody>
          {userLoading ? (
            <CenterBox height={100}>
              <Dots size={21} />
            </CenterBox>
          ) : (
            <Form
              initialValues={user || {}}
              validationSchema={userFormValidation}
              onSubmit={handleUserSubmit}>
              {({ isValid, dirty }) => (
                <>
                  <Row>
                    <Col className="mb-2">
                      <h5>User information</h5>
                    </Col>
                  </Row>
                  <Row className="align-content-end">
                    <Col md="4">
                      <FormGroup className="mb-0">
                        <Label htmlFor="firstName">First Name</Label>
                        <Input type="text" name="firstName" id="firstName" />
                        <FormErrorMessage name="firstName" />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className="mb-0">
                        <Label htmlFor="lastName">Last Name</Label>
                        <Input type="text" name="lastName" id="lastName" />
                        <FormErrorMessage name="lastName" />
                      </FormGroup>
                    </Col>
                    <Col style={{ paddingTop: '1.7rem' }}>
                      <SubmitButton
                        disabled={!isValid || !dirty || userLoading}
                        create={false}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {authLoading ? (
            <CenterBox height={100}>
              <Dots size={21} />
            </CenterBox>
          ) : (
            <Form
              initialValues={{ email: admin.email }}
              validationSchema={credentialFormValidation}
              onSubmit={handleCredentialSubmit}>
              {({ isValid, dirty }) => (
                <>
                  <Row>
                    <Col className="mb-2">
                      <h5>Update password</h5>
                    </Col>
                  </Row>
                  <Row className="align-content-end">
                    <Col md="3">
                      <FormGroup className="mb-0">
                        <Label htmlFor="email">Email</Label>
                        <Input type="text" name="email" id="email" disabled />
                      </FormGroup>
                    </Col>
                    {admin.provider === 'email' ? (
                      <>
                        <Col md="2">
                          <FormGroup className="mb-0">
                            <Label htmlFor="currentPassword">
                              Current password
                            </Label>
                            <Input
                              type="password"
                              name="currentPassword"
                              id="currentPassword"
                            />
                            <FormErrorMessage name="password" />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className="mb-0">
                            <Label htmlFor="newPassword">New password</Label>
                            <Input
                              type="password"
                              name="newPassword"
                              id="newPassword"
                            />
                            <FormErrorMessage name="password" />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className="mb-0">
                            <Label htmlFor="confirmPassword">
                              Confirm password
                            </Label>
                            <Input
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                            />
                            <FormErrorMessage name="confirmPassword" />
                          </FormGroup>
                        </Col>
                        <Col style={{ paddingTop: '1.7rem' }}>
                          <SubmitButton
                            disabled={!isValid || !dirty || authLoading}
                            create={false}
                          />
                        </Col>
                      </>
                    ) : (
                      <Col className="align-self-end">
                        <p className="text-muted">
                          You are connected with your {admin.provider} account.
                        </p>
                      </Col>
                    )}
                  </Row>
                </>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default UserInfo;
