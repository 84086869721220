import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_INSIGHT_STATE,
});

export const fetchInsights = (
  startDate,
  endDate,
  filters = null,
  withPodcasts = false,
) => ({
  type: types.FETCH_INSIGHTS.REQUEST,
  startDate,
  endDate,
  filters,
  withPodcasts,
});

export const fetchInsightsSuccess = (
  insights,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_INSIGHTS.SUCCESS,
  insights,
  startDate,
  endDate,
  filters,
});

export const fetchInsightsFailure = (error) => ({
  type: types.FETCH_INSIGHTS.FAILURE,
  error,
});

export const fetchInsightsByPodcast = (podcast) => ({
  type: types.FETCH_INSIGHTS_BY_PODCAST.REQUEST,
  podcast,
});

export const fetchInsightsByPodcastSuccess = (insights) => ({
  type: types.FETCH_INSIGHTS_BY_PODCAST.SUCCESS,
  insights,
});

export const fetchInsightsByPodcastFailure = (error) => ({
  type: types.FETCH_INSIGHTS_BY_PODCAST.FAILURE,
  error,
});

export const fetchMostPlayedPodcasts = (
  startDate,
  endDate,
  filters,
  limit,
) => ({
  type: types.FETCH_MOST_PLAYED_PODCASTS.REQUEST,
  startDate,
  endDate,
  filters,
  limit,
});

export const fetchMostPlayedPodcastsSuccess = (playedPodcasts) => ({
  type: types.FETCH_MOST_PLAYED_PODCASTS.SUCCESS,
  playedPodcasts,
});

export const fetchMostPlayedPodcastsFailure = (error) => ({
  type: types.FETCH_MOST_PLAYED_PODCASTS.FAILURE,
  error,
});
