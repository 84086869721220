import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SITE_INSIGHT_STATE,
});

export const fetchInsights = (
  startDate,
  endDate,
  filters = null,
  withPodcasts = false,
  withPodcastsLimit = 10,
) => ({
  type: types.FETCH_SITE_INSIGHTS.REQUEST,
  startDate,
  endDate,
  filters,
  withPodcasts,
  withPodcastsLimit,
});

export const fetchInsightsSuccess = (
  insights,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_SITE_INSIGHTS.SUCCESS,
  insights,
  startDate,
  endDate,
  filters,
});

export const fetchInsightsFailure = (error) => ({
  type: types.FETCH_SITE_INSIGHTS.FAILURE,
  error,
});

export const fetchInsightsBySite = (site) => ({
  type: types.FETCH_SITE_INSIGHTS_BY_PODCAST.REQUEST,
  site,
});

export const fetchInsightsBySiteSuccess = (insights) => ({
  type: types.FETCH_SITE_INSIGHTS_BY_PODCAST.SUCCESS,
  insights,
});

export const fetchInsightsBySiteFailure = (error) => ({
  type: types.FETCH_SITE_INSIGHTS_BY_PODCAST.FAILURE,
  error,
});
