import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import * as insightsActions from '../../store/insights/actions';
import { useDispatch } from 'react-redux';
import InsightMetrics from './InsightMetrics';

import colors from '../CommonStyle/colors';
import { INSIGHT_LEVEL } from '../../config/insight';

const PodcastInsights = ({ podcast }) => {
  const { title } = podcast;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(insightsActions.fetchInsightsByPodcast(podcast));
  }, []);

  return (
    <Card>
      <CardBody>
        <Row className="text-center m-2">
          <h2 style={{ color: colors.indigo }}>{title}</h2>
        </Row>
        <Row>
          <Col md="12">
            <InsightMetrics
              podcast={podcast}
              insightLevel={INSIGHT_LEVEL.PODCAST}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PodcastInsights;
