import React, { useState } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import * as clustersActions from '../../../store/clusters/actions';
import { useDispatch } from 'react-redux';
import { Form, Input } from '../../../lib/availity/form';
import { Select } from '../../../lib/availity/select';
import FormErrorMessage from '../../Common/FormErrorMessage';
import IABContentTaxonomySelector from '../../Common/IABContentTaxonomySelector';
import '../../../lib/availity/yup';
import formValidation from '../formValidation';
import {
  SubmitButton,
  CancelButton,
} from '../../Common/Modal/modalActionButtons';

import { autoId } from '../../../helpers/sharedFunction';
import languageTag from '../../../lib/languageTag';
import { CAMPAIGN_STATUS } from '../../../config/campaign';
import toastr from 'toastr';

const FormCampaignClusterDetail = ({
  cluster,
  campaign,
  user,
  loading,
  onSave,
  onCancel,
}) => {
  const [selectedIncludeIABTaxonomies, setIncludeSelectedIABTaxonomies] =
    useState(cluster?.includeSelectedIABTaxonomies || []);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const { includeKeywords = '' } = values;

    if (includeKeywords === '' && !selectedIncludeIABTaxonomies.length)
      return toastr.error('Enter at least one cluster feature!', 'Error');

    if (!cluster) {
      const clusterId = autoId();

      const cluster = {
        id: clusterId,
        ...values,
        includeSelectedIABTaxonomies: selectedIncludeIABTaxonomies,
        user: user,
        campaignId: campaign.id,
        campaignStatus: campaign.status,
      };

      dispatch(clustersActions.createDraftCluster(cluster));
    } else {
      const cluster = {
        id: campaign.clusterId,
        ...values,
        includeSelectedIABTaxonomies: selectedIncludeIABTaxonomies,
        campaignStatus: campaign.status,
      };
      cluster?.setup === CAMPAIGN_STATUS.NEW
        ? dispatch(clustersActions.updateDraftCluster(cluster))
        : dispatch(clustersActions.updateCluster(cluster));
    }

    onSave();
  };

  return (
    <Form
      initialValues={cluster || {}}
      validationSchema={formValidation(2)}
      onSubmit={handleSubmit}>
      {({ isValid }) => (
        <div className="shadow-lg p-2 bg-white rounded">
          <Row className="py-4">
            <Col md="6">
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <Input id="name" name="name" />
                  <FormErrorMessage name="name" />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="langTag">Content Language</Label>
                  <Select
                    name="langTag"
                    id="langTag"
                    options={languageTag.map(({ tag, language }) => ({
                      value: tag,
                      label: language,
                    }))}
                  />
                  <FormErrorMessage name="langTag" />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="includeKeywords">Include Keywords</Label>
                  <Input
                    name="includeKeywords"
                    id="includeKeywords"
                    type="textarea"
                  />
                  <FormErrorMessage name="includeKeywords" />
                  <p className="font-size-12 text-muted">
                    Insert comma separated keywords
                  </p>
                </FormGroup>
              </Col>
            </Col>
            <Col md="6">
              <Label>Include IAB Content Taxonomy</Label>
              <IABContentTaxonomySelector
                selected={cluster?.includeSelectedIABTaxonomies || []}
                onCheck={(checked) => setIncludeSelectedIABTaxonomies(checked)}
              />
            </Col>
          </Row>
          <div className="modal-footer">
            <CancelButton disabled={loading} onClick={onCancel} />
            <SubmitButton disabled={!isValid || loading} create={!cluster} />
          </div>
        </div>
      )}
    </Form>
  );
};

export default FormCampaignClusterDetail;
