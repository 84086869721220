import types from './actionTypes';
import initialFilters from '../../config/filters';
import { addOrReplaceObjectInArray } from '../../helpers/reducerHelper';

const initialState = {
  loading: false,
  podcasts: [],
  startDate: null,
  endDate: null,
  filters: Object.assign({}, initialFilters),
  loadMore: false,
  error: '',
};

const podcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PODCAST_STATE:
      return initialState;
    case types.CREATE_PODCAST.REQUEST:
    case types.UPDATE_PODCAST.REQUEST:
    case types.FETCH_PODCASTS.REQUEST:
    case types.FETCH_PODCASTS_BY_IDS.REQUEST:
    case types.FETCH_RELATED_PODCASTS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_PODCAST.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.UPDATE_PODCAST.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        podcasts: addOrReplaceObjectInArray(
          state.podcasts,
          action.podcast,
          'id',
          'merge',
        ),
      };
    case types.FETCH_PODCASTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        podcasts: action.podcasts,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: action.filters,
        loadMore: action.loadMore,
      };
    case types.FETCH_PODCASTS_BY_IDS.SUCCESS:
    case types.FETCH_RELATED_PODCASTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        podcasts: action.podcasts,
      };
    case types.CREATE_PODCAST.FAILURE:
    case types.UPDATE_PODCAST.FAILURE:
    case types.FETCH_PODCASTS.FAILURE:
    case types.FETCH_PODCASTS_BY_IDS.FAILURE:
    case types.FETCH_RELATED_PODCASTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default podcastReducer;
