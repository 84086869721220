import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as insightsActions from '../../store/publishers/insights/actions';
import * as publishersActions from '../../store/publishers/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
} from '../../selectors/auth';
import { selectPublishersByActualRole } from '../../selectors/publisher';
import {
  totalMetric,
  averageListeningTime as averageListeningTimeSelector,
} from '../../selectors/insight';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import MiniCard from '../../components/Common/MiniCard';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Publishers/Insights/tableColumns';
import {
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';
import { INSIGHT_LEVEL } from '../../config/insight';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { secondsToHms, addOrRemove } from '../../helpers/sharedFunction';

const Insights = (props) => {
  const loading = useSelector((state) => state.PublisherInsight.loading);

  const insights = useSelector((state) => state.PublisherInsight.insights);
  const publishers = useSelector(selectPublishersByActualRole);

  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isStaff = isAdmin || isManager;

  const plays = useSelector(
    totalMetric('unique-play', INSIGHT_LEVEL.PUBLISHER),
  );
  const completed = useSelector(
    totalMetric('completed', INSIGHT_LEVEL.PUBLISHER),
  );
  const percentageCompleted = (completed / plays) * 100;
  const listenedPodcasts = useSelector(
    totalMetric('listenedPodcasts', INSIGHT_LEVEL.PUBLISHER),
  );
  const listeningTime = useSelector(
    totalMetric('listeningTime', INSIGHT_LEVEL.PUBLISHER),
  );
  const averageListeningTime = useSelector(
    averageListeningTimeSelector(INSIGHT_LEVEL.PUBLISHER),
  );

  const [selectedInsight, setSelectedInsight] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const startDate = useSelector((state) => state.PublisherInsight.startDate);
  const endDate = useSelector((state) => state.PublisherInsight.endDate);
  const dataFilters = useSelector((state) => state.PublisherInsight.filters);

  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('01/08/2021', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc(),
    endDate: momentEndDate || moment.utc(),
    publisherId: dataFilters?.publisherId || (isStaff ? '' : publishers[0]?.id),
    siteIds: dataFilters?.siteIds,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(
    ({ startDate, endDate, publisherId, siteIds }) => {
      const selectedStartDate = startDate.utc().toDate();
      const selectedEndDate = endDate.utc().toDate();
      dispatch(
        insightsActions.fetchInsights(selectedStartDate, selectedEndDate, {
          publisherId,
          siteIds,
        }),
      );
      setSelectedFilters({ startDate, endDate, publisherId, siteIds });
    },
    [],
  );

  useEffect(() => {
    insights.length === 0 && refresh(selectedFilters);
    dispatch(publishersActions.syncPublishers());
  }, []);

  const openViewModal = (insight) => {
    setSelectedInsight(insight);
    setViewModal(true);
  };

  const { t } = useTranslation();

  const metrics = [
    {
      title: t('Plays'),
      text: plays,
      color: 'primary',
      icon: 'bx bx-play-circle',
    },
    {
      title: t('Completed'),
      text: completed,
      color: 'success',
      icon: 'bx bx-check-circle',
      percentage: `${
        isFinite(percentageCompleted) ? percentageCompleted.toFixed(0) : 0
      }%`,
    },
    {
      title: t('Unique Listened Spoken Articles'),
      text: listenedPodcasts,
      color: 'info',
      icon: 'bx bx-headphone',
    },
    {
      title: t('Average Listening Time'),
      text: averageListeningTime > 0 ? secondsToHms(averageListeningTime) : 0,
      color: 'dark',
      icon: 'bx bx-time',
    },
    {
      title: t('Listening Time'),
      text: listeningTime > 0 ? secondsToHms(listeningTime) : 0,
      color: 'dark',
      icon: 'bx bx-time',
    },
  ];

  const columns = useMemo(
    () => createTableColumns(publishers, openViewModal, isStaff),
    [publishers, isStaff],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Publisher Insights"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showPublisherFilters
            initialFilters={selectedFilters}
            showAllFilter={isStaff}
          />
          <Row>
            {metrics.map((metric, index) => (
              <Col key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={insights}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Publisher Insight"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedInsight} icon="mdi mdi-web" />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Insights;
