import { createSelector } from 'reselect';

export const selectCreative = (id) =>
  createSelector(
    (state) => state.Creative.draftCreatives,
    (state) => state.Creative.creatives,
    (draftCreatives, creatives) =>
      id
        ? [...draftCreatives, ...creatives].find(
            (creative) => creative.id === id,
          )
        : null,
  );

export const selectCreativeByCampaign = (campaignId, onlyDraft = false) =>
  createSelector(
    (state) => state.Creative.draftCreatives,
    (state) => state.Creative.creatives,
    (draftCreatives, creatives) => {
      if (!campaignId) return [];

      return onlyDraft
        ? [...draftCreatives, ...creatives].filter(
            (creative) =>
              creative.campaignId === campaignId && creative.isDraft,
          )
        : [...draftCreatives, ...creatives].filter(
            (creative) => creative.campaignId === campaignId,
          );
    },
  );

export const countCreativeByCampaign = (campaignId, onlyDraft = false) =>
  createSelector(
    selectCreativeByCampaign(campaignId, onlyDraft),
    (creatives) => creatives.length,
  );

export const selectDraftCreatives = createSelector(
  (state) => state.Creative.draftCreatives,
  (state) => state.Creative.creatives,
  (draftCreatives, creatives) =>
    [...draftCreatives, ...creatives].filter(({ isDraft }) => !!isDraft),
);

export const countDraftCreatives = createSelector(
  selectDraftCreatives,
  (creatives) => creatives.length,
);
