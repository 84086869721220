import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Alert as AlertBox,
  Spinner,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as sitesActions from '../../store/sites/actions';
import * as publishersActions from '../../store/publishers/actions';
import * as networksActions from '../../store/networks/actions';

import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormSite from '../../components/Sites/FormSite';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Sites/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  ExportButtonPlacement,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';

import ImportVastUrls from '../../components/Sites/ImportVastUrls';

import { useTranslation } from 'react-i18next';
import adManagerLogo from '../../assets/images/google-ad-manager.svg';
import { addOrRemove } from '../../helpers/sharedFunction';

const List = (props) => {
  const loading = useSelector((state) => state.Site.loading);
  const sites = useSelector((state) => state.Site.sites);
  const publishers = useSelector((state) => state.Publisher.publishers);
  const networks = useSelector((state) => state.Network.networks);

  const error = useSelector((state) => state.Site.error);
  const success = useSelector((state) => state.Site.success);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [adUnitAlerts, setAdUnitAlerts] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [importVastModal, setImportVastModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sitesActions.syncSites());
    dispatch(publishersActions.syncPublishers());
    dispatch(networksActions.syncNetworks());
  }, []);

  const openViewModal = useCallback(
    (site) => {
      setSelectedSite(site);
      setViewModal(true);
    },
    [setSelectedSite, setViewModal],
  );

  const openEditModal = useCallback(
    (site) => {
      setSelectedSite(site);
      setEditModal(true);
    },
    [setSelectedSite, setEditModal],
  );

  const openAdUnitAlert = useCallback(
    (site) => {
      dispatch(sitesActions.resetMessage());
      setSelectedSite(site);
      setAdUnitAlerts(true);
    },
    [setSelectedSite, setAdUnitAlerts],
  );

  const handleCreateAdUnits = () => {
    dispatch(sitesActions.createAdUnits(selectedSite));
  };

  const openImportVastModal = () => {
    dispatch(sitesActions.resetMessage());
    setImportVastModal(true);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        openEditModal,
        openAdUnitAlert,
        publishers,
        networks,
      ),
    [publishers, networks],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Site"
            breadcrumbItem="List"
            onImportVast={openImportVastModal}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Site"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                    <ExportButtonPlacement />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={sites}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Site"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedSite}
              collection="sites"
              icon="mdi mdi-web"
            />
          </Modal>
          <Modal
            size="xl"
            title="Site"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormSite
              closeModal={() => setEditModal(false)}
              create={!selectedSite}
              loading={loading}
              site={selectedSite}
            />
          </Modal>
          <Alert
            isOpen={adUnitAlerts}
            type={error ? 'danger' : success ? 'success' : 'custom'}
            title="Do you want to create ad units?"
            loading={loading}
            mainIcon={adManagerLogo}
            confirmBtnLabel="Create"
            cancelBtnLabel={error || success ? 'Close' : 'Cancel'}
            onCancel={() => setAdUnitAlerts(false)}
            onConfirm={handleCreateAdUnits}
            disabledBtn={loading}
            disableButtonOnConfirm={true}
            dependencies={[selectedSite]}>
            <h5>{selectedSite?.name}</h5>
            {error && <AlertBox color="danger">{error}</AlertBox>}
            {success && <AlertBox color="success">{success}</AlertBox>}
          </Alert>
          <Modal
            size="lg"
            title={t('Import Vast URLs')}
            isOpen={importVastModal}
            toggle={() => setImportVastModal(!importVastModal)}
            showClose
            scrollable={false}>
            <ImportVastUrls />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
