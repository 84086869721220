import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import Loader from '../Loader';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ amount, customer, paymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(paymentSuccess);

  const handlePayment = async () => {
    setIsProcessing(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setIsProcessing(false);
      return;
    }

    const response = await axios.post(
      `https://us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net/ws-ws/create-stripe-intent`,
      { amount, customerId: customer.id },
    );
    const { clientSecret } = response.data;

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}${window.location.pathname}?payment_success`,
      },
      redirect: 'if_required',
    });

    if (error) {
      setError(error.message);
      setSuccess(false);
    } else if (paymentIntent?.status === 'succeeded') {
      setError('');
      setSuccess(true);
    } else {
      setError('Unexpected payment state');
      setSuccess(false);
    }

    setIsProcessing(false);
  };

  const { t } = useTranslation();

  return (
    <div className="position-relative">
      {isProcessing && <Loader size={4} top={150} />}
      {error && <Alert color="danger">{error}</Alert>}
      {success ? (
        <Alert color="success">
          <i class="bx bx-happy-heart-eyes font-size-22 align-middle mr-2"></i>
          {t('Payment completed!')}
        </Alert>
      ) : (
        <>
          <PaymentElement />
          <button
            type="button"
            className="btn btn-success btn-rounded btn-block waves-effect waves-light my-3 px-2"
            onClick={handlePayment}
            disabled={isProcessing}>
            {isProcessing ? t('Processing...') : t('Pay Now')}
          </button>
        </>
      )}
    </div>
  );
};

export default CheckoutForm;
