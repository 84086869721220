import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { isLoading, getInsightLevel } from '../../selectors/dashboard';
import * as dashboardActions from '../../store/dashboard/actions';

import WelcomeBoxWide from '../../components/Dashboard/WelcomeBoxWide';
import TotalPodcastMetrics from '../../components/Dashboard/TotalPodcastMetrics';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import PodcastMetrics from '../../components/Dashboard/PodcastMetrics';
import PodcastsGraph from '../../components/Dashboard/PodcastsGraph';
import SiteRevenues from '../../components/Dashboard/SiteRevenues';
import SiteInsightMetrics from '../../components/Dashboard/SiteInsightMetrics';
import ListeningInsightGraph from '../../components/Dashboard/ListeningInsightGraph';
import Modal from '../../components/Common/Modal/Modal';
import MostPlayedBar from '../../components/Dashboard/MostPlayedBar';
import ImportRevenues from '../../components/Sites/Revenues/ImportRevenues';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

const AdminDashboard = (props) => {
  const loading = useSelector(isLoading);
  const insightLevel = useSelector(getInsightLevel);
  const [mostPlayedModal, setMostPlayedModal] = useState(false);
  const [importRevenuesModal, setImportRevenuesModal] = useState(false);

  const startDate = useSelector((state) => state.Dashboard.startDate);
  const endDate = useSelector((state) => state.Dashboard.endDate);
  const filters = useSelector((state) => state.Dashboard.filters);
  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('01/08/2021', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc(),
    endDate: momentEndDate || moment.utc(),
    publisherId: filters?.publisherId || '',
    siteIds: filters?.siteIds || null,
  });

  const dispatch = useDispatch();

  const refresh = ({ startDate, endDate, publisherId, siteIds }) => {
    const selectedStartDate = startDate.utc().toDate();
    const selectedEndDate = endDate.utc().toDate();
    dispatch(
      dashboardActions.setFilters(selectedStartDate, selectedEndDate, {
        publisherId,
        siteIds,
      }),
    );
    setSelectedFilters({ startDate, endDate, publisherId, siteIds });
  };

  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs
        title={t('Dashboard')}
        onRefresh={() => refresh(selectedFilters)}
        onImportRevenues={() => setImportRevenuesModal(true)}
        loading={loading}
      />
      <Row>
        <Col md="5">
          <WelcomeBoxWide />
        </Col>
        <TotalPodcastMetrics loading={loading} />
      </Row>
      <DateRangeFilter
        onUpdate={refresh}
        minDate={minDate}
        maxDate={maxDate}
        startDate={selectedFilters.startDate}
        endDate={selectedFilters.endDate}
        loading={loading}
        showPublisherFilters
        initialFilters={filters}
      />
      <Row>
        <PodcastMetrics insightLevel={insightLevel} loading={loading} />
      </Row>
      <Row>
        <Col md="9">
          <PodcastsGraph
            dateRange={selectedFilters}
            insightLevel={insightLevel}
            loading={loading}
          />
        </Col>
        <Col md="3" className="d-flex">
          <SiteRevenues insightLevel="site" loading={loading} />
        </Col>
      </Row>
      <Row>
        <Col md="4" className="d-flex">
          <SiteInsightMetrics
            insightLevel={insightLevel}
            onPressMostPlayed={() => setMostPlayedModal(true)}
            loading={loading}
          />
        </Col>
        <Col md="8" className="d-flex">
          <ListeningInsightGraph
            dateRange={selectedFilters}
            insightLevel={insightLevel}
            loading={loading}
          />
        </Col>
      </Row>
      <Modal
        size="lg"
        title={`${t('Podcasts')} - ${t('Most Played')}`}
        isOpen={mostPlayedModal}
        toggle={() => setMostPlayedModal(!mostPlayedModal)}
        showClose
        scrollable={false}>
        <MostPlayedBar
          startDate={selectedFilters.startDate}
          endDate={selectedFilters.endDate}
          publisherId={selectedFilters.publisherId}
          siteIds={selectedFilters.siteIds}
          limit={10}
        />
      </Modal>
      <Modal
        size="lg"
        title={t('Import Revenues')}
        isOpen={importRevenuesModal}
        toggle={() => setImportRevenuesModal(!importRevenuesModal)}
        showClose
        scrollable={false}>
        <ImportRevenues />
      </Modal>
    </>
  );
};

export default AdminDashboard;
