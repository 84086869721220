import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as networksActions from '../../store/networks/actions';
import * as publishersActions from '../../store/publishers/actions';

import Modal from '../../components/Common/Modal/Modal';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormNetwork from '../../components/Networks/FormNetwork';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Networks/tableColumns';
import { CreateButton } from '../../components/Common/DataTable/tableActionButtons';

const List = (props) => {
  const loading = useSelector((state) => state.Network.loading);
  const networks = useSelector((state) => state.Network.networks);
  const publishers = useSelector((state) => state.Publisher.publishers);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(networksActions.syncNetworks());
    dispatch(publishersActions.syncPublishers());
  }, []);

  const openViewModal = (network) => {
    setSelectedNetwork(network);
    setViewModal(true);
  };

  const openEditModal = (network) => {
    setSelectedNetwork(network);
    setEditModal(true);
  };

  const columns = useMemo(
    () => createTableColumns(publishers, openViewModal, openEditModal),
    [publishers],
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Network" breadcrumbItem="List" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new Network"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={networks}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Network"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedNetwork}
              icon="bx bx-buildings"
            />
          </Modal>
          <Modal
            size="xl"
            title="Network"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormNetwork
              closeModal={() => setEditModal(false)}
              create={!selectedNetwork}
              loading={loading}
              network={selectedNetwork}
            />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
