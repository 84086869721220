import React, { memo } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import { useSelector } from 'react-redux';
import { drawSeriesListeningDailyGraph } from '../../selectors/insight';

import ReactApexChart from 'react-apexcharts';
import CenterBox from '../Common/CenterBox';
import { useTranslation } from 'react-i18next';

const ListeningInsightGraph = ({ dateRange, insightLevel, loading }) => {
  const { t } = useTranslation();

  const dataGraph = useSelector(
    drawSeriesListeningDailyGraph(dateRange, insightLevel, t),
  );

  const dailyGraph = {
    series: dataGraph.series,
    options: {
      chart: {
        toolbar: 'false',
        type: 'line',
        stacked: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dddd, dd MMM yyyy',
        },
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 10,
          offsetX: 20,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        curve: 'smooth',
        width: [0, 0, 2],
        dashArray: [0, 0, 6],
      },
      yaxis: [
        {
          seriesName: dataGraph.series[0].name,
          tickAmount: 5,
          max: parseInt(dataGraph.max),
          min: parseInt(dataGraph.min),
          forceNiceScale: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[0].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[0].color,
            },
            formatter: (val, index) => val.toFixed(0),
          },
          title: {
            text: 'Events',
            style: {
              color: dataGraph.series[0].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: dataGraph.series[1].name,
          tickAmount: 5,
          max: parseInt(dataGraph.max),
          min: parseInt(dataGraph.min),
          forceNiceScale: true,
          show: false,
          labels: {
            style: {
              colors: dataGraph.series[1].color,
            },
            formatter: (val, index) => val.toFixed(0),
          },
        },
        {
          seriesName: dataGraph.series[2].name,
          max: 100,
          min: 0,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[2].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[2].color,
            },
            formatter: (val, index) => `${val.toFixed(2)} %`,
          },
          title: {
            text: t('eLTR'),
            style: {
              color: dataGraph.series[2].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Card className="flex-fill">
        <CardBody>
          <CardTitle className="mb-4 float-sm-left">{t('Insights')}</CardTitle>
          <div className="clearfix"></div>
          {!loading ? (
            <div id="line-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                series={dailyGraph.series}
                options={dailyGraph.options}
                type="line"
                height={290}
              />
            </div>
          ) : (
            <CenterBox height={290}>
              <i className="bx bx-hourglass bx-spin font-size-32 text-secondary"></i>
            </CenterBox>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default memo(ListeningInsightGraph);
