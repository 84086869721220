import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CAMPAIGN_INSIGHT_STATE,
});

export const fetchInsights = (startDate, endDate, filters = null) => ({
  type: types.FETCH_CAMPAIGN_INSIGHTS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchInsightsSuccess = (
  insights,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_CAMPAIGN_INSIGHTS.SUCCESS,
  insights,
  startDate,
  endDate,
  filters,
});

export const fetchInsightsFailure = (error) => ({
  type: types.FETCH_CAMPAIGN_INSIGHTS.FAILURE,
  error,
});

export const fetchInsightsByCampaign = (campaign, startDate, endDate) => ({
  type: types.FETCH_CAMPAIGN_INSIGHTS_BY_CAMPAIGN.REQUEST,
  campaign,
  startDate,
  endDate,
});

export const fetchInsightsByCampaignSuccess = (insights) => ({
  type: types.FETCH_CAMPAIGN_INSIGHTS_BY_CAMPAIGN.SUCCESS,
  insights,
});

export const fetchInsightsByCampaignFailure = (error) => ({
  type: types.FETCH_CAMPAIGN_INSIGHTS_BY_CAMPAIGN.FAILURE,
  error,
});
