import { createSelector } from 'reselect';
import { userCustomerId, userPublisherId, isPublisher } from './auth';
import { selectUser } from './user';

export const selectCustomer = (id) =>
  createSelector(
    (state) => state.Customer.customers,
    (customers) => {
      if (!id) return null;
      return customers.find((customer) => customer.id === id);
    },
  );

export const actualCustomer = createSelector(
  (state) => state.Customer.customers,
  userCustomerId,
  userPublisherId,
  (customers, customerId, publisherId) =>
    customerId
      ? customers.find((customer) => customer.id === customerId)
      : publisherId
      ? customers.find((customer) => customer.publisherId === publisherId)
      : null,
);

export const selectCustomerByPublisher = (publisherId) =>
  createSelector(
    (state) => state.Customer.customers,
    (customers) => {
      if (!publisherId) return null;
      return customers.find((customer) => customer.publisherId === publisherId);
    },
  );

export const selectCustomerByUser = (userId) =>
  createSelector(
    selectUser(userId),
    (state) => state.Customer.customers,
    (user, customers) => {
      if (!user || (!user.customerId && !user.publisherId)) return null;
      return user.customerId
        ? customers.find((customer) => customer.id === user.customerId)
        : customers.find(
            (customer) => customer.publisherId === user.publisherId,
          );
    },
  );

export const selectCustomersWithoutPublisher = createSelector(
  (state) => state.Customer.customers,
  (customers) => customers.filter(({ isPublisher }) => !isPublisher),
);

export const creditAmountEmpty = (ignorePublisher = true) =>
  createSelector(actualCustomer, isPublisher, (customer, isPublisher) => {
    if (!customer) return false;
    if (isPublisher && ignorePublisher) return false;
    return customer.creditAmount <= 0;
  });

export const creditAmountLow = (ignorePublisher = true) =>
  createSelector(actualCustomer, isPublisher, (customer, isPublisher) => {
    if (!customer) return false;
    if (isPublisher && ignorePublisher) return false;
    return customer.creditAmount > 0 && customer.creditAmount <= 30;
  });
