import { createSelector } from 'reselect';
import { userNetworkId } from './auth';
import { actualPublisher } from './publisher';
import roles from '../config/roles';

export const actualNetwork = createSelector(
  (state) => state.Network.networks,
  userNetworkId,
  actualPublisher,
  (networks, networkId, publisher) =>
    networks.find(({ id }) => id === networkId || id === publisher?.networkId),
);

export const selectNetwork = (id) =>
  createSelector(
    (state) => state.Network.networks,
    (networks) => (id ? networks.find((network) => network.id === id) : null),
  );

export const selectNetworksByRole = (role, id = null) =>
  createSelector(
    (state) => state.Network.networks,
    (networks) => {
      if ([roles.ADMIN, roles.SUPER_ADMIN, roles.MANAGER].includes(role))
        return networks;
      if (role === roles.NETWORKER)
        return networks.filter((network) => network.id === id);
      return [];
    },
  );
