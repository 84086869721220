import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_COUNTRY_STATE,
});

export const createCountry = (country) => ({
  type: types.CREATE_COUNTRY.REQUEST,
  country,
});

export const createCountrySuccess = (country) => ({
  type: types.CREATE_COUNTRY.SUCCESS,
  country,
});

export const createCountryFailure = (error) => ({
  type: types.CREATE_COUNTRY.FAILURE,
  error,
});

export const updateCountry = (country) => ({
  type: types.UPDATE_COUNTRY.REQUEST,
  country,
});

export const updateCountrySuccess = () => ({
  type: types.UPDATE_COUNTRY.SUCCESS,
});

export const updateCountryFailure = (error) => ({
  type: types.UPDATE_COUNTRY.FAILURE,
  error,
});

export const syncCountries = () => ({
  type: types.SYNC_COUNTRIES.REQUEST,
});

export const syncCountriesSuccess = (countries) => ({
  type: types.SYNC_COUNTRIES.SUCCESS,
  countries,
});

export const syncCountriesFailure = (error) => ({
  type: types.SYNC_COUNTRIES.FAILURE,
  error,
});

export const fetchCountry = (countryId) => ({
  type: types.FETCH_COUNTRY.REQUEST,
  countryId,
});

export const fetchCountrySuccess = (country) => ({
  type: types.FETCH_COUNTRY.SUCCESS,
  country,
});

export const fetchCountryFailure = (error) => ({
  type: types.FETCH_COUNTRY.FAILURE,
  error,
});
