import React, { useState, useEffect, memo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  UncontrolledTooltip,
} from 'reactstrap';

import * as creativesActions from '../../../store/campaigns/creatives/actions';
import * as insightsActions from '../../../store/campaigns/creatives/insights/actions';
import { useSelector, useDispatch } from 'react-redux';
import { totalMetric } from '../../../selectors/campaignInsight';
import { selectCreativeByCampaign } from '../../../selectors/creative';

import CampaignGraph from '../CampaignGraph';
import SpeakUpArticlePreview from '../../Common/SpeakUpArticlePreview';
import { CloseButton } from '../../Common/Modal/modalActionButtons';
import colors from '../../CommonStyle/colors';
import { INSIGHT_LEVEL } from '../../../config/insight';
import { campaignStatusColor } from '../../../config/campaign';
import { cdnUrl, toHHMMSS } from '../../../helpers/sharedFunction';
import { useTranslation } from 'react-i18next';

const CreativeInsights = ({ campaign, startDate, endDate, days }) => {
  const loading = useSelector((state) => state.Creative.loading);
  const loadingInsight = useSelector((state) => state.CreativeInsight.loading);
  const isLoading = loading || loadingInsight;

  const creatives = useSelector(selectCreativeByCampaign(campaign.id));
  const impressions = useSelector(
    totalMetric('impressions', INSIGHT_LEVEL.CREATIVE),
  );

  const [selectedCreative, setSelectedCreative] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(creativesActions.fetchCreativesByCampaignId(campaign.id));
  }, [campaign]);

  useEffect(() => {
    selectedCreative &&
      dispatch(
        insightsActions.fetchInsightsByCreative(
          selectedCreative,
          startDate.toDate(),
          endDate.toDate(),
        ),
      );
  }, [selectedCreative, startDate, endDate]);

  const { t } = useTranslation();

  return (
    <Card className="flex-fill">
      <CardBody className="p-0">
        <Row className="m-2 align-items-center">
          <h5 className="m-0" style={{ color: colors.indigo }}>
            {selectedCreative
              ? `Creative: ${selectedCreative.name}`
              : 'Creatives'}
          </h5>
          {!selectedCreative && (
            <span className="badge badge-secondary font-size-12 ml-3">
              {creatives.length}
            </span>
          )}
          {selectedCreative && (
            <div className="ml-3">
              <CloseButton onClick={() => setSelectedCreative(null)} />
            </div>
          )}
        </Row>
        {creatives.length && (
          <Row>
            <Col md="12">
              <Collapse isOpen={!selectedCreative}>
                <div className="table-responsive">
                  <Table className="align-middle">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        {false && <th>{t('Duration')}</th>}
                        <th>
                          {t('Lifetime streaming')}
                          {'  '}
                          <i
                            id="streaming_info"
                            className="bx bx-info-circle"></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="streaming_info">
                            {t('Indicative values')}
                          </UncontrolledTooltip>
                        </th>
                        <th>
                          {t('Total Spent')}
                          {'  '}
                          <i
                            id="streaming_info"
                            className="bx bx-info-circle"></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="streaming_info">
                            {t('Indicative values')}
                          </UncontrolledTooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {creatives.map((creative) => (
                        <tr key={creative.id}>
                          <td className="align-middle">
                            <button
                              type="button"
                              disabled={isLoading}
                              className="btn btn-sm btn-outline-dark waves-effect"
                              onClick={() => setSelectedCreative(creative)}>
                              {isLoading ? (
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mx-3"></i>
                              ) : (
                                <i className="bx bx-bar-chart-alt-2 font-size-16 align-middle mx-3"></i>
                              )}
                            </button>
                          </td>
                          <td className="align-middle">
                            <img
                              src={cdnUrl(creative.image)}
                              alt=""
                              className="rounded"
                              style={{ height: 45 }}
                            />
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-13">
                              {creative.name}
                            </h5>
                            <p className="text-muted mb-0">{creative.title}</p>
                          </td>
                          <td className="align-middle">
                            <span
                              className={`badge badge-${campaignStatusColor(
                                creative.status,
                              )} font-size-12`}>
                              {creative.status.toUpperCase()}
                            </span>
                          </td>
                          {false && (
                            <td className="align-middle">
                              <p className="text-muted mb-0">
                                {toHHMMSS(creative.audioDuration)}
                              </p>
                            </td>
                          )}
                          <td className="align-middle">
                            <p className="text-muted mb-0">
                              {creative.impressions || '-'}
                            </p>
                          </td>
                          <td className="align-middle">
                            {campaign?.free ? (
                              <span className="badge badge-success font-size-12">
                                FREE
                              </span>
                            ) : (
                              <p className="text-muted mb-0">
                                {creative.amountSpent
                                  ? `€ ${creative.amountSpent.toFixed(2)}`
                                  : '-'}
                              </p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
              <Collapse isOpen={!!selectedCreative}>
                <Row>
                  <Col md="4">
                    <SpeakUpArticlePreview
                      title={selectedCreative?.title}
                      brand={selectedCreative?.brand}
                      image={selectedCreative?.image}
                      link={selectedCreative?.link}
                      audio={selectedCreative?.audioFile}
                    />
                  </Col>
                  <Col md="8">
                    <Card className="mb-2">
                      <CardBody>
                        <div className="d-flex flex-wrap">
                          <div>
                            <p className="text-muted font-weight-medium mb-3">
                              {t('All Streaming')}
                            </p>
                            <div className="d-flex flex-row">
                              <h4 className="text-primary mb-1">
                                {impressions}
                              </h4>
                              <span className="text-muted mb-1 ml-2 align-self-end">
                                in {days === 1 ? `${days} day` : `${days} days`}
                              </span>
                            </div>
                          </div>
                          <div className="ml-auto align-self-end">
                            <i className="bx bx-headphone font-size-30 text-light"></i>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <CampaignGraph
                      dateRange={{ startDate, endDate }}
                      insightLevel={INSIGHT_LEVEL.CREATIVE}
                      loading={isLoading}
                    />
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default memo(CreativeInsights);
