import React, { useState } from 'react';
import SweetAlert from '../../../lib/react-bootstrap-sweetalert/src';
import { useTranslation } from 'react-i18next';
import loadingIcon from '../../../assets/images/loader.png';

const Alert = (props) => {
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false);
  const disableButtonOnConfirm = props.disableButtonOnConfirm ?? false;

  const handleOnConfirm = () => {
    props.onConfirm();
    disableButtonOnConfirm && setConfirmBtnDisabled(true);
  };

  const handleOnCancel = () => {
    props.onCancel();
    disableButtonOnConfirm && setConfirmBtnDisabled(false);
  };

  const { t } = useTranslation();

  return props.isOpen ? (
    <SweetAlert
      type={props.type || 'custom'}
      showCancel
      customIcon={props.loading ? loadingIcon : props.mainIcon || ''}
      title={props.title}
      onConfirm={handleOnConfirm}
      onCancel={handleOnCancel}
      dependencies={props.dependencies || []}
      closeOnClickOutside={false}
      customButtons={
        <>
          {props.onCancel && (
            <button
              type="button"
              className="btn btn-danger w-md waves-effect waves-light"
              style={{ marginRight: '10px' }}
              onClick={handleOnCancel}>
              {t(props.cancelBtnLabel || 'Cancel')}
            </button>
          )}
          {props.onConfirm && (
            <button
              type="button"
              className="btn btn-primary w-xs waves-effect waves-light"
              onClick={handleOnConfirm}
              disabled={confirmBtnDisabled || !!props.disabledBtn}>
              {t(props.confirmBtnLabel)}
            </button>
          )}
        </>
      }>
      {props.children || ''}
    </SweetAlert>
  ) : null;
};

export default Alert;
