import moment from 'moment';

export const CAMPAIGN_STATUS = {
  NEW: 'new',
  IN_REVIEW: 'in review',
  APPROVED: 'approved',
  PROCESSING: 'processing',
  REJECTED: 'rejected',
  SCHEDULED: 'scheduled',
  LEARNING: 'learning',
  RUNNING: 'running',
  PAUSED: 'paused',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
  ERROR: 'error',
};

export const CAMPAIGN_CLUSTER_INITIAL_STATE = {
  NO_CLUSTER: 'NO_CLUSTER',
  CREATE_CLUSTER: 'CREATE_CLUSTER',
};

export const campaignStatusColor = (status) => {
  switch (status) {
    case CAMPAIGN_STATUS.APPROVED:
    case CAMPAIGN_STATUS.LEARNING:
    case CAMPAIGN_STATUS.RUNNING:
      return 'success';
    case CAMPAIGN_STATUS.PAUSED:
    case CAMPAIGN_STATUS.COMPLETED:
    case CAMPAIGN_STATUS.ARCHIVED:
      return 'secondary';
    case CAMPAIGN_STATUS.REJECTED:
    case CAMPAIGN_STATUS.ERROR:
      return 'danger';
    default:
      return 'warning';
  }
};

export const isEditable = (status) => {
  const {
    NEW,
    IN_REVIEW,
    REJECTED,
    SCHEDULED,
    LEARNING,
    RUNNING,
    PAUSED,
    COMPLETED,
  } = CAMPAIGN_STATUS;
  return [
    NEW,
    IN_REVIEW,
    REJECTED,
    SCHEDULED,
    LEARNING,
    RUNNING,
    PAUSED,
    COMPLETED,
  ].includes(status);
};

export const GAM_DEVICE_CATEGORY_NAME_ALLOWED = [
  'Desktop',
  'Smartphone',
  'Tablet',
];

export const DEFAULT_AMOUNTS = [50, 100, 200, 400, 600, 800, 1000];
export const DEFAULT_PPL = 0.3;

export const DEFAULT_ARTICLES_CREATED_SINCE = moment()
  .subtract(6, 'months')
  .startOf('day');

export const CDN_ENDPOINT = 'cdn.speakup.ai';

export const THIRD_PARTY_TRACKING_TYPES = [
  {
    label: 'Start',
    value: 'CREATIVE_VIEW',
  },
  {
    label: 'Click',
    value: 'CLICK_TRACKING',
  },
];

export const GAM_BASE_URL = 'https://admanager.google.com/22389033291';
