import React from 'react';
import { ViewButton } from '../../Common/DataTable/tableActionButtons';
import { getFilter, getLabel } from '../../Common/DataTable/tableHelpers';
import { secondsToHms } from '../../../helpers/sharedFunction';
import moment from 'moment';

const createTableColumns = (sites, openViewModal, isStaff = false) => [
  {
    title: 'Site',
    name: 'siteId',
    width: 180,
    formatterComponent: ({ value }) => getLabel(value, sites, 'id', 'name'),
    filter: (value, filter) =>
      getFilter(getLabel(value, sites, 'id', 'name'), filter.value),
    fixed: 'left',
  },
  {
    title: 'Day',
    name: 'date',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('dddd, DD/MM/YYYY'),
    filter: (value, filter) =>
      getFilter(moment(value).format('dddd, DD/MM/YYYY'), filter.value),
    fixed: 'left',
  },
  {
    title: 'Articles',
    name: 'podcasts',
    width: 120,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'Spoken Articles',
    name: 'synthesized',
    width: 120,
    summary: 'sum',
  },
  {
    title: 'Published',
    name: 'published',
    width: 120,
    summary: 'sum',
  },
  {
    title: 'Synthesis Cost',
    name: 'synthesisCost',
    width: 120,
    formatterComponent: ({ value }) =>
      `€ ${isFinite(value) ? value.toFixed(2) : 0}`,
    summary: 'sum',
    skip: !isStaff,
  },
  {
    title: 'Streaming',
    name: 'unique-play',
    width: 120,
    summary: 'sum',
  },
  {
    title: 'Pauses',
    name: 'pause',
    width: 120,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'First Quartile',
    name: 'first-quartile',
    width: 120,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'Midpoint',
    name: 'midpoint',
    width: 120,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'Third-quartile',
    name: 'third-quartile',
    width: 120,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'Completed',
    name: 'completed',
    width: 120,
    summary: 'sum',
  },
  {
    title: 'Unique Listened Spoken Articles',
    name: 'listenedPodcasts',
    width: 120,
    summary: 'sum',
  },
  {
    title: 'Listening Time',
    name: 'listeningTime',
    width: 200,
    formatterComponent: ({ value }) => `${value > 0 ? secondsToHms(value) : 0}`,
    filter: (value, filter) =>
      getFilter(`${value > 0 ? secondsToHms(value) : 0}`, filter.value),
    summary: 'sum',
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
    skip: !isStaff,
  },
];

export default createTableColumns;
