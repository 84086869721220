import React, { useState, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Alert as AlertBox,
  Spinner,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
} from '../../selectors/auth';
import { actualUser } from '../../selectors/user';
import { creditAmountEmpty as creditAmountEmptySelector } from '../../selectors/customer';
import { countCampaignsWithDrafts } from '../../selectors/campaign';
import * as creativesActions from '../../store/campaigns/creatives/actions';
import * as campaignsActions from '../../store/campaigns/actions';
import * as customersActions from '../../store/customers/actions';
import * as usersActions from '../../store/users/actions';

import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FormCreative from '../../components/Campaigns/Creatives/FormCreative';
import PublishCampaigns from '../../components/Campaigns/PublishCampaigns';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import RejectAlert from '../../components/Campaigns/RejectAlert';
import createTableColumns from '../../components/Campaigns/Creatives/tableColumns';
import {
  CountRowTablePlacement,
  RemoteButton,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';

import { useTranslation } from 'react-i18next';
import { CAMPAIGN_STATUS } from '../../config/campaign';
import { DEFAULT_CONFIG_TABLE } from '../../components/Common/DataTable/tableConfig';
import { capitalize, addOrRemove } from '../../helpers/sharedFunction';

const REJECT_LABELS = [
  { title: 'Do you want to reject this Creative?', btnLabel: 'Reject' },
  { title: 'Do you confirm this reason', btnLabel: 'Confirm' },
];

const Creatives = (props) => {
  const loading = useSelector((state) => state.Creative.loading);
  const loadingUser = useSelector((state) => state.User.loading);
  const isLoading = loading || loadingUser;

  const loadMore = useSelector((state) => state.Creative.loadMore);

  const creatives = useSelector((state) => state.Creative.creatives);
  const draftCreatives = useSelector((state) => state.Creative.draftCreatives);
  const campaignsWithDrafts = useSelector(countCampaignsWithDrafts);
  const creditAmountEmpty = useSelector(creditAmountEmptySelector());

  const error = useSelector((state) => state.Creative.error);
  const success = useSelector((state) => state.Creative.success);

  const campaigns = useSelector((state) => state.Campaign.campaigns);
  const customers = useSelector((state) => state.Customer.customers);

  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const canApproveCreatives = isAdmin || isManager;
  const user = useSelector(actualUser);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [discardDraftsAlert, setDiscardDraftsAlert] = useState(false);
  const [approveAlert, setApproveAlert] = useState(false);
  const [rejectAlert, setRejectAlert] = useState(false);
  const [reprocessAlert, setReprocessAlert] = useState(false);
  const [actionAlert, setActionAlert] = useState(false);
  const [publishCampaignsModal, setPublishCampaignsModal] = useState(false);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [actionCreative, setActionCreative] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  const refresh = () => {
    dispatch(
      creativesActions.fetchCreatives(
        false,
        null,
        DEFAULT_CONFIG_TABLE.DATA_FETCH_LIMIT,
      ),
    );
    //setSelectedPodcasts([]);
    //tableFilters.forEach((filter) => filter('')); //reset filters
  };

  const handleFetchDataTable = () => {
    const startAfterId = creatives.length
      ? creatives[creatives.length - 1].id
      : null;

    dispatch(
      creativesActions.fetchCreatives(
        false,
        startAfterId,
        DEFAULT_CONFIG_TABLE.DATA_FETCH_LIMIT,
      ),
    );
  };

  useEffect(() => {
    dispatch(customersActions.syncCustomers());
    dispatch(usersActions.syncUsers());
    ![...creatives, ...draftCreatives].length && refresh();
  }, []);

  const openViewModal = (creative) => {
    setSelectedCreative(creative);
    setViewModal(true);
  };

  const openEditModal = (creative) => {
    setSelectedCreative(creative);
    setEditModal(true);
  };

  const openDeleteAlert = (creative) => {
    setSelectedCreative(creative);
    setDeleteAlert(true);
  };

  const handleDeleteCreative = () => {
    if (selectedCreative.status === CAMPAIGN_STATUS.NEW)
      dispatch(creativesActions.deleteDraftCreative(selectedCreative));
    setDeleteAlert(false);
  };

  const handleDiscardDrafts = () => {
    dispatch(campaignsActions.discardDraftCampaigns());
    setDiscardDraftsAlert(false);
  };

  const openApproveAlert = (creative) => {
    setSelectedCreative(creative);
    setApproveAlert(true);
  };

  const handleApproveCreative = () => {
    if (selectedCreative.status === CAMPAIGN_STATUS.IN_REVIEW)
      dispatch(creativesActions.approveCreative(selectedCreative));
    setApproveAlert(false);
  };

  const openRejectAlert = (creative) => {
    setSelectedCreative(creative);
    setRejectAlert(true);
  };

  const handleRejectCreative = (reason) => {
    if (selectedCreative.status === CAMPAIGN_STATUS.IN_REVIEW)
      dispatch(creativesActions.rejectCreative(selectedCreative, reason));
    setRejectAlert(false);
  };

  const openReprocessAlert = (creative) => {
    setSelectedCreative(creative);
    setReprocessAlert(true);
  };

  const handleReprocessCreative = () => {
    if (selectedCreative.status === CAMPAIGN_STATUS.ERROR)
      dispatch(creativesActions.reprocessCreative(selectedCreative));
    setReprocessAlert(false);
  };

  const openActionAlert = (creative, action) => {
    dispatch(creativesActions.resetMessage());
    setSelectedCreative(creative);
    setActionCreative(action);
    setActionAlert(true);
  };

  const handleActionCreative = () => {
    dispatch(creativesActions.actionCreative(selectedCreative, actionCreative));
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        campaigns,
        customers,
        openViewModal,
        openEditModal,
        openDeleteAlert,
        openApproveAlert,
        openRejectAlert,
        openReprocessAlert,
        openActionAlert,
        isAdmin,
        canApproveCreatives,
        creditAmountEmpty,
      ),
    [isAdmin, canApproveCreatives, creditAmountEmpty],
  );

  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Breadcrumbs
              title="Creatives"
              onRefresh={refresh}
              breadcrumbItem="Creatives"
              size="8"
            />
            <Col sx="4" className="text-right">
              {!!campaignsWithDrafts && (
                <>
                  <button
                    type="button"
                    disabled={isLoading}
                    className="btn btn-outline-danger waves-effect"
                    onClick={() => setDiscardDraftsAlert(true)}>
                    {isLoading ? (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                    ) : (
                      <i className="bx bx-trash font-size-16 align-middle mr-2"></i>
                    )}
                    {t('Discard Drafts')}
                  </button>
                  <button
                    type="button"
                    disabled={isLoading}
                    className="btn btn-outline-success waves-effect"
                    style={{ marginLeft: '20px' }}
                    onClick={() => setPublishCampaignsModal(true)}>
                    {isLoading ? (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                    ) : (
                      <i className="bx bx-cloud-upload font-size-16 align-middle mr-2"></i>
                    )}
                    {t('Review and Publish')}
                  </button>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!isLoading}
                    />
                    <RemoteButton
                      label="Load more"
                      disabled={!loadMore || loading}
                      onPress={handleFetchDataTable}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={[...draftCreatives, ...creatives]}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Creative"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedCreative}
              collection="creatives"
              icon="dripicons-broadcast"
            />
          </Modal>
          <Modal
            size="xl"
            title={`Creative: ${selectedCreative?.name} (${selectedCreative?.status})`}
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormCreative
              closeModal={() => setEditModal(false)}
              loading={loading}
              user={user}
              creative={selectedCreative}
              className="mx-2 my-3"
            />
          </Modal>
          <Alert
            isOpen={deleteAlert}
            title="Do you want to delete this Creative?"
            confirmBtnLabel="Delete"
            onCancel={() => setDeleteAlert(false)}
            onConfirm={handleDeleteCreative}>
            There's no coming back!
          </Alert>
          <Alert
            isOpen={discardDraftsAlert}
            title="Do you want to discard Draft Campaign?"
            confirmBtnLabel="Discard Drafts"
            onCancel={() => setDiscardDraftsAlert(false)}
            onConfirm={handleDiscardDrafts}>
            There's no coming back!
          </Alert>
          <Alert
            isOpen={approveAlert}
            title="Do you want to approve this Creative?"
            confirmBtnLabel="Approve"
            onCancel={() => setApproveAlert(false)}
            onConfirm={handleApproveCreative}>
            There's no coming back!
          </Alert>
          <RejectAlert
            isOpen={rejectAlert}
            labels={REJECT_LABELS}
            onCancel={() => setRejectAlert(false)}
            onConfirm={handleRejectCreative}
          />
          <Alert
            isOpen={reprocessAlert}
            title="Do you want to reprocess this Creative?"
            confirmBtnLabel="Reprocess"
            onCancel={() => setReprocessAlert(false)}
            onConfirm={handleReprocessCreative}>
            There's no coming back!
          </Alert>
          <Modal
            size="xl"
            title={t('Review and Publish')}
            isOpen={publishCampaignsModal}
            toggle={() => setPublishCampaignsModal(!publishCampaignsModal)}
            showClose
            scrollable={false}>
            <PublishCampaigns />
          </Modal>
          <Alert
            isOpen={actionAlert}
            type={error ? 'danger' : success ? 'success' : 'custom'}
            title={`Do you want to ${actionCreative} creative?`}
            loading={loading}
            confirmBtnLabel={capitalize(actionCreative || 'waiting')}
            cancelBtnLabel={error || success ? 'Close' : 'Cancel'}
            onCancel={() => setActionAlert(false)}
            onConfirm={handleActionCreative}
            disabledBtn={loading}
            disableButtonOnConfirm={true}
            dependencies={[selectedCreative]}>
            <h5>{selectedCreative?.name}</h5>
            {error && <AlertBox color="danger">{error}</AlertBox>}
            {success && <AlertBox color="success">{success}</AlertBox>}
          </Alert>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Creatives;
