import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_MONTHLY_SITE_REVENUE_STATE,
});

export const createMonthlySiteRevenue = (insights, month, isFinalBalance) => ({
  type: types.CREATE_MONTHLY_SITE_REVENUES.REQUEST,
  insights,
  month,
  isFinalBalance,
});

export const createMonthlySiteRevenueSuccess = (message) => ({
  type: types.CREATE_MONTHLY_SITE_REVENUES.SUCCESS,
  message,
});

export const createMonthlySiteRevenueFailure = (error) => ({
  type: types.CREATE_MONTHLY_SITE_REVENUES.FAILURE,
  error,
});

export const fetchMonthlyRevenues = (startDate, endDate, filters = null) => ({
  type: types.FETCH_MONTHLY_SITE_REVENUES.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchMonthlyRevenuesSuccess = (
  revenues,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_MONTHLY_SITE_REVENUES.SUCCESS,
  revenues,
  startDate,
  endDate,
  filters,
});

export const fetchMonthlyRevenuesFailure = (error) => ({
  type: types.FETCH_MONTHLY_SITE_REVENUES.FAILURE,
  error,
});
