import React, { memo } from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { isAdmin as isAdminSelector } from '../../selectors/auth';
import { actualPublisher } from '../../selectors/publisher';
import colors from '../CommonStyle/colors';
import { isRtl } from '../../lib/languageTag';

const PreviewPodcast = ({ podcast }) => {
  const isAdmin = useSelector(isAdminSelector);
  const publisher = useSelector(actualPublisher);
  const showDownloadButton =
    isAdmin || (publisher && publisher.downloadPodcasts);

  const { title, audioUrl, synthesized, langTag, text } = podcast;
  const url = `${audioUrl}?timestamp=${new Date().getTime()}`;

  const dir = isRtl(langTag) ? 'rtl' : 'ltr';

  return (
    <Card>
      <CardBody>
        <Row className="text-center m-3">
          <h2 style={{ color: colors.indigo }} dir={dir}>
            {title}
          </h2>
        </Row>
        {audioUrl && synthesized === 'completed' && (
          <Row className="d-flex justify-content-center align-items-center">
            <audio
              src={url}
              className="mr-3"
              controls
              controlsList={showDownloadButton ? 'downlaod' : 'nodownload'}
            />
            {showDownloadButton && (
              <a
                href={url}
                className="btn btn-light"
                target="_blank"
                rel="noopener noreferrer">
                <i className="mdi mdi-download font-size-16 align-middle me-2"></i>{' '}
                Download MP3
              </a>
            )}
          </Row>
        )}
        <Row className="mx-3 my-4">
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="text-justify"
            dir={dir}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(PreviewPodcast);
