const statsTypes = {
  RESET_MONTHLY_SITE_REVENUE_STATE: 'RESET_MONTHLY_SITE_REVENUE_STATE',
  CREATE_MONTHLY_SITE_REVENUES: {
    REQUEST: 'CREATE_MONTHLY_SITE_REVENUES.REQUEST',
    SUCCESS: 'CREATE_MONTHLY_SITE_REVENUES.SUCCESS',
    FAILURE: 'CREATE_MONTHLY_SITE_REVENUES.FAILURE',
  },
  FETCH_MONTHLY_SITE_REVENUES: {
    REQUEST: 'FETCH_MONTHLY_SITE_REVENUES.REQUEST',
    SUCCESS: 'FETCH_MONTHLY_SITE_REVENUES.SUCCESS',
    FAILURE: 'FETCH_MONTHLY_SITE_REVENUES.FAILURE',
  },
};

export default statsTypes;
