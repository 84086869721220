import React from 'react';
import { Row, Col, Card, CardBody, Media } from 'reactstrap';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo-sm-light.png';
import headphoneImg from '../../assets/images/headphone-play.png';
import { useTranslation } from 'react-i18next';

const WelcomeBoxWide = ({ network }) => {
  const user = useSelector((state) => state.Auth.admin);
  const headerImageUrl = network?.headerImageUrl;

  const { t } = useTranslation();

  const logoSound = () => {
    const audio = new Audio(
      'https://firebasestorage.googleapis.com/v0/b/speakup-player.appspot.com/o/assets%2FAudioboost_logo_Estesa.mp3?alt=media&token=c0cfd9f3-2eed-4716-bb50-9a0aa1ef2fa9',
    );
    audio.play();
  };

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <Row style={{ rowGap: '20px' }}>
              <Col lg={headerImageUrl ? 6 : 9}>
                <Media>
                  <div className="mr-3">
                    <img
                      src={user.photoURL || logo}
                      alt=""
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <Media className="align-self-center" body>
                    <div className="text-muted">
                      <h6 className="mb-2 text-dark">
                        {t('Welcome to SpeakUp CMS')}
                      </h6>
                      <h5 className="mb-1">{user.displayName}</h5>
                      <p className="mb-0">{user.role}</p>
                    </div>
                  </Media>
                </Media>
              </Col>
              {headerImageUrl ? (
                <Col lg="6">
                  <img
                    src={headerImageUrl}
                    style={{ maxWidth: '100%', maxHeight: '75px' }}
                    alt=""
                  />
                </Col>
              ) : (
                <Col lg="3" className="d-none d-sm-block align-self-end">
                  <img
                    src={headphoneImg}
                    alt=""
                    className="img-fluid"
                    style={{ height: '70px', cursor: 'pointer' }}
                    onClick={logoSound}
                  />
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default WelcomeBoxWide;
