import { createSelector } from 'reselect';
import roles from '../config/roles';

export const selectCountry = (id) =>
  createSelector(
    (state) => state.Country.countries,
    (countries) => {
      if (!id) return null;
      const country = countries.filter((country) => country.id === id);
      return country.length > 0 ? country[0] : null;
    },
  );

export const actualCountry = createSelector(
  (state) => state.Dashboard.countryId,
  (state) => state.Country.countries,
  (countryId, countries) => {
    if (!countryId) return null;
    const country = countries.filter((country) => country.id === countryId);
    return country.length > 0 ? country[0] : null;
  },
);

export const activeCountries = createSelector(
  (state) => state.Country.countries,
  (countries) => countries.filter((country) => country.active),
);

export const availableCountries = createSelector(
  (state) => state.Auth.admin,
  activeCountries,
  (user, countries) => {
    if (user.role === roles.SUPER_ADMIN) return countries;
    return countries.filter((country) => user.countryIds.includes(country.id));
  },
);
