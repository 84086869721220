import types from './actionTypes';
import initialFilters from '../../../../config/filters';

const initialState = {
  loading: false,
  insights: [],
  startDate: null,
  endDate: null,
  filters: Object.assign({}, initialFilters),
  error: '',
};

const insightReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CREATIVE_INSIGHT_STATE:
      return initialState;
    case types.FETCH_CREATIVE_INSIGHTS.REQUEST:
    case types.FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.FETCH_CREATIVE_INSIGHTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        insights: action.insights,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: action.filters,
      };
    case types.FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        insights: action.insights,
      };
    case types.FETCH_CREATIVE_INSIGHTS.FAILURE:
    case types.FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default insightReducer;
