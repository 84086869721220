const statsTypes = {
  RESET_CAMPAIGN_STATE: 'RESET_CAMPAIGN_STATE',
  RESET_CAMPAIGN_MESSAGE: 'RESET_CAMPAIGN_MESSAGE',
  RESET_CAMPAIGN_PUBLISH_RESULTS: 'RESET_CAMPAIGN_PUBLISH_RESULTS',
  CREATE_CAMPAIGN: {
    REQUEST: 'CREATE_CAMPAIGN.REQUEST',
    SUCCESS: 'CREATE_CAMPAIGN.SUCCESS',
    FAILURE: 'CREATE_CAMPAIGN.FAILURE',
  },
  UPDATE_CAMPAIGN: {
    REQUEST: 'UPDATE_CAMPAIGN.REQUEST',
    DRAFT_SUCCESS: 'UPDATE_CAMPAIGN.DRAFT_SUCCESS',
    SUCCESS: 'UPDATE_CAMPAIGN.SUCCESS',
    FAILURE: 'UPDATE_CAMPAIGN.FAILURE',
  },
  DELETE_DRAFT_CAMPAIGN: {
    REQUEST: 'DELETE_DRAFT_CAMPAIGN.REQUEST',
    SUCCESS: 'DELETE_DRAFT_CAMPAIGN.SUCCESS',
    FAILURE: 'DELETE_DRAFT_CAMPAIGN.FAILURE',
  },
  APPROVE_CAMPAIGN: {
    REQUEST: 'APPROVE_CAMPAIGN.REQUEST',
    SUCCESS: 'APPROVE_CAMPAIGN.SUCCESS',
    FAILURE: 'APPROVE_CAMPAIGN.FAILURE',
  },
  REJECT_CAMPAIGN: {
    REQUEST: 'REJECT_CAMPAIGN.REQUEST',
    SUCCESS: 'REJECT_CAMPAIGN.SUCCESS',
    FAILURE: 'REJECT_CAMPAIGN.FAILURE',
  },
  UPDATE_REJECT_MESSAGE_CAMPAIGN: {
    REQUEST: 'UPDATE_REJECT_MESSAGE_CAMPAIGN.REQUEST',
    SUCCESS: 'UPDATE_REJECT_MESSAGE_CAMPAIGN.SUCCESS',
    FAILURE: 'UPDATE_REJECT_MESSAGE_CAMPAIGN.FAILURE',
  },
  REPROCESS_CAMPAIGN: {
    REQUEST: 'REPROCESS_CAMPAIGN.REQUEST',
    SUCCESS: 'REPROCESS_CAMPAIGN.SUCCESS',
    FAILURE: 'REPROCESS_CAMPAIGN.FAILURE',
  },
  FETCH_CAMPAIGNS: {
    REQUEST: 'FETCH_CAMPAIGNS.REQUEST',
    SUCCESS: 'FETCH_CAMPAIGNS.SUCCESS',
    FAILURE: 'FETCH_CAMPAIGNS.FAILURE',
  },
  FETCH_CAMPAIGN_BY_ID: {
    REQUEST: 'FETCH_CAMPAIGN_BY_ID.REQUEST',
    SUCCESS: 'FETCH_CAMPAIGN_BY_ID.SUCCESS',
    FAILURE: 'FETCH_CAMPAIGN_BY_ID.FAILURE',
  },
  DISCARD_DRAFT_CAMPAIGNS: {
    REQUEST: 'DISCARD_DRAFT_CAMPAIGNS.REQUEST',
    SUCCESS: 'DISCARD_DRAFT_CAMPAIGNS.SUCCESS',
    FAILURE: 'DISCARD_DRAFT_CAMPAIGNS.FAILURE',
  },
  PUBLISH_CAMPAIGNS: {
    REQUEST: 'PUBLISH_CAMPAIGNS.REQUEST',
    SUCCESS: 'PUBLISH_CAMPAIGNS.SUCCESS',
    FAILURE: 'PUBLISH_CAMPAIGNS.FAILURE',
  },
  ACTION_CAMPAIGN: {
    REQUEST: 'ACTION_CAMPAIGN.REQUEST',
    SUCCESS: 'ACTION_CAMPAIGN.SUCCESS',
    FAILURE: 'ACTION_CAMPAIGN.FAILURE',
  },
};

export default statsTypes;
