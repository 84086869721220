import { put, all, call, takeLeading, select } from 'redux-saga/effects';
import types from './actionTypes';
import * as revenuesActions from './actions';
import rsf from '../../../helpers/firebase';
import { toDateFirebase } from '../../../helpers/sharedFunction';

const revenueTransformer = (revenue, data) => ({
  id: revenue.id,
  ...data,
  averageSongDuration: data.averageSongDuration ?? 0,
  date: toDateFirebase(revenue, data, 'date').toDate(),
});

function* fetchRevenuesSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const revenuesRef = rsf.firestore.createCollectionRefWithFilters(
      'dailySiteRevenues',
      countryId,
      startDate,
      endDate,
      filters,
      null,
      null,
      'date',
    );

    const revenuesSnap = yield call(rsf.firestore.getCollection, revenuesRef);

    const revenues = revenuesSnap.docs.map((revenue) =>
      revenueTransformer(revenue, revenue.data()),
    );

    yield put(
      revenuesActions.fetchRevenuesSuccess(
        revenues,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(revenuesActions.fetchRevenuesFailure(error));
  }
}

function* revenueSaga() {
  yield all([
    takeLeading(types.FETCH_SITE_REVENUES.REQUEST, fetchRevenuesSaga),
  ]);
}

export default revenueSaga;
