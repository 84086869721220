import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Alert as AlertBox, Collapse, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as creativesActions from '../../../store/campaigns/creatives/actions';
import { selectCampaign } from '../../../selectors/campaign';
import { selectCreativeByCampaign } from '../../../selectors/creative';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
} from '../../../selectors/auth';
import { creditAmountEmpty as creditAmountEmptySelector } from '../../../selectors/customer';
import DataTableNext from '../../Common/DataTable/DataTableNext';
import Modal from '../../Common/Modal/Modal';
import Alert from '../../Common/Modal/Alert';
import FormCreative from '../Creatives/FormCreative';
import SpeakUpArticlePreview from '../../Common/SpeakUpArticlePreview';
import RejectAlert from '../RejectAlert';
import { NextButton, PrevButton } from '../../Common/Modal/modalActionButtons';
import createTableColumns from '../Creatives/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  RefreshButton,
  ToggleColumnButton,
} from '../../Common/DataTable/tableActionButtons';
import { CAMPAIGN_STATUS } from '../../../config/campaign';
import { capitalize, addOrRemove } from '../../../helpers/sharedFunction';

import { useTranslation } from 'react-i18next';
const REJECT_LABELS = [
  { title: 'Do you want to reject this Creative?', btnLabel: 'Reject' },
  { title: 'Do you confirm this reason', btnLabel: 'Confirm' },
];

const FormCampaignCreative = ({ campaignId, user, onPrev, onNext }) => {
  const campaign = useSelector(selectCampaign(campaignId));
  const creatives = useSelector(selectCreativeByCampaign(campaignId));
  const loading = useSelector((state) => state.Creative.loading);

  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const canApproveCreatives = isAdmin || isManager;
  const creditAmountEmpty = useSelector(creditAmountEmptySelector());

  const error = useSelector((state) => state.Creative.error);
  const success = useSelector((state) => state.Creative.success);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [approveAlert, setApproveAlert] = useState(false);
  const [rejectAlert, setRejectAlert] = useState(false);
  const [reprocessAlert, setReprocessAlert] = useState(false);
  const [actionAlert, setActionAlert] = useState(false);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [actionCreative, setActionCreative] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(creativesActions.fetchCreativesByCampaignId(campaignId));
  }, []);

  const onRefresh = () => {
    dispatch(creativesActions.fetchCreativesByCampaignId(campaignId));
  };

  const openViewModal = (creative) => {
    setSelectedCreative(creative);
    setViewModal(true);
  };

  const openEditModal = (creative) => {
    setSelectedCreative(creative);
    setEditModal(true);
  };

  const openDeleteAlert = (creative) => {
    setSelectedCreative(creative);
    setDeleteAlert(true);
  };

  const handleDeleteCreative = () => {
    if (selectedCreative.status === CAMPAIGN_STATUS.NEW)
      dispatch(creativesActions.deleteDraftCreative(selectedCreative));
    setDeleteAlert(false);
  };

  const openApproveAlert = (creative) => {
    setSelectedCreative(creative);
    setApproveAlert(true);
  };

  const handleApproveCreative = () => {
    if (selectedCreative.status === CAMPAIGN_STATUS.IN_REVIEW)
      dispatch(creativesActions.approveCreative(selectedCreative));
    setApproveAlert(false);
  };

  const openRejectAlert = (creative) => {
    setSelectedCreative(creative);
    setRejectAlert(true);
  };

  const handleRejectCreative = (reason) => {
    if (selectedCreative.status === CAMPAIGN_STATUS.IN_REVIEW)
      dispatch(creativesActions.rejectCreative(selectedCreative, reason));
    setRejectAlert(false);
  };

  const openReprocessAlert = (creative) => {
    setSelectedCreative(creative);
    setReprocessAlert(true);
  };

  const handleReprocessCreative = () => {
    if (selectedCreative.status === CAMPAIGN_STATUS.ERROR)
      dispatch(creativesActions.reprocessCreative(selectedCreative));
    setReprocessAlert(false);
  };

  const openActionAlert = (creative, action) => {
    dispatch(creativesActions.resetMessage());
    setSelectedCreative(creative);
    setActionCreative(action);
    setActionAlert(true);
  };

  const handleActionCreative = () => {
    dispatch(creativesActions.actionCreative(selectedCreative, actionCreative));
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        null,
        null,
        openViewModal,
        openEditModal,
        openDeleteAlert,
        openApproveAlert,
        openRejectAlert,
        openReprocessAlert,
        openActionAlert,
        false,
        canApproveCreatives,
        creditAmountEmpty,
      ),
    [isAdmin, canApproveCreatives, creditAmountEmpty],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const { t } = useTranslation();

  return (
    <>
      <Collapse isOpen={editModal}>
        {editModal && (
          <FormCreative
            closeModal={() => setEditModal(false)}
            loading={loading}
            campaign={campaign}
            user={user}
            creative={selectedCreative}
          />
        )}
      </Collapse>
      <Collapse isOpen={!editModal}>
        <Row className="align-items-center justify-content-end m-auto">
          <RefreshButton disabled={loading} onPress={onRefresh} />
          <CountRowTablePlacement />
          <Spinner color="secondary" animation="border" hidden={!loading} />
          <CreateButton
            label="Add Creative"
            disabled={loading}
            onPress={openEditModal}
          />
          <ToggleColumnButton
            columns={columns}
            hiddenColumns={hiddenColumns}
            onToggleColumn={handleToggleColumn}
          />
        </Row>
        <DataTableNext
          loading={loading}
          rows={creatives}
          columns={columns}
          filters={filters}
          onFiltersChange={setFilters}
          sorting={sorting}
          onSortingChange={setSorting}
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          hiddenColumns={hiddenColumns}
          onHiddenColumnsChange={setHiddenColumns}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <div className="modal-footer">
          <PrevButton
            disabled={loading}
            onClick={() => onPrev(campaign.free ? 2 : 1)}
          />
          {!!creatives.length && (
            <NextButton
              label="Save"
              type="button"
              disabled={loading}
              onClick={onNext}
            />
          )}
        </div>
      </Collapse>
      <Modal
        size="md"
        title={selectedCreative?.name || 'Creative'}
        isOpen={viewModal}
        toggle={() => setViewModal(!viewModal)}
        scrollable={false}>
        <div className="position-relative m-auto" style={{ width: '330px' }}>
          <SpeakUpArticlePreview
            title={selectedCreative?.title}
            brand={selectedCreative?.brand}
            image={selectedCreative?.image}
            link={selectedCreative?.link}
            audio={selectedCreative?.audioFile}
          />
        </div>
      </Modal>
      <Alert
        isOpen={deleteAlert}
        title="Do you want to delete this Creative?"
        confirmBtnLabel="Delete"
        onCancel={() => setDeleteAlert(false)}
        onConfirm={handleDeleteCreative}>
        There's no coming back!
      </Alert>
      <Alert
        isOpen={approveAlert}
        title="Do you want to approve this Creative?"
        confirmBtnLabel="Approve"
        onCancel={() => setApproveAlert(false)}
        onConfirm={handleApproveCreative}>
        There's no coming back!
      </Alert>
      <RejectAlert
        isOpen={rejectAlert}
        labels={REJECT_LABELS}
        onCancel={() => setRejectAlert(false)}
        onConfirm={handleRejectCreative}
      />
      <Alert
        isOpen={reprocessAlert}
        title="Do you want to reprocess this Creative?"
        confirmBtnLabel="Reprocess"
        onCancel={() => setReprocessAlert(false)}
        onConfirm={handleReprocessCreative}>
        There's no coming back!
      </Alert>
      <Alert
        isOpen={actionAlert}
        type={error ? 'danger' : success ? 'success' : 'custom'}
        title={`Do you want to ${actionCreative} creative?`}
        loading={loading}
        confirmBtnLabel={capitalize(actionCreative || 'waiting')}
        cancelBtnLabel={error || success ? 'Close' : 'Cancel'}
        onCancel={() => setActionAlert(false)}
        onConfirm={handleActionCreative}
        disabledBtn={loading}
        disableButtonOnConfirm={true}
        dependencies={[selectedCreative]}>
        <h5>{selectedCreative?.name}</h5>
        {error && <AlertBox color="danger">{error}</AlertBox>}
        {success && <AlertBox color="success">{success}</AlertBox>}
      </Alert>
    </>
  );
};

export default FormCampaignCreative;
