import React from 'react';
import { Progress } from 'reactstrap';
import {
  InsightsButton,
  ViewButton,
  EditButton,
  DeleteButton,
  ApproveButton,
  RejectButton,
  ReprocessButton,
  PlayButton,
  PauseButton,
  OpenUrlButton,
} from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getSort,
  getLabels,
  getLabel,
} from '../Common/DataTable/tableHelpers';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import {
  CAMPAIGN_STATUS,
  campaignStatusColor,
  GAM_BASE_URL,
} from '../../config/campaign';

const rejectionMessagesFormatter = (value, row) => {
  if (!(Array.isArray(value) && value.length)) return '';
  return (
    <div className="flex-fill text-center">
      <i
        id={'rejectionMessages_' + row.id}
        className="bx bx-error-circle font-size-20 text-danger"></i>
      <UncontrolledTooltip
        placement="top"
        target={'rejectionMessages_' + row.id}>
        {value.map(({ name, reason }) => (
          <li
            className="d-inline-block text-truncate"
            style={{ maxWidth: '200px' }}>{`${name}: ${reason}`}</li>
        ))}
      </UncontrolledTooltip>
    </div>
  );
};

const createTableColumns = (
  sites,
  customers,
  openViewModal,
  openEditModal,
  openDeleteAlert,
  openApproveAlert,
  openRejectAlert,
  openReprocessAlert,
  openActionAlert,
  openCampaignInsightsModal,
  isAdmin,
  isCustomer,
  canApproveCampaigns,
  campaignsToApprove,
  creditAmountEmpty,
) => {
  return [
    {
      title: 'Insights',
      name: 'insights',
      width: 70,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          {row.impressions > 0 && (
            <InsightsButton row={row} onPress={openCampaignInsightsModal} />
          )}
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
      fixed: 'left',
    },
    {
      title: 'Name',
      name: 'name',
      width: 250,
      fixed: 'left',
    },
    {
      title: 'Status',
      name: 'status',
      width: 110,
      fixed: 'left',
      formatterComponent: ({ value }) => (
        <span
          className={`badge badge-${campaignStatusColor(value)} font-size-12`}>
          {value.toUpperCase()}
        </span>
      ),
    },
    {
      title: 'Pacing',
      name: 'pacing',
      width: 100,
      formatterComponent: ({ row }) => {
        const { deliveryIndicator = 0 } = row;
        if (!deliveryIndicator || deliveryIndicator === 'N/A') return '';
        const color =
          deliveryIndicator <= 0.75
            ? 'danger'
            : deliveryIndicator <= 0.92
            ? 'warning'
            : 'success';
        return (
          <Progress
            color={color}
            value={(deliveryIndicator / 2) * 100}
            style={{
              height: '20px',
              fontSize: '12px',
            }}>
            {Math.round(deliveryIndicator * 100)}%
          </Progress>
        );
      },
      filter: 'disabled',
      sorting: 'disabled',
    },
    {
      title: 'Lifetime Streaming',
      name: 'impressions',
      width: 120,
      formatterComponent: ({ value }) => value || 0,
      filter: 'disabled',
      sorting: (a, b) => getSort(a || 0, b || 0),
    },
    {
      title: 'Start Date',
      name: 'startDate',
      width: 120,
      formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
      filter: (value, filter) =>
        getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
      sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    },
    {
      title: 'End Date',
      name: 'endDate',
      width: 120,
      formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
      filter: (value, filter) =>
        getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
      sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
    },
    {
      title: 'Daily Budget',
      name: 'dailyBudget',
      width: 100,
      formatterComponent: ({ value, row }) =>
        row.free ? (
          <span className="badge badge-success font-size-12">FREE</span>
        ) : (
          `€ ${value ?? 0}`
        ),
      filter: (value, filter, row) =>
        getFilter(row.free ? 'free' : value ? `${value}` : '0', filter.value),
      sorting: 'disabled',
    },
    {
      title: 'Total Spent',
      name: 'amountSpent',
      width: 100,
      formatterComponent: ({ value }) => `€ ${value ? value.toFixed(2) : 0}`,
      filter: (value, filter) =>
        getFilter(value ? `${value}` : '0', filter.value),
      sorting: (a, b) => getSort(a ?? 0, b ?? 0),
    },
    {
      title: 'Errors',
      name: 'rejectionMessages',
      width: 100,
      formatterComponent: ({ value, row }) =>
        rejectionMessagesFormatter(value, row),
      filter: 'disabled',
      sorting: 'disabled',
      // csvFormatter: (cell, row) =>
      //   Array.isArray(cell) && cell.length ? cell.join('|') : '',
    },
    {
      title: 'Sites',
      name: 'siteIds',
      formatterComponent: ({ value }) => getLabels(value, sites, 'id', 'name'),
      filter: (value, filter) =>
        getFilter(getLabels(value, sites, 'id', 'name'), filter.value),
      wordWrapEnabled: true,
      hidden: true,
    },
    {
      title: 'Customer',
      name: 'customerId',
      width: 100,
      formatterComponent: ({ value }) =>
        getLabel(value, customers, 'id', 'name'),
      filter: (value, filter) =>
        getFilter(getLabel(value, customers, 'id', 'name'), filter.value),
      hidden: true,
    },
    {
      title: 'Draft',
      name: 'isDraft',
      width: 100,
      sort: true,
      formatterComponent: ({ value }) =>
        value ? (
          <span className="badge badge-danger font-size-12">YES</span>
        ) : (
          <span className="badge badge-secondary font-size-12">NO</span>
        ),
      filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
      sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
    },
    {
      title: 'Action',
      name: 'action',
      width: 190,
      align: 'center',
      formatterComponent: ({ row }) => {
        const { NEW, SCHEDULED, LEARNING, RUNNING, PAUSED } = CAMPAIGN_STATUS;
        return (
          <ul className="list-inline font-size-20 contact-links mb-0">
            {isAdmin && <ViewButton row={row} onPress={openViewModal} />}
            {!(isCustomer && row.free) && (
              <EditButton row={row} onPress={openEditModal} />
            )}
            {row.status === NEW && (
              <DeleteButton row={row} onPress={openDeleteAlert} />
            )}
            {canApproveCampaigns &&
              campaignsToApprove.map(({ id }) => id).includes(row.id) && (
                <>
                  <ApproveButton row={row} onPress={openApproveAlert} />
                  <RejectButton row={row} onPress={openRejectAlert} />
                </>
              )}
            {row.status === PAUSED && !row.isDraft && !creditAmountEmpty && (
              <PlayButton
                row={row}
                onPress={(row) => openActionAlert(row, 'resume')}
                tooltip="Resume"
              />
            )}
            {[SCHEDULED, LEARNING, RUNNING].includes(row.status) &&
              !row.isDraft && (
                <PauseButton
                  row={row}
                  onPress={(row) => openActionAlert(row, 'pause')}
                />
              )}
            {row.status === CAMPAIGN_STATUS.ERROR && canApproveCampaigns && (
              <ReprocessButton
                row={row}
                onPress={(row) => openReprocessAlert(row)}
                label="Reprocess Campaign"
              />
            )}
            {isAdmin && row.gamLineItemId && (
              <OpenUrlButton
                row={row}
                icon="bx bxl-google"
                url={`${GAM_BASE_URL}#delivery/line_item/detail/line_item_id=${row.gamLineItemId}`}
                label="Open in Google Ad Manager"
              />
            )}
          </ul>
        );
      },
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
