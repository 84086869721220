import {
  put,
  all,
  call,
  takeLeading,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as creativesActions from './actions';
import * as campaignsActions from '../actions';
import { selectCreative } from '../../../selectors/creative';
import { actualCustomer } from '../../../selectors/customer';

import firebase from 'firebase/app';
import rsf from '../../../helpers/firebase';

import { toDateFirebase, isEqualObjs } from '../../../helpers/sharedFunction';
import { DELETE_SENTINEL } from '../../../helpers/reducerHelper';
import { CAMPAIGN_STATUS } from '../../../config/campaign';
import moment from 'moment';

const STORAGE_BASE_PATH = 'tempCreativeFiles/';

function* createCreativeSaga({ creative }) {
  try {
    const { id, startDate, endDate, files, audioDuration } = creative;
    const countryId = yield select((state) => state.Dashboard.countryId);

    let imageURL, audioFileURL;
    if (files) {
      const { image, audioFile } = files;
      [imageURL, audioFileURL] = yield all(
        Object.values({ image, audioFile }).map((file) =>
          call(uploadFile, id, file),
        ),
      );
    }

    const audioBitrate =
      Math.ceil(Math.round(files.audioFile.size / 128 / audioDuration) / 16) *
      16;
    delete creative.files;

    yield put(
      creativesActions.createCreativeSuccess({
        ...creative,
        startDate: moment(startDate).startOf('day').toDate(),
        endDate: moment(endDate).endOf('day').toDate(),
        countryId,
        isDraft: true,
        status: CAMPAIGN_STATUS.NEW,
        ...(imageURL && { image: imageURL }),
        ...(audioFileURL && { audioFile: audioFileURL }),
        audioBitrate,
      }),
    );
  } catch (error) {
    yield put(creativesActions.createCreativeFailure(error));
  }
}

function* updateCreativeSaga({ creative }) {
  try {
    const { id, startDate, endDate, files, audioDuration } = creative;

    let imageURL, audioFileURL, audioBitrate;
    if (files?.image) imageURL = yield call(uploadFile, id, files?.image);
    if (files?.audioFile) {
      audioFileURL = yield call(uploadFile, id, files?.audioFile);
      audioBitrate =
        Math.ceil(Math.round(files.audioFile.size / 128 / audioDuration) / 16) *
        16;
    }

    delete creative.files;
    const updateCreative = {
      ...creative,
      startDate: moment(startDate).startOf('day').toDate(),
      endDate: moment(endDate).endOf('day').toDate(),
      isDraft: true,
      ...(imageURL && { image: imageURL }),
      ...(audioFileURL && { audioFile: audioFileURL }),
      ...(audioBitrate && { audioBitrate }),
    };

    const prevCreative = yield select(selectCreative(id));
    console.log({ prevCreative, updateCreative });
    if (isEqualObjs(prevCreative, updateCreative))
      throw new Error('nothing to update!');

    yield put(
      creative.status === CAMPAIGN_STATUS.NEW
        ? creativesActions.updateDraftCreativeSuccess(updateCreative)
        : creativesActions.updateCreativeSuccess(updateCreative),
    );
  } catch (error) {
    yield put(creativesActions.updateCreativeFailure(error));
  }
}

function* uploadFile(creativeId, file) {
  const filePath = `${STORAGE_BASE_PATH}${creativeId}/${file.path.replace(
    /[^a-z0-9.]/gi,
    '_',
  )}`;
  const task = rsf.storage.uploadFile(filePath, file, {
    contentType: file.type,
  });

  task.on('state_changed', (snapshot) => {
    const percentage = Math.round(
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
    );
    console.log(`${filePath} - ${percentage}%`);
  });

  yield task; // Wait for upload to complete

  const url = yield call(rsf.storage.getDownloadURL, task.snapshot.ref);
  console.log(url);
  return url;
}

function* deleteDraftCreativeSaga({ creative }) {
  try {
    const { id, status, files, image, audioFile } = creative;

    files &&
      Object.values(files).map((file) => URL.revokeObjectURL(file.preview));

    if (status === CAMPAIGN_STATUS.NEW)
      yield all(
        [image, audioFile].map((file) => {
          const path = new URL(decodeURIComponent(file)).pathname;
          const fileName = path.substring(path.lastIndexOf('/') + 1);
          const filePath = `${STORAGE_BASE_PATH}${id}/${fileName}`;
          return call(rsf.storage.deleteFile, filePath);
        }),
      );

    yield put(creativesActions.deleteDraftCreativeSuccess(creative));
  } catch (error) {
    yield put(creativesActions.deleteDraftCreativeFailure(error));
  }
}

function* approveCreativeSaga({ creative }) {
  try {
    const userId = yield select((state) => state.Auth.admin.id);

    const creativesRef = firebase
      .firestore()
      .collection('creatives')
      .doc(creative.id);

    yield call(
      rsf.firestore.setDocument,
      creativesRef,
      {
        status: CAMPAIGN_STATUS.APPROVED,
        approvedAt: firebase.firestore.FieldValue.serverTimestamp(),
        approvedByUserId: userId,
        rejectionMessage: firebase.firestore.FieldValue.delete(),
        rejectedByUserId: firebase.firestore.FieldValue.delete(),
      },
      { merge: true },
    );
    yield put(
      campaignsActions.updateRejectMessageCampaign(
        { id: creative.campaignId },
        { id: creative.id },
        'remove',
      ),
    );
    yield put(
      creativesActions.approveCreativeSuccess({
        id: creative.id,
        status: CAMPAIGN_STATUS.APPROVED,
        approvedAt: new Date(),
        approvedByUserId: userId,
        rejectionMessage: DELETE_SENTINEL,
        rejectedByUserId: DELETE_SENTINEL,
      }),
    );
  } catch (error) {
    yield put(creativesActions.approveCreativeFailure(error));
  }
}

function* rejectCreativeSaga({ creative, reason }) {
  try {
    const userId = yield select((state) => state.Auth.admin.id);
    const { id, name, campaignId } = creative;

    const creativesRef = firebase.firestore().collection('creatives').doc(id);

    yield call(
      rsf.firestore.setDocument,
      creativesRef,
      {
        status: CAMPAIGN_STATUS.REJECTED,
        rejectionMessage: reason,
        rejectedByUserId: userId,
      },
      { merge: true },
    );
    yield put(
      campaignsActions.updateRejectMessageCampaign(
        { id: campaignId },
        { id, name, reason, ref: 'creative' },
      ),
    );
    yield put(
      creativesActions.rejectCreativeSuccess({
        id: creative.id,
        status: CAMPAIGN_STATUS.REJECTED,
        rejectionMessage: reason,
        rejectedByUserId: userId,
      }),
    );
  } catch (error) {
    yield put(creativesActions.rejectCreativeFailure(error));
  }
}

function* reprocessCreativeSaga({ creative }) {
  try {
    const creativesRef = firebase
      .firestore()
      .collection('creatives')
      .doc(creative.id);

    yield call(
      rsf.firestore.setDocument,
      creativesRef,
      {
        status: CAMPAIGN_STATUS.APPROVED,
        error: firebase.firestore.FieldValue.delete(),
      },
      { merge: true },
    );
    yield put(
      creativesActions.reprocessCreativeSuccess({
        id: creative.id,
        status: CAMPAIGN_STATUS.APPROVED,
        error: DELETE_SENTINEL,
      }),
    );
  } catch (error) {
    yield put(creativesActions.reprocessCreativeFailure(error));
  }
}

function* fetchCreativesSaga({ forceUpdate, startAfterId, limit }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const customer = yield select(actualCustomer);

    const startAfterSnap = startAfterId
      ? yield call(rsf.firestore.getDocument, `creatives/${startAfterId}`)
      : null;

    let creativesRef = firebase
      .firestore()
      .collection('creatives')
      .where('countryId', '==', countryId)
      .limit(limit);

    if (startAfterSnap) creativesRef = creativesRef.startAfter(startAfterSnap);
    if (customer)
      creativesRef = creativesRef.where('customerId', '==', customer.id);

    const creativesSnap = yield call(rsf.firestore.getCollection, creativesRef);

    let creatives = [];

    creativesSnap.forEach((creative) => {
      creatives.push(creativeTransformer(creative));
    });

    const previousCreatives = startAfterSnap
      ? yield select((state) => state.Creative.creatives)
      : [];
    const loadMore = limit && creatives.length === limit ? true : false;

    yield put(
      creativesActions.fetchCreativesSuccess(
        [...previousCreatives, ...creatives],
        forceUpdate,
        loadMore,
      ),
    );
  } catch (error) {
    yield put(creativesActions.fetchCreativesFailure(error));
  }
}

function* fetchCreativesByCampaignIdSaga({ campaignId, forceUpdate }) {
  try {
    const creativesRef = firebase
      .firestore()
      .collection('creatives')
      .where('campaignId', '==', campaignId)
      .orderBy('startDate', 'asc');

    const creativesSnap = yield call(rsf.firestore.getCollection, creativesRef);

    let creatives = [];
    creativesSnap.forEach((creative) => {
      creatives.push(creativeTransformer(creative));
    });

    yield put(
      creativesActions.fetchCreativesByCampaignIdSuccess(
        creatives,
        forceUpdate,
      ),
    );
  } catch (error) {
    yield put(creativesActions.fetchCreativesByCampaignIdFailure(error));
  }
}

const creativeTransformer = (creative) => {
  const data = creative.data();
  return {
    id: creative.id,
    ...data,
    startDate: toDateFirebase(creative, data, 'startDate').toDate(),
    endDate: toDateFirebase(creative, data, 'endDate').toDate(),
    createdAt: toDateFirebase(creative, data).toDate(),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(creative, data, 'updatedAt').toDate(),
    }),
    ...(data.approvedAt && {
      approvedAt: toDateFirebase(creative, data, 'approvedAt').toDate(),
    }),
    ...(data.stoppedAt && {
      stoppedAt: toDateFirebase(creative, data, 'stoppedAt').toDate(),
    }),
  };
};

function* actionCreativeSaga({ creative, action }) {
  try {
    const actionCreativeFunction = firebase
      .functions()
      .httpsCallable('actionCreative-actionCreative');
    const { data } = yield call(actionCreativeFunction, {
      creativeId: creative.id,
      action,
    });

    if (data.error) throw new Error(data.error.message);

    yield put(
      creativesActions.actionCreativeSuccess(data.data, `Creative ${action}d!`),
    );
  } catch (error) {
    yield put(creativesActions.actionCreativeFailure(error));
  }
}

function* creativeSaga() {
  yield all([
    takeLatest(types.FETCH_CREATIVES.REQUEST, fetchCreativesSaga),
    takeLeading(types.CREATE_CREATIVE.REQUEST, createCreativeSaga),
    takeLeading(types.UPDATE_CREATIVE.REQUEST, updateCreativeSaga),
    takeEvery(types.DELETE_DRAFT_CREATIVE.REQUEST, deleteDraftCreativeSaga),
    takeLeading(types.APPROVE_CREATIVE.REQUEST, approveCreativeSaga),
    takeLeading(types.REJECT_CREATIVE.REQUEST, rejectCreativeSaga),
    takeLeading(types.REPROCESS_CREATIVE.REQUEST, reprocessCreativeSaga),
    takeLeading(
      types.FETCH_CREATIVES_BY_CAMPAIGN_ID.REQUEST,
      fetchCreativesByCampaignIdSaga,
    ),
    takeLeading(types.ACTION_CREATIVE.REQUEST, actionCreativeSaga),
  ]);
}

export default creativeSaga;
