export const MANDATORY_CSV_IMPORT_VAST_FIELDS = [
  {
    name: 'siteId',
    type: 'id',
    required: true,
  },
  {
    name: 'domain',
    type: 'string',
    required: true,
  },
  {
    name: 'vastUrls',
    type: 'string',
    required: false,
  },
  {
    name: 'advSlots',
    type: 'string',
    required: false,
  },
];

export const SCRAPERS = [
  {
    label: 'Google',
    value: 'google',
  },
  {
    label: 'SmartProxy',
    value: 'smartProxy',
  },
];

export const ADS_TXT_STATUS = {
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  ERROR: 'error',
};
