export default [
  { tag: 'sq-AL', language: 'Albanian Albania' },
  { tag: 'ar-SA', language: 'Arabic Saudi Arabia' },
  { tag: 'cs-CZ', language: 'Czech Czech Republic' },
  { tag: 'da-DK', language: 'Danish Denmark' },
  { tag: 'de-DE', language: 'German Germany' },
  { tag: 'el-GR', language: 'Modern Greek Greece' },
  { tag: 'en-AU', language: 'English Australia' },
  { tag: 'en-GB', language: 'English United Kingdom' },
  { tag: 'en-IE', language: 'English Ireland' },
  { tag: 'en-US', language: 'English United States' },
  { tag: 'en-ZA', language: 'English South Africa' },
  { tag: 'es-ES', language: 'Spanish Spain' },
  { tag: 'es-MX', language: 'Spanish Mexico' },
  { tag: 'fi-FI', language: 'Finnish Finland' },
  { tag: 'fr-CA', language: 'French Canada' },
  { tag: 'fr-FR', language: 'French France' },
  { tag: 'he-IL', language: 'Hebrew Israel' },
  { tag: 'hi-IN', language: 'Hindi India' },
  { tag: 'hu-HU', language: 'Hungarian Hungary' },
  { tag: 'id-ID', language: 'Indonesian Indonesia' },
  { tag: 'it-IT', language: 'Italian Italy' },
  { tag: 'ja-JP', language: 'Japanese Japan' },
  { tag: 'ko-KR', language: 'Korean Republic of Korea' },
  { tag: 'nl-BE', language: 'Dutch Belgium' },
  { tag: 'nl-NL', language: 'Dutch Netherlands' },
  { tag: 'no-NO', language: 'Norwegian Norway' },
  { tag: 'pl-PL', language: 'Polish Poland' },
  { tag: 'pt-BR', language: 'Portuguese Brazil' },
  { tag: 'pt-PT', language: 'Portuguese Portugal' },
  { tag: 'ro-RO', language: 'Romanian Romania' },
  { tag: 'ru-RU', language: 'Russian Russian Federation' },
  { tag: 'sk-SK', language: 'Slovak Slovakia' },
  { tag: 'sv-SE', language: 'Swedish Sweden' },
  { tag: 'th-TH', language: 'Thai Thailand' },
  { tag: 'tr-TR', language: 'Turkish Turkey' },
  { tag: 'zh-CN', language: 'Chinese China' },
  { tag: 'zh-HK', language: 'Chinese Hong Kong' },
  { tag: 'zh-TW', language: 'Chinese Taiwan' },
];

const RTL_LANGUAGES = ['ar', 'he'];

export const isRtl = (langTag) =>
  langTag && RTL_LANGUAGES.includes(langTag.split('-')[0]);
