import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';

import * as revenuesActions from '../../store/revenues/adUnits/actions';
import * as sitesActions from '../../store/sites/actions';
import { useDispatch, useSelector } from 'react-redux';
import { totalMetric } from '../../selectors/revenue';

import Modal from '../../components/Common/Modal/Modal';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import MiniCard from '../../components/Common/MiniCard';
import ImportSpreakerRevenues from '../../components/Revenues/ImportSpreakerRevenues';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Revenues/tableColumns';
import { ToggleColumnButton } from '../../components/Common/DataTable/tableActionButtons';
import { addOrRemove } from '../../helpers/sharedFunction';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

const List = (props) => {
  const loading = useSelector((state) => state.Revenue.loading);

  const revenues = useSelector((state) => state.Revenue.revenues);
  const sites = useSelector((state) => state.Site.sites);

  const impressions = useSelector(totalMetric('impressions'));
  const grossRevenue = useSelector(totalMetric('grossRevenue'));

  const [selectedRevenue, setSelectedRevenue] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [importSpreakerRevenuesModal, setImportSpreakerRevenuesModal] =
    useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const startDate = useSelector((state) => state.Revenue.startDate);
  const endDate = useSelector((state) => state.Revenue.endDate);
  const dataFilters = useSelector((state) => state.Revenue.filters);

  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('01/08/2021', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc(),
    endDate: momentEndDate || moment.utc(),
    publisherId: dataFilters?.publisherId || '',
    siteIds: dataFilters?.siteIds,
  });

  const dispatch = useDispatch();

  const refresh = useCallback(
    ({ startDate, endDate, publisherId, siteIds }) => {
      const selectedStartDate = startDate.utc().toDate();
      const selectedEndDate = endDate.utc().toDate();
      dispatch(
        revenuesActions.fetchRevenues(selectedStartDate, selectedEndDate, {
          publisherId,
          siteIds,
        }),
      );
      setSelectedFilters({ startDate, endDate, publisherId, siteIds });
    },
    [],
  );

  useEffect(() => {
    revenues.length === 0 && refresh(selectedFilters);
    dispatch(sitesActions.syncSites());
  }, []);

  const openViewModal = (revenue) => {
    setSelectedRevenue(revenue);
    setViewModal(true);
  };

  const { t } = useTranslation();

  const metrics = [
    {
      title: t('Impressions'),
      text: Math.floor(impressions),
      color: 'primary',
      icon: 'fas fa-ad',
    },
    {
      title: t('Gross Revenue'),
      text: `${isFinite(grossRevenue) ? grossRevenue.toFixed(2) : 0} €`,
      color: 'dark',
      icon: 'bx bx-euro',
    },
  ];

  const columns = useMemo(
    () => createTableColumns(sites, openViewModal),
    [sites],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Daily Revenues"
            breadcrumbItem="List"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
            onClickButtons={[() => setImportSpreakerRevenuesModal(true)]}
            iconButtons={['bx bx-import']}
            titleButtons={['Import Spreaker Revenues']}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showPublisherFilters
            initialFilters={selectedFilters}
          />
          <Row>
            {metrics.map((metric, index) => (
              <Col key={'metric_' + index}>
                <MiniCard {...metric} loading={loading} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end mb-2">
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={revenues}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="Daily Revenue"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement element={selectedRevenue} icon="mdi mdi-web" />
          </Modal>
          <Modal
            size="lg"
            title={t('Import Spreaker Revenues')}
            isOpen={importSpreakerRevenuesModal}
            toggle={() =>
              setImportSpreakerRevenuesModal(!importSpreakerRevenuesModal)
            }
            showClose
            scrollable={false}>
            <ImportSpreakerRevenues />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
