import React, { memo } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { drawSeriesDailyGraph } from '../../selectors/campaignInsight';
import ReactApexChart from 'react-apexcharts';

import CenterBox from '../Common/CenterBox';
import colors from '../CommonStyle/colors';
import { useTranslation } from 'react-i18next';

const CampaignGraph = ({
  dateRange,
  insightLevel,
  device = 'all',
  loading,
}) => {
  const { t } = useTranslation();

  const dataGraph = useSelector(
    drawSeriesDailyGraph(dateRange, insightLevel, t, device),
  );

  const dailyGraph = {
    series: dataGraph.series,
    options: {
      chart: {
        toolbar: 'false',
        type: 'area',
        stacked: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dddd, dd MMM yyyy',
        },
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 10,
          offsetX: 20,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100],
        },
      },
      markers: {
        size: 3,
        strokeWidth: 3,

        hover: {
          size: 4,
          sizeOffset: 2,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: colors.dark,
          },
        ],
      },
      yaxis: [
        {
          seriesName: dataGraph.series[0].name,
          tickAmount: 5,
          max: parseInt(dataGraph.max),
          min: parseInt(dataGraph.min),
          forceNiceScale: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: dataGraph.series[0].color,
          },
          labels: {
            style: {
              colors: dataGraph.series[0].color,
            },
            formatter: (val, index) => (val ? val.toFixed(0) : '0'),
          },
          title: {
            text: dataGraph.series[0].name,
            style: {
              color: dataGraph.series[0].color,
              fontSize: '15px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
      ],
    },
  };

  return (
    <Card className="flex-fill">
      <CardBody>
        {!loading ? (
          <div id="line-chart" className="apex-charts" dir="ltr">
            <ReactApexChart
              series={dailyGraph.series}
              options={dailyGraph.options}
              type="line"
              height={250}
            />
          </div>
        ) : (
          <CenterBox height={290}>
            <i className="bx bx-hourglass bx-spin font-size-32 text-secondary"></i>
          </CenterBox>
        )}
      </CardBody>
    </Card>
  );
};

export default memo(CampaignGraph);
