import types from './actionTypes';

const initialState = {
  loading: false,
  playlists: [],
  error: '',
};

const playlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PLAYLIST_STATE:
      return initialState;
    case types.CREATE_PLAYLIST.REQUEST:
    case types.UPDATE_PLAYLIST.REQUEST:
    case types.SYNC_PLAYLISTS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_PLAYLIST.SUCCESS:
    case types.UPDATE_PLAYLIST.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_PLAYLISTS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        playlists: [...action.playlists],
      };
    case types.CREATE_PLAYLIST.FAILURE:
    case types.UPDATE_PLAYLIST.FAILURE:
    case types.SYNC_PLAYLISTS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default playlistReducer;
