import { createSelector } from 'reselect';
import { calculateListeningTime } from '../helpers/insightHelper';
import {
  uniqueArrayByKey,
  getDates,
  dateIsInRange,
  mergeAndSum,
  orderValueMap,
  sliceObj,
} from '../helpers/sharedFunction';

import { INSIGHT_LEVEL } from '../config/insight';
import colors from '../components/CommonStyle/colors';
import moment from 'moment';

export const getInsight = (level) =>
  createSelector(
    (state) => state.Insight.insights,
    (state) => state.SiteInsight.insights,
    (state) => state.PublisherInsight.insights,
    (state) => state.NetworkInsight.insights,
    (state) => state.CountryInsight.insights,
    (podcast, site, publisher, network, country) => {
      if (level === INSIGHT_LEVEL.PODCAST) return podcast;
      if (level === INSIGHT_LEVEL.SITE) return site;
      if (level === INSIGHT_LEVEL.PUBLISHER) return publisher;
      if (level === INSIGHT_LEVEL.NETWORK) return network;
      return country;
    },
  );

export const totalMetric = (metric, level) =>
  createSelector(getInsight(level), (insights) =>
    insights.map((insight) => insight[metric] || 0).reduce((a, b) => a + b, 0),
  );

export const listenThroughRate = (level) =>
  createSelector(
    totalMetric('unique-play', level),
    totalMetric('completed', level),
    (play, completed) => (completed / play) * 100,
  );

export const percentageFirstQuartile = (level) =>
  createSelector(
    totalMetric('first-quartile', level),
    totalMetric('unique-play', level),
    (firstQuartile, play) => (firstQuartile / play) * 100,
  );

export const percentageMidpoint = (level) =>
  createSelector(
    totalMetric('midpoint', level),
    totalMetric('unique-play', level),
    (midpoint, play) => (midpoint / play) * 100,
  );

export const percentageThirdQuartile = (level) =>
  createSelector(
    totalMetric('third-quartile', level),
    totalMetric('unique-play', level),
    (thirdQuartile, play) => (thirdQuartile / play) * 100,
  );

export const totalPodcastListeningTime = (level) =>
  createSelector(getInsight(level), (insights) =>
    insights
      .map((insight) => calculateListeningTime(insight))
      .reduce((a, b) => a + b, 0),
  );

export const averageListeningTime = (level) =>
  createSelector(
    totalPodcastListeningTime(level),
    totalMetric('listeningTime', level),
    totalMetric('unique-play', level),
    (podcastListeningTime, listeningTime, plays) =>
      (podcastListeningTime || listeningTime) / plays,
  );

export const averagePodcastDuration = createSelector(
  (state) => state.Insight.insights,
  (insights) => {
    const durations = insights.filter((insight) => !!insight['song-duration']);
    const uniqueSongs = uniqueArrayByKey(durations, 'podcastId');
    return (
      uniqueSongs
        .map((insight) => insight['song-duration'])
        .reduce((a, b) => a + b, 0) / uniqueSongs.length
    );
  },
);

const insightWithAverageSongDurationCount = (level) =>
  createSelector(
    getInsight(level),
    (insights) =>
      insights.filter(({ averageSongDuration }) => averageSongDuration > 0)
        .length,
  );

export const averageDurationTime = (level) =>
  createSelector(
    totalMetric('averageSongDuration', level),
    insightWithAverageSongDurationCount(level),
    (averageSongDuration, insights) => averageSongDuration / insights,
  );

export const mostPlayedPodcasts = (level, limit) =>
  createSelector(getInsight(level), (insights) => {
    const playedPodcasts = insights.map((insight) =>
      sliceObj(insight.playedPodcasts, limit * 10),
    );
    const mostPlayedPodcasts = sliceObj(
      orderValueMap(mergeAndSum(playedPodcasts), 'desc'),
      limit,
    );
    return Object.keys(mostPlayedPodcasts).map((key) => ({
      id: key,
      plays: mostPlayedPodcasts[key],
    }));
  });

export const drawSeriesDailyGraph = (dateRange, level, t) =>
  createSelector(getInsight(level), (insights) => {
    const { startDate, endDate } = dateRange;
    const dates = getDates(startDate.toDate(), endDate.toDate());

    let dataGraph = {
      series: [
        {
          name: t('Articles'),
          data: [],
          type: 'line',
          color: colors.dark,
        },
        {
          name: t('Spoken Articles'),
          data: [],
          type: 'column',
          color: colors.info,
        },
        {
          name: t('Published'),
          data: [],
          type: 'column',
          color: colors.success,
        },
        {
          name: t('Errors'),
          data: [],
          type: 'column',
          color: colors.danger,
        },
        {
          name: t('Costs'),
          data: [],
          type: 'line',
          color: colors.warning,
        },
      ],
      max: -9999999999,
      min: 99999999999,
    };

    dates.forEach((day) => {
      const startDay = moment(day).utc().startOf('day').toDate();
      const endDay = moment(day).utc().endOf('day').toDate();

      const dailyInsights = insights.filter((insight) =>
        dateIsInRange(new Date(insight.date), startDay, endDay),
      );

      const podcasts = dailyInsights
        .map((insight) => insight.podcasts)
        .reduce((a, b) => a + b, 0);

      const synthesized = dailyInsights
        .map((insight) => insight.synthesized)
        .reduce((a, b) => a + b, 0);

      const published = dailyInsights
        .map((insight) => insight.published)
        .reduce((a, b) => a + b, 0);

      const errors = dailyInsights
        .map((insight) => insight.errors)
        .reduce((a, b) => a + b, 0);

      const synthesisCost = dailyInsights
        .map((insight) => insight.synthesisCost)
        .reduce((a, b) => a + b, 0);

      dataGraph.series[0].data.push([startDay.getTime(), podcasts]);
      dataGraph.series[1].data.push([startDay.getTime(), synthesized]);
      dataGraph.series[2].data.push([startDay.getTime(), published]);
      dataGraph.series[3].data.push([startDay.getTime(), errors]);
      dataGraph.series[4].data.push([
        startDay.getTime(),
        isFinite(synthesisCost) ? synthesisCost.toFixed(2) : 0,
      ]);

      dataGraph.max = Math.max(
        dataGraph.max,
        podcasts,
        synthesized,
        published,
        errors,
      );
      dataGraph.min = Math.min(
        dataGraph.min,
        podcasts,
        synthesized,
        published,
        errors,
      );
    });
    return dataGraph;
  });

export const drawSeriesListeningDailyGraph = (dateRange, level, t) =>
  createSelector(getInsight(level), (insights) => {
    const { startDate, endDate } = dateRange;

    const dates = getDates(startDate.toDate(), endDate.toDate());

    let dataGraph = {
      series: [
        {
          name: t('Streaming'),
          data: [],
          type: 'column',
          color: colors.warning,
        },
        {
          name: t('Completed'),
          data: [],
          type: 'column',
          color: colors.success,
        },
        {
          name: t('eLTR'),
          data: [],
          type: 'line',
          color: colors.dark,
        },
      ],
      max: -9999999999,
      min: 99999999999,
    };

    dates.forEach((day) => {
      const startDay = moment(day).utc().startOf('day').toDate();
      const endDay = moment(day).utc().endOf('day').toDate();

      const selectedInsights = insights.filter((insight) =>
        dateIsInRange(new Date(insight.date), startDay, endDay),
      );

      const play = selectedInsights
        .map((insight) => insight['unique-play'] || 0)
        .reduce((a, b) => a + b, 0);

      const completed = selectedInsights
        .map((insight) => insight['completed'] || 0)
        .reduce((a, b) => a + b, 0);

      const eLTR = (completed / play) * 100;

      dataGraph.series[0].data.push([startDay.getTime(), play]);
      dataGraph.series[1].data.push([startDay.getTime(), completed]);
      dataGraph.series[2].data.push([
        startDay.getTime(),
        isFinite(eLTR) ? eLTR.toFixed(2) : 0,
      ]);

      dataGraph.max = Math.max(dataGraph.max, play, completed);
      dataGraph.min = Math.min(dataGraph.min, play, completed);
    });
    return dataGraph;
  });

export const drawSeriesPlayedBar = () =>
  createSelector(
    (state) => state.Insight.playedPodcasts,
    (podcasts) => {
      if (!Array.isArray(podcasts) || !podcasts.length)
        return {
          series: [],
          labels: [],
        };

      podcasts = podcasts.sort((a, b) => b.plays - a.plays);

      return {
        series: podcasts.map(({ plays }) => plays),
        labels: podcasts.map(({ title }) =>
          title ? `${title.substring(0, 60)}...` : '',
        ),
      };
    },
  );
