const statsTypes = {
  RESET_CLUSTER_STATE: 'RESET_CLUSTER_STATE',
  CREATE_CLUSTER: {
    REQUEST: 'CREATE_CLUSTER.REQUEST',
    SUCCESS: 'CREATE_CLUSTER.SUCCESS',
    FAILURE: 'CREATE_CLUSTER.FAILURE',
  },
  UPDATE_CLUSTER: {
    REQUEST: 'UPDATE_CLUSTER.REQUEST',
    SUCCESS: 'UPDATE_CLUSTER.SUCCESS',
    FAILURE: 'UPDATE_CLUSTER.FAILURE',
  },
  CREATE_DRAFT_CLUSTER: {
    REQUEST: 'CREATE_DRAFT_CLUSTER.REQUEST',
    SUCCESS: 'CREATE_DRAFT_CLUSTER.SUCCESS',
    FAILURE: 'CREATE_DRAFT_CLUSTER.FAILURE',
  },
  UPDATE_DRAFT_CLUSTER: {
    REQUEST: 'UPDATE_DRAFT_CLUSTER.REQUEST',
    SUCCESS: 'UPDATE_DRAFT_CLUSTER.SUCCESS',
    FAILURE: 'UPDATE_DRAFT_CLUSTER.FAILURE',
  },
  DELETE_DRAFT_CLUSTER: {
    REQUEST: 'DELETE_DRAFT_CLUSTER.REQUEST',
    SUCCESS: 'DELETE_DRAFT_CLUSTER.SUCCESS',
    FAILURE: 'DELETE_DRAFT_CLUSTER.FAILURE',
  },
  SYNC_CLUSTERS: {
    REQUEST: 'SYNC_CLUSTERS.REQUEST',
    SUCCESS: 'SYNC_CLUSTERS.SUCCESS',
    FAILURE: 'SYNC_CLUSTERS.FAILURE',
  },
};

export default statsTypes;
