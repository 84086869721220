export default {
  publisherId: '',
  siteIds: null,
};

export const networkFilters = {
  networkId: '',
};

export const showAllSelect = { id: '', name: 'All' };
