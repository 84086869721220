import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_DASHBOARD_STATE,
});

export const setFilters = (startDate, endDate, filters) => ({
  type: types.SET_DASHBOARD_FILTERS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const setFiltersSuccess = (startDate, endDate, filters) => ({
  type: types.SET_DASHBOARD_FILTERS.SUCCESS,
  startDate,
  endDate,
  filters,
});

export const setFiltersFailure = (error) => ({
  type: types.SET_DASHBOARD_FILTERS.FAILURE,
  error,
});

export const setCountry = (countryId) => ({
  type: types.SET_COUNTRY.REQUEST,
  countryId,
});

export const setCountrySuccess = (countryId) => ({
  type: types.SET_COUNTRY.SUCCESS,
  countryId,
});

export const setCountryFailure = (error) => ({
  type: types.SET_COUNTRY.FAILURE,
  error,
});

export const fetchInsights = (publisher) => ({
  type: types.FETCH_DASHBOARD_INSIGHTS.REQUEST,
  publisher,
});

export const fetchInsightsSuccess = (insights) => ({
  type: types.FETCH_DASHBOARD_INSIGHTS.SUCCESS,
  insights,
});

export const fetchInsightsFailure = (error) => ({
  type: types.FETCH_DASHBOARD_INSIGHTS.FAILURE,
  error,
});
