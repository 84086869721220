const statsTypes = {
  RESET_COUNTRY_INSIGHT_STATE: 'RESET_COUNTRY_INSIGHT_STATE',
  FETCH_COUNTRY_INSIGHTS: {
    REQUEST: 'FETCH_COUNTRY_INSIGHTS.REQUEST',
    SUCCESS: 'FETCH_COUNTRY_INSIGHTS.SUCCESS',
    FAILURE: 'FETCH_COUNTRY_INSIGHTS.FAILURE',
  },
  FETCH_COUNTRY_INSIGHTS_BY_PODCAST: {
    REQUEST: 'FETCH_COUNTRY_INSIGHTS_BY_PODCAST.REQUEST',
    SUCCESS: 'FETCH_COUNTRY_INSIGHTS_BY_PODCAST.SUCCESS',
    FAILURE: 'FETCH_COUNTRY_INSIGHTS_BY_PODCAST.FAILURE',
  },
};

export default statsTypes;
