import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import { getFilter } from '../Common/DataTable/tableHelpers';

const createTableColumns = (openViewModal, openEditModal) => {
  return [
    {
      title: 'Name',
      name: 'name',
    },
    {
      title: 'Company Name',
      name: 'companyName',
    },
    {
      title: 'City',
      name: 'city',
    },
    {
      title: 'Country',
      name: 'country',
      width: 100,
    },
    {
      title: 'Publisher?',
      name: 'isPublisher',
      width: 120,
      formatterComponent: ({ value }) =>
        value ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
      filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    },
    {
      title: 'Credit',
      name: 'creditAmount',
      width: 100,
      formatterComponent: ({ value }) => `${value ? value.toFixed(2) : 0} €`,
      filter: 'disabled',
    },
    {
      title: 'Stripe',
      name: 'stripeSetup',
      width: 130,
      formatterComponent: ({ value }) =>
        value === 'completed' ? (
          <span className="badge badge-success font-size-12">
            {value.toUpperCase()}
          </span>
        ) : value === 'error' ? (
          <span className="badge badge-danger font-size-12">
            {value.toUpperCase()}
          </span>
        ) : (
          <span className="badge badge-warning font-size-12">
            {value ? value.toUpperCase() : ''}
          </span>
        ),
      filter: (value, filter) => getFilter(value, filter.value),
    },
    {
      title: 'Action',
      name: 'action',
      width: 120,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          <EditButton row={row} onPress={openEditModal} />
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
