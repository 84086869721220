import React, { useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Collapse,
  Label,
} from 'reactstrap';
import * as campaignsActions from '../../../store/campaigns/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCampaign } from '../../../selectors/campaign';
import { selectCluster } from '../../../selectors/cluster';
import { campaignClusterForSelect } from '../../../selectors/cluster';
import { Form } from '../../../lib/availity/form';
import { Select } from '../../../lib/availity/select';
import FormCampaignClusterDetail from './FormCampaignClusterDetail';
import { NextButton, PrevButton } from '../../Common/Modal/modalActionButtons';
import ClusterDetails from '../../Clusters/ClusterDetails';

import { Dots } from 'react-activity';
import { useTranslation } from 'react-i18next';
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_CLUSTER_INITIAL_STATE,
} from '../../../config/campaign';

const FormCampaignCluster = ({ campaignId, user, onPrev, onNext }) => {
  const campaign = useSelector(selectCampaign(campaignId));
  const cluster = useSelector(selectCluster(campaign?.clusterId));
  const loading = useSelector((state) => state.Cluster.loading);
  const loadingCampaign = useSelector((state) => state.Campaign.loading);
  const isLoading = loading || loadingCampaign;

  const clusters = useSelector(campaignClusterForSelect(campaign, user));

  const { NO_CLUSTER, CREATE_CLUSTER } = CAMPAIGN_CLUSTER_INITIAL_STATE;

  const [selectedClusterId, setSelectedClusterId] = useState(
    campaign?.clusterId || NO_CLUSTER,
  );

  const showEditClusterButton =
    campaign.clusterId === CREATE_CLUSTER ||
    cluster?.isDraft ||
    cluster?.setup === CAMPAIGN_STATUS.IN_REVIEW;
  const [showClusterForm, setShowClusterForm] = useState(false);

  const dispatch = useDispatch();

  const onChangeCluster = (value) => {
    if (selectedClusterId === value) return;

    dispatch(
      campaignsActions.updateCampaign({
        ...campaign,
        clusterId: value,
      }),
    );
    setSelectedClusterId(value);
    setShowClusterForm(false);
  };

  const { t } = useTranslation();

  return (
    <>
      {!isLoading ? (
        <>
          <Row>
            <Col md="5">
              <Form initialValues={{ clusterId: campaign?.clusterId }}>
                <FormGroup>
                  <Label htmlFor="clusterId">Cluster</Label>
                  <InputGroup>
                    <Select
                      name="clusterId"
                      id="clusterId"
                      onChange={onChangeCluster}
                      value={selectedClusterId}
                      options={clusters}
                      isDisabled={campaign.approvedAt}
                    />
                    {showEditClusterButton && (
                      <InputGroupAddon addonType="append">
                        <button
                          type="button"
                          className="btn btn-success waves-effect waves-light mb-2 mr-2"
                          disabled={isLoading || showClusterForm}
                          onClick={() => setShowClusterForm(!showClusterForm)}>
                          <i className="bx bx-down-arrow-alt mr-1"></i>
                          {t(
                            campaign.clusterId === CREATE_CLUSTER
                              ? 'Create'
                              : 'Edit',
                          )}
                        </button>
                      </InputGroupAddon>
                    )}
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
            <Col md="7">
              {cluster?.isPublic && <ClusterDetails cluster={cluster} />}
            </Col>
          </Row>
          {showClusterForm && (
            <FormCampaignClusterDetail
              cluster={cluster}
              campaign={campaign}
              user={user}
              loading={isLoading}
              onSave={() => setShowClusterForm(false)}
              onCancel={() => setShowClusterForm(false)}
            />
          )}
        </>
      ) : (
        <Dots />
      )}
      <Collapse isOpen={!showClusterForm}>
        <div className="modal-footer">
          <PrevButton disabled={isLoading} onClick={() => onPrev()} />
          <NextButton
            type="button"
            disabled={
              isLoading || (!cluster && selectedClusterId !== NO_CLUSTER)
            }
            onClick={() => onNext()}
          />
        </div>
      </Collapse>
    </>
  );
};

export default FormCampaignCluster;
