import { createSelector } from 'reselect';
import { selectCampaign } from './campaign';
import { isAdmin as isAdminSelector } from './auth';
import { CAMPAIGN_CLUSTER_INITIAL_STATE } from '../config/campaign';

export const selectCluster = (id) =>
  createSelector(
    (state) => state.Cluster.draftClusters,
    (state) => state.Cluster.clusters,
    (draftCluster, clusters) =>
      id
        ? [...draftCluster, ...clusters].find((cluster) => cluster.id === id)
        : null,
  );

export const selectClusterByCustomer = (customerId) =>
  createSelector(
    (state) => state.Cluster.clusters,
    (clusters) =>
      customerId
        ? clusters.filter((cluster) => cluster.customerId === customerId)
        : [],
  );

export const selectClusterByCampaign = (campaignId, onlyDraft = false) =>
  createSelector(
    selectCampaign(campaignId),
    (state) => state.Cluster.draftClusters,
    (state) => state.Cluster.clusters,
    (campaign, draftClusters, clusters) => {
      if (!campaign || !campaign.clusterId) return null;

      return onlyDraft
        ? [...draftClusters, ...clusters]
            .filter(({ isDraft }) => !!isDraft)
            .find(({ id }) => campaign.clusterId === id)
        : [...draftClusters, ...clusters].find(
            ({ id }) => campaign.clusterId === id,
          );
    },
  );

export const selectAdminClusters = createSelector(
  (state) => state.Cluster.clusters,
  (clusters) =>
    clusters.filter(
      ({ customerId, publisherId }) => !customerId && !publisherId,
    ),
);

export const selectPublicClusters = createSelector(
  (state) => state.Cluster.clusters,
  (clusters) => clusters.filter(({ isPublic }) => !!isPublic),
);

export const campaignClusterForSelect = (campaign, user) =>
  createSelector(
    isAdminSelector,
    (state) => state.Cluster.draftClusters,
    selectAdminClusters,
    selectPublicClusters,
    selectClusterByCustomer(user.customerId),
    selectCluster(campaign.clusterId),
    (
      isAdmin,
      draftClusters,
      adminClusters,
      publicClusters,
      customerClusters,
      cluster,
    ) => {
      const { NO_CLUSTER, CREATE_CLUSTER } = CAMPAIGN_CLUSTER_INITIAL_STATE;
      const newCluster = !campaign.newClusterCreated
        ? [
            {
              label: 'Create New Cluster',
              value: CREATE_CLUSTER,
            },
          ]
        : [];

      let clusters = isAdmin
        ? [...draftClusters, ...adminClusters, ...customerClusters]
        : [...draftClusters, ...publicClusters, ...customerClusters];
      clusters = clusters.find(({ id }) => cluster?.id === id)
        ? clusters
        : [...(cluster ? [cluster] : []), ...clusters];

      return [
        { label: 'No Cluster', value: NO_CLUSTER },
        ...newCluster,
        ...clusters.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      ];
    },
  );
