import '../../lib/availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  langTag: yup.string().label('language content').required(),
  articlesCreatedSince: yup.date().required(),
  includeKeywords: yup
    .string()
    .matches(
      /^[a-zA-Z\u00C0-\u017F0-9]+((,|'|-|\s|\/)[a-zA-Z\u00C0-\u017F0-9]+)*[a-zA-Z\u00C0-\u017F0-9]+$/,
      { excludeEmptyString: true },
    ),
  excludeKeywords: yup
    .string()
    .matches(
      /^[a-zA-Z\u00C0-\u017F0-9]+((,|'|-|\s|\/)[a-zA-Z\u00C0-\u017F0-9]+)*[a-zA-Z\u00C0-\u017F0-9]+$/,
      { excludeEmptyString: true },
    ),
  includeSiteIds: yup.array().nullable(),
  excludeSiteIds: yup.array().nullable(),
  active: yup.boolean().required(),
});

export default formValidation;
