import cloneDeep from 'lodash.clonedeep';

export const calculateListeningTime = (insight) => {
  const insightData = cloneDeep(insight);
  const duration = insightData['song-duration'];
  if (!duration) return 0;

  const stepMetrics = 0.25 / 2;
  const keyMetrics = {
    completed: 1,
    'third-quartile': 0.75 + stepMetrics,
    midpoint: 0.5 + stepMetrics,
    'first-quartile': 0.25 + stepMetrics,
    'unique-play': 0 + stepMetrics,
  };

  let listeningTime = 0;
  let counters = Object.keys(keyMetrics).map((key) => insightData[key] || 0);

  for (let i = 0; i < counters.length; i++) {
    if (counters[i] > 0) {
      for (let j = i + 1; j < counters.length; j++) counters[j] -= counters[i];
    }
  }

  counters.forEach((counter, index) => {
    listeningTime +=
      duration * (counter > 0 ? counter : 0) * Object.values(keyMetrics)[index];
  });

  return listeningTime;
};
