import { createSelector } from 'reselect';

export const actualUser = createSelector(
  (state) => state.User.users,
  (state) => state.Auth.admin.id,
  (users, userId) => users.find((user) => user.id === userId),
);

export const selectUser = (id) =>
  createSelector(
    (state) => state.User.users,
    (users) => {
      if (!id) return null;
      return users.find((user) => user.id === id);
    },
  );

export const selectUserByRole = (roles) =>
  createSelector(
    (state) => state.User.users,
    (users) => {
      if (!roles) return null;
      const arrayRoles = Array.isArray(roles) ? roles : [roles];
      const selectedUsers = users.filter((user) =>
        arrayRoles.includes(user.role),
      );
      return selectedUsers.length > 0 ? selectedUsers : null;
    },
  );

export const selectAdvertiserUsers = (onlyActive = false) =>
  createSelector(
    (state) => state.User.users,
    (users) =>
      onlyActive
        ? users.filter(({ isAdvertiser, active }) => isAdvertiser && active)
        : users.filter(({ isAdvertiser }) => !!isAdvertiser),
  );

export const userName = (id) =>
  createSelector(selectUser(id), (user) => {
    if (!user) return null;
    return `${user.firstName} ${user.lastName}`;
  });

export const userEmailInUse = (excludeUserId = null) =>
  createSelector(
    (state) => state.User.users,
    (users) =>
      users
        .filter((user) => user.id !== excludeUserId)
        .map((user) => user.email),
  );
