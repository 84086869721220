import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  takeEvery,
  take,
  cancel,
  select,
  delay,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as clustersActions from './actions';
import * as campaignsActions from '../campaigns/actions';

import { selectCustomerByUser } from '../../selectors/customer';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import { toDateFirebase } from '../../helpers/sharedFunction';
import { DEFAULT_ARTICLES_CREATED_SINCE } from '../../config/campaign';
import toastr from 'toastr';

function* createClusterSaga({ cluster }) {
  try {
    const clustersRef = firebase.firestore().collection('clusters');
    const countryId = yield select((state) => state.Dashboard.countryId);
    const userId = yield select((state) => state.Auth.admin.id);

    yield call(rsf.firestore.addDocument, clustersRef, {
      ...cluster,
      setup: 'new',
      countryId,
      userId,
      size: 0,
      articlesCreatedSince: firebase.firestore.Timestamp.fromDate(
        new Date(cluster.articlesCreatedSince),
      ),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    yield put(clustersActions.createClusterSuccess(cluster));
    toastr.success('Cluster created!', '');
  } catch (error) {
    yield put(clustersActions.createClusterFailure(error));
  }
}

function* createDraftClusterSaga({ cluster }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const { user, campaignId, campaignStatus } = cluster;
    const customer = yield select(selectCustomerByUser(user.id));
    delete cluster.user;
    delete cluster.campaignId;
    delete cluster.campaignStatus;

    yield delay(1000);

    yield put(
      clustersActions.createDraftClusterSuccess({
        ...cluster,
        setup: 'new',
        countryId,
        customerId: customer.id,
        userId: user.id,
        isDraft: true,
        articlesCreatedSince: DEFAULT_ARTICLES_CREATED_SINCE.toDate(),
      }),
    );
    yield put(
      campaignsActions.updateCampaign({
        id: campaignId,
        status: campaignStatus,
        clusterId: cluster.id,
        newClusterCreated: true,
      }),
    );
  } catch (error) {
    yield put(clustersActions.createDraftClusterFailure(error));
  }
}

function* updateClusterSaga({ cluster }) {
  try {
    const clustersRef = firebase
      .firestore()
      .collection('clusters')
      .doc(cluster.id);
    delete cluster.id;
    delete cluster.createdAt;

    yield call(
      rsf.firestore.setDocument,
      clustersRef,
      {
        ...cluster,
        ...(cluster.articlesCreatedSince && {
          articlesCreatedSince: firebase.firestore.Timestamp.fromDate(
            new Date(cluster.articlesCreatedSince),
          ),
        }),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(clustersActions.updateClusterSuccess());
    toastr.success('Cluster updated!', '');
  } catch (error) {
    yield put(clustersActions.updateClusterFailure(error));
  }
}

function* updateDraftClusterSaga({ cluster }) {
  try {
    const { campaignId, campaignStatus } = cluster;
    delete cluster.campaignId;
    delete cluster.campaignStatus;

    yield delay(1000);

    yield put(
      clustersActions.updateDraftClusterSuccess({
        ...cluster,
        isDraft: true,
      }),
    );
    yield put(
      campaignsActions.updateCampaign({
        id: campaignId,
        status: campaignStatus,
        clusterId: cluster.id,
      }),
    );
  } catch (error) {
    yield put(clustersActions.updateDraftClusterFailure(error));
  }
}

function* deleteDraftClusterSaga({ cluster }) {
  try {
    yield delay(1000);

    yield put(clustersActions.deleteDraftClusterSuccess(cluster));
  } catch (error) {
    yield put(clustersActions.deleteDraftClusterFailure(error));
  }
}

const clusterTransformer = (payload) => {
  let clusters = [];

  payload.forEach((cluster) => {
    const data = cluster.data();

    clusters.push({
      id: cluster.id,
      ...data,
      ...(data.articlesCreatedSince && {
        articlesCreatedSince: toDateFirebase(
          cluster,
          data,
          'articlesCreatedSince',
        ).toDate(),
      }),
      ...(data.createdAt && {
        createdAt: toDateFirebase(cluster, data).toDate(),
      }),
      ...(data.updatedAt && {
        updatedAt: toDateFirebase(cluster, data, 'updatedAt').toDate(),
      }),
    });
  });

  return clusters;
};

function* syncClustersSaga() {
  const countryId = yield select((state) => state.Dashboard.countryId);
  const clustersRef = firebase
    .firestore()
    .collection('clusters')
    .where('countryId', '==', countryId)
    .orderBy('createdAt', 'desc');

  const task = yield fork(rsf.firestore.syncCollection, clustersRef, {
    successActionCreator: clustersActions.syncClustersSuccess,
    failureActionCreator: clustersActions.syncClustersFailure,
    transform: (payload) => clusterTransformer(payload),
  });

  yield take(types.RESET_CLUSTER_STATE);
  yield cancel(task);
}

function* clusterSaga() {
  yield all([
    takeLatest(types.SYNC_CLUSTERS.REQUEST, syncClustersSaga),
    takeLeading(types.CREATE_CLUSTER.REQUEST, createClusterSaga),
    takeLeading(types.CREATE_DRAFT_CLUSTER.REQUEST, createDraftClusterSaga),
    takeLeading(types.UPDATE_CLUSTER.REQUEST, updateClusterSaga),
    takeLeading(types.UPDATE_DRAFT_CLUSTER.REQUEST, updateDraftClusterSaga),
    takeEvery(types.DELETE_DRAFT_CLUSTER.REQUEST, deleteDraftClusterSaga),
  ]);
}

export default clusterSaga;
