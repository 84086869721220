import React, { useState, useEffect, useMemo } from 'react';

import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';
import * as podcastsActions from '../../store/podcasts/actions';
import * as podcastAnalysesActions from '../../store/podcasts/analyses/actions';
import * as sitesActions from '../../store/sites/actions';
import * as clustersActions from '../../store/clusters/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPodcastAnalysis } from '../../selectors/podcast';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
  isQualityChecker as isQualityCheckerSelector,
  userPublisherId,
} from '../../selectors/auth';
import { actualPublisher } from '../../selectors/publisher';

import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import ReactJson from 'react-json-view';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DateRangeFilter from '../../components/Common/DateRangeFilter/DateRangeFilter';
import FormPodcast from '../../components/Podcasts/FormPodcast';
import PreviewPodcast from '../../components/Podcasts/PreviewPodcast';
import PreviewCode from '../../components/Podcasts/PreviewCode';
import PodcastInsights from '../../components/Insights/PodcastInsights';
import BatchPodcastBar from '../../components/Podcasts/BatchPodcastBar';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import createTableColumns from '../../components/Podcasts/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
  RemoteButton,
  ToggleColumnButton,
} from '../../components/Common/DataTable/tableActionButtons';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DEFAULT_CONFIG_TABLE } from '../../components/Common/DataTable/tableConfig';
import { addOrRemove } from '../../helpers/sharedFunction';
import { DELETE_SENTINEL } from '../../helpers/reducerHelper';

const List = (props) => {
  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [selectedPodcastIds, setSelectedPodcastIds] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [previewPodcastModal, setPreviewPodcastModal] = useState(false);
  const [synthesizePodcastAlert, setSynthesizePodcastAlert] = useState(false);
  const [publishPodcastAlert, setPublishPodcastAlert] = useState(false);
  const [rescrapePodcastAlert, setRescrapePodcastAlert] = useState(false);
  const [reprocessPodcastAlert, setReprocessPodcastAlert] = useState(false);
  const [insightsModal, setInsightsModal] = useState(false);
  const [podcastAnalysisModal, setPodcastAnalysisModal] = useState(false);
  const [playerCodeModal, setPlayerCodeModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const loading = useSelector((state) => state.Podcast.loading);
  const loadMore = useSelector((state) => state.Podcast.loadMore);

  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isQualityChecker = useSelector(isQualityCheckerSelector);
  const isStaff = isAdmin || isManager || isQualityChecker;

  const publisherId = useSelector(userPublisherId);
  const publisher = useSelector(actualPublisher);

  const podcasts = useSelector((state) => state.Podcast.podcasts);
  const podcastAnalysis = useSelector(
    selectPodcastAnalysis(selectedPodcast?.id),
  );
  const sites = useSelector((state) => state.Site.sites);
  const clusters = useSelector((state) => state.Cluster.clusters);

  const startDate = useSelector((state) => state.Podcast.startDate);
  const endDate = useSelector((state) => state.Podcast.endDate);
  const dataFilters = useSelector((state) => state.Podcast.filters);

  const momentStartDate = startDate ? moment(startDate) : null;
  const momentEndDate = endDate ? moment(endDate) : null;

  const maxDate = moment.utc();
  const minDate = moment.utc('01/08/2021', 'DD/MM/YYYY');

  const [selectedFilters, setSelectedFilters] = useState({
    startDate: momentStartDate || moment.utc(),
    endDate: momentEndDate || moment.utc(),
    publisherId: dataFilters?.publisherId || publisherId || '',
    siteIds: dataFilters?.siteIds,
  });

  const dispatch = useDispatch();

  const refresh = ({ startDate, endDate, publisherId, siteIds }) => {
    const selectedStartDate = startDate.utc().toDate();
    const selectedEndDate = endDate.utc().toDate();
    dispatch(
      podcastsActions.fetchPodcasts(
        selectedStartDate,
        selectedEndDate,
        {
          publisherId,
          siteIds,
        },
        null,
        DEFAULT_CONFIG_TABLE.DATA_FETCH_LIMIT,
      ),
    );
    setSelectedFilters({ startDate, endDate, publisherId, siteIds });
    setSelectedPodcastIds([]);
  };

  const handleFetchDataTable = () => {
    const { startDate, endDate, publisherId, siteIds } = selectedFilters;
    const selectedStartDate = startDate.utc().startOf('day').toDate();
    const selectedEndDate = endDate.utc().endOf('day').toDate();
    const startAfterId = podcasts.length
      ? podcasts[podcasts.length - 1].id
      : null;

    dispatch(
      podcastsActions.fetchPodcasts(
        selectedStartDate,
        selectedEndDate,
        { publisherId, siteIds },
        startAfterId,
        DEFAULT_CONFIG_TABLE.DATA_FETCH_LIMIT,
      ),
    );
  };

  useEffect(() => {
    dispatch(sitesActions.syncSites());
    dispatch(clustersActions.syncClusters());
  }, []);

  const openViewModal = (podcast) => {
    setSelectedPodcast(podcast);
    setViewModal(true);
  };

  const openEditModal = (podcast) => {
    setSelectedPodcast(podcast);
    setEditModal(true);
  };

  const openPreviewPodcastModal = (podcast) => {
    setSelectedPodcast(podcast);
    setPreviewPodcastModal(true);
  };

  const openSynthesizePodcastAlert = (podcast) => {
    setSelectedPodcast(podcast);
    setSynthesizePodcastAlert(true);
  };

  const handleSynthesizePodcast = () => {
    dispatch(
      podcastsActions.updatePodcast({
        id: selectedPodcast.id,
        synthesized: 'ready',
      }),
    );
    setSynthesizePodcastAlert(false);
  };

  const openPublishPodcastAlert = (podcast) => {
    setSelectedPodcast(podcast);
    setPublishPodcastAlert(true);
  };

  const handlePublishPodcast = () => {
    dispatch(
      podcastsActions.updatePodcast({
        id: selectedPodcast.id,
        published: !selectedPodcast.published,
        publishedAt: !selectedPodcast.published ? new Date() : DELETE_SENTINEL,
      }),
    );
    setPublishPodcastAlert(false);
  };

  const openRescrapePodcastAlert = (podcast) => {
    setSelectedPodcast(podcast);
    setRescrapePodcastAlert(true);
  };

  const handleRescrapePodcast = () => {
    dispatch(
      podcastsActions.updatePodcast({
        id: selectedPodcast.id,
        scraped: 'new',
      }),
    );
    setRescrapePodcastAlert(false);
  };

  const openReprocessPodcastAlert = (podcast) => {
    setSelectedPodcast(podcast);
    setReprocessPodcastAlert(true);
  };

  const handleReprocessPodcast = () => {
    let podcast = { id: selectedPodcast.id, error: '' };
    if (
      selectedPodcast.scraped === 'error' ||
      selectedPodcast.synthesized === 'error'
    )
      podcast = { ...podcast, scraped: 'new', synthesized: 'new' };
    dispatch(podcastsActions.updatePodcast(podcast));
    setReprocessPodcastAlert(false);
  };

  const openInsightsModal = (podcast) => {
    setSelectedPodcast(podcast);
    setInsightsModal(true);
  };

  const openPodcastAnalysisModal = (podcast) => {
    setSelectedPodcast(podcast);
    dispatch(podcastAnalysesActions.fetchPodcastAnalysis(podcast.id));
    setPodcastAnalysisModal(true);
  };

  const openPlayerCodeModal = (podcast) => {
    setSelectedPodcast(podcast);
    setPlayerCodeModal(true);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        openEditModal,
        openPreviewPodcastModal,
        openSynthesizePodcastAlert,
        openPublishPodcastAlert,
        openRescrapePodcastAlert,
        openReprocessPodcastAlert,
        openInsightsModal,
        openPodcastAnalysisModal,
        openPlayerCodeModal,
        isStaff,
        sites,
        clusters,
      ),
    [isStaff, sites, clusters],
  );
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.filter(({ hidden }) => !!hidden).map(({ name }) => name),
  );
  const handleToggleColumn = (name) => {
    setHiddenColumns(addOrRemove(hiddenColumns, name));
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Spoken Articles"
            onRefresh={() => refresh(selectedFilters)}
            loading={loading}
          />
          <DateRangeFilter
            onUpdate={refresh}
            minDate={minDate}
            maxDate={maxDate}
            startDate={selectedFilters.startDate}
            endDate={selectedFilters.endDate}
            loading={loading}
            showPublisherFilters
            initialFilters={selectedFilters}
            showAllFilter={isStaff}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    {(isAdmin || publisher?.createPodcasts) && (
                      <CreateButton
                        label="Create new Spoken Article"
                        disabled={loading}
                        onPress={openEditModal}
                      />
                    )}
                    <RemoteButton
                      label="Load more"
                      disabled={!loadMore || loading}
                      onPress={handleFetchDataTable}
                    />
                    <ToggleColumnButton
                      columns={columns}
                      hiddenColumns={hiddenColumns}
                      onToggleColumn={handleToggleColumn}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={podcasts}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    hiddenColumns={hiddenColumns}
                    onHiddenColumnsChange={setHiddenColumns}
                    selection={selectedPodcastIds}
                    onSelectionChange={setSelectedPodcastIds}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="xl"
            title="Spoken Article"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormPodcast
              closeModal={() => setEditModal(false)}
              create={!selectedPodcast}
              loading={loading}
              podcast={selectedPodcast}
            />
          </Modal>
          <Modal
            size="lg"
            title="Spoken Article"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedPodcast}
              icon="bx bx-headphone"
              collection="podcasts"
            />
          </Modal>
          <Modal
            size="lg"
            title="Spoken Article"
            isOpen={previewPodcastModal}
            toggle={() => setPreviewPodcastModal(!previewPodcastModal)}
            showClose
            scrollable={false}>
            <PreviewPodcast podcast={selectedPodcast} />
          </Modal>
          <Alert
            isOpen={synthesizePodcastAlert}
            title="Do you want to synthesize this Article?"
            confirmBtnLabel="Synthesize"
            onCancel={() => setSynthesizePodcastAlert(false)}
            onConfirm={handleSynthesizePodcast}>
            Enjoy your podcast!
          </Alert>
          <Alert
            isOpen={publishPodcastAlert}
            title={`Do you want to ${
              selectedPodcast && selectedPodcast.published
                ? 'withdraw'
                : 'publish'
            } this Spoken Article?`}
            confirmBtnLabel={
              selectedPodcast && selectedPodcast.published
                ? 'Withdraw'
                : 'Publish'
            }
            onCancel={() => setPublishPodcastAlert(false)}
            onConfirm={handlePublishPodcast}>
            Keep attention!
          </Alert>
          <Alert
            isOpen={rescrapePodcastAlert}
            title="Do you want to rescrape this Article?"
            confirmBtnLabel="Rescrape"
            onCancel={() => setRescrapePodcastAlert(false)}
            onConfirm={handleRescrapePodcast}>
            Keep attention!
          </Alert>
          <Alert
            isOpen={reprocessPodcastAlert}
            title="Do you want to reprocess this Article?"
            confirmBtnLabel="Reprocess"
            onCancel={() => setReprocessPodcastAlert(false)}
            onConfirm={handleReprocessPodcast}>
            There's no coming back!
          </Alert>
          <Modal
            size="lg"
            title="Spoken Article Insights"
            isOpen={insightsModal}
            toggle={() => setInsightsModal(!insightsModal)}
            showClose
            scrollable={false}>
            <PodcastInsights podcast={selectedPodcast} />
          </Modal>
          <Modal
            size="lg"
            title="Analysis"
            isOpen={podcastAnalysisModal}
            toggle={() => setPodcastAnalysisModal(!podcastAnalysisModal)}
            showClose
            scrollable={false}>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ReactJson
                    src={podcastAnalysis}
                    collapsed={2}
                    collapseStringsAfterLength={70}
                  />
                </CardBody>
              </Card>
            </Col>
          </Modal>
          <Modal
            size="lg"
            title="Get Audio Player Code"
            isOpen={playerCodeModal}
            toggle={() => setPlayerCodeModal(!playerCodeModal)}
            showClose
            scrollable={false}>
            <PreviewCode podcast={selectedPodcast} />
          </Modal>
          <BatchPodcastBar selectedPodcastIds={selectedPodcastIds} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
