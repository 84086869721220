import React, { useState, useEffect, useMemo } from 'react';

import { Row, Col, Card, CardBody, Container, Spinner } from 'reactstrap';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';

import * as usersActions from '../../store/users/actions';
import * as networksActions from '../../store/networks/actions';
import * as publishersActions from '../../store/publishers/actions';
import * as customersActions from '../../store/customers/actions';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Common/Modal/Modal';
import Alert from '../../components/Common/Modal/Alert';
import ShowSingleElement from '../../components/Common/ShowSingleElement';
import FormUser from '../../components/Users/FormUser';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import createTableColumns from '../../components/Users/tableColumns';
import {
  CountRowTablePlacement,
  CreateButton,
} from '../../components/Common/DataTable/tableActionButtons';

const List = (props) => {
  const loading = useSelector((state) => state.User.loading);

  const users = useSelector((state) => state.User.users);
  const user = useSelector((state) => state.Auth.admin);
  const networks = useSelector((state) => state.Network.networks);
  const publishers = useSelector((state) => state.Publisher.publishers);
  const customers = useSelector((state) => state.Customer.customers);

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [userActivationAlert, setUserActivationAlert] = useState(false);
  const [userDisablingAlert, setUserDisablingAlert] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.syncUsers());
    !networks.length && dispatch(networksActions.syncNetworks());
    !publishers.length && dispatch(publishersActions.syncPublishers());
    !customers.length && dispatch(customersActions.syncCustomers());
  }, []);

  const openEditModal = (user = null) => {
    setSelectedUser(user);
    setEditModal(true);
  };

  const openViewModal = (user) => {
    setSelectedUser(user);
    setViewModal(true);
  };

  const openUserActivationAlert = (user) => {
    setSelectedUser(user);
    setUserActivationAlert(true);
  };

  const handleUserActivation = () => {
    dispatch(
      usersActions.userActivation({
        id: selectedUser.id,
        email: selectedUser.email,
      }),
    );
    setUserActivationAlert(false);
  };

  const openUserDisablingAlert = (user) => {
    setSelectedUser(user);
    setUserDisablingAlert(true);
  };

  const handleUserDisabling = () => {
    dispatch(
      usersActions.updateUser({
        id: selectedUser.id,
        active: false,
      }),
    );
    setUserDisablingAlert(false);
  };

  const columns = useMemo(
    () =>
      createTableColumns(
        openViewModal,
        openEditModal,
        openUserActivationAlert,
        openUserDisablingAlert,
        user,
        networks,
        publishers,
        customers,
      ),
    [user, networks, publishers, customers],
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Users" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center justify-content-end m-auto">
                    <CountRowTablePlacement />
                    <Spinner
                      color="secondary"
                      animation="border"
                      hidden={!loading}
                    />
                    <CreateButton
                      label="Create new User"
                      disabled={loading}
                      onPress={openEditModal}
                    />
                  </Row>
                  <DataTableNext
                    loading={loading}
                    rows={users}
                    columns={columns}
                    filters={filters}
                    onFiltersChange={setFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    className="mt-2"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            title="User"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
            showClose
            scrollable={false}>
            <ShowSingleElement
              element={selectedUser}
              icon="bx bx bx-user-circle"
            />
          </Modal>
          <Modal
            size="xl"
            title="User"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
            scrollable={false}>
            <FormUser
              closeModal={() => setEditModal(false)}
              create={!selectedUser}
              loading={loading}
              user={selectedUser}
            />
          </Modal>
          <Alert
            isOpen={userActivationAlert}
            title={`Do you want activate ${selectedUser?.displayName}?`}
            confirmBtnLabel="Activate"
            onCancel={() => setUserActivationAlert(false)}
            onConfirm={handleUserActivation}>
            An activation e-mail will be sent to {selectedUser?.email}
          </Alert>
          <Alert
            isOpen={userDisablingAlert}
            title={`Do you want disable ${selectedUser?.displayName}?`}
            confirmBtnLabel="Disable"
            onCancel={() => setUserDisablingAlert(false)}
            onConfirm={handleUserDisabling}>
            No alert will be sent to this user
          </Alert>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
