import React from 'react';
import { Button } from 'reactstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import useClipboard from 'react-hook-clipboard';
import { useTranslation } from 'react-i18next';
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

const CodeBlock = ({ code, language, style = a11yDark }) => {
  const [clipboard, copyToClipboard] = useClipboard();

  const { t } = useTranslation();
  return (
    <div className="position-relative">
      <Button
        color={clipboard ? 'success' : 'secondary'}
        outline={clipboard ? true : false}
        className="btn-sm waves-effect waves-light ml-1 position-absolute font-size-9"
        style={{ right: 4, top: 4 }}
        onClick={() => copyToClipboard(code)}>
        <i
          className={`bx ${
            clipboard ? 'bx-check' : 'bx-copy-alt'
          } align-middle mr-1`}></i>
        {clipboard ? t('Copied') : t('Copy')}
      </Button>
      <SyntaxHighlighter
        language={language}
        style={style}
        wrapLines={true}
        wrapLongLines={false}
        showLineNumbers={true}
        showInlineLineNumbers={false}
        customStyle={{
          border: '1px solid #c3c3c3',
          borderRadius: '5px',
        }}>
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlock;
