import roles from './roles';

export default {
  SUPER_ADMIN: [
    roles.SUPER_ADMIN,
    roles.ADMIN,
    roles.MANAGER,
    roles.NETWORKER,
    roles.PUBLISHER,
    roles.CUSTOMER,
    roles.QUALITY_CHECKER,
    roles.OPERATOR,
  ],
  ADMIN: [
    roles.ADMIN,
    roles.MANAGER,
    roles.NETWORKER,
    roles.PUBLISHER,
    roles.CUSTOMER,
    roles.QUALITY_CHECKER,
    roles.OPERATOR,
  ],
  MANAGER: [
    roles.MANAGER,
    roles.NETWORKER,
    roles.OPERATOR,
    roles.QUALITY_CHECKER,
  ],
  PUBLISHER: [roles.PUBLISHER, roles.CUSTOMER],
  CUSTOMER: [roles.CUSTOMER, roles.PUBLISHER],
  OPERATOR: [roles.OPERATOR],
};
