import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import * as sitesActions from '../../store/sites/actions';
import * as podcastsActions from '../../store/podcasts/actions';
import { selectSeedingSites } from '../../selectors/site';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import '../../lib/availity/yup';
import * as yup from 'yup';
import DataTableNext from '../../components/Common/DataTable/DataTableNext';
import { OpenUrlButton } from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel, getSort } from '../Common/DataTable/tableHelpers';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

const RelatedPodcasts = ({ siteIds, clusterId }) => {
  const loadingPodcasts = useSelector((state) => state.Podcast.loading);
  const loadingSites = useSelector((state) => state.Site.loading);
  const loading = loadingPodcasts || loadingSites;

  const podcasts = useSelector((state) => state.Podcast.podcasts);

  let sites = useSelector(selectSeedingSites);
  sites = Array.isArray(siteIds)
    ? sites.filter(({ id }) => siteIds.includes(id))
    : sites;

  const [selectedSiteId, setSelectedSiteId] = useState(sites[0]?.id);

  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sitesActions.syncSites());
    dispatch(podcastsActions.fetchRelatedPodcasts(selectedSiteId, clusterId));
  }, []);

  const onChangeSite = (value) => {
    setSelectedSiteId(value);
    dispatch(podcastsActions.fetchRelatedPodcasts(value, clusterId));
  };

  const columns = useMemo(
    () => [
      {
        title: 'Title',
        name: 'title',
      },
      {
        title: 'Site',
        name: 'siteId',
        width: 150,
        formatterComponent: ({ value }) => getLabel(value, sites, 'id', 'name'),
        filter: (value, filter) =>
          getFilter(getLabel(value, sites, 'id', 'name'), filter.value),
      },

      {
        title: 'Published at',
        name: 'articlePublishedAt',
        width: 120,
        formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
        filter: (value, filter) =>
          getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
        sorting: (a, b) => getSort(moment(a).valueOf(), moment(b).valueOf()),
      },
      {
        title: 'Action',
        name: 'action',
        width: 80,
        align: 'center',
        formatterComponent: ({ row }) => (
          <ul className="list-inline font-size-20 contact-links mb-0">
            <OpenUrlButton row={row} />
          </ul>
        ),
        filter: 'disabled',
        sorting: 'disabled',
      },
    ],
    [sites],
  );

  const { t } = useTranslation();

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Row className="align-items-center mb-2">
              <Col md="6">
                <Form
                  initialValues={{ siteId: selectedSiteId }}
                  validationSchema={yup.object().shape({
                    siteIds: yup.array().nullable(),
                  })}
                  className="d-flex align-items-center">
                  <p className="font-size-16 font-weight-medium my-0 mr-2">
                    {t('Sites')}:
                  </p>
                  <Select
                    name="siteId"
                    id="siteId"
                    onChange={onChangeSite}
                    value={selectedSiteId}
                    options={sites.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    isDisabled={loading}
                  />
                </Form>
              </Col>
              <Col md="6">
                <Spinner
                  color="secondary"
                  animation="border"
                  hidden={!loading}
                />
              </Col>
            </Row>
            <DataTableNext
              loading={loading}
              rows={podcasts}
              columns={columns}
              filters={filters}
              onFiltersChange={setFilters}
              sorting={sorting}
              onSortingChange={setSorting}
              pageSize={25}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default RelatedPodcasts;
