import React from 'react';
import { Card, CardTitle, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ClusterDetails = ({ cluster }) => {
  const { t } = useTranslation();

  const size =
    cluster.size < 1000 ? 1000 : `${Math.floor(cluster.size / 1000)}k`;

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{t('Cluster details')}</CardTitle>
        <div className="d-flex mb-3">
          <div className="mr-4 avatar-sm align-self-center rounded-circle overflow-hidden">
            <span className="avatar-title bg-success">
              <i className="bx bx-radar font-size-24"></i>
            </span>
          </div>
          <div className="flex-grow-1 d-flex align-items-center">
            <h4 className="mb-0">{cluster.name}</h4>
          </div>
        </div>
        {cluster.description && (
          <p className="text-muted mb-2">{cluster.description}</p>
        )}
        <h5>
          {t('Size')}:{' '}
          <span className="text-muted font-size-14">
            {t('about')} {size} {t('contents')}
          </span>
        </h5>
      </CardBody>
    </Card>
  );
};

export default ClusterDetails;
