import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Spinner,
} from 'reactstrap';
import * as countriesActions from '../../store/countries/actions';
import { useDispatch } from 'react-redux';
import { Select } from '../../lib/availity/select';
import { Form, Input } from '../../lib/availity/form';
import { FieldArray } from 'formik';
import FormErrorMessage from '../Common/FormErrorMessage';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';
import languageTag from '../../lib/languageTag';

const createAdvZonesFunction = firebase
  .functions()
  .httpsCallable('createAdvZones-createAdvZones');

const FormCountry = (props) => {
  const editingCountry = props.country || {};
  const [active, setActive] = useState(editingCountry.active || false);

  const [advZoneGroupIdLoading, setAdvZoneGroupIdLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const countryData = {
      ...values,
      active,
    };

    dispatch(
      props.create
        ? countriesActions.createCountry(countryData)
        : countriesActions.updateCountry({
            id: editingCountry.id,
            ...countryData,
          }),
    );
    props.closeModal();
  };

  const createAdvZones = (values, setFieldValue) => {
    setAdvZoneGroupIdLoading(true);
    createAdvZonesFunction({ name: values.name })
      .then(({ data }) => {
        console.log(data);
        setFieldValue('adswizzZoneGroupId', data.adswizzZoneGroupId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setAdvZoneGroupIdLoading(false);
      });
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...editingCountry,
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" name="name" id="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="languageTag">Language</Label>
                    <Select
                      name="languageTag"
                      id="languageTag"
                      options={languageTag.map((lang) => ({
                        value: lang.tag,
                        label: lang.language,
                      }))}
                    />
                    <FormErrorMessage name="languageTag" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        name="active"
                        defaultChecked={active}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="active"
                        onClick={(e) => setActive(!active)}>
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" className="text-right">
                  {advZoneGroupIdLoading && (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                  <button
                    type="button"
                    disabled={!values.name || values.adswizzZoneGroupId}
                    className="btn btn-success waves-effect waves-light mb-2 mr-2"
                    onClick={() => createAdvZones(values, setFieldValue)}>
                    <i className="bx bx-world mr-1"></i>
                    Create zones
                  </button>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="adswizzZoneGroupId">
                      Adswizz Zone Group ID
                    </Label>
                    <Input
                      id="adswizzZoneGroupId"
                      name="adswizzZoneGroupId"
                      type="number"
                      min={0}
                    />
                    <FormErrorMessage name="adswizzZoneGroupId" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="10">
                  <FieldArray
                    name="adsTxtRows"
                    render={(arrayHelpers) => {
                      const adsTxtRows = values.adsTxtRows;
                      return (
                        <FormGroup>
                          <Label htmlFor="adsTxtRows">Ads.Txt Rows</Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            onClick={() => arrayHelpers.push([])}>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(adsTxtRows) &&
                            adsTxtRows.map((adsTxtRow, index) => (
                              <Row key={index} className="mb-2">
                                <Col md="6">
                                  <InputGroup>
                                    <Input name={`adsTxtRows[${index}]`} />
                                    <InputGroupAddon addonType="append">
                                      <button
                                        className="btn btn-danger p-0"
                                        style={{ width: '31px' }}
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }>
                                        <i className="bx bx-trash d-block font-size-15" />
                                      </button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                  <FormErrorMessage
                                    name={`adsTxtRows[${index}]`}
                                  />
                                </Col>
                              </Row>
                            ))}
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCountry;
