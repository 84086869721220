import types from './actionTypes';
import {
  addOrReplaceObjectInArray,
  removeItem,
} from '../../../helpers/reducerHelper';

const initialState = {
  loading: false,
  creatives: [],
  draftCreatives: [],
  error: '',
  success: '',
};

const creativeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CREATIVE_STATE:
      return initialState;
    case types.RESET_CREATIVE_MESSAGE:
      return {
        ...state,
        error: '',
        success: '',
      };
    case types.CREATE_CREATIVE.REQUEST:
    case types.UPDATE_CREATIVE.REQUEST:
    case types.DELETE_DRAFT_CREATIVE.REQUEST:
    case types.APPROVE_CREATIVE.REQUEST:
    case types.REJECT_CREATIVE.REQUEST:
    case types.REPROCESS_CREATIVE.REQUEST:
    case types.ACTION_CREATIVE.REQUEST:
    case types.FETCH_CREATIVES.REQUEST:
    case types.FETCH_CREATIVES_BY_CAMPAIGN_ID.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.FETCH_CREATIVES.SUCCESS:
    case types.FETCH_CREATIVES_BY_CAMPAIGN_ID.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        creatives: addOrReplaceObjectInArray(
          state.creatives,
          action.creatives,
          'id',
          action.forceUpdate ? 'no_merge' : 'skip_draft',
        ),
      };
    case types.CREATE_CREATIVE.SUCCESS:
    case types.UPDATE_CREATIVE.DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        draftCreatives: addOrReplaceObjectInArray(
          state.draftCreatives,
          action.creative,
          'id',
          'merge',
        ),
      };
    case types.UPDATE_CREATIVE.SUCCESS:
    case types.APPROVE_CREATIVE.SUCCESS:
    case types.REJECT_CREATIVE.SUCCESS:
    case types.REPROCESS_CREATIVE.SUCCESS:
    case types.ACTION_CREATIVE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        creatives: addOrReplaceObjectInArray(
          state.creatives,
          action.creative,
          'id',
          'merge',
        ),
        ...(action.message && { success: action.message }),
      };
    case types.DELETE_DRAFT_CREATIVE.SUCCESS:
      return {
        ...state,
        loading: false,
        draftCreatives: removeItem(state.draftCreatives, action.creative),
      };
    case types.CREATE_CREATIVE.FAILURE:
    case types.UPDATE_CREATIVE.FAILURE:
    case types.DELETE_DRAFT_CREATIVE.FAILURE:
    case types.APPROVE_CREATIVE.FAILURE:
    case types.REJECT_CREATIVE.FAILURE:
    case types.REPROCESS_CREATIVE.FAILURE:
    case types.ACTION_CREATIVE.FAILURE:
    case types.FETCH_CREATIVES.FAILURE:
    case types.FETCH_CREATIVES_BY_CAMPAIGN_ID.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default creativeReducer;
