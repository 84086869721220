import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_SITE_REVENUE_STATE,
});

export const fetchRevenues = (startDate, endDate, filters = null) => ({
  type: types.FETCH_SITE_REVENUES.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchRevenuesSuccess = (
  revenues,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_SITE_REVENUES.SUCCESS,
  revenues,
  startDate,
  endDate,
  filters,
});

export const fetchRevenuesFailure = (error) => ({
  type: types.FETCH_SITE_REVENUES.FAILURE,
  error,
});
