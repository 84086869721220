import '../../lib/availity/yup';
import * as yup from 'yup';

const formValidation = (step, campaign) => {
  let schema = null;

  if (step === 1)
    schema = {
      name: yup.string().min(5).required(),
      dates: yup.object().shape({
        startDate: yup.date().label('start date').required(),
        endDate: yup
          .date()
          .label('end date')
          .required()
          .test('isInFuture', 'end date must be in the future', (value) => {
            if (value > new Date()) return true;
            if (
              new Date(campaign?.endDate).getTime() ===
              new Date(value).getTime()
            )
              return true; //not modified
            return false;
          }),
      }),
      free: yup.boolean().required(),
      siteIds: yup.array().when('free', {
        is: (value) => !!value,
        then: yup.array().required(),
      }),
      dailyBudget: yup
        .number()
        .integer()
        .min(20)
        .when('free', {
          is: (value) => !value,
          then: yup.number().required(),
        }),
      ppl: yup.number().min(0.01).max(10),
    };

  if (step === 2)
    schema = {
      name: yup.string().min(5).required(),
      langTag: yup.string().label('language content').required(),
      includeKeywords: yup
        .string()
        .label('include keywords')
        .matches(
          /^[a-zA-Z\u00C0-\u017F0-9]+((,|'|-|\s|\/)[a-zA-Z\u00C0-\u017F0-9]+)*[a-zA-Z\u00C0-\u017F0-9]+$/,
          { excludeEmptyString: true },
        ),
    };

  return yup.object().shape(schema);
};

export default formValidation;
