import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';
import CSVUploadReader from '../Common/CSVReader';

import * as sitesActions from '../../store/sites/actions';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Dots } from 'react-activity';

const CONFIG_CSV = {
  header: true,
  delimiter: ',',
  dynamicTyping: true,
  skipEmptyLines: true,
};

const DOWNLOAD_STATUS = {
  INITIAL: '',
  DOWNLOADING: 'downloading',
  COMPLETED: 'completed',
  ERROR: 'error',
};

const ImportVastUrls = (props) => {
  const [downloadStatus, setDownloadStatus] = useState(DOWNLOAD_STATUS.INITIAL);
  const [downloadError, setDownloadError] = useState('');
  const loading = useSelector((state) => state.Site.loading);
  const error = useSelector((state) => state.Site.error);
  const success = useSelector((state) => state.Site.success);

  const dispatch = useDispatch();

  const handleCSVData = ({ data, errors }) => {
    resetError();
    if (errors.length) {
      setDownloadStatus(DOWNLOAD_STATUS.ERROR);
      setDownloadError(`${errors[0].message} at row ${errors[0].row}`);
    }
    dispatch(sitesActions.importVast(data));
  };

  const resetError = () => {
    setDownloadError('');
  };

  const handleUploadRejected = () => {
    setDownloadStatus(DOWNLOAD_STATUS.ERROR);
    setDownloadError('Upload error');
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        {loading ? (
          <Dots />
        ) : (
          <>
            {downloadStatus === DOWNLOAD_STATUS.ERROR ||
              (error && <Alert color="danger">{downloadError || error}</Alert>)}
            {success && <Alert color="success">{success}</Alert>}
            <Row>
              <Col md="12">
                <p className="font-size-16 mb-4">
                  {t('Upload CSV from this')}{' '}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1Vukoi2CCit5MjtvdT6F1fGdX8PkVFmhU_nSQox7AC8U/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Google Sheet
                  </a>
                  .
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="12">
                <CSVUploadReader
                  config={CONFIG_CSV}
                  onUploadAccepted={handleCSVData}
                  onUploadRejected={handleUploadRejected}
                />
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ImportVastUrls;
