import React from 'react';
import { ViewButton } from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel, roundTo } from '../Common/DataTable/tableHelpers';
import { CURRENCIES } from '../../config/revenue';
import moment from 'moment';

const createTableColumns = (sites, openViewModal) => [
  {
    title: 'Site',
    name: 'siteId',
    width: 180,
    getCellValue: (row) => getLabel(row.siteId, sites),
    fixed: 'left',
  },
  {
    title: 'Day',
    name: 'day',
    width: 120,
    formatterComponent: ({ row }) =>
      moment(row.date).format('dddd, DD/MM/YYYY'),
    filter: (value, filter, row) =>
      getFilter(moment(row.date).format('dddd, DD/MM/YYYY'), filter.value),
    fixed: 'left',
  },
  {
    title: 'Product',
    name: 'product',
  },
  {
    title: 'Provider',
    name: 'provider',
  },
  {
    title: 'Type',
    name: 'type',
  },
  {
    title: 'Currency',
    name: 'currency',
    formatterComponent: ({ value }) => value || 'EUR',
  },
  {
    title: 'Impressions',
    name: 'impressions',
    summary: 'sum',
  },
  {
    title: 'Plays',
    name: 'play',
    getCellValue: (row) =>
      isFinite(row.impressions) ? Math.floor(row.play) : 0,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'First Quartile',
    name: 'first-quartile',
    getCellValue: (row) =>
      isFinite(row.impressions) ? Math.floor(row['first-quartile']) : 0,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'Midpoint',
    name: 'midpoint',
    getCellValue: (row) =>
      isFinite(row.impressions) ? Math.floor(row.midpoint) : 0,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'Third-quartile',
    name: 'third-quartile',
    getCellValue: (row) =>
      isFinite(row.impressions) ? Math.floor(row['third-quartile']) : 0,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'Completed',
    name: 'completed',
    getCellValue: (row) =>
      isFinite(row.impressions) ? Math.floor(row.completed) : 0,
    summary: 'sum',
    hidden: true,
  },
  {
    title: 'Gross Revenue',
    name: 'grossRevenue',
    getCellValue: (row) =>
      isFinite(row.grossRevenue) ? roundTo(row.grossRevenue) : 0,
    formatterComponent: ({ value, row }) =>
      `${CURRENCIES[row?.currency || 'EUR']} ${value}`,
    summary: 'sum',
  },
  {
    title: 'Action',
    name: 'action',
    width: 80,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <ViewButton row={row} onPress={openViewModal} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
