import types from './actionTypes';
import {
  addOrReplaceObjectInArray,
  removeItem,
} from '../../helpers/reducerHelper';

const initialState = {
  loading: false,
  clusters: [],
  draftClusters: [],
  error: '',
};

const clusterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CLUSTER_STATE:
      return initialState;
    case types.CREATE_CLUSTER.REQUEST:
    case types.UPDATE_CLUSTER.REQUEST:
    case types.CREATE_DRAFT_CLUSTER.REQUEST:
    case types.UPDATE_DRAFT_CLUSTER.REQUEST:
    case types.DELETE_DRAFT_CLUSTER.REQUEST:
    case types.SYNC_CLUSTERS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_CLUSTER.SUCCESS:
    case types.UPDATE_CLUSTER.SUCCESS:
      return {
        ...state,
        loading: false,
        draftClusters: [],
        error: '',
      };
    case types.CREATE_DRAFT_CLUSTER.SUCCESS:
      return {
        ...state,
        loading: false,
        draftClusters: [action.cluster, ...state.draftClusters],
      };
    case types.UPDATE_DRAFT_CLUSTER.SUCCESS:
      return {
        ...state,
        loading: false,
        draftClusters: addOrReplaceObjectInArray(
          state.draftClusters,
          action.cluster,
          'id',
          'merge',
        ),
      };
    case types.DELETE_DRAFT_CLUSTER.SUCCESS:
      return {
        ...state,
        loading: false,
        draftClusters: removeItem(state.draftClusters, action.cluster),
      };
    case types.SYNC_CLUSTERS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        clusters: [...action.clusters],
      };
    case types.CREATE_CLUSTER.FAILURE:
    case types.UPDATE_CLUSTER.FAILURE:
    case types.CREATE_DRAFT_CLUSTER.FAILURE:
    case types.UPDATE_DRAFT_CLUSTER.FAILURE:
    case types.DELETE_DRAFT_CLUSTER.FAILURE:
    case types.SYNC_CLUSTERS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default clusterReducer;
