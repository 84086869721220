import {
  takeEvery,
  put,
  all,
  call,
  takeLeading,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as insightsActions from './actions';
import * as podcastsActions from '../../podcasts/actions';

import firebase from 'firebase/app';
import rsf from '../../../helpers/firebase';

import {
  toDateFirebase,
  mergeAndSum,
  orderValueMap,
} from '../../../helpers/sharedFunction';

const insightTransformer = (insight, data) => ({
  id: insight.id,
  ...data,
  averageSongDuration: data.averageSongDuration ?? 0,
  date: toDateFirebase(insight, data, 'date').toDate(),
});

function* fetchInsightsSaga({
  startDate,
  endDate,
  filters,
  withPodcasts,
  withPodcastsLimit,
}) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const insightsRef = rsf.firestore.createCollectionRefWithFilters(
      'dailyNetworkInsights',
      countryId,
      startDate,
      endDate,
      filters,
      null,
      null,
      'date',
    );

    const insightsSnap = yield call(rsf.firestore.getCollection, insightsRef);

    let insights = [];

    insightsSnap.forEach((insight) => {
      const data = insight.data();
      insights.push(insightTransformer(insight, data));
    });

    if (withPodcasts) {
      const playedPodcasts = insights.map((insight) => insight.playedPodcasts);
      const mostPlayedPodcasts = orderValueMap(
        mergeAndSum(playedPodcasts),
        'desc',
      );
      const podcastIds = Object.keys(mostPlayedPodcasts).slice(
        0,
        withPodcastsLimit,
      );

      podcastIds.length &&
        (yield put(podcastsActions.fetchPodcastsByIds(podcastIds)));
    }

    yield put(
      insightsActions.fetchInsightsSuccess(
        insights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(insightsActions.fetchInsightsFailure(error));
  }
}

function* fetchInsightsByNetworkSaga({ network }) {
  try {
    const insightsRef = firebase
      .firestore()
      .collection('dailyNetworkInsights')
      .where('networkId', '==', network.id)
      .orderBy('date', 'asc');

    const insightsSnap = yield call(rsf.firestore.getCollection, insightsRef);
    const insights = insightsSnap.docs.map((insight) =>
      insightTransformer(insight, insight.data()),
    );

    yield put(insightsActions.fetchInsightsByNetworkSuccess(insights));
  } catch (error) {
    yield put(insightsActions.fetchInsightsByNetworkFailure(error));
  }
}

function* insightSaga() {
  yield all([
    takeEvery(types.FETCH_NETWORK_INSIGHTS.REQUEST, fetchInsightsSaga),
    takeLeading(
      types.FETCH_NETWORK_INSIGHTS_BY_PODCAST.REQUEST,
      fetchInsightsByNetworkSaga,
    ),
  ]);
}

export default insightSaga;
