import React from 'react';
import {
  ViewButton,
  InfoButton,
  EditButton,
  DeleteButton,
  ApproveButton,
  RejectButton,
  ReprocessButton,
  PlayButton,
  PauseButton,
} from '../../Common/DataTable/tableActionButtons';
import { getFilter, getLabel } from '../../Common/DataTable/tableHelpers';
import moment from 'moment';
import {
  CAMPAIGN_STATUS,
  campaignStatusColor,
  isEditable,
} from '../../../config/campaign';

const createTableColumns = (
  campaigns,
  customers,
  openViewModal,
  openEditModal,
  openDeleteAlert,
  openApproveAlert,
  openRejectAlert,
  openReprocessAlert,
  openActionAlert,
  isAdmin,
  canApproveCreatives,
  creditAmountEmpty,
) => [
  {
    title: 'Show',
    name: 'show',
    width: 70,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        {!isEditable(row.status) && (
          <InfoButton row={row} label="Show creative" onPress={openViewModal} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
    fixed: 'left',
  },
  {
    title: 'Name',
    name: 'name',
  },
  {
    title: 'Status',
    name: 'status',
    width: 110,
    formatterComponent: ({ value }) => (
      <span
        className={`badge badge-${campaignStatusColor(value)} font-size-12`}>
        {value.toUpperCase()}
      </span>
    ),
  },
  {
    title: 'Start Date',
    name: 'startDate',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
    filter: (value, filter) =>
      getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
  },
  {
    title: 'End Date',
    name: 'endDate',
    width: 120,
    formatterComponent: ({ value }) => moment(value).format('DD/MM/YYYY'),
    filter: (value, filter) =>
      getFilter(moment(value).format('DD/MM/YYYY'), filter.value),
  },
  {
    title: 'Draft',
    name: 'isDraft',
    width: 100,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-danger font-size-12">YES</span>
      ) : (
        <span className="badge badge-secondary font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
  },
  ...(Array.isArray(campaigns) && campaigns.length
    ? [
        {
          title: 'Campaign',
          name: 'campaignId',
          formatterComponent: ({ value }) =>
            getLabel(value, campaigns, 'id', 'name'),
          filter: (value, filter) =>
            getFilter(getLabel(value, campaigns, 'id', 'name'), filter.value),
        },
      ]
    : []),
  ...(Array.isArray(customers) && customers.length
    ? [
        {
          title: 'Customer',
          name: 'customerId',
          width: 100,
          formatterComponent: ({ value }) =>
            getLabel(value, customers, 'id', 'name'),
          filter: (value, filter) =>
            getFilter(getLabel(value, customers, 'id', 'name'), filter.value),
        },
      ]
    : []),
  {
    title: 'Action',
    name: 'action',
    width: 160,
    align: 'center',
    formatterComponent: ({ row }) => {
      const { SCHEDULED, RUNNING, PAUSED } = CAMPAIGN_STATUS;
      return (
        <ul className="list-inline font-size-20 contact-links mb-0">
          {isAdmin && <ViewButton row={row} onPress={openViewModal} />}
          {(isEditable(row.status) || canApproveCreatives) && (
            <EditButton row={row} onPress={openEditModal} />
          )}
          {row.status === CAMPAIGN_STATUS.NEW && (
            <DeleteButton row={row} onPress={openDeleteAlert} />
          )}
          {canApproveCreatives &&
            row.status === CAMPAIGN_STATUS.IN_REVIEW &&
            !row.isDraft && (
              <>
                <ApproveButton row={row} onPress={openApproveAlert} />
                <RejectButton row={row} onPress={openRejectAlert} />
              </>
            )}
          {row.status === PAUSED && !row.isDraft && !creditAmountEmpty && (
            <PlayButton
              row={row}
              onPress={(row) => openActionAlert(row, 'activate')}
              tooltip="Activate"
            />
          )}
          {[SCHEDULED, RUNNING].includes(row.status) && !row.isDraft && (
            <PauseButton
              row={row}
              onPress={(row) => openActionAlert(row, 'pause')}
            />
          )}
          {row.status === CAMPAIGN_STATUS.ERROR && canApproveCreatives && (
            <ReprocessButton
              row={row}
              onPress={(row) => openReprocessAlert(row)}
              label="Reprocess Creative"
            />
          )}
        </ul>
      );
    },
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
