import types from './actionTypes';
import {
  addOrReplaceObjectInArray,
  removeItem,
} from '../../helpers/reducerHelper';

const initialState = {
  loading: false,
  campaigns: [],
  draftCampaigns: [],
  error: '',
  success: '',
  publishResults: [],
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CAMPAIGN_STATE:
      return initialState;
    case types.RESET_CAMPAIGN_MESSAGE:
      return {
        ...state,
        error: '',
        success: '',
      };
    case types.RESET_CAMPAIGN_PUBLISH_RESULTS:
      return {
        ...state,
        publishResults: [],
      };
    case types.CREATE_CAMPAIGN.REQUEST:
    case types.UPDATE_CAMPAIGN.REQUEST:
    case types.DELETE_DRAFT_CAMPAIGN.REQUEST:
    case types.APPROVE_CAMPAIGN.REQUEST:
    case types.REJECT_CAMPAIGN.REQUEST:
    case types.UPDATE_REJECT_MESSAGE_CAMPAIGN.REQUEST:
    case types.REPROCESS_CAMPAIGN.REQUEST:
    case types.ACTION_CAMPAIGN.REQUEST:
    case types.FETCH_CAMPAIGNS.REQUEST:
    case types.FETCH_CAMPAIGN_BY_ID.REQUEST:
    case types.DISCARD_DRAFT_CAMPAIGNS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        publishResults: [],
      };
    case types.PUBLISH_CAMPAIGNS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_CAMPAIGN.SUCCESS:
    case types.UPDATE_CAMPAIGN.DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        draftCampaigns: addOrReplaceObjectInArray(
          state.draftCampaigns,
          action.campaign,
          'id',
          'merge',
        ),
      };
    case types.UPDATE_CAMPAIGN.SUCCESS:
    case types.APPROVE_CAMPAIGN.SUCCESS:
    case types.REJECT_CAMPAIGN.SUCCESS:
    case types.UPDATE_REJECT_MESSAGE_CAMPAIGN.SUCCESS:
    case types.REPROCESS_CAMPAIGN.SUCCESS:
    case types.ACTION_CAMPAIGN.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        campaigns: addOrReplaceObjectInArray(
          state.campaigns,
          action.campaign,
          'id',
          'merge',
        ),
        ...(action.message && { success: action.message }),
      };
    case types.FETCH_CAMPAIGNS.SUCCESS:
    case types.FETCH_CAMPAIGN_BY_ID.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        campaigns: addOrReplaceObjectInArray(
          state.campaigns,
          action.campaigns,
          'id',
          action.forceUpdate ? 'no_merge' : 'skip_draft',
        ),
        loadMore: action.loadMore,
      };
    case types.DELETE_DRAFT_CAMPAIGN.SUCCESS:
      return {
        ...state,
        loading: false,
        draftCampaigns: removeItem(state.draftCampaigns, action.campaign),
      };
    case types.DISCARD_DRAFT_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        loading: false,
        draftCampaigns: [],
      };
    case types.PUBLISH_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        publishResults: action.results,
      };
    case types.CREATE_CAMPAIGN.FAILURE:
    case types.UPDATE_CAMPAIGN.FAILURE:
    case types.DELETE_DRAFT_CAMPAIGN.FAILURE:
    case types.APPROVE_CAMPAIGN.FAILURE:
    case types.REJECT_CAMPAIGN.FAILURE:
    case types.UPDATE_REJECT_MESSAGE_CAMPAIGN.FAILURE:
    case types.REPROCESS_CAMPAIGN.FAILURE:
    case types.ACTION_CAMPAIGN.FAILURE:
    case types.FETCH_CAMPAIGNS.FAILURE:
    case types.FETCH_CAMPAIGN_BY_ID.FAILURE:
    case types.DISCARD_DRAFT_CAMPAIGNS.FAILURE:
    case types.PUBLISH_CAMPAIGNS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
        publishResults: [],
      };
    default:
      return state;
  }
};

export default campaignReducer;
