import '../../lib/availity/yup';
import * as yup from 'yup';

const ADS_TXT_STRING = /^[a-zA-Z0-9.,\s]{4,}$/g;

const formValidation = yup.object().shape({
  name: yup.string().required(),
  languageTag: yup.string().required(),
  adswizzZoneGroupId: yup.number().positive().required(),
  adsTxtRows: yup.array().of(yup.string().required().matches(ADS_TXT_STRING)),
});

export default formValidation;
