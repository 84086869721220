const statsTypes = {
  RESET_PODCAST_ANALYSIS_STATE: 'RESET_PODCAST_ANALYSIS_STATE',
  FETCH_PODCAST_ANALYSIS: {
    REQUEST: 'FETCH_PODCAST_ANALYSIS.REQUEST',
    SUCCESS: 'FETCH_PODCAST_ANALYSIS.SUCCESS',
    FAILURE: 'FETCH_PODCAST_ANALYSIS.FAILURE',
  },
};

export default statsTypes;
