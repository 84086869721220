import React from 'react';
import { ViewButton, EditButton } from '../Common/DataTable/tableActionButtons';
import { getFilter, getLabel } from '../Common/DataTable/tableHelpers';

const sitesNames = (row, sites) => {
  const siteData = sites.filter((site) => site.publisherId === row.id);
  const siteName = siteData ? siteData.map((site) => site.name) : null;
  return siteName
    ? siteName.reduce(
        (accumulator, currentValue) => `${accumulator} ${currentValue},`,
        '',
      )
    : '';
};

const createTableColumns = (sites, networks, openViewModal, openEditModal) => {
  return [
    {
      title: 'Name',
      name: 'name',
    },
    {
      title: 'Sites',
      name: 'siteIds',
      formatterComponent: ({ row }) => sitesNames(row, sites),
      filter: (value, filter, row) =>
        getFilter(sitesNames(row, sites), filter.value),
      wordWrapEnabled: true,
    },
    {
      title: 'Network',
      name: 'networkId',
      headerStyle: { width: '180px' },
      formatterComponent: ({ value }) =>
        getLabel(value, networks, 'id', 'name'),
      filter: (value, filter) =>
        getFilter(getLabel(value, networks, 'id', 'name'), filter.value),
    },
    {
      title: 'Active',
      name: 'active',
      width: 100,
      sort: true,
      formatterComponent: ({ value }) =>
        value ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
      filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    },
    {
      title: 'Action',
      name: 'action',
      width: 140,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          <EditButton row={row} onPress={openEditModal} />
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
