import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_PUBLISHER_STATE,
});

export const createPublisher = (publisher) => ({
  type: types.CREATE_PUBLISHER.REQUEST,
  publisher,
});

export const createPublisherSuccess = (publisher) => ({
  type: types.CREATE_PUBLISHER.SUCCESS,
  publisher,
});

export const createPublisherFailure = (error) => ({
  type: types.CREATE_PUBLISHER.FAILURE,
  error,
});

export const updatePublisher = (publisher) => ({
  type: types.UPDATE_PUBLISHER.REQUEST,
  publisher,
});

export const updatePublisherSuccess = () => ({
  type: types.UPDATE_PUBLISHER.SUCCESS,
});

export const updatePublisherFailure = (error) => ({
  type: types.UPDATE_PUBLISHER.FAILURE,
  error,
});

export const updateNetworkId = (networkIds, prevNetworkIds, networkId) => ({
  type: types.UPDATE_NETWORK_ID.REQUEST,
  networkIds,
  prevNetworkIds,
  networkId,
});

export const updateNetworkIdSuccess = () => ({
  type: types.UPDATE_NETWORK_ID.SUCCESS,
});

export const updateNetworkIdFailure = (error) => ({
  type: types.UPDATE_NETWORK_ID.FAILURE,
  error,
});

export const syncPublishers = () => ({
  type: types.SYNC_PUBLISHERS.REQUEST,
});

export const syncPublishersSuccess = (publishers) => ({
  type: types.SYNC_PUBLISHERS.SUCCESS,
  publishers,
});

export const syncPublishersFailure = (error) => ({
  type: types.SYNC_PUBLISHERS.FAILURE,
  error,
});
