import types from './actionTypes';
import initialFilters from '../../../config/filters';

const initialState = {
  loading: false,
  revenues: [],
  startDate: null,
  endDate: null,
  filters: Object.assign({}, initialFilters),
  error: '',
  success: '',
};

const revenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_AD_UNIT_REVENUE_STATE:
      return initialState;
    case types.FETCH_AD_UNIT_REVENUES.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.IMPORT_SPREAKER_REVENUES.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.FETCH_AD_UNIT_REVENUES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        revenues: action.revenues,
        startDate: action.startDate,
        endDate: action.endDate,
        filters: action.filters,
      };
    case types.IMPORT_SPREAKER_REVENUES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        success: action.message,
      };
    case types.FETCH_AD_UNIT_REVENUES.FAILURE:
    case types.IMPORT_SPREAKER_REVENUES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
        success: '',
      };
    default:
      return state;
  }
};

export default revenueReducer;
