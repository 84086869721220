import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';
import { capitalize } from '../../helpers/sharedFunction';

import logoDark from '../../assets/images/logo-dark.png';

const AccountConfirmation = () => {
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="d-block text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logoDark}
                    alt="Audioboost Logo"
                    height="80"
                    className="auth-logo-dark mx-auto"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4 className="mb-3">Great!</h4>
                        <p className="text-muted">
                          Your account has been successfully verified.
                          <br />
                          You will receive an activation e-mail in the next 48
                          hours.
                          <br />
                          <br />
                          If you don't receive the email, please check your spam
                          folder or contact us at{' '}
                          <a href="mailto:support@audioboost.it">
                            support@audioboost.it
                          </a>
                        </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Back to login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AccountConfirmation);
