import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_PODCAST_ANALYSIS_STATE,
});

export const fetchPodcastAnalysis = (podcastId) => ({
  type: types.FETCH_PODCAST_ANALYSIS.REQUEST,
  podcastId,
});

export const fetchPodcastAnalysisSuccess = (podcastAnalysis) => ({
  type: types.FETCH_PODCAST_ANALYSIS.SUCCESS,
  podcastAnalysis,
});

export const fetchPodcastAnalysisFailure = (error) => ({
  type: types.FETCH_PODCAST_ANALYSIS.FAILURE,
  error,
});
