import React, { useState, memo } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../CommonStyle/react_dates_overrides.css';
import { DateRangePicker } from 'react-dates';
import YearlyPicker from './YearlyPicker';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import DatePresetButton from './DatePresetButton';
import { datePresets } from '../../../config/datePresets';

import FilterSitesBox from './Filter/FilterSitesBox';
import FilterPublishersBox from './Filter/FilterPublishersBox';
import FilterNetworksBox from './Filter/FilterNetworksBox';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TYPES = {
  DAILY: 'daily',
  YEARLY: 'yearly',
};

const DateRageFilter = ({
  loading,
  type = TYPES.DAILY,
  startDate,
  endDate,
  initialFilters,
  minDate,
  maxDate,
  onUpdate,
  showSiteFilters,
  showPublisherFilters,
  showNetworkFilters,
  showAllFilter = true,
}) => {
  const [filters, setFilters] = useState({
    ...initialFilters,
    startDate,
    endDate,
  });
  const [dateFocusInput, setDateFocusInput] = useState(null);

  const handleDatesChange = (ranges) => {
    let { startDate, endDate } = ranges;
    if (!endDate) endDate = startDate;
    if (!startDate) startDate = endDate;
    setFilters((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const handleYearChange = (year) => {
    const startDate = moment(year, 'YYYY').startOf('year');
    const endDate = moment(year, 'YYYY').endOf('year');
    setFilters((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const onPresetClick = (presetName) => {
    const { startDate, endDate } = datePresets.filter(
      (preset) => preset.name === presetName,
    )[0];
    setFilters((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const handleSiteFilters = (newSiteIds) => {
    const siteIds = !newSiteIds || !newSiteIds.length ? null : newSiteIds;
    setFilters(({ startDate, endDate }) => ({
      startDate,
      endDate,
      siteIds,
    }));
  };

  const handlePublisherFilters = (newPublisherId, newSiteIds) => {
    const publisherId = newPublisherId || '';
    const siteIds = !newSiteIds || !newSiteIds.length ? null : newSiteIds;
    setFilters(({ startDate, endDate }) => ({
      startDate,
      endDate,
      publisherId,
      siteIds,
    }));
  };

  const handleNetworkFilters = (newNetworkId) => {
    const networkId = newNetworkId || '';
    setFilters(({ startDate, endDate }) => ({
      startDate,
      endDate,
      networkId,
    }));
  };

  const { t } = useTranslation();

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody className="date-range-filter text-right">
            {type === TYPES.DAILY && (
              <>
                {datePresets.map((preset) => (
                  <DatePresetButton
                    key={preset.name}
                    color={
                      moment(preset.startDate).isSame(
                        filters.startDate,
                        'day',
                      ) && moment(preset.endDate).isSame(filters.endDate, 'day')
                        ? 'info'
                        : 'secondary'
                    }
                    loading={loading}
                    range={preset.name}
                    title={t(preset.label)}
                    onClick={() => onPresetClick(preset.name)}
                  />
                ))}
                <DateRangePicker
                  //renderCalendarInfo={this.renderDatePresets}
                  onDatesChange={(ranges) => handleDatesChange(ranges)}
                  onFocusChange={(input) => setDateFocusInput(input)}
                  focusedInput={dateFocusInput}
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  displayFormat="DD/MM/YYYY"
                  small={true}
                  startDateId="startDateFilter"
                  endDateId="endDateIdFilter"
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={loading}
                  minimumNights={0}
                  firstDayOfWeek={1}
                  numberOfMonths={2}
                  hideKeyboardShortcutsPanel={true}
                  showDefaultInputIcon={true}
                  isOutsideRange={(day) =>
                    isAfterDay(day, maxDate) || isBeforeDay(day, minDate)
                  }
                />
              </>
            )}
            {type === TYPES.YEARLY && (
              <YearlyPicker
                startDate={filters.startDate}
                endDate={filters.endDate}
                minDate={minDate}
                maxDate={maxDate}
                onChange={handleYearChange}
              />
            )}
            <Button
              color="info"
              type="button"
              className="btn btn-primary waves-effect waves-light"
              disabled={loading}
              onClick={() => onUpdate(filters)}
              style={{ marginLeft: '10px' }}>
              {t('Update')}
            </Button>
          </CardBody>
          {showSiteFilters && (
            <FilterSitesBox
              onUpdate={handleSiteFilters}
              initialFilters={initialFilters}
              showAllFilter={showAllFilter}
            />
          )}
          {showNetworkFilters && (
            <FilterNetworksBox
              onUpdate={handleNetworkFilters}
              initialFilters={initialFilters}
              showAllFilter={showAllFilter}
            />
          )}
          {showPublisherFilters && (
            <FilterPublishersBox
              onUpdate={handlePublisherFilters}
              initialFilters={initialFilters}
              showAllFilter={showAllFilter}
            />
          )}
        </Card>
      </Col>
      {filters && ( //workaround to force update filters
        <div style={{ display: 'none' }}>
          <ul>
            {Object.keys(filters).map((key) => (
              <li key={key}>{key}</li>
            ))}
          </ul>
        </div>
      )}
    </Row>
  );
};

export default memo(DateRageFilter);
