import React, { useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import * as clustersActions from '../../store/clusters/actions';
import { useDispatch } from 'react-redux';

import { Form, Input } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import Date from '../../lib/availity/date';
import IABContentTaxonomySelector from '../Common/IABContentTaxonomySelector';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { DEFAULT_ARTICLES_CREATED_SINCE } from '../../config/campaign';
import languageTag from '../../lib/languageTag';
import firebase from 'firebase/app';
import moment from 'moment';
import toastr from 'toastr';

const FormCluster = ({ cluster, sites, loading, create, closeModal }) => {
  sites = sites.map((site) => ({
    label: site.name,
    value: site.id,
  }));

  const isEditable = create || (cluster?.setup === 'new' && !cluster?.active);

  const [selectedIncludeIABTaxonomies, setIncludeSelectedIABTaxonomies] =
    useState(cluster?.includeSelectedIABTaxonomies || []);
  const [selectedExcludeIABTaxonomies, setExcludeSelectedIABTaxonomies] =
    useState(cluster?.excludeSelectedIABTaxonomies || []);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const {
      includeKeywords = '',
      excludeKeywords = '',
      includeSiteIds,
      excludeSiteIds,
    } = values;

    if (
      includeKeywords === '' &&
      excludeKeywords === '' &&
      !selectedIncludeIABTaxonomies.length &&
      !selectedExcludeIABTaxonomies.length
    )
      return toastr.error('Enter at least one cluster feature!', 'Error');

    if (
      includeKeywords
        .split(',')
        .find((keyword) => excludeKeywords.split(',').includes(keyword))
    )
      return toastr.error(
        'You selected the same include and exclude keywords!',
        'Error',
      );

    if (
      selectedIncludeIABTaxonomies.find((category) =>
        selectedExcludeIABTaxonomies.includes(category),
      )
    )
      return toastr.error(
        'You selected the same include and exclude categories!',
        'Error',
      );

    const newCluster = {
      ...values,
      includeKeywords,
      excludeKeywords,
      includeSelectedIABTaxonomies: selectedIncludeIABTaxonomies,
      excludeSelectedIABTaxonomies: selectedExcludeIABTaxonomies,
      isPublic: values.isPublic || false,
      active: values.active || false,
    };

    dispatch(
      create
        ? clustersActions.createCluster({
            ...newCluster,
            ...(Array.isArray(includeSiteIds) &&
              includeSiteIds.length && { includeSiteIds }),
            ...(Array.isArray(excludeSiteIds) &&
              excludeSiteIds.length && { excludeSiteIds }),
          })
        : clustersActions.updateCluster({
            id: cluster?.id,
            ...newCluster,
            includeSiteIds:
              Array.isArray(includeSiteIds) && includeSiteIds.length
                ? includeSiteIds
                : firebase.firestore.FieldValue.delete(),
            excludeSiteIds:
              Array.isArray(excludeSiteIds) && excludeSiteIds.length
                ? excludeSiteIds
                : firebase.firestore.FieldValue.delete(),
          }),
    );
    closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            articlesCreatedSince: DEFAULT_ARTICLES_CREATED_SINCE,
            ...(cluster ?? {}),
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label htmlFor="description">Description</Label>
                    <Input
                      id="description"
                      name="description"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        name="active"
                        checked={!!values.active}
                      />
                      <label className="custom-control-label" htmlFor="active">
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="langTag">Content Language</Label>
                    <Select
                      name="langTag"
                      id="langTag"
                      options={languageTag.map(({ tag, language }) => ({
                        value: tag,
                        label: language,
                      }))}
                      isDisabled={!isEditable}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="articlesCreatedSince">
                      Articles Created Since
                    </Label>
                    <Date
                      id="articlesCreatedSince"
                      name="articlesCreatedSince"
                      format="DD/MM/YYYY"
                      max={moment()}
                      disabled={!isEditable}
                    />
                    <p className="font-size-12 text-muted">
                      Consider only items imported on or after this date
                    </p>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="isPublic"
                        name="isPublic"
                        checked={!!values.isPublic}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isPublic">
                        Public Cluster?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="includeKeywords">Include Keywords</Label>
                    <Input
                      name="includeKeywords"
                      id="includeKeywords"
                      type="textarea"
                      disabled={!isEditable}
                    />
                    <p className="font-size-12 text-muted">
                      Insert comma separated keywords
                    </p>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="excludeKeywords">Exclude Keywords</Label>
                    <Input
                      name="excludeKeywords"
                      id="excludeKeywords"
                      type="textarea"
                      disabled={!isEditable}
                    />
                    <p className="font-size-12 text-muted">
                      Insert comma separated keywords
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="includeSiteIds">Include Site</Label>
                    <Select
                      name="includeSiteIds"
                      id="includeSiteIds"
                      isMulti
                      isDisabled={!!values.excludeSiteIds || !isEditable}
                      options={sites}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="excludeSiteIds">Exclude Site</Label>
                    <Select
                      name="excludeSiteIds"
                      id="excludeSiteIds"
                      isMulti
                      isDisabled={!!values.includeSiteIds || !isEditable}
                      options={sites}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label>Include IAB Content Taxonomy</Label>
                  <IABContentTaxonomySelector
                    selected={cluster?.includeSelectedIABTaxonomies}
                    onCheck={(checked) =>
                      setIncludeSelectedIABTaxonomies(checked)
                    }
                    disabled={!isEditable}
                  />
                </Col>
                <Col md="6">
                  <Label>Exclude IAB Content Taxonomy</Label>
                  <IABContentTaxonomySelector
                    selected={cluster?.excludeSelectedIABTaxonomies}
                    onCheck={(checked) =>
                      setExcludeSelectedIABTaxonomies(checked)
                    }
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCluster;
