import React, { memo } from 'react';
import { Card, CardBody, Table, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { totalMetric } from '../../selectors/site';
import CenterBox from '../Common/CenterBox';
import { useTranslation } from 'react-i18next';
import colors from '../CommonStyle/colors';

const SiteRevenues = ({ insightLevel, loading }) => {
  const totalNetRevenue = useSelector(
    totalMetric('totalNetRevenue', insightLevel),
  );
  const netRevenue = useSelector(totalMetric('netRevenue', insightLevel));
  const impressions = useSelector(totalMetric('impressions', insightLevel));
  const seedingNetRevenue = useSelector(
    totalMetric('seedingNetRevenue', insightLevel),
  );
  const seedingPlays = useSelector(totalMetric('seedingPlays', insightLevel));

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card className="w-100">
        <CardBody>
          <div className="clearfix">
            <h4 className="card-title mb-4">{t('Publisher Revenues')}</h4>
          </div>
          {!loading ? (
            <>
              <div className="d-flex mt-1">
                <div className="mr-4 avatar-sm align-self-center rounded-circle overflow-hidden">
                  <span className="avatar-title bg-primary">
                    <i className="fas fa-ad font-size-24"></i>
                  </span>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted mb-2">{t('Total')}</p>
                  <h3 className="mb-0">
                    {`${
                      isFinite(totalNetRevenue) ? totalNetRevenue.toFixed(2) : 0
                    } €`}
                  </h3>
                </div>
              </div>
              <div className="table-responsive mt-5">
                <Table className="table align-middle mb-0">
                  <tbody>
                    <tr key="adv">
                      <td>
                        <h5 className="mb-1">{t('Advertising')}</h5>
                        <p className="text-muted mb-0">{`${
                          isFinite(netRevenue) ? netRevenue.toFixed(2) : 0
                        } €`}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-1">{t('Impressions')}</p>
                        <h5 className="mb-0">{Math.floor(impressions)}</h5>
                      </td>
                    </tr>
                    <tr key="seeding">
                      <td>
                        <h5 className="mb-1">{t('Seeding')}</h5>
                        <p className="text-muted mb-0">{`${
                          isFinite(seedingNetRevenue)
                            ? seedingNetRevenue.toFixed(2)
                            : 0
                        } €`}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-1">{t('Plays')}</p>
                        <h5 className="mb-0">{Math.floor(seedingPlays)}</h5>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            <CenterBox height={290}>
              <Spinner
                color={colors.dark}
                size={14}
                speed={1}
                animating={true}
              />
            </CenterBox>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default memo(SiteRevenues);
