import {
  fork,
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  take,
  cancel,
  select,
} from 'redux-saga/effects';
import allowedRoles from '../../config/assignAllowedRoles';

import types from './actionTypes';
import * as usersActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import toastr from 'toastr';
import { toDateFirebase } from '../../helpers/sharedFunction';

function* createUserSaga({ user }) {
  try {
    const { email, password } = user;
    const { user: userData } = yield call(
      rsf.auth.createUserWithEmailAndPassword,
      email,
      password,
    );

    delete user.password;
    delete user.confirmPassword;

    yield call(rsf.firestore.setDocument, `users/${userData.uid}`, {
      ...user,
      active: false,
      provider: 'email',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    const actionAuthFunction = firebase
      .functions()
      .httpsCallable('actionAuth-actionAuth');

    const { data } = yield call(actionAuthFunction, {
      email,
      credential: {
        email,
        password,
      },
      action: 'user-activation',
    });
    if (data.error) throw new Error(data.error.message);

    yield put(usersActions.createUserSuccess(user));
    toastr.success('User created and Credential email sent!', '');
  } catch (error) {
    yield put(usersActions.createUserFailure(error));
  }
}

function* updateUserSaga({ user }) {
  try {
    const usersRef = firebase.firestore().collection('users').doc(user.id);
    delete user.id;
    delete user.createdAt;

    yield call(
      rsf.firestore.setDocument,
      usersRef,
      {
        ...user,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
    yield put(usersActions.updateUserSuccess(user));
    toastr.success('User updated!', '');
  } catch (error) {
    yield put(usersActions.updateUserFailure(error));
  }
}

function* userActivationSaga({ user }) {
  try {
    const actionAuthFunction = firebase
      .functions()
      .httpsCallable('actionAuth-actionAuth');

    const { data } = yield call(actionAuthFunction, {
      email: user.email,
      action: 'user-activation',
    });
    if (data.error) throw new Error(data.error.message);

    yield put(usersActions.userActivationSuccess());
    toastr.success(data.message, '');
  } catch (error) {
    yield put(usersActions.userActivationFailure(error));
  }
}

const userTransformer = (payload, params) => {
  const admin = params.user;
  const roles = allowedRoles[admin.role];
  let users = [];

  payload.forEach((user) => {
    const data = user.data();
    if (roles.includes(data.role) || user.id === admin.id) {
      users.push({
        id: user.id,
        ...data,
        ...(data.createdAt && {
          createdAt: toDateFirebase(user, data).toDate(),
        }),
        ...(data.updatedAt && {
          updatedAt: toDateFirebase(user, data, 'updatedAt').toDate(),
        }),
      });
    }
  });

  return users;
};

function* syncUsersSaga() {
  const user = yield select((state) => state.Auth.admin);
  const usersRef = firebase
    .firestore()
    .collection('users')
    .orderBy('createdAt', 'desc');

  const task = yield fork(rsf.firestore.syncCollection, usersRef, {
    successActionCreator: usersActions.syncUsersSuccess,
    failureActionCreator: usersActions.syncUsersFailure,
    params: {
      user,
    },
    transform: userTransformer,
  });

  yield take(types.RESET_USER_STATE);
  yield cancel(task);
}

function* userSaga() {
  yield all([
    takeLatest(types.SYNC_USERS.REQUEST, syncUsersSaga),
    takeLeading(types.CREATE_USER.REQUEST, createUserSaga),
    takeLeading(types.UPDATE_USER.REQUEST, updateUserSaga),
    takeLatest(types.USER_ACTIVATION.REQUEST, userActivationSaga),
  ]);
}

export default userSaga;
