import React, { useEffect, useState, memo } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '../../../../lib/availity/form';
import { Select } from '../../../../lib/availity/select';
import '../../../../lib/availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import * as sitesActions from '../../../../store/sites/actions';

import { useTranslation } from 'react-i18next';

const MAX_SELECTED_SITES = 10;

const FilterSitesBox = ({ initialFilters, onUpdate }) => {
  const [siteIds, setSiteIds] = useState(initialFilters?.siteIds || null);

  const sites = useSelector((state) => state.Site.sites);
  const loading = useSelector((state) => state.Site.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sitesActions.syncSites());
  }, []);

  const handleFilters = (values) => {
    setSiteIds(values);
    onUpdate(values);
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ siteIds }}
        validationSchema={yup.object().shape({
          siteIds: yup.array().nullable(),
        })}
        className="d-flex">
        <Col md="12" className="d-flex align-items-center px-0">
          <h5 className="text-muted my-0 ml-5 mr-2">{t('Sites')}:</h5>
          <Select
            name="siteIds"
            id="siteIds"
            isMulti
            maxLength={MAX_SELECTED_SITES}
            value={siteIds}
            onChange={handleFilters}
            options={sites.map((site) => ({
              label: site.name,
              value: site.id,
            }))}
            isDisabled={loading}
            closeMenuOnSelect={false}
            blurInputOnSelect={true}
          />
        </Col>
      </Form>
    </CardBody>
  );
};

export default memo(FilterSitesBox);
