import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
} from 'reactstrap';
import { Form, Input } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import FormErrorMessage from '../Common/FormErrorMessage';

import * as customersActions from '../../store/customers/actions';
import * as publishersActions from '../../store/publishers/actions';
import { selectPublishersWithoutCustomer } from '../../selectors/publisher';
import { useDispatch, useSelector } from 'react-redux';

import firebase from 'firebase/app';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import countryCodes from '../../lib/countryCodes.json';

const FormCustomer = (props) => {
  const editingCustomer = props.customer || {};
  const publishers = useSelector(
    selectPublishersWithoutCustomer(editingCustomer.id),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(publishersActions.syncPublishers());
  }, []);

  const handleSubmit = (values) => {
    const customer = {
      ...values,
      showSpreakerEpisodeURL: values.showSpreakerEpisodeURL || false,
      showMegaphoneEpisodeURL: values.showMegaphoneEpisodeURL || false,
    };

    dispatch(
      props.create
        ? customersActions.createCustomer({
            ...customer,
            ...(values.isPublisher && {
              publisherId: values.publisherId,
            }),
          })
        : customersActions.updateCustomer(
            {
              id: editingCustomer.id,
              ...customer,
              ...(!values.isPublisher && {
                publisherId: firebase.firestore.FieldValue.delete(),
              }),
            },
            editingCustomer.publisherId,
          ),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ creditAmount: 0, ...editingCustomer }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values }) => (
            <>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" name="name" id="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="companyName">Company Name</Label>
                    <Input type="text" name="companyName" id="companyName" />
                    <FormErrorMessage name="companyName" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="taxId">Tax ID</Label>
                    <Input
                      type="text"
                      name="taxId"
                      id="taxId"
                      disabled={!props.create}
                    />
                  </FormGroup>
                  <FormErrorMessage name="taxId" />
                  <p className="font-size-12 text-muted">
                    Insert with country code (ex. IT12345678901)
                  </p>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="email">E-mail</Label>
                    <Input type="email" name="email" id="email" />
                    <FormErrorMessage name="email" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <Label htmlFor="address">Address</Label>
                    <Input type="text" name="address" id="address" />
                    <FormErrorMessage name="address" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="city">City</Label>
                    <Input type="text" name="city" id="city" />
                    <FormErrorMessage name="city" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="zip">Zip</Label>
                    <Input type="text" name="zip" id="zip" />
                    <FormErrorMessage name="zip" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="country">Country</Label>
                    <Select
                      name="country"
                      id="country"
                      options={Object.keys(countryCodes).map((key) => ({
                        value: key,
                        label: countryCodes[key],
                      }))}
                    />
                    <FormErrorMessage name="country" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="creditLine"
                        name="creditLine"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="creditLine">
                        Credit Line?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <Collapse isOpen={values.creditLine}>
                    <FormGroup>
                      <Label htmlFor="creditAmount">Credit Amount</Label>
                      <Input
                        type="number"
                        name="creditAmount"
                        id="creditAmount"
                        max={20000}
                        step="100"
                      />
                    </FormGroup>
                  </Collapse>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="isPublisher"
                        name="isPublisher"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isPublisher">
                        Is Publisher?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <Collapse isOpen={values.isPublisher}>
                    <FormGroup>
                      <Label htmlFor="publisherId">Publisher</Label>
                      <Select
                        name="publisherId"
                        id="publisherId"
                        options={publishers.map((publisher) => ({
                          label: publisher.name,
                          value: publisher.id,
                        }))}
                      />
                    </FormGroup>
                    <FormErrorMessage name="publisherId" />
                  </Collapse>
                </Col>
              </Row>
              <Row>
                <h5>Campaign - Seeding</h5>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="showSpreakerEpisodeURL"
                        name="showSpreakerEpisodeURL"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="showSpreakerEpisodeURL">
                        Show Spreaker.com Episode URL
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="showMegaphoneEpisodeURL"
                        name="showMegaphoneEpisodeURL"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="showMegaphoneEpisodeURL">
                        Show Megaphone Episode URL
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCustomer;
