import React from 'react';
import {
  ViewButton,
  EditButton,
  ActiveButton,
  CountryFlag,
} from '../Common/DataTable/tableActionButtons';
import { getLabels, getFilter } from '../Common/DataTable/tableHelpers';

const createTableColumns = (
  sites,
  openViewModal,
  openEditModal,
  openActiveAlert,
) => {
  return [
    {
      title: 'Name',
      name: 'name',
    },
    {
      title: 'Description',
      name: 'description',
      filter: 'disabled',
      sorting: 'disabled',
    },
    {
      title: 'Content Lang',
      name: 'langTag',
      align: 'center',
      formatterComponent: ({ row }) => <CountryFlag row={row} />,
      filter: 'disabled',
    },
    {
      title: 'Include Sites',
      name: 'includeSiteIds',
      formatterComponent: ({ value }) => getLabels(value, sites, 'id', 'name'),
      filter: (value, filter) =>
        getFilter(getLabels(value, sites, 'id', 'name'), filter.value),
    },
    {
      title: 'Exclude Sites',
      name: 'excludeSiteIds',
      formatterComponent: ({ value }) => getLabels(value, sites, 'id', 'name'),
      filter: (value, filter) =>
        getFilter(getLabels(value, sites, 'id', 'name'), filter.value),
    },
    {
      title: 'Public',
      name: 'isPublic',
      width: 100,
      sort: true,
      formatterComponent: ({ value }) =>
        value ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
      filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    },
    {
      title: 'Size',
      name: 'size',
      width: 100,
    },
    {
      title: 'Active',
      name: 'active',
      width: 100,
      sort: true,
      formatterComponent: ({ value }) =>
        value ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
      filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
    },
    {
      title: 'Setup',
      name: 'setup',
      width: 110,
      formatterComponent: ({ value }) =>
        value === 'completed' ? (
          <span className="badge badge-success font-size-12">
            {value.toUpperCase()}
          </span>
        ) : value === 'error' ? (
          <span className="badge badge-danger font-size-12">
            {value.toUpperCase()}
          </span>
        ) : (
          <span className="badge badge-warning font-size-12">
            {value.toUpperCase()}
          </span>
        ),
      filter: (value, filter) => getFilter(value, filter.value),
    },
    {
      title: 'Action',
      name: 'action',
      width: 160,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          {row.setup !== 'building' && (
            <>
              <EditButton row={row} onPress={openEditModal} />
              <ActiveButton row={row} onPress={openActiveAlert} />
            </>
          )}
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
