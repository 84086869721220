import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { isAdvertiser as isAdvertiserSelector } from '../../selectors/auth';
import { actualCustomer } from '../../selectors/customer';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import UserInfo from './UserInfo';
import CompanyInfo from './CompanyInfo';
import CustomerTransaction from './CustomerTransaction';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';

const Settings = (props) => {
  const admin = useSelector((state) => state.Auth.admin);
  const isAdvertiser = useSelector(isAdvertiserSelector);
  const customer = useSelector(actualCustomer);

  const [activeTab, setActiveTab] = useState('1');

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" size="8" />
          <div className="checkout-tabs">
            <Row>
              <Col lg="2" sm="3">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1');
                      }}>
                      <i className="mdi mdi-account-circle d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Account Details</p>
                    </NavLink>
                  </NavItem>
                  {isAdvertiser && customer && (
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          setActiveTab('2');
                        }}>
                        <i className="bx bxs-business d-block check-nav-icon mt-4 mb-2" />
                        <p className="fw-bold mb-4">Business Info</p>
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              </Col>
              <Col lg="10" sm="9">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <UserInfo admin={admin} />
                      </TabPane>
                      {isAdvertiser && customer && (
                        <TabPane
                          tabId="2"
                          id="v-pills-payment"
                          role="tabpanel"
                          aria-labelledby="v-pills-payment-tab">
                          <>
                            <h3 className="mb-4">Business information</h3>
                            <CompanyInfo customer={customer} />
                            <CustomerTransaction customer={customer} />
                          </>
                        </TabPane>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
