import React from 'react';
import { ErrorMessage } from 'formik';
import { capitalize } from '../../helpers/sharedFunction';

const FormErrorMessage = ({ name }) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <p className="font-size-12 my-1">
        <i className="bx bxs-error font-size-15 text-danger align-middle mr-1"></i>
        <span className="text-danger align-middle">
          {capitalize(
            typeof msg === 'object'
              ? Object.values(msg)
                  .map((value) => value)
                  .join(', ')
              : msg,
          )}
        </span>
      </p>
    )}
  </ErrorMessage>
);

export default FormErrorMessage;
