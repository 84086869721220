const DELETE_SENTINEL = '__delete__';

const removeDeleteSentinelValue = (obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== DELETE_SENTINEL),
  );

const insertItem = (array, item) => {
  let newArray = array.slice();
  newArray.splice(0, 0, item);
  return newArray;
};

const removeItem = (array, item, index = 'id', index2 = null) => {
  return !index2
    ? array.filter((arr) => arr[index] !== item[index])
    : array.filter((arr) => arr[index] !== item[index2]);
};

//actions: 'no_merge', 'merge', 'skip'
const addOrReplaceObjectInArray = (
  array,
  items,
  index = 'id',
  action = 'no_merge',
) => {
  items = Array.isArray(items) ? items : [items];
  let newArray = array;

  items.forEach((item) => {
    let replace = false;
    newArray = newArray.map((arr) => {
      if (arr[index] !== item[index]) {
        return arr;
      } else {
        replace = true;
        if (action === 'skip') return arr;
        if (action === 'skip_draft' && arr.isDraft) return arr;
        if (action === 'merge') {
          const newObj = {
            ...arr,
            ...item,
          };
          return removeDeleteSentinelValue(newObj);
        }
        return {
          ...item,
        };
      }
    });
    newArray = replace ? newArray : insertItem(newArray, item);
  });
  return newArray;
};

export { DELETE_SENTINEL, insertItem, removeItem, addOrReplaceObjectInArray };
