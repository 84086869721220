import React, { useState, useCallback } from 'react';

import './YearlyPicker.scss';

const YearlyPicker = ({ startDate, endDate, minDate, maxDate, onChange }) => {
  const minYear = minDate.year();
  const maxYear = maxDate.year();

  const [selectedYear, setSelectedYear] = useState(startDate.year());

  const generateYearOptions = useCallback(() => {
    const years = [];
    for (let year = minYear; year <= maxYear; year++) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>,
      );
    }
    return years;
  }, [minYear, maxYear]);

  const handleChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    onChange(year);
  };

  return (
    <div className="yearlyPicker">
      <button className="yearlyPicker_calendarIcon" type="button">
        <svg focusable="false" viewBox="0 0 1393.1 1500">
          <path d="m107 1393h241v-241h-241zm295 0h268v-241h-268zm-295-295h241v-268h-241zm295 0h268v-268h-268zm-295-321h241v-241h-241zm616 616h268v-241h-268zm-321-616h268v-241h-268zm643 616h241v-241h-241zm-322-295h268v-268h-268zm-294-723v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm616 723h241v-268h-241zm-322-321h268v-241h-268zm322 0h241v-241h-241zm27-402v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm321-54v1072c0 29-11 54-32 75s-46 32-75 32h-1179c-29 0-54-11-75-32s-32-46-32-75v-1072c0-29 11-54 32-75s46-32 75-32h107v-80c0-37 13-68 40-95s57-39 94-39h54c37 0 68 13 95 39 26 26 39 58 39 95v80h321v-80c0-37 13-69 40-95 26-26 57-39 94-39h54c37 0 68 13 94 39s40 58 40 95v80h107c29 0 54 11 75 32s32 46 32 75z"></path>
        </svg>
      </button>
      <div className="yearlyPicker_input">
        <select
          className="yearlyPicker_input_value"
          value={selectedYear}
          onChange={handleChange}>
          {generateYearOptions()}
        </select>
      </div>
    </div>
  );
};

export default YearlyPicker;
