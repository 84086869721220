import {
  put,
  all,
  call,
  takeLeading,
  takeLatest,
  takeEvery,
  delay,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as campaignsActions from './actions';
import * as creativesActions from './creatives/actions';
import * as clustersActions from '../clusters/actions';

import { selectCampaign } from '../../selectors/campaign';
import { actualCustomer, selectCustomerByUser } from '../../selectors/customer';
import { selectCreativeByCampaign } from '../../selectors/creative';
import { selectClusterByCampaign } from '../../selectors/cluster';

import firebase from 'firebase/app';
import rsf from '../../helpers/firebase';

import { toDateFirebase } from '../../helpers/sharedFunction';
import { DELETE_SENTINEL } from '../../helpers/reducerHelper';
import { DEFAULT_CONFIG_TABLE } from '../../components/Common/DataTable/tableConfig';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { removeItem } from '../../helpers/reducerHelper';
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_CLUSTER_INITIAL_STATE,
} from '../../config/campaign';

function* createCampaignSaga({ campaign }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const { user, startDate, endDate } = campaign;
    const customer = yield select(selectCustomerByUser(user.id));

    delete campaign.user;

    yield delay(1000);

    yield put(
      campaignsActions.createCampaignSuccess({
        ...campaign,
        startDate: moment(startDate).startOf('day').toDate(),
        endDate: moment(endDate).endOf('day').toDate(),
        countryId,
        customerId: customer.id,
        userId: user.id,
        isDraft: true,
        status: CAMPAIGN_STATUS.NEW,
        ...(!campaign.free && {
          clusterId: CAMPAIGN_CLUSTER_INITIAL_STATE.NO_CLUSTER,
          newClusterCreated: false,
        }),
      }),
    );
  } catch (error) {
    yield put(campaignsActions.createCampaignFailure(error));
  }
}

function* updateCampaignSaga({ campaign }) {
  try {
    const { startDate, endDate } = campaign;

    yield delay(1000);

    const updateCampaign = {
      ...campaign,
      startDate: moment(startDate).startOf('day').toDate(),
      endDate: moment(endDate).endOf('day').toDate(),
      isDraft: true,
    };

    yield put(
      campaign.status === CAMPAIGN_STATUS.NEW
        ? campaignsActions.updateDraftCampaignSuccess(updateCampaign)
        : campaignsActions.updateCampaignSuccess(updateCampaign),
    );
  } catch (error) {
    yield put(campaignsActions.updateCampaignFailure(error));
  }
}

function* deleteDraftCampaignSaga({ campaign }) {
  try {
    const cluster = yield select(selectClusterByCampaign(campaign.id, true));
    const creatives = yield select(selectCreativeByCampaign(campaign.id, true));
    const draftCreatives = yield select(
      (state) => state.Creative.draftCreatives,
    );
    const draftCreativeIds = draftCreatives.map(({ id }) => id);

    if (cluster) yield put(clustersActions.deleteDraftCluster(cluster));
    yield all(
      creatives
        .filter(({ id }) => draftCreativeIds.includes(id))
        .map((creative) => put(creativesActions.deleteDraftCreative(creative))),
    );

    yield delay(1000);
    yield put(campaignsActions.deleteDraftCampaignSuccess(campaign));
  } catch (error) {
    yield put(campaignsActions.deleteDraftCampaignFailure(error));
  }
}

function* approveCampaignSaga({ campaign }) {
  try {
    const userId = yield select((state) => state.Auth.admin.id);

    const campaignsRef = firebase
      .firestore()
      .collection('campaigns')
      .doc(campaign.id);

    yield call(
      rsf.firestore.setDocument,
      campaignsRef,
      {
        status: CAMPAIGN_STATUS.APPROVED,
        approvedAt: firebase.firestore.FieldValue.serverTimestamp(),
        approvedByUserId: userId,
        rejectionMessages: firebase.firestore.FieldValue.delete(),
        rejectedByUserId: firebase.firestore.FieldValue.delete(),
      },
      { merge: true },
    );
    yield put(
      campaignsActions.approveCampaignSuccess({
        id: campaign.id,
        status: CAMPAIGN_STATUS.APPROVED,
        approvedAt: new Date(),
        approvedByUserId: userId,
        rejectionMessages: DELETE_SENTINEL,
        rejectedByUserId: DELETE_SENTINEL,
      }),
    );
  } catch (error) {
    yield put(campaignsActions.approveCampaignFailure(error));
  }
}

function* rejectCampaignSaga({ campaign, reason }) {
  try {
    const userId = yield select((state) => state.Auth.admin.id);
    const rejectionMessages = campaign.rejectionMessages || [];

    const { id, name } = campaign;

    const campaignsRef = firebase
      .firestore()
      .collection('campaigns')
      .doc(campaign.id);

    yield call(
      rsf.firestore.setDocument,
      campaignsRef,
      {
        status: CAMPAIGN_STATUS.REJECTED,
        rejectionMessages: [
          { id, name, reason, ref: 'campaign' },
          ...rejectionMessages,
        ],
        rejectedByUserId: userId,
      },
      { merge: true },
    );
    yield put(
      campaignsActions.rejectCampaignSuccess({
        id: campaign.id,
        status: CAMPAIGN_STATUS.REJECTED,
        rejectionMessages: [
          { id, name, reason, ref: 'campaign' },
          ...rejectionMessages,
        ],
        rejectedByUserId: userId,
      }),
    );
  } catch (error) {
    yield put(campaignsActions.rejectCampaignFailure(error));
  }
}

function* updateRejectMessageCampaignSaga({ campaign, reason, operation }) {
  try {
    campaign = yield select(selectCampaign(campaign.id));
    const { rejectionMessages = [] } = campaign;

    let newRejectionMessages =
      operation === 'remove'
        ? removeItem(rejectionMessages, reason)
        : operation === 'add'
        ? [...rejectionMessages, reason]
        : rejectionMessages;

    const campaignsRef = firebase
      .firestore()
      .collection('campaigns')
      .doc(campaign.id);

    yield call(
      rsf.firestore.setDocument,
      campaignsRef,
      {
        rejectionMessages: newRejectionMessages,
      },
      { merge: true },
    );
    yield put(
      campaignsActions.updateRejectMessageCampaignSuccess({
        id: campaign.id,
        rejectionMessages: newRejectionMessages,
      }),
    );
  } catch (error) {
    yield put(campaignsActions.updateRejectMessageCampaignFailure(error));
  }
}

function* reprocessCampaignSaga({ campaign }) {
  try {
    const campaignsRef = firebase
      .firestore()
      .collection('campaigns')
      .doc(campaign.id);

    yield call(
      rsf.firestore.setDocument,
      campaignsRef,
      {
        status: CAMPAIGN_STATUS.APPROVED,
        error: firebase.firestore.FieldValue.delete(),
      },
      { merge: true },
    );
    yield put(
      campaignsActions.reprocessCampaignSuccess({
        id: campaign.id,
        status: CAMPAIGN_STATUS.APPROVED,
        error: DELETE_SENTINEL,
      }),
    );
  } catch (error) {
    yield put(campaignsActions.reprocessCampaignFailure(error));
  }
}

const campaignTransformer = (campaign) => {
  const data = campaign.data();
  return {
    id: campaign.id,
    ...data,
    startDate: toDateFirebase(campaign, data, 'startDate').toDate(),
    endDate: toDateFirebase(campaign, data, 'endDate').toDate(),
    createdAt: toDateFirebase(campaign, data).toDate(),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(campaign, data, 'updatedAt').toDate(),
    }),
    ...(data.approvedAt && {
      approvedAt: toDateFirebase(campaign, data, 'approvedAt').toDate(),
    }),
    ...(data.stoppedAt && {
      stoppedAt: toDateFirebase(campaign, data, 'stoppedAt').toDate(),
    }),
  };
};

function* fetchCampaignsSaga({ forceUpdate, startAfterId, limit }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const customer = yield select(actualCustomer);

    const startAfterSnap = startAfterId
      ? yield call(rsf.firestore.getDocument, `campaigns/${startAfterId}`)
      : null;

    let campaignsRef = firebase
      .firestore()
      .collection('campaigns')
      .where('countryId', '==', countryId)
      .limit(limit);

    if (startAfterSnap) campaignsRef = campaignsRef.startAfter(startAfterSnap);
    if (customer)
      campaignsRef = campaignsRef.where('customerId', '==', customer.id);

    const campaignsSnap = yield call(rsf.firestore.getCollection, campaignsRef);

    let campaigns = [];

    campaignsSnap.forEach((campaign) => {
      campaigns.push(campaignTransformer(campaign));
    });

    const previousCampaigns = startAfterSnap
      ? yield select((state) => state.Campaign.campaigns)
      : [];
    const loadMore = limit && campaigns.length === limit ? true : false;

    yield put(
      campaignsActions.fetchCampaignsSuccess(
        [...previousCampaigns, ...campaigns],
        forceUpdate,
        loadMore,
      ),
    );
  } catch (error) {
    yield put(campaignsActions.fetchCampaignsFailure(error));
  }
}

function* fetchCampaignByIdSaga({ campaignId, forceUpdate }) {
  try {
    const campaignRef = firebase
      .firestore()
      .collection('campaigns')
      .doc(campaignId);

    const campaignDoc = yield call(rsf.firestore.getDocument, campaignRef);
    if (!campaignDoc.exists)
      throw new Error(`Campaign with id ${campaignId} not found!`);

    const campaign = campaignTransformer(campaignDoc);

    yield put(
      campaignsActions.fetchCampaignByIdSuccess([campaign], forceUpdate),
    );
  } catch (error) {
    yield put(campaignsActions.fetchCampaignByIdFailure(error));
  }
}

function* discardDraftCampaignsSaga() {
  try {
    const draftCreatives = yield select(
      (state) => state.Creative.draftCreatives,
    );
    yield all(
      draftCreatives.map((creative) =>
        put(creativesActions.deleteDraftCreative(creative)),
      ),
    );
    const draftClusters = yield select((state) => state.Cluster.draftClusters);
    yield all(
      draftClusters.map((cluster) =>
        put(clustersActions.deleteDraftCluster(cluster)),
      ),
    );
    yield put(
      campaignsActions.fetchCampaigns(
        true,
        null,
        DEFAULT_CONFIG_TABLE.DATA_FETCH_LIMIT,
      ),
    );
    yield put(creativesActions.resetState());
    yield put(campaignsActions.discardDraftCampaignsSuccess());
  } catch (error) {
    yield put(campaignsActions.fetchCampaignsFailure(error));
  }
}

function* publishCampaignsSaga({ campaigns }) {
  try {
    if (!Array.isArray(campaigns))
      throw new Error('campaigns must be an array!');

    const results = yield all(
      campaigns.map((campaign) => call(publishCampaign, campaign)),
    );
    yield put(
      campaignsActions.fetchCampaigns(
        true,
        null,
        DEFAULT_CONFIG_TABLE.DATA_FETCH_LIMIT,
      ),
    );
    yield put(campaignsActions.publishCampaignsSuccess(results));
  } catch (error) {
    yield put(campaignsActions.publishCampaignsFailure(error));
  }
}

function* publishCampaign(campaign) {
  const cluster = yield select(selectClusterByCampaign(campaign.id, true));
  const creatives = yield select(selectCreativeByCampaign(campaign.id, true));

  const publishCampaignFunction = firebase
    .functions()
    .httpsCallable('publishCampaign-publishCampaign');
  const { data } = yield call(
    publishCampaignFunction,
    prepareToPublish(
      cloneDeep(campaign),
      cloneDeep(cluster),
      creatives.map((creative) => cloneDeep(creative)),
    ),
  );

  if (data.status === 200) {
    yield call(deleteDraftCampaignSaga, {
      campaign,
    });
    yield delay(500);
    yield put(creativesActions.fetchCreativesByCampaignId(campaign.id, true));
  }

  yield delay(1000);
  return { campaignId: campaign.id, name: campaign.name, ...data };
}

const prepareToPublish = (campaign, cluster, creatives) => {
  creatives = creatives.map((creative) => {
    delete creative.isDraft;
    return {
      ...creative,
      startDate: moment(creative.startDate).startOf('day').utc().toDate(),
      endDate: moment(creative.endDate).endOf('day').utc().toDate(),
    };
  });

  Object.values(CAMPAIGN_CLUSTER_INITIAL_STATE).includes(campaign.clusterId) &&
    delete campaign.clusterId;
  delete campaign.newClusterCreated;
  delete cluster?.isDraft;
  return {
    campaign: {
      ...campaign,
      startDate: moment(campaign.startDate).startOf('day').utc().toDate(),
      endDate: moment(campaign.endDate).endOf('day').utc().toDate(),
    },
    cluster: cluster || '',
    creatives,
  };
};

function* actionCampaignSaga({ campaign, action }) {
  try {
    const actionCampaignFunction = firebase
      .functions()
      .httpsCallable('actionCampaign-actionCampaign');
    const { data } = yield call(actionCampaignFunction, {
      campaignId: campaign.id,
      action,
    });

    if (data.error) throw new Error(data.error.message);

    yield put(
      campaignsActions.actionCampaignSuccess(data.data, `Campaign ${action}d!`),
    );
  } catch (error) {
    yield put(campaignsActions.actionCampaignFailure(error));
  }
}

function* campaignSaga() {
  yield all([
    takeLatest(types.FETCH_CAMPAIGNS.REQUEST, fetchCampaignsSaga),
    takeLeading(types.FETCH_CAMPAIGN_BY_ID.REQUEST, fetchCampaignByIdSaga),
    takeLeading(types.CREATE_CAMPAIGN.REQUEST, createCampaignSaga),
    takeLeading(types.UPDATE_CAMPAIGN.REQUEST, updateCampaignSaga),
    takeEvery(types.DELETE_DRAFT_CAMPAIGN.REQUEST, deleteDraftCampaignSaga),
    takeLeading(types.APPROVE_CAMPAIGN.REQUEST, approveCampaignSaga),
    takeLeading(types.REJECT_CAMPAIGN.REQUEST, rejectCampaignSaga),
    takeLeading(
      types.UPDATE_REJECT_MESSAGE_CAMPAIGN.REQUEST,
      updateRejectMessageCampaignSaga,
    ),
    takeLeading(types.REPROCESS_CAMPAIGN.REQUEST, reprocessCampaignSaga),
    takeLeading(
      types.DISCARD_DRAFT_CAMPAIGNS.REQUEST,
      discardDraftCampaignsSaga,
    ),
    takeLeading(types.PUBLISH_CAMPAIGNS.REQUEST, publishCampaignsSaga),
    takeLeading(types.ACTION_CAMPAIGN.REQUEST, actionCampaignSaga),
  ]);
}

export default campaignSaga;
