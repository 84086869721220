import React, { useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import * as publishersActions from '../../store/publishers/actions';
import { useDispatch } from 'react-redux';
import { Form, Input } from '../../lib/availity/form';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import { generateRandomString } from '../../helpers/sharedFunction';
import firebase from 'firebase/app';

const FormPublisher = (props) => {
  const editingPublisher = props.publisher || {};

  const [active, setActive] = useState(editingPublisher.active || false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const apiKey = values.apiKey && values.apiKey !== '' ? values.apiKey : null;
    delete values.apiKey;

    const publisher = {
      ...values,
      active,
    };

    dispatch(
      props.create
        ? publishersActions.createPublisher({
            ...publisher,
            ...(apiKey && { apiKey }),
          })
        : publishersActions.updatePublisher({
            id: editingPublisher.id,
            ...publisher,
            apiKey: apiKey || firebase.firestore.FieldValue.delete(),
          }),
    );
    props.closeModal();
  };

  const handleGenerateAPIKey = useCallback((setFieldValue) => {
    const key = generateRandomString(30);
    setFieldValue('apiKey', key);
  }, []);

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingPublisher, active }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        defaultChecked={active}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="active"
                        onClick={(e) => {
                          setActive(!active);
                        }}>
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="logoUrl">Logo</Label>
                    <Input id="logoUrl" name="logoUrl" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="apiKey">API Key</Label>
                    <Input id="apiKey" name="apiKey" disabled />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Generate API Key</Label>
                    <Button
                      color="dark"
                      outline
                      className="btn-sm waves-effect waves-light d-block"
                      onClick={() => handleGenerateAPIKey(setFieldValue)}>
                      <i className="bx bx-key align-middle mr-2"></i>
                      Generate
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <h5>Publisher Permissions</h5>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="createPodcasts"
                        name="createPodcasts"
                        checked={!!values.createPodcasts}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="createPodcasts">
                        Create Podcasts?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="downloadPodcasts"
                        name="downloadPodcasts"
                        checked={!!values.downloadPodcasts}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="downloadPodcasts">
                        Download Podcasts?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormPublisher;
