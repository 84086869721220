import React, { useEffect, useState, memo } from 'react';
import { Col, CardBody } from 'reactstrap';
import { Form } from '../../../../lib/availity/form';
import { Select } from '../../../../lib/availity/select';
import '../../../../lib/availity/yup';
import * as yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import { selectPublishersByActualRole } from '../../../../selectors/publisher';
import { selectSitesByPublisher } from '../../../../selectors/site';
import * as networksActions from '../../../../store/networks/actions';
import * as publishersActions from '../../../../store/publishers/actions';
import * as sitesActions from '../../../../store/sites/actions';
import { showAllSelect } from '../../../../config/filters';

import { useTranslation } from 'react-i18next';

const MAX_SELECTED_SITES = 10;

const FilterPublishersBox = ({ initialFilters, onUpdate, showAllFilter }) => {
  const loadingPublishers = useSelector((state) => state.Publisher.loading);
  const loadingSites = useSelector((state) => state.Site.loading);

  const selectedPublishers = useSelector(selectPublishersByActualRole);
  const publishersForSelect = [
    ...(showAllFilter ? [showAllSelect] : []),
    ...selectedPublishers,
  ];
  const [publisherId, setPublisherId] = useState(
    initialFilters?.publisherId || publishersForSelect[0]?.id,
  );

  const sites = useSelector(selectSitesByPublisher(publisherId));
  const [siteIds, setSiteIds] = useState(initialFilters?.siteIds || null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(networksActions.syncNetworks());
    dispatch(publishersActions.syncPublishers());
    dispatch(sitesActions.syncSites());
  }, []);

  const handleFilters = (value, field, setFieldValue) => {
    if (field === 'publisherId') {
      setPublisherId(value);
      setSiteIds(null);
      setFieldValue('siteIds', null);
    } else setSiteIds(value);

    onUpdate(
      field === 'publisherId' ? value : publisherId,
      field === 'publisherId' ? null : field === 'siteIds' ? value : siteIds,
    );
  };

  const { t } = useTranslation();

  return (
    <CardBody className="pt-0">
      <Form
        initialValues={{ publisherId, siteIds }}
        validationSchema={yup.object().shape({
          publisherId: yup.string(),
          siteIds: yup.array().nullable(),
        })}
        enableReinitialize={true}
        className="d-flex">
        {({ setFieldValue }) => (
          <>
            <Col md="3" className="d-flex align-items-center px-0">
              <h5 className="text-muted m-0 mr-2">{t('Publisher')}:</h5>
              <Select
                name="publisherId"
                id="publisherId"
                isMulti={false}
                value={publisherId}
                onChange={(value) =>
                  handleFilters(value, 'publisherId', setFieldValue)
                }
                options={publishersForSelect.map(({ id, name }) => ({
                  label: t(name),
                  value: id,
                }))}
                isDisabled={loadingPublishers}
                closeMenuOnSelect={true}
                blurInputOnSelect={true}
              />
            </Col>
            <Col md="9" className="d-flex align-items-center px-0">
              <h5 className="text-muted my-0 ml-5 mr-2">{t('Sites')}:</h5>
              <Select
                name="siteIds"
                id="siteIds"
                isMulti
                maxLength={MAX_SELECTED_SITES}
                value={siteIds}
                onChange={(values) =>
                  handleFilters(values, 'siteIds', setFieldValue)
                }
                options={sites.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                isDisabled={publisherId === '' || loadingSites}
                closeMenuOnSelect={false}
                blurInputOnSelect={true}
              />
            </Col>
          </>
        )}
      </Form>
    </CardBody>
  );
};

export default memo(FilterPublishersBox);
