export default [
  {
    value: '1',
    label: 'Automotive',
    children: [
      {
        value: '1.1',
        label: 'Auto Body Styles',
        children: [
          {
            value: '1.1.1',
            label: 'Commercial Trucks',
          },
          {
            value: '1.1.10',
            label: 'Sedan',
          },
          {
            value: '1.1.11',
            label: 'Station Wagon',
          },
          {
            value: '1.1.12',
            label: 'SUV',
          },
          {
            value: '1.1.13',
            label: 'Van',
          },
          {
            value: '1.1.2',
            label: 'Convertible',
          },
          {
            value: '1.1.3',
            label: 'Coupe',
          },
          {
            value: '1.1.4',
            label: 'Crossover',
          },
          {
            value: '1.1.5',
            label: 'Hatchback',
          },
          {
            value: '1.1.6',
            label: 'Microcar',
          },
          {
            value: '1.1.7',
            label: 'Minivan',
          },
          {
            value: '1.1.8',
            label: 'Off-Road Vehicles',
          },
          {
            value: '1.1.9',
            label: 'Pickup Trucks',
          },
        ],
      },
      {
        value: '1.10',
        label: 'Auto Type',
        children: [
          {
            value: '1.10.1',
            label: 'Budget Cars',
          },
          {
            value: '1.10.2',
            label: 'Certified Pre-Owned Cars',
          },
          {
            value: '1.10.3',
            label: 'Classic Cars',
          },
          {
            value: '1.10.4',
            label: 'Concept Cars',
          },
          {
            value: '1.10.5',
            label: 'Driverless Cars',
          },
          {
            value: '1.10.6',
            label: 'Green Vehicles',
          },
          {
            value: '1.10.7',
            label: 'Luxury Cars',
          },
          {
            value: '1.10.8',
            label: 'Performance Cars',
          },
        ],
      },
      {
        value: '1.11',
        label: 'Car Culture',
      },
      {
        value: '1.12',
        label: 'Dash Cam Videos',
      },
      {
        value: '1.13',
        label: 'Motorcycles',
      },
      {
        value: '1.14',
        label: 'Road-Side Assistance',
      },
      {
        value: '1.15',
        label: 'Scooters',
      },
      {
        value: '1.2',
        label: 'Auto Buying and Selling',
      },
      {
        value: '1.3',
        label: 'Auto Insurance',
      },
      {
        value: '1.4',
        label: 'Auto Parts',
      },
      {
        value: '1.5',
        label: 'Auto Recalls',
      },
      {
        value: '1.6',
        label: 'Auto Repair',
      },
      {
        value: '1.7',
        label: 'Auto Safety',
      },
      {
        value: '1.8',
        label: 'Auto Shows',
      },
      {
        value: '1.9',
        label: 'Auto Technology',
        children: [
          {
            value: '1.9.1',
            label: 'Auto Infotainment Technologies',
          },
          {
            value: '1.9.2',
            label: 'Auto Navigation Systems',
          },
          {
            value: '1.9.3',
            label: 'Auto Safety Technologies',
          },
        ],
      },
    ],
  },
  {
    value: '2',
    label: 'Books and Literature',
    children: [
      {
        value: '2.1',
        label: 'Art and Photography Books',
      },
      {
        value: '2.2',
        label: 'Biographies',
      },
      {
        value: '2.3',
        label: "Children's Literature",
      },
      {
        value: '2.4',
        label: 'Comics and Graphic Novels',
      },
      {
        value: '2.5',
        label: 'Cookbooks',
      },
      {
        value: '2.6',
        label: 'Fiction',
      },
      {
        value: '2.7',
        label: 'Poetry',
      },
      {
        value: '2.8',
        label: 'Travel Books',
      },
      {
        value: '2.9',
        label: 'Young Adult Literature',
      },
    ],
  },
  {
    value: '3',
    label: 'Business and Finance',
    children: [
      {
        value: '3.1',
        label: 'Business',
        children: [
          {
            value: '3.1.1',
            label: 'Business Accounting & Finance',
          },
          {
            value: '3.1.10',
            label: 'Human Resources',
          },
          {
            value: '3.1.11',
            label: 'Large Business',
          },
          {
            value: '3.1.12',
            label: 'Logistics',
          },
          {
            value: '3.1.13',
            label: 'Marketing and Advertising',
          },
          {
            value: '3.1.14',
            label: 'Sales',
          },
          {
            value: '3.1.15',
            label: 'Small and Medium-sized Business',
          },
          {
            value: '3.1.16',
            label: 'Startups',
          },
          {
            value: '3.1.2',
            label: 'Business Administration',
          },
          {
            value: '3.1.3',
            label: 'Business Banking & Finance',
            children: [
              {
                value: '3.1.3.1',
                label: 'Angel Investment',
              },
              {
                value: '3.1.3.2',
                label: 'Bankruptcy',
              },
              {
                value: '3.1.3.3',
                label: 'Business Loans',
              },
              {
                value: '3.1.3.4',
                label: 'Debt Factoring & Invoice Discounting',
              },
              {
                value: '3.1.3.5',
                label: 'Mergers and Acquisitions',
              },
              {
                value: '3.1.3.6',
                label: 'Private Equity',
              },
              {
                value: '3.1.3.7',
                label: 'Sale & Lease Back',
              },
              {
                value: '3.1.3.8',
                label: 'Venture Capital',
              },
            ],
          },
          {
            value: '3.1.4',
            label: 'Business I.T.',
          },
          {
            value: '3.1.5',
            label: 'Business Operations',
          },
          {
            value: '3.1.6',
            label: 'Consumer Issues',
            children: [
              {
                value: '3.1.6.1',
                label: 'Recalls',
              },
            ],
          },
          {
            value: '3.1.7',
            label: 'Executive Leadership & Management',
          },
          {
            value: '3.1.8',
            label: 'Government Business',
          },
          {
            value: '3.1.9',
            label: 'Green Solutions',
          },
        ],
      },
      {
        value: '3.2',
        label: 'Economy',
        children: [
          {
            value: '3.2.1',
            label: 'Commodities',
          },
          {
            value: '3.2.2',
            label: 'Currencies',
          },
          {
            value: '3.2.3',
            label: 'Financial Crisis',
          },
          {
            value: '3.2.4',
            label: 'Financial Reform',
          },
          {
            value: '3.2.5',
            label: 'Financial Regulation',
          },
          {
            value: '3.2.6',
            label: 'Gasoline Prices',
          },
          {
            value: '3.2.7',
            label: 'Housing Market',
          },
          {
            value: '3.2.8',
            label: 'Interest Rates',
          },
          {
            value: '3.2.9',
            label: 'Job Market',
          },
        ],
      },
      {
        value: '3.3',
        label: 'Industries',
        children: [
          {
            value: '3.3.1',
            label: 'Advertising Industry',
          },
          {
            value: '3.3.10',
            label: 'Education industry',
          },
          {
            value: '3.3.11',
            label: 'Entertainment Industry',
          },
          {
            value: '3.3.12',
            label: 'Environmental Services Industry',
          },
          {
            value: '3.3.13',
            label: 'Financial Industry',
          },
          {
            value: '3.3.14',
            label: 'Food Industry',
          },
          {
            value: '3.3.15',
            label: 'Healthcare Industry',
          },
          {
            value: '3.3.16',
            label: 'Hospitality Industry',
          },
          {
            value: '3.3.17',
            label: 'Information Services Industry',
          },
          {
            value: '3.3.18',
            label: 'Legal Services Industry',
          },
          {
            value: '3.3.19',
            label: 'Logistics and Transportation Industry',
          },
          {
            value: '3.3.2',
            label: 'Agriculture',
          },
          {
            value: '3.3.20',
            label: 'Management Consulting Industry',
          },
          {
            value: '3.3.21',
            label: 'Manufacturing Industry',
          },
          {
            value: '3.3.22',
            label: 'Mechanical and Industrial Engineering Industry',
          },
          {
            value: '3.3.23',
            label: 'Media Industry',
          },
          {
            value: '3.3.24',
            label: 'Metals Industry',
          },
          {
            value: '3.3.25',
            label: 'Non-Profit Organizations',
          },
          {
            value: '3.3.26',
            label: 'Pharmaceutical Industry',
          },
          {
            value: '3.3.27',
            label: 'Power and Energy Industry',
          },
          {
            value: '3.3.28',
            label: 'Publishing Industry',
          },
          {
            value: '3.3.29',
            label: 'Real Estate Industry',
          },
          {
            value: '3.3.3',
            label: 'Apparel Industry',
          },
          {
            value: '3.3.30',
            label: 'Retail Industry',
          },
          {
            value: '3.3.31',
            label: 'Technology Industry',
          },
          {
            value: '3.3.32',
            label: 'Telecommunications Industry',
          },
          {
            value: '3.3.4',
            label: 'Automotive Industry',
          },
          {
            value: '3.3.5',
            label: 'Aviation Industry',
          },
          {
            value: '3.3.6',
            label: 'Biotech and Biomedical Industry',
          },
          {
            value: '3.3.7',
            label: 'Civil Engineering Industry',
          },
          {
            value: '3.3.8',
            label: 'Construction Industry',
          },
          {
            value: '3.3.9',
            label: 'Defense Industry',
          },
        ],
      },
    ],
  },
  {
    value: '4',
    label: 'Careers',
    children: [
      {
        value: '4.1',
        label: 'Apprenticeships',
      },
      {
        value: '4.2',
        label: 'Career Advice',
      },
      {
        value: '4.3',
        label: 'Career Planning',
      },
      {
        value: '4.4',
        label: 'Job Search',
        children: [
          {
            value: '4.4.1',
            label: 'Job Fairs',
          },
          {
            value: '4.4.2',
            label: 'Resume Writing and Advice',
          },
        ],
      },
      {
        value: '4.5',
        label: 'Remote Working',
      },
      {
        value: '4.6',
        label: 'Vocational Training',
      },
    ],
  },
  {
    value: '5',
    label: 'Education',
    children: [
      {
        value: '5.1',
        label: 'Adult Education',
      },
      {
        value: '5.10',
        label: 'Private School',
      },
      {
        value: '5.11',
        label: 'Secondary Education',
      },
      {
        value: '5.12',
        label: 'Special Education',
      },
      {
        value: '5.2',
        label: 'College Education',
        children: [
          {
            value: '5.2.1',
            label: 'College Planning',
          },
          {
            value: '5.2.2',
            label: 'Postgraduate Education',
            children: [
              {
                value: '5.2.2.1',
                label: 'Professional School',
              },
            ],
          },
          {
            value: '5.2.3',
            label: 'Undergraduate Education',
          },
        ],
      },
      {
        value: '5.3',
        label: 'Early Childhood Education',
      },
      {
        value: '5.4',
        label: 'Educational Assessment',
        children: [
          {
            value: '5.4.1',
            label: 'Standardized Testing',
          },
        ],
      },
      {
        value: '5.5',
        label: 'Homeschooling',
      },
      {
        value: '5.6',
        label: 'Homework and Study',
      },
      {
        value: '5.7',
        label: 'Language Learning',
      },
      {
        value: '5.8',
        label: 'Online Education',
      },
      {
        value: '5.9',
        label: 'Primary Education',
      },
    ],
  },
  {
    value: '6',
    label: 'Events and Attractions',
    children: [
      {
        value: '6.1',
        label: 'Amusement and Theme Parks',
      },
      {
        value: '6.10',
        label: 'Fashion Events',
      },
      {
        value: '6.11',
        label: 'Historic Site and Landmark Tours',
      },
      {
        value: '6.12',
        label: 'Malls & Shopping Centers',
      },
      {
        value: '6.13',
        label: 'Museums & Galleries',
      },
      {
        value: '6.14',
        label: 'Musicals',
      },
      {
        value: '6.15',
        label: 'National & Civic Holidays',
      },
      {
        value: '6.16',
        label: 'Nightclubs',
      },
      {
        value: '6.17',
        label: 'Outdoor Activities',
      },
      {
        value: '6.18',
        label: 'Parks & Nature',
      },
      {
        value: '6.19',
        label: 'Party Supplies and Decorations',
      },
      {
        value: '6.2',
        label: 'Awards Shows',
      },
      {
        value: '6.20',
        label: 'Personal Celebrations & Life Events',
        children: [
          {
            value: '6.20.1',
            label: 'Anniversary',
          },
          {
            value: '6.20.10',
            label: 'Wedding',
          },
          {
            value: '6.20.2',
            label: 'Baby Shower',
          },
          {
            value: '6.20.3',
            label: 'Bachelor Party',
          },
          {
            value: '6.20.4',
            label: 'Bachelorette Party',
          },
          {
            value: '6.20.5',
            label: 'Birth',
          },
          {
            value: '6.20.6',
            label: 'Birthday',
          },
          {
            value: '6.20.7',
            label: 'Funeral',
          },
          {
            value: '6.20.8',
            label: 'Graduation',
          },
          {
            value: '6.20.9',
            label: 'Prom',
          },
        ],
      },
      {
        value: '6.21',
        label: 'Political Event',
      },
      {
        value: '6.22',
        label: 'Religious Events',
      },
      {
        value: '6.23',
        label: 'Sporting Events',
      },
      {
        value: '6.24',
        label: 'Theater Venues and Events',
      },
      {
        value: '6.25',
        label: 'Zoos & Aquariums',
      },
      {
        value: '6.3',
        label: 'Bars & Restaurants',
      },
      {
        value: '6.4',
        label: 'Business Expos & Conferences',
      },
      {
        value: '6.5',
        label: 'Casinos & Gambling',
      },
      {
        value: '6.6',
        label: 'Cinemas and Events',
      },
      {
        value: '6.7',
        label: 'Comedy Events',
      },
      {
        value: '6.8',
        label: 'Concerts & Music Events',
      },
      {
        value: '6.9',
        label: 'Fan Conventions',
      },
    ],
  },
  {
    value: '7',
    label: 'Family and Relationships',
    children: [
      {
        value: '7.1',
        label: 'Bereavement',
      },
      {
        value: '7.2',
        label: 'Dating',
      },
      {
        value: '7.3',
        label: 'Divorce',
      },
      {
        value: '7.4',
        label: 'Eldercare',
      },
      {
        value: '7.5',
        label: 'Marriage and Civil Unions',
      },
      {
        value: '7.6',
        label: 'Parenting',
        children: [
          {
            value: '7.6.1',
            label: 'Adoption and Fostering',
          },
          {
            value: '7.6.2',
            label: 'Daycare and Pre-School',
          },
          {
            value: '7.6.3',
            label: 'Internet Safety',
          },
          {
            value: '7.6.4',
            label: 'Parenting Babies and Toddlers',
          },
          {
            value: '7.6.5',
            label: 'Parenting Children Aged 4-11',
          },
          {
            value: '7.6.6',
            label: 'Parenting Teens',
          },
          {
            value: '7.6.8',
            label: 'Special Needs Kids',
          },
        ],
      },
      {
        value: '7.7',
        label: 'Single Life',
      },
    ],
  },
  {
    value: '8',
    label: 'Fine Art',
    children: [
      {
        value: '8.1',
        label: 'Costume',
      },
      {
        value: '8.2',
        label: 'Dance',
      },
      {
        value: '8.3',
        label: 'Design',
      },
      {
        value: '8.4',
        label: 'Digital Arts',
      },
      {
        value: '8.5',
        label: 'Fine Art Photography',
      },
      {
        value: '8.7',
        label: 'Modern Art',
      },
      {
        value: '8.8',
        label: 'Opera',
      },
      {
        value: '8.9',
        label: 'Theater',
      },
    ],
  },
  {
    value: '9',
    label: 'Food & Drink',
    children: [
      {
        value: '9.1',
        label: 'Alcoholic Beverages',
      },
      {
        value: '9.10',
        label: 'Vegan Diets',
      },
      {
        value: '9.11',
        label: 'Vegetarian Diets',
      },
      {
        value: '9.12',
        label: 'World Cuisines',
      },
      {
        value: '9.2',
        label: 'Barbecues and Grilling',
      },
      {
        value: '9.3',
        label: 'Cooking',
      },
      {
        value: '9.4',
        label: 'Desserts and Baking',
      },
      {
        value: '9.5',
        label: 'Dining Out',
      },
      {
        value: '9.6',
        label: 'Food Allergies',
      },
      {
        value: '9.7',
        label: 'Food Movements',
      },
      {
        value: '9.8',
        label: 'Healthy Cooking and Eating',
      },
      {
        value: '9.9',
        label: 'Non-Alcoholic Beverages',
      },
    ],
  },
  {
    value: '10',
    label: 'Healthy Living',
    children: [
      {
        value: '10.1',
        label: "Children's Health",
      },
      {
        value: '10.2',
        label: 'Fitness and Exercise',
        children: [
          {
            value: '10.2.1',
            label: 'Participant Sports',
          },
          {
            value: '10.2.2',
            label: 'Running and Jogging',
          },
        ],
      },
      {
        value: '10.3',
        label: "Men's Health",
      },
      {
        value: '10.4',
        label: 'Nutrition',
      },
      {
        value: '10.5',
        label: 'Senior Health',
      },
      {
        value: '10.6',
        label: 'Weight Loss',
      },
      {
        value: '10.7',
        label: 'Wellness',
        children: [
          {
            value: '10.7.1',
            label: 'Alternative Medicine',
            children: [
              {
                value: '10.7.1.1',
                label: 'Herbs and Supplements',
              },
              {
                value: '10.7.1.2',
                label: 'Holistic Health',
              },
            ],
          },
          {
            value: '10.7.2',
            label: 'Physical Therapy',
          },
          {
            value: '10.7.3',
            label: 'Smoking Cessation',
          },
        ],
      },
      {
        value: '10.8',
        label: "Women's Health",
      },
    ],
  },
  {
    value: '11',
    label: 'Hobbies & Interests',
    children: [
      {
        value: '11.1',
        label: 'Antiquing and Antiques',
      },
      {
        value: '11.10',
        label: 'Magic and Illusion',
      },
      {
        value: '11.11',
        label: 'Model Toys',
      },
      {
        value: '11.12',
        label: 'Musical Instruments',
      },
      {
        value: '11.13',
        label: 'Paranormal Phenomena',
      },
      {
        value: '11.14',
        label: 'Radio Control',
      },
      {
        value: '11.15',
        label: 'Sci-fi and Fantasy',
      },
      {
        value: '11.16',
        label: 'Workshops and Classes',
      },
      {
        value: '11.2',
        label: 'Arts and Crafts',
        children: [
          {
            value: '11.2.1',
            label: 'Beadwork',
          },
          {
            value: '11.2.2',
            label: 'Candle and Soap Making',
          },
          {
            value: '11.2.3',
            label: 'Drawing and Sketching',
          },
          {
            value: '11.2.4',
            label: 'Jewelry Making',
          },
          {
            value: '11.2.5',
            label: 'Needlework',
          },
          {
            value: '11.2.6',
            label: 'Painting',
          },
          {
            value: '11.2.7',
            label: 'Photography',
          },
          {
            value: '11.2.8',
            label: 'Scrapbooking',
          },
          {
            value: '11.2.9',
            label: 'Woodworking',
          },
        ],
      },
      {
        value: '11.3',
        label: 'Beekeeping',
      },
      {
        value: '11.4',
        label: 'Birdwatching',
      },
      {
        value: '11.5',
        label: 'Cigars',
      },
      {
        value: '11.6',
        label: 'Collecting',
        children: [
          {
            value: '11.6.1',
            label: 'Comic Books',
          },
          {
            value: '11.6.2',
            label: 'Stamps and Coins',
          },
        ],
      },
      {
        value: '11.7',
        label: 'Content Production',
        children: [
          {
            value: '11.7.1',
            label: 'Audio Production',
          },
          {
            value: '11.7.2',
            label: 'Freelance Writing',
          },
          {
            value: '11.7.3',
            label: 'Screenwriting',
          },
          {
            value: '11.7.4',
            label: 'Video Production',
          },
        ],
      },
      {
        value: '11.8',
        label: 'Games and Puzzles',
        children: [
          {
            value: '11.8.1',
            label: 'Board Games and Puzzles',
          },
          {
            value: '11.8.2',
            label: 'Card Games',
          },
          {
            value: '11.8.3',
            label: 'Roleplaying Games',
          },
        ],
      },
      {
        value: '11.9',
        label: 'Genealogy and Ancestry',
      },
    ],
  },
  {
    value: '12',
    label: 'Home & Garden',
    children: [
      {
        value: '12.1',
        label: 'Gardening',
      },
      {
        value: '12.10',
        label: 'Remodeling & Construction',
      },
      {
        value: '12.11',
        label: 'Smart Home',
      },
      {
        value: '12.2',
        label: 'Home Appliances',
      },
      {
        value: '12.3',
        label: 'Home Entertaining',
      },
      {
        value: '12.4',
        label: 'Home Improvement',
      },
      {
        value: '12.5',
        label: 'Home Security',
      },
      {
        value: '12.6',
        label: 'Indoor Environmental Quality',
      },
      {
        value: '12.7',
        label: 'Interior Decorating',
      },
      {
        value: '12.8',
        label: 'Landscaping',
      },
      {
        value: '12.9',
        label: 'Outdoor Decorating',
      },
    ],
  },
  {
    value: '13',
    label: 'Medical Health',
    children: [
      {
        value: '13.1',
        label: 'Diseases and Conditions',
        children: [
          {
            value: '13.1.1',
            label: 'Allergies',
          },
          {
            value: '13.1.10',
            label: 'Ear, Nose and Throat Conditions',
          },
          {
            value: '13.1.11',
            label: 'Endocrine and Metabolic Diseases',
            children: [
              {
                value: '13.1.11.1',
                label: 'Hormonal Disorders',
              },
              {
                value: '13.1.11.2',
                label: 'Menopause',
              },
              {
                value: '13.1.11.3',
                label: 'Thyroid Disorders',
              },
            ],
          },
          {
            value: '13.1.12',
            label: 'Eye and Vision Conditions',
          },
          {
            value: '13.1.13',
            label: 'Foot Health',
          },
          {
            value: '13.1.14',
            label: 'Heart and Cardiovascular Diseases',
          },
          {
            value: '13.1.15',
            label: 'Infectious Diseases',
          },
          {
            value: '13.1.16',
            label: 'Injuries',
            children: [
              {
                value: '13.1.16.1',
                label: 'First Aid',
              },
            ],
          },
          {
            value: '13.1.17',
            label: 'Lung and Respiratory Health',
          },
          {
            value: '13.1.18',
            label: 'Mental Health',
          },
          {
            value: '13.1.19',
            label: 'Reproductive Health',
            children: [
              {
                value: '13.1.19.1',
                label: 'Birth Control',
              },
              {
                value: '13.1.19.2',
                label: 'Infertility',
              },
              {
                value: '13.1.19.3',
                label: 'Pregnancy',
              },
            ],
          },
          {
            value: '13.1.2',
            label: 'Blood Disorders',
          },
          {
            value: '13.1.20',
            label: 'Sexual Health',
            children: [
              {
                value: '13.1.20.1',
                label: 'Sexual Conditions',
              },
            ],
          },
          {
            value: '13.1.21',
            label: 'Skin and Dermatology',
          },
          {
            value: '13.1.22',
            label: 'Sleep Disorders',
          },
          {
            value: '13.1.23',
            label: 'Substance Abuse',
          },
          {
            value: '13.1.3',
            label: 'Bone and Joint Conditions',
          },
          {
            value: '13.1.4',
            label: 'Brain and Nervous System Disorders',
          },
          {
            value: '13.1.5',
            label: 'Cancer',
          },
          {
            value: '13.1.6',
            label: 'Cold and Flu',
          },
          {
            value: '13.1.7',
            label: 'Dental Health',
          },
          {
            value: '13.1.8',
            label: 'Diabetes',
          },
          {
            value: '13.1.9',
            label: 'Digestive Disorders',
          },
        ],
      },
      {
        value: '13.2',
        label: 'Medical Tests',
      },
      {
        value: '13.3',
        label: 'Pharmaceutical Drugs',
      },
      {
        value: '13.4',
        label: 'Surgery',
      },
      {
        value: '13.5',
        label: 'Vaccines',
      },
      {
        value: '13.6',
        label: 'Cosmetic Medical Services',
      },
    ],
  },
  {
    value: '14',
    label: 'Movies',
    children: [
      {
        value: '14.1',
        label: 'Action and Adventure Movies',
      },
      {
        value: '14.10',
        label: 'Romance Movies',
      },
      {
        value: '14.11',
        label: 'Science Fiction Movies',
      },
      {
        value: '14.12',
        label: 'Special Interest Movies',
      },
      {
        value: '14.2',
        label: 'Animation Movies',
      },
      {
        value: '14.3',
        label: 'Comedy Movies',
      },
      {
        value: '14.4',
        label: 'Crime and Mystery Movies',
      },
      {
        value: '14.5',
        label: 'Documentary Movies',
      },
      {
        value: '14.6',
        label: 'Drama Movies',
      },
      {
        value: '14.7',
        label: 'Family and Children Movies',
      },
      {
        value: '14.8',
        label: 'Fantasy Movies',
      },
      {
        value: '14.9',
        label: 'Horror Movies',
      },
    ],
  },
  {
    value: '15',
    label: 'Music and Audio',
    children: [
      {
        value: '15.1',
        label: 'Adult Contemporary Music',
        children: [
          {
            value: '15.1.1',
            label: 'Soft AC Music',
          },
          {
            value: '15.1.2',
            label: 'Urban AC Music',
          },
        ],
      },
      {
        value: '15.2',
        label: 'Adult Album Alternative',
      },
      {
        value: '15.6',
        label: "Children's Music",
      },
      {
        value: '15.7',
        label: 'Classic Hits',
      },
      {
        value: '15.8',
        label: 'Classical Music',
      },
      {
        value: '15.9',
        label: 'College Radio',
      },
      {
        value: '15.10',
        label: 'Comedy (Music and Audio)',
      },
      {
        value: '15.12',
        label: 'Contemporary Hits/Pop/Top 40',
      },
      {
        value: '15.13',
        label: 'Country Music',
      },
      {
        value: '15.14',
        label: 'Dance and Electronic Music',
      },
      {
        value: '15.22',
        label: 'World/International Music',
      },
      {
        value: '15.18',
        label: 'Gospel Music',
      },
      {
        value: '15.20',
        label: 'Hip Hop Music',
      },
      {
        value: '15.21',
        label: 'Inspirational/New Age Music',
      },
      {
        value: '15.23',
        label: 'Jazz',
      },
      {
        value: '15.28',
        label: 'Reggae',
      },
      {
        value: '15.3',
        label: 'Blues',
      },
      {
        value: '15.32',
        label: 'Rock Music',
        children: [
          {
            value: '15.32.1',
            label: 'Album-oriented Rock',
          },
          {
            value: '15.32.2',
            label: 'Alternative Rock',
          },
          {
            value: '15.32.3',
            label: 'Classic Rock',
          },
          {
            value: '15.32.4',
            label: 'Hard Rock',
          },
        ],
      },
      {
        value: '15.35',
        label: 'Soundtracks, TV and Showtunes',
      },
      {
        value: '15.36',
        label: 'Sports Radio',
      },
      {
        value: '15.39',
        label: 'Talk Radio',
        children: [
          {
            value: '15.39.1',
            label: 'Business News Radio',
          },
          {
            value: '15.39.2',
            label: 'Educational Radio',
          },
          {
            value: '15.39.3',
            label: 'News Radio',
          },
          {
            value: '15.39.4',
            label: 'News/Talk Radio',
          },
          {
            value: '15.39.5',
            label: 'Public Radio',
          },
        ],
      },
      {
        value: '15.43',
        label: 'Urban Contemporary Music',
      },
    ],
  },
  {
    value: '16',
    label: 'News and Politics',
    children: [
      {
        value: '16.1',
        label: 'Crime',
      },
      {
        value: '16.2',
        label: 'Disasters',
      },
      {
        value: '16.3',
        label: 'International News',
      },
      { value: '16.4', label: 'Law' },
      {
        value: '16.5',
        label: 'Local News',
      },
      {
        value: '16.6',
        label: 'National News',
      },
      {
        value: '16.7',
        label: 'Politics',
        children: [
          {
            value: '16.7.1',
            label: 'Elections',
          },
          {
            value: '16.7.2',
            label: 'Political Issues',
          },
          {
            value: '16.7.3',
            label: 'War and Conflicts',
          },
        ],
      },
      {
        value: '16.8',
        label: 'Weather',
      },
    ],
  },
  {
    value: '17',
    label: 'Personal Finance',
    children: [
      {
        value: '17.1',
        label: 'Consumer Banking',
      },
      {
        value: '17.2',
        label: 'Financial Assistance',
        children: [
          {
            value: '17.2.1',
            label: 'Government Support and Welfare',
          },
          {
            value: '17.2.2',
            label: 'Student Financial Aid',
          },
        ],
      },
      {
        value: '17.3',
        label: 'Financial Planning',
      },
      {
        value: '17.4',
        label: 'Frugal Living',
      },
      {
        value: '17.5',
        label: 'Insurance',
        children: [
          {
            value: '17.5.1',
            label: 'Health Insurance',
          },
          {
            value: '17.5.2',
            label: 'Home Insurance',
          },
          {
            value: '17.5.3',
            label: 'Life Insurance',
          },
          {
            value: '17.5.4',
            label: 'Motor Insurance',
          },
          {
            value: '17.5.5',
            label: 'Pet Insurance',
          },
          {
            value: '17.5.6',
            label: 'Travel Insurance',
          },
        ],
      },
      {
        value: '17.6',
        label: 'Personal Debt',
        children: [
          {
            value: '17.6.1',
            label: 'Credit Cards',
          },
          {
            value: '17.6.2',
            label: 'Home Financing',
          },
          {
            value: '17.6.3',
            label: 'Personal Loans',
          },
          {
            value: '17.6.4',
            label: 'Student Loans',
          },
        ],
      },
      {
        value: '17.7',
        label: 'Personal Investing',
        children: [
          {
            value: '17.7.1',
            label: 'Hedge Funds',
          },
          {
            value: '17.7.2',
            label: 'Mutual Funds',
          },
          {
            value: '17.7.3',
            label: 'Options',
          },
          {
            value: '17.7.4',
            label: 'Stocks and Bonds',
          },
        ],
      },
      {
        value: '17.8',
        label: 'Personal Taxes',
      },
      {
        value: '17.9',
        label: 'Retirement Planning',
      },
      {
        value: '17.10',
        label: 'Home Utilities',
        children: [
          {
            value: '17.10.1',
            label: 'Gas and Electric',
          },
          {
            value: '17.10.2',
            label: 'Internet Service Providers',
          },
          {
            value: '17.10.3',
            label: 'Phone Services',
          },
          {
            value: '17.10.4',
            label: 'Water Services',
          },
        ],
      },
    ],
  },
  {
    value: '18',
    label: 'Pets',
    children: [
      {
        value: '18.1',
        label: 'Birds',
      },
      {
        value: '18.2',
        label: 'Cats',
      },
      {
        value: '18.3',
        label: 'Dogs',
      },
      {
        value: '18.4',
        label: 'Fish and Aquariums',
      },
      {
        value: '18.5',
        label: 'Large Animals',
      },
      {
        value: '18.6',
        label: 'Pet Adoptions',
      },
      {
        value: '18.7',
        label: 'Reptiles',
      },
      {
        value: '18.8',
        label: 'Veterinary Medicine',
      },
      {
        value: '18.9',
        label: 'Pet Supplies',
      },
    ],
  },
  {
    value: '19',
    label: 'Pop Culture',
    children: [
      {
        value: '19.1',
        label: 'Celebrity Deaths',
      },
      {
        value: '19.2',
        label: 'Celebrity Families',
      },
      {
        value: '19.3',
        label: 'Celebrity Homes',
      },
      {
        value: '19.4',
        label: 'Celebrity Pregnancy',
      },
      {
        value: '19.5',
        label: 'Celebrity Relationships',
      },
      {
        value: '19.6',
        label: 'Celebrity Scandal',
      },
      {
        value: '19.7',
        label: 'Celebrity Style',
      },
      {
        value: '19.8',
        label: 'Humor and Satire',
      },
    ],
  },
  {
    value: '20',
    label: 'Real Estate',
    children: [
      {
        value: '20.1',
        label: 'Apartments',
      },
      {
        value: '20.10',
        label: 'Retail Property',
      },
      {
        value: '20.11',
        label: 'Vacation Properties',
      },
      {
        value: '20.2',
        label: 'Developmental Sites',
      },
      {
        value: '20.3',
        label: 'Hotel Properties',
      },
      {
        value: '20.4',
        label: 'Houses',
      },
      {
        value: '20.5',
        label: 'Industrial Property',
      },
      {
        value: '20.6',
        label: 'Land and Farms',
      },
      {
        value: '20.7',
        label: 'Office Property',
      },
      {
        value: '20.8',
        label: 'Real Estate Buying and Selling',
      },
      {
        value: '20.9',
        label: 'Real Estate Renting and Leasing',
      },
    ],
  },
  {
    value: '21',
    label: 'Religion & Spirituality',
    children: [
      {
        value: '21.1',
        label: 'Agnosticism',
      },
      {
        value: '21.10',
        label: 'Spirituality',
      },
      {
        value: '21.2',
        label: 'Astrology',
      },
      {
        value: '21.3',
        label: 'Atheism',
      },
      {
        value: '21.4',
        label: 'Buddhism',
      },
      {
        value: '21.5',
        label: 'Christianity',
      },
      {
        value: '21.6',
        label: 'Hinduism',
      },
      {
        value: '21.7',
        label: 'Islam',
      },
      {
        value: '21.8',
        label: 'Judaism',
      },
      {
        value: '21.9',
        label: 'Sikhism',
      },
    ],
  },
  {
    value: '22',
    label: 'Science',
    children: [
      {
        value: '22.1',
        label: 'Biological Sciences',
      },
      {
        value: '22.2',
        label: 'Chemistry',
      },
      {
        value: '22.3',
        label: 'Environment',
      },
      {
        value: '22.4',
        label: 'Genetics',
      },
      {
        value: '22.5',
        label: 'Geography',
      },
      {
        value: '22.6',
        label: 'Geology',
      },
      {
        value: '22.7',
        label: 'Physics',
      },
      {
        value: '22.8',
        label: 'Space and Astronomy',
      },
    ],
  },
  {
    value: '23',
    label: 'Shopping',
    children: [
      {
        value: '23.1',
        label: 'Coupons and Discounts',
      },
      {
        value: '23.2',
        label: 'Flower Shopping',
      },
      {
        value: '23.3',
        label: 'Gifts and Greetings Cards',
      },
      {
        value: '23.4',
        label: 'Grocery Shopping',
      },
      {
        value: '23.5',
        label: 'Holiday Shopping',
      },
      {
        value: '23.6',
        label: 'Household Supplies',
      },
      {
        value: '23.7',
        label: 'Lotteries and Scratchcards',
      },
      {
        value: '23.8',
        label: 'Sales and Promotions',
      },
      {
        value: '23.9',
        label: "Children's Games and Toys",
      },
    ],
  },
  {
    value: '24',
    label: 'Sports',
    children: [
      {
        value: '24.1',
        label: 'American Football',
      },
      {
        value: '24.10',
        label: 'Boxing',
      },
      {
        value: '24.11',
        label: 'Cheerleading',
      },
      {
        value: '24.12',
        label: 'College Sports',
        children: [
          {
            value: '24.12.1',
            label: 'College Football',
          },
          {
            value: '24.12.2',
            label: 'College Basketball',
          },
          {
            value: '24.12.3',
            label: 'College Baseball',
          },
        ],
      },
      {
        value: '24.13',
        label: 'Cricket',
      },
      {
        value: '24.14',
        label: 'Cycling',
      },
      {
        value: '24.15',
        label: 'Darts',
      },
      {
        value: '24.16',
        label: 'Disabled Sports',
      },
      {
        value: '24.17',
        label: 'Diving',
      },
      {
        value: '24.18',
        label: 'Equine Sports',
        children: [
          {
            value: '24.18.1',
            label: 'Horse Racing',
          },
        ],
      },
      {
        value: '24.19',
        label: 'Extreme Sports',
        children: [
          {
            value: '24.19.1',
            label: 'Canoeing and Kayaking',
          },
          {
            value: '24.19.2',
            label: 'Climbing',
          },
          {
            value: '24.19.3',
            label: 'Paintball',
          },
          {
            value: '24.19.4',
            label: 'Scuba Diving',
          },
          {
            value: '24.19.5',
            label: 'Skateboarding',
          },
          {
            value: '24.19.6',
            label: 'Snowboarding',
          },
          {
            value: '24.19.7',
            label: 'Surfing and Bodyboarding',
          },
          {
            value: '24.19.8',
            label: 'Waterskiing and Wakeboarding',
          },
        ],
      },
      {
        value: '24.2',
        label: 'Australian Rules Football',
      },
      {
        value: '24.20',
        label: 'Fantasy Sports',
      },
      {
        value: '24.21',
        label: 'Field Hockey',
      },
      {
        value: '24.22',
        label: 'Figure Skating',
      },
      {
        value: '24.23',
        label: 'Fishing Sports',
      },
      {
        value: '24.24',
        label: 'Golf',
      },
      {
        value: '24.25',
        label: 'Gymnastics',
      },
      {
        value: '24.26',
        label: 'Hunting and Shooting',
      },
      {
        value: '24.27',
        label: 'Ice Hockey',
      },
      {
        value: '24.28',
        label: 'Inline Skating',
      },
      {
        value: '24.29',
        label: 'Lacrosse',
      },
      {
        value: '24.3',
        label: 'Auto Racing',
        children: [
          {
            value: '24.3.1',
            label: 'Motorcycle Sports',
          },
        ],
      },
      {
        value: '24.30',
        label: 'Martial Arts',
      },
      {
        value: '24.31',
        label: 'Olympic Sports',
        children: [
          {
            value: '24.31.1',
            label: 'Summer Olympic Sports',
          },
          {
            value: '24.31.2',
            label: 'Winter Olympic Sports',
          },
        ],
      },
      {
        value: '24.32',
        label: 'Poker and Professional Gambling',
      },
      {
        value: '24.33',
        label: 'Rodeo',
      },
      {
        value: '24.34',
        label: 'Rowing',
      },
      {
        value: '24.35',
        label: 'Rugby',
        children: [
          {
            value: '24.35.1',
            label: 'Rugby League',
          },
          {
            value: '24.35.2',
            label: 'Rugby Union',
          },
        ],
      },
      {
        value: '24.36',
        label: 'Sailing',
      },
      {
        value: '24.37',
        label: 'Skiing',
      },
      {
        value: '24.38',
        label: 'Snooker/Pool/Billiards',
      },
      {
        value: '24.39',
        label: 'Soccer',
      },
      {
        value: '24.4',
        label: 'Badminton',
      },
      {
        value: '24.40',
        label: 'Softball',
      },
      {
        value: '24.41',
        label: 'Squash',
      },
      {
        value: '24.42',
        label: 'Swimming',
      },
      {
        value: '24.43',
        label: 'Table Tennis',
      },
      {
        value: '24.44',
        label: 'Tennis',
      },
      {
        value: '24.45',
        label: 'Track and Field',
      },
      {
        value: '24.46',
        label: 'Volleyball',
      },
      {
        value: '24.47',
        label: 'Walking',
      },
      {
        value: '24.48',
        label: 'Water Polo',
      },
      {
        value: '24.49',
        label: 'Weightlifting',
      },
      {
        value: '24.5',
        label: 'Baseball',
      },
      {
        value: '24.50',
        label: 'Wrestling',
      },
      {
        value: '24.6',
        label: 'Basketball',
      },
      {
        value: '24.7',
        label: 'Beach Volleyball',
      },
      {
        value: '24.8',
        label: 'Bodybuilding',
      },
      {
        value: '24.9',
        label: 'Bowling',
      },
    ],
  },
  {
    value: '25',
    label: 'Style & Fashion',
    children: [
      {
        value: '25.1',
        label: 'Beauty',
        children: [
          {
            value: '25.1.1',
            label: 'Hair Care',
          },
          {
            value: '25.1.2',
            label: 'Makeup and Accessories',
          },
          {
            value: '25.1.3',
            label: 'Nail Care',
          },
          {
            value: '25.1.4',
            label: 'Natural and Organic Beauty',
          },
          {
            value: '25.1.5',
            label: 'Perfume and Fragrance',
          },
          {
            value: '25.1.6',
            label: 'Skin Care',
          },
        ],
      },
      {
        value: '25.10',
        label: "Women's Fashion",
        children: [
          {
            value: '25.10.1',
            label: "Women's Accessories",
            children: [
              {
                value: '25.10.1.1',
                label: "Women's Glasses",
              },
              {
                value: '25.10.1.2',
                label: "Women's Handbags and Wallets",
              },
              {
                value: '25.10.1.3',
                label: "Women's Hats and Scarves",
              },
              {
                value: '25.10.1.4',
                label: "Women's Jewelry and Watches",
              },
            ],
          },
          {
            value: '25.10.2',
            label: "Women's Clothing",
            children: [
              {
                value: '25.10.2.1',
                label: "Women's Business Wear",
              },
              {
                value: '25.10.2.2',
                label: "Women's Casual Wear",
              },
              {
                value: '25.10.2.3',
                label: "Women's Formal Wear",
              },
              {
                value: '25.10.2.4',
                label: "Women's Intimates and Sleepwear",
              },
              {
                value: '25.10.2.5',
                label: "Women's Outerwear",
              },
              {
                value: '25.10.2.6',
                label: "Women's Sportswear",
              },
            ],
          },
          {
            value: '25.10.3',
            label: "Women's Shoes and Footwear",
          },
        ],
      },
      {
        value: '25.2',
        label: 'Body Art',
      },
      {
        value: '25.3',
        label: "Children's Clothing",
      },
      {
        value: '25.4',
        label: 'Designer Clothing',
      },
      {
        value: '25.5',
        label: 'Fashion Trends',
      },
      {
        value: '25.6',
        label: 'High Fashion',
      },
      {
        value: '25.7',
        label: "Men's Fashion",
        children: [
          {
            value: '25.7.1',
            label: "Men's Accessories",
            children: [
              {
                value: '25.7.1.1',
                label: "Men's Jewelry and Watches",
              },
            ],
          },
          {
            value: '25.7.2',
            label: "Men's Clothing",
            children: [
              {
                value: '25.7.2.1',
                label: "Men's Business Wear",
              },
              {
                value: '25.7.2.2',
                label: "Men's Casual Wear",
              },
              {
                value: '25.7.2.3',
                label: "Men's Formal Wear",
              },
              {
                value: '25.7.2.4',
                label: "Men's Outerwear",
              },
              {
                value: '25.7.2.5',
                label: "Men's Sportswear",
              },
              {
                value: '25.7.2.6',
                label: "Men's Underwear and Sleepwear",
              },
            ],
          },
          {
            value: '25.7.3',
            label: "Men's Shoes and Footwear",
          },
        ],
      },
      {
        value: '25.8',
        label: 'Personal Care',
        children: [
          {
            value: '25.8.1',
            label: 'Bath and Shower',
          },
          {
            value: '25.8.2',
            label: 'Deodorant and Antiperspirant',
          },
          {
            value: '25.8.3',
            label: 'Oral care',
          },
          {
            value: '25.8.4',
            label: 'Shaving',
          },
        ],
      },
      {
        value: '25.9',
        label: 'Street Style',
      },
    ],
  },
  {
    value: '26',
    label: 'Technology & Computing',
    children: [
      {
        value: '26.1',
        label: 'Artificial Intelligence',
      },
      {
        value: '26.2',
        label: 'Augmented Reality',
      },
      {
        value: '26.3',
        label: 'Computing',
        children: [
          {
            value: '26.3.1',
            label: 'Computer Networking',
          },
          {
            value: '26.3.2',
            label: 'Computer Peripherals',
          },
          {
            value: '26.3.3',
            label: 'Computer Software and Applications',
            children: [
              {
                value: '26.3.3.1',
                label: '3-D Graphics',
              },
              {
                value: '26.3.3.10',
                label: 'Photo Editing Software',
              },
              {
                value: '26.3.3.11',
                label: 'Shareware and Freeware',
              },
              {
                value: '26.3.3.12',
                label: 'Video Software',
              },
              {
                value: '26.3.3.13',
                label: 'Web Conferencing',
              },
              {
                value: '26.3.3.2',
                label: 'Antivirus Software',
              },
              {
                value: '26.3.3.3',
                label: 'Browsers',
              },
              {
                value: '26.3.3.4',
                label: 'Computer Animation',
              },
              {
                value: '26.3.3.5',
                label: 'Databases',
              },
              {
                value: '26.3.3.6',
                label: 'Desktop Publishing',
              },
              {
                value: '26.3.3.7',
                label: 'Digital Audio',
              },
              {
                value: '26.3.3.8',
                label: 'Graphics Software',
              },
              {
                value: '26.3.3.9',
                label: 'Operating Systems',
              },
            ],
          },
          {
            value: '26.3.4',
            label: 'Data Storage and Warehousing',
          },
          {
            value: '26.3.5',
            label: 'Desktops',
          },
          {
            value: '26.3.6',
            label: 'Information and Network Security',
          },
          {
            value: '26.3.7',
            label: 'Internet',
            children: [
              {
                value: '26.3.7.1',
                label: 'Cloud Computing',
              },
              {
                value: '26.3.7.10',
                label: 'Web Development',
              },
              {
                value: '26.3.7.11',
                label: 'Web Hosting',
              },
              { value: '26.3.7.2', label: 'Email' },
              {
                value: '26.3.7.3',
                label: 'Internet for Beginners',
              },
              {
                value: '26.3.7.4',
                label: 'Internet of Things',
              },
              {
                value: '26.3.7.5',
                label: 'IT and Internet Support',
              },
              {
                value: '26.3.7.6',
                label: 'Search',
              },
              {
                value: '26.3.7.7',
                label: 'Social Networking',
              },
              {
                value: '26.3.7.9',
                label: 'Web Design and HTML',
              },
            ],
          },
          {
            value: '26.3.8',
            label: 'Laptops',
          },
          {
            value: '26.3.9',
            label: 'Programming Languages',
          },
        ],
      },
      {
        value: '26.4',
        label: 'Consumer Electronics',
        children: [
          {
            value: '26.4.1',
            label: 'Cameras and Camcorders',
          },
          {
            value: '26.4.2',
            label: 'Home Entertainment Systems',
          },
          {
            value: '26.4.3',
            label: 'Smartphones',
          },
          {
            value: '26.4.4',
            label: 'Tablets and E-readers',
          },
          {
            value: '26.4.5',
            label: 'Wearable Technology',
          },
        ],
      },
      {
        value: '26.5',
        label: 'Robotics',
      },
      {
        value: '26.6',
        label: 'Virtual Reality',
      },
    ],
  },
  {
    value: '27',
    label: 'Television',
    children: [
      {
        value: '27.1',
        label: 'Animation TV',
      },
      {
        value: '27.10',
        label: 'Soap Opera TV',
      },
      {
        value: '27.11',
        label: 'Special Interest TV',
      },
      {
        value: '27.12',
        label: 'Sports TV',
      },
      {
        value: '27.2',
        label: "Children's TV",
      },
      {
        value: '27.3',
        label: 'Comedy TV',
      },
      {
        value: '27.4',
        label: 'Drama TV',
      },
      {
        value: '27.5',
        label: 'Factual TV',
      },
      {
        value: '27.6',
        label: 'Holiday TV',
      },
      {
        value: '27.7',
        label: 'Music TV',
      },
      {
        value: '27.8',
        label: 'Reality TV',
      },
      {
        value: '27.9',
        label: 'Science Fiction TV',
      },
    ],
  },
  {
    value: '28',
    label: 'Travel',
    children: [
      {
        value: '28.1',
        label: 'Travel Accessories',
      },
      {
        value: '28.2',
        label: 'Travel Locations',
        children: [
          {
            value: '28.2.1',
            label: 'Africa Travel',
          },
          {
            value: '28.2.2',
            label: 'Asia Travel',
          },
          {
            value: '28.2.3',
            label: 'Australia and Oceania Travel',
          },
          {
            value: '28.2.4',
            label: 'Europe Travel',
          },
          {
            value: '28.2.5',
            label: 'North America Travel',
          },
          {
            value: '28.2.6',
            label: 'Polar Travel',
          },
          {
            value: '28.2.7',
            label: 'South America Travel',
          },
        ],
      },
      {
        value: '28.4',
        label: 'Travel Type',
        children: [
          {
            value: '28.4.1',
            label: 'Adventure Travel',
          },
          {
            value: '28.4.10',
            label: 'Family Travel',
          },
          {
            value: '28.4.11',
            label: 'Honeymoons and Getaways',
          },
          {
            value: '28.4.12',
            label: 'Hotels and Motels',
          },
          {
            value: '28.4.13',
            label: 'Rail Travel',
          },
          {
            value: '28.4.14',
            label: 'Road Trips',
          },
          {
            value: '28.4.15',
            label: 'Spas',
          },
          {
            value: '28.4.2',
            label: 'Air Travel',
          },
          {
            value: '28.4.3',
            label: 'Beach Travel',
          },
          {
            value: '28.4.4',
            label: 'Bed & Breakfasts',
          },
          {
            value: '28.4.5',
            label: 'Budget Travel',
          },
          {
            value: '28.4.6',
            label: 'Business Travel',
          },
          {
            value: '28.4.7',
            label: 'Camping',
          },
          {
            value: '28.4.8',
            label: 'Cruises',
          },
          {
            value: '28.4.9',
            label: 'Day Trips',
          },
        ],
      },
    ],
  },
  {
    value: '29',
    label: 'Video Gaming',
    children: [
      {
        value: '29.1',
        label: 'Console Games',
      },
      {
        value: '29.2',
        label: 'eSports',
      },
      {
        value: '29.3',
        label: 'Mobile Games',
      },
      {
        value: '29.4',
        label: 'PC Games',
      },
      {
        value: '29.5',
        label: 'Video Game Genres',
        children: [
          {
            value: '29.5.1',
            label: 'Action Video Games',
          },
          {
            value: '29.5.10',
            label: 'Role-Playing Video Games',
          },
          {
            value: '29.5.11',
            label: 'Simulation Video Games',
          },
          {
            value: '29.5.12',
            label: 'Sports Video Games',
          },
          {
            value: '29.5.13',
            label: 'Strategy Video Games',
          },
          {
            value: '29.5.2',
            label: 'Action-Adventure Video Games',
          },
          {
            value: '29.5.3',
            label: 'Adventure Video Games',
          },
          {
            value: '29.5.4',
            label: 'Casual Games',
          },
          {
            value: '29.5.5',
            label: 'Educational Video Games',
          },
          {
            value: '29.5.6',
            label: 'Exercise and Fitness Video Games',
          },
          {
            value: '29.5.7',
            label: 'MMOs',
          },
          {
            value: '29.5.8',
            label: 'Music and Party Video Games',
          },
          {
            value: '29.5.9',
            label: 'Puzzle Video Games',
          },
        ],
      },
    ],
  },
];
