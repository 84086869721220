import React, { useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import * as playlistsActions from '../../store/playlists/actions';
import { useDispatch } from 'react-redux';
import { Form, Input } from '../../lib/availity/form';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import firebase from 'firebase/app';

const FormPlaylist = (props) => {
  const editingPlaylist = props.playlist || {};

  const [active, setActive] = useState(editingPlaylist.active || false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const apiKey = values.apiKey && values.apiKey !== '' ? values.apiKey : null;
    delete values.apiKey;

    const playlist = {
      ...values,
      active,
    };

    dispatch(
      props.create
        ? playlistsActions.createPlaylist({
            ...playlist,
            ...(apiKey && { apiKey }),
          })
        : playlistsActions.updatePlaylist({
            id: editingPlaylist.id,
            ...playlist,
            apiKey: apiKey || firebase.firestore.FieldValue.delete(),
          }),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingPlaylist, active }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ errors, isSubmitting, isValid, status, values }) => (
            <>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        defaultChecked={active}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="active"
                        onClick={(e) => {
                          setActive(!active);
                        }}>
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormPlaylist;
