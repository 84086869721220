const statsTypes = {
  RESET_NETWORK_STATE: 'RESET_NETWORK_STATE',
  CREATE_NETWORK: {
    REQUEST: 'CREATE_NETWORK.REQUEST',
    SUCCESS: 'CREATE_NETWORK.SUCCESS',
    FAILURE: 'CREATE_NETWORK.FAILURE',
  },
  UPDATE_NETWORK: {
    REQUEST: 'UPDATE_NETWORK.REQUEST',
    SUCCESS: 'UPDATE_NETWORK.SUCCESS',
    FAILURE: 'UPDATE_NETWORK.FAILURE',
  },
  SYNC_NETWORKS: {
    REQUEST: 'SYNC_NETWORKS.REQUEST',
    SUCCESS: 'SYNC_NETWORKS.SUCCESS',
    FAILURE: 'SYNC_NETWORKS.FAILURE',
  },
};

export default statsTypes;
