import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthMiddleware = ({ component: Component, layout: Layout }) => {
  const loggedIn = useSelector((state) => state.Auth.loggedIn);
  const user = useSelector((state) => state.Auth.admin);
  return (
    <Route
      render={(props) => {
        // here you can apply condition
        if (!loggedIn || !user)
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default withRouter(AuthMiddleware);
