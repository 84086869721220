import { createSelector } from 'reselect';
import { getDates, dateIsInRange, capitalize } from '../helpers/sharedFunction';

import { INSIGHT_LEVEL } from '../config/insight';
import colors from '../components/CommonStyle/colors';
import moment from 'moment';

export const getInsight = (level, device = 'all') =>
  createSelector(
    // (state) => state.AdUnitInsight.insights,
    (state) => state.CreativeInsight.insights,
    (state) => state.CampaignInsight.insights,
    (creative, campaign) => {
      let insights = [];
      // if (level === INSIGHT_LEVEL.AD_UNIT) insights = adUnit;
      if (level === INSIGHT_LEVEL.CREATIVE) insights = creative;
      if (level === INSIGHT_LEVEL.CAMPAIGN) insights = campaign;
      insights =
        device === 'all'
          ? insights
          : insights.map(({ deviceInsights, date }) => {
              const insight = deviceInsights.find(
                ({ deviceName }) => deviceName === capitalize(device),
              );
              return { date, ...insight };
            });
      return insights;
    },
  );

export const totalMetric = (metric, level, device = 'all') =>
  createSelector(getInsight(level, device), (insights) =>
    insights.map((insight) => insight[metric] || 0).reduce((a, b) => a + b, 0),
  );

export const listenThroughRate = (level, device = 'all') =>
  createSelector(
    totalMetric('play', level, device),
    totalMetric('completed', level, device),
    (play, completed) => (completed / play) * 100,
  );

export const percentageFirstQuartile = (level, device = 'all') =>
  createSelector(
    totalMetric('first-quartile', level, device),
    totalMetric('play', level, device),
    (firstQuartile, play) => (firstQuartile / play) * 100,
  );

export const percentageMidpoint = (level, device = 'all') =>
  createSelector(
    totalMetric('midpoint', level, device),
    totalMetric('play', level, device),
    (midpoint, play) => (midpoint / play) * 100,
  );

export const percentageThirdQuartile = (level, device = 'all') =>
  createSelector(
    totalMetric('third-quartile', level, device),
    totalMetric('play', level, device),
    (thirdQuartile, play) => (thirdQuartile / play) * 100,
  );

export const amountSpent = createSelector(
  (state) => state.CampaignInsight.insights,
  (insights) =>
    insights
      .map(({ amountSpent }) => amountSpent || 0)
      .reduce((a, b) => a + b, 0),
);

export const drawSeriesDailyGraph = (dateRange, level, t, device = 'all') =>
  createSelector(getInsight(level, device), (insights) => {
    const { startDate, endDate } = dateRange;
    const dates = getDates(startDate.toDate(), endDate.toDate());

    let dataGraph = {
      series: [
        {
          name: t('Streaming'),
          data: [],
          type: 'area',
          color: colors.primary,
        },
        // {
        //   name: t('Listen Through Rate'),
        //   data: [],
        //   type: 'line',
        //   color: colors.dark,
        // },
      ],
      max: -9999999999,
      min: 99999999999,
    };

    dates.forEach((day) => {
      const startDay = moment(day).utc().startOf('day').toDate();
      const endDay = moment(day).utc().endOf('day').toDate();

      const dailyInsights = insights.filter((insight) =>
        dateIsInRange(new Date(insight.date), startDay, endDay),
      );

      const impressions = dailyInsights
        .map((insight) => insight.impressions)
        .reduce((a, b) => a + b, 0);

      // const play = dailyInsights
      //   .map((insight) => insight.play)
      //   .reduce((a, b) => a + b, 0);

      // const completed = dailyInsights
      //   .map((insight) => insight.completed)
      //   .reduce((a, b) => a + b, 0);

      // const LTR = (completed / play) * 100;

      dataGraph.series[0].data.push([day.getTime(), impressions]);
      // dataGraph.series[1].data.push([day.getTime(), isFinite(LTR) ? LTR : 0]);

      dataGraph.max = Math.max(
        dataGraph.max,
        impressions,
        // isFinite(LTR) ? LTR : 0,
      );
      dataGraph.min = Math.min(
        dataGraph.min,
        impressions,
        // isFinite(LTR) ? LTR : 0,
      );
    });
    return dataGraph;
  });
