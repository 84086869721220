import types from './actionTypes';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_USER_STATE:
      return initialState;
    case types.CREATE_USER.REQUEST:
    case types.UPDATE_USER.REQUEST:
    case types.USER_ACTIVATION.REQUEST:
    case types.SYNC_USERS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_USER.SUCCESS:
    case types.UPDATE_USER.SUCCESS:
    case types.USER_ACTIVATION.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_USERS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        users: [...action.users],
      };
    case types.CREATE_USER.FAILURE:
    case types.UPDATE_USER.FAILURE:
    case types.USER_ACTIVATION.FAILURE:
    case types.SYNC_USERS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default userReducer;
