import { createSelector } from 'reselect';
import { INSIGHT_LEVEL } from '../config/insight';
import roles from '../config/roles';

export const isLoading = createSelector(
  (state) => state.Dashboard.loading,
  (state) => state.SiteInsight.loading,
  (state) => state.PublisherInsight.loading,
  (state) => state.NetworkInsight.loading,
  (state) => state.CountryInsight.loading,
  (dashboard, site, publisher, network, country) =>
    dashboard || site || publisher || network || country,
);

export const getInsightLevel = createSelector(
  (state) => state.Auth.admin,
  (state) => state.Dashboard.filters,
  (admin, filters) =>
    filters?.publisherId === '' && admin.role === roles.NETWORKER
      ? INSIGHT_LEVEL.NETWORK
      : filters?.publisherId === ''
      ? INSIGHT_LEVEL.COUNTRY
      : Array.isArray(filters?.siteIds)
      ? INSIGHT_LEVEL.SITE
      : INSIGHT_LEVEL.PUBLISHER,
);
