import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CREATIVE_STATE,
});

export const resetMessage = () => ({
  type: types.RESET_CREATIVE_MESSAGE,
});

export const createCreative = (creative) => ({
  type: types.CREATE_CREATIVE.REQUEST,
  creative,
});

export const createCreativeSuccess = (creative) => ({
  type: types.CREATE_CREATIVE.SUCCESS,
  creative,
});

export const createCreativeFailure = (error) => ({
  type: types.CREATE_CREATIVE.FAILURE,
  error,
});

export const updateCreative = (creative) => ({
  type: types.UPDATE_CREATIVE.REQUEST,
  creative,
});

export const updateDraftCreativeSuccess = (creative) => ({
  type: types.UPDATE_CREATIVE.DRAFT_SUCCESS,
  creative,
});

export const updateCreativeSuccess = (creative) => ({
  type: types.UPDATE_CREATIVE.SUCCESS,
  creative,
});

export const updateCreativeFailure = (error) => ({
  type: types.UPDATE_CREATIVE.FAILURE,
  error,
});

export const deleteDraftCreative = (creative) => ({
  type: types.DELETE_DRAFT_CREATIVE.REQUEST,
  creative,
});

export const deleteDraftCreativeSuccess = (creative) => ({
  type: types.DELETE_DRAFT_CREATIVE.SUCCESS,
  creative,
});

export const deleteDraftCreativeFailure = (error) => ({
  type: types.DELETE_DRAFT_CREATIVE.FAILURE,
  error,
});

export const approveCreative = (creative) => ({
  type: types.APPROVE_CREATIVE.REQUEST,
  creative,
});

export const approveCreativeSuccess = (creative) => ({
  type: types.APPROVE_CREATIVE.SUCCESS,
  creative,
});

export const approveCreativeFailure = (error) => ({
  type: types.APPROVE_CREATIVE.FAILURE,
  error,
});

export const rejectCreative = (creative, reason) => ({
  type: types.REJECT_CREATIVE.REQUEST,
  creative,
  reason,
});

export const rejectCreativeSuccess = (creative) => ({
  type: types.REJECT_CREATIVE.SUCCESS,
  creative,
});

export const rejectCreativeFailure = (error) => ({
  type: types.REJECT_CREATIVE.FAILURE,
  error,
});

export const reprocessCreative = (creative) => ({
  type: types.REPROCESS_CREATIVE.REQUEST,
  creative,
});

export const reprocessCreativeSuccess = (creative) => ({
  type: types.REPROCESS_CREATIVE.SUCCESS,
  creative,
});

export const reprocessCreativeFailure = (error) => ({
  type: types.REPROCESS_CREATIVE.FAILURE,
  error,
});

export const fetchCreatives = (
  forceUpdate = false,
  startAfterId = null,
  limit = null,
) => ({
  type: types.FETCH_CREATIVES.REQUEST,
  forceUpdate,
  startAfterId,
  limit,
});

export const fetchCreativesSuccess = (
  creatives,
  forceUpdate,
  loadMore = false,
) => ({
  type: types.FETCH_CREATIVES.SUCCESS,
  creatives,
  forceUpdate,
  loadMore,
});

export const fetchCreativesFailure = (error) => ({
  type: types.FETCH_CREATIVES.FAILURE,
  error,
});

export const fetchCreativesByCampaignId = (
  campaignId,
  forceUpdate = false,
) => ({
  type: types.FETCH_CREATIVES_BY_CAMPAIGN_ID.REQUEST,
  campaignId,
  forceUpdate,
});

export const fetchCreativesByCampaignIdSuccess = (creatives, forceUpdate) => ({
  type: types.FETCH_CREATIVES_BY_CAMPAIGN_ID.SUCCESS,
  creatives,
  forceUpdate,
});

export const fetchCreativesByCampaignIdFailure = (error) => ({
  type: types.FETCH_CREATIVES_BY_CAMPAIGN_ID.FAILURE,
  error,
});

export const discardDraftCreatives = () => ({
  type: types.DISCARD_DRAFT_CREATIVES.REQUEST,
});

export const discardDraftCreativesSuccess = () => ({
  type: types.DISCARD_DRAFT_CREATIVES.SUCCESS,
});

export const discardDraftCreativesFailure = (error) => ({
  type: types.DISCARD_DRAFT_CREATIVES.FAILURE,
  error,
});

export const actionCreative = (creative, action) => ({
  type: types.ACTION_CREATIVE.REQUEST,
  creative,
  action,
});

export const actionCreativeSuccess = (creative, message) => ({
  type: types.ACTION_CREATIVE.SUCCESS,
  creative,
  message,
});

export const actionCreativeFailure = (error) => ({
  type: types.ACTION_CREATIVE.FAILURE,
  error,
});
