import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CREATIVE_INSIGHT_STATE,
});

export const fetchInsights = (startDate, endDate, filters = null) => ({
  type: types.FETCH_CREATIVE_INSIGHTS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchInsightsSuccess = (
  insights,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_CREATIVE_INSIGHTS.SUCCESS,
  insights,
  startDate,
  endDate,
  filters,
});

export const fetchInsightsFailure = (error) => ({
  type: types.FETCH_CREATIVE_INSIGHTS.FAILURE,
  error,
});

export const fetchInsightsByCreative = (creative, startDate, endDate) => ({
  type: types.FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.REQUEST,
  creative,
  startDate,
  endDate,
});

export const fetchInsightsByCreativeSuccess = (insights) => ({
  type: types.FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.SUCCESS,
  insights,
});

export const fetchInsightsByCreativeFailure = (error) => ({
  type: types.FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.FAILURE,
  error,
});
