export const INSIGHT_LEVEL = {
  COUNTRY: 'country',
  NETWORK: 'network',
  SITE: 'site',
  PUBLISHER: 'publisher',
  PODCAST: 'podcast',
  CAMPAIGN: 'campaign',
  CREATIVE: 'creative',
  AD_UNIT: 'adUnit',
};

export const PERCENTILE_PERCENTAGE = {
  'first-quartile': 0.25,
  midpoint: 0.5,
  'third-quartile': 0.75,
  completed: 1,
};
