import { createSelector } from 'reselect';
import { CAMPAIGN_STATUS } from '../config/campaign';

export const selectCampaign = (id) =>
  createSelector(
    (state) => state.Campaign.draftCampaigns,
    (state) => state.Campaign.campaigns,
    (draftCampaigns, campaigns) =>
      id
        ? [...draftCampaigns, ...campaigns].find(
            (campaign) => campaign.id === id,
          )
        : null,
  );

export const selectCampaignsBy = (key, value) =>
  createSelector(
    (state) => state.Campaign.draftCampaigns,
    (state) => state.Campaign.campaigns,
    (draftCampaigns, campaigns) =>
      [...draftCampaigns, ...campaigns].filter(
        (campaign) => campaign[key] === value,
      ),
  );

export const selectDraftCampaigns = createSelector(
  (state) => state.Campaign.draftCampaigns,
  (state) => state.Campaign.campaigns,
  (draftCampaigns, campaigns) =>
    [...draftCampaigns, ...campaigns].filter(({ isDraft }) => !!isDraft),
);

export const countDraftCampaigns = createSelector(
  selectDraftCampaigns,
  (campaigns) => campaigns.length,
);

export const selectCampaignsWithDrafts = createSelector(
  (state) => state.Campaign.draftCampaigns,
  (state) => state.Campaign.campaigns,
  (state) => state.Creative.draftCreatives,
  (state) => state.Creative.creatives,
  (draftCampaigns, campaigns, draftCreatives, creatives) =>
    [...draftCampaigns, ...campaigns].filter(({ id, isDraft }) => {
      const draftCreative = [...draftCreatives, ...creatives].find(
        ({ campaignId, isDraft }) => campaignId === id && isDraft,
      );
      return draftCreative || isDraft;
    }),
);

export const countCampaignsWithDrafts = createSelector(
  selectCampaignsWithDrafts,
  (campaigns) => campaigns.length,
);

export const selectCampaignsReadyToBeApproved = createSelector(
  selectCampaignsBy('status', CAMPAIGN_STATUS.IN_REVIEW),
  (state) => state.Creative.draftCreatives,
  (state) => state.Creative.creatives,
  (campaigns, draftCreatives, creatives) =>
    campaigns
      .filter(({ isDraft }) => !isDraft)
      .filter(({ id }) => {
        const allCreatives = [...draftCreatives, ...creatives];
        if (!allCreatives.length) return false;

        return allCreatives
          .filter(({ campaignId }) => campaignId === id)
          .every(
            ({ status }) =>
              ![CAMPAIGN_STATUS.PROCESSING, CAMPAIGN_STATUS.ERROR].includes(
                status,
              ),
          );
      }),
);
