import '../../lib/availity/yup';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  name: yup.string().required(),
  companyName: yup.string().required(),
  taxId: yup.string().required(),
  email: yup.string().email().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  zip: yup.string().required(),
  country: yup.string().required(),
  creditLine: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  creditAmount: yup.number().required(),
  isPublisher: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  publisherId: yup.string().when('isPublisher', {
    is: true,
    then: yup.string().required(),
  }),
  showSpreakerEpisodeURL: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  showMegaphoneEpisodeURL: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
});

export default formValidation;
