import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_TRANSACTION_STATE,
});

export const createTransaction = (transaction) => ({
  type: types.CREATE_TRANSACTION.REQUEST,
  transaction,
});

export const createTransactionSuccess = () => ({
  type: types.CREATE_TRANSACTION.SUCCESS,
});

export const createTransactionFailure = (error) => ({
  type: types.CREATE_TRANSACTION.FAILURE,
  error,
});

export const updateTransaction = (transaction) => ({
  type: types.UPDATE_TRANSACTION.REQUEST,
  transaction,
});

export const updateTransactionSuccess = (transaction) => ({
  type: types.UPDATE_TRANSACTION.SUCCESS,
  transaction,
});

export const updateTransactionFailure = (error) => ({
  type: types.UPDATE_TRANSACTION.FAILURE,
  error,
});

export const fetchTransactions = (startDate, endDate, filters = null) => ({
  type: types.FETCH_TRANSACTIONS.REQUEST,
  startDate,
  endDate,
  filters,
});

export const fetchTransactionsSuccess = (
  transactions,
  startDate,
  endDate,
  filters,
) => ({
  type: types.FETCH_TRANSACTIONS.SUCCESS,
  transactions,
  startDate,
  endDate,
  filters,
});

export const fetchTransactionsFailure = (error) => ({
  type: types.FETCH_TRANSACTIONS.FAILURE,
  error,
});

export const fetchTransactionsByCustomer = (customer) => ({
  type: types.FETCH_TRANSACTIONS_BY_CUSTOMER.REQUEST,
  customer,
});

export const fetchTransactionsByCustomerSuccess = (transactions) => ({
  type: types.FETCH_TRANSACTIONS_BY_CUSTOMER.SUCCESS,
  transactions,
});

export const fetchTransactionsByCustomerFailure = (error) => ({
  type: types.FETCH_TRANSACTIONS_BY_CUSTOMER.FAILURE,
  error,
});
