import types from './actionTypes';

export const resetState = () => ({
  type: types.RESET_CAMPAIGN_STATE,
});

export const resetMessage = () => ({
  type: types.RESET_CAMPAIGN_MESSAGE,
});

export const resetCampaignPublishResults = () => ({
  type: types.RESET_CAMPAIGN_PUBLISH_RESULTS,
});

export const createCampaign = (campaign) => ({
  type: types.CREATE_CAMPAIGN.REQUEST,
  campaign,
});

export const createCampaignSuccess = (campaign) => ({
  type: types.CREATE_CAMPAIGN.SUCCESS,
  campaign,
});

export const createCampaignFailure = (error) => ({
  type: types.CREATE_CAMPAIGN.FAILURE,
  error,
});

export const updateCampaign = (campaign) => ({
  type: types.UPDATE_CAMPAIGN.REQUEST,
  campaign,
});

export const updateDraftCampaignSuccess = (campaign) => ({
  type: types.UPDATE_CAMPAIGN.DRAFT_SUCCESS,
  campaign,
});

export const updateCampaignSuccess = (campaign) => ({
  type: types.UPDATE_CAMPAIGN.SUCCESS,
  campaign,
});

export const updateCampaignFailure = (error) => ({
  type: types.UPDATE_CAMPAIGN.FAILURE,
  error,
});

export const deleteDraftCampaign = (campaign) => ({
  type: types.DELETE_DRAFT_CAMPAIGN.REQUEST,
  campaign,
});

export const deleteDraftCampaignSuccess = (campaign) => ({
  type: types.DELETE_DRAFT_CAMPAIGN.SUCCESS,
  campaign,
});

export const deleteDraftCampaignFailure = (error) => ({
  type: types.DELETE_DRAFT_CAMPAIGN.FAILURE,
  error,
});

export const approveCampaign = (campaign) => ({
  type: types.APPROVE_CAMPAIGN.REQUEST,
  campaign,
});

export const approveCampaignSuccess = (campaign) => ({
  type: types.APPROVE_CAMPAIGN.SUCCESS,
  campaign,
});

export const approveCampaignFailure = (error) => ({
  type: types.APPROVE_CAMPAIGN.FAILURE,
  error,
});

export const rejectCampaign = (campaign, reason) => ({
  type: types.REJECT_CAMPAIGN.REQUEST,
  campaign,
  reason,
});

export const rejectCampaignSuccess = (campaign) => ({
  type: types.REJECT_CAMPAIGN.SUCCESS,
  campaign,
});

export const rejectCampaignFailure = (error) => ({
  type: types.REJECT_CAMPAIGN.FAILURE,
  error,
});

export const updateRejectMessageCampaign = (
  campaign,
  reason,
  operation = 'add',
) => ({
  type: types.UPDATE_REJECT_MESSAGE_CAMPAIGN.REQUEST,
  campaign,
  reason,
  operation,
});

export const updateRejectMessageCampaignSuccess = (campaign) => ({
  type: types.UPDATE_REJECT_MESSAGE_CAMPAIGN.SUCCESS,
  campaign,
});

export const updateRejectMessageCampaignFailure = (error) => ({
  type: types.UPDATE_REJECT_MESSAGE_CAMPAIGN.FAILURE,
  error,
});

export const reprocessCampaign = (campaign) => ({
  type: types.REPROCESS_CAMPAIGN.REQUEST,
  campaign,
});

export const reprocessCampaignSuccess = (campaign) => ({
  type: types.REPROCESS_CAMPAIGN.SUCCESS,
  campaign,
});

export const reprocessCampaignFailure = (error) => ({
  type: types.REPROCESS_CAMPAIGN.FAILURE,
  error,
});

export const fetchCampaigns = (
  forceUpdate = false,
  startAfterId = null,
  limit = null,
) => ({
  type: types.FETCH_CAMPAIGNS.REQUEST,
  forceUpdate,
  startAfterId,
  limit,
});

export const fetchCampaignsSuccess = (
  campaigns,
  forceUpdate,
  loadMore = false,
) => ({
  type: types.FETCH_CAMPAIGNS.SUCCESS,
  campaigns,
  forceUpdate,
  loadMore,
});

export const fetchCampaignsFailure = (error) => ({
  type: types.FETCH_CAMPAIGNS.FAILURE,
  error,
});

export const fetchCampaignById = (campaignId, forceUpdate = false) => ({
  type: types.FETCH_CAMPAIGN_BY_ID.REQUEST,
  campaignId,
  forceUpdate,
});

export const fetchCampaignByIdSuccess = (campaigns, forceUpdate) => ({
  type: types.FETCH_CAMPAIGN_BY_ID.SUCCESS,
  campaigns,
  forceUpdate,
});

export const fetchCampaignByIdFailure = (error) => ({
  type: types.FETCH_CAMPAIGN_BY_ID.FAILURE,
  error,
});

export const discardDraftCampaigns = () => ({
  type: types.DISCARD_DRAFT_CAMPAIGNS.REQUEST,
});

export const discardDraftCampaignsSuccess = () => ({
  type: types.DISCARD_DRAFT_CAMPAIGNS.SUCCESS,
});

export const discardDraftCampaignsFailure = (error) => ({
  type: types.DISCARD_DRAFT_CAMPAIGNS.FAILURE,
  error,
});

export const publishCampaigns = (campaigns) => ({
  type: types.PUBLISH_CAMPAIGNS.REQUEST,
  campaigns,
});

export const publishCampaignsSuccess = (results) => ({
  type: types.PUBLISH_CAMPAIGNS.SUCCESS,
  results,
});

export const publishCampaignsFailure = (error) => ({
  type: types.PUBLISH_CAMPAIGNS.FAILURE,
  error,
});

export const actionCampaign = (campaign, action) => ({
  type: types.ACTION_CAMPAIGN.REQUEST,
  campaign,
  action,
});

export const actionCampaignSuccess = (campaign, message) => ({
  type: types.ACTION_CAMPAIGN.SUCCESS,
  campaign,
  message,
});

export const actionCampaignFailure = (error) => ({
  type: types.ACTION_CAMPAIGN.FAILURE,
  error,
});
