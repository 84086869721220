import React, { useState, useEffect, useMemo } from 'react';
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as sitesActions from '../../store/sites/actions';
import * as customersActions from '../../store/customers/actions';
import { selectUser } from '../../selectors/user';

import FormCreateCampaign from './Forms/FormCreateCampaign';
import FormCampaignCluster from './Forms/FormCampaignCluster';
import FormCampaignCreative from './Forms/FormCampaignCreative';
import classnames from 'classnames';
import { autoId } from '../../helpers/sharedFunction';

const FormCampaign = ({ campaign, userId, closeModal }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  const user = useSelector(selectUser(campaign?.userId || userId));
  const campaignId = useMemo(() => campaign?.id || autoId(), [campaign]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sitesActions.syncSites());
    dispatch(customersActions.syncCustomers());
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };

  const handlePrev = (prev = 1) => {
    toggleTab(activeTab - prev);
  };

  const handleNext = (next = 1) => {
    toggleTab(activeTab + next);
  };

  return (
    <Card>
      <CardBody>
        <div className="wizard clearfix">
          <div className="steps clearfix">
            <ul>
              <NavItem
                className={classnames({
                  current: activeTab === 1,
                })}>
                <NavLink
                  className={classnames({
                    active: activeTab === 1,
                  })}
                  disabled>
                  <span className="number">1</span> Campaign
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames({
                  current: activeTab === 2,
                })}>
                <NavLink
                  className={classnames({
                    active: activeTab === 2,
                  })}
                  disabled>
                  <span className="number">2</span> <span>Cluster</span>
                </NavLink>
              </NavItem>
              <NavItem
                className={classnames({
                  current: activeTab === 3,
                })}>
                <NavLink
                  className={
                    (classnames({
                      active: activeTab === 3,
                    }),
                    'done')
                  }
                  disabled>
                  <span className="number">3</span> Creatives
                </NavLink>
              </NavItem>
            </ul>
          </div>
          <div className="content clearfix">
            <TabContent activeTab={activeTab} className="body">
              <TabPane tabId={1}>
                {passedSteps.includes(1) && activeTab === 1 && (
                  <FormCreateCampaign
                    campaignId={campaignId}
                    user={user}
                    onNext={handleNext}
                  />
                )}
              </TabPane>
              <TabPane tabId={2}>
                {passedSteps.includes(2) && activeTab === 2 && (
                  <FormCampaignCluster
                    campaignId={campaignId}
                    user={user}
                    onNext={handleNext}
                    onPrev={handlePrev}
                  />
                )}
              </TabPane>
              <TabPane tabId={3}>
                {passedSteps.includes(3) && activeTab === 3 && (
                  <FormCampaignCreative
                    campaignId={campaignId}
                    user={user}
                    onNext={closeModal}
                    onPrev={handlePrev}
                  />
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default FormCampaign;
