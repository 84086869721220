import {
  takeEvery,
  put,
  all,
  call,
  takeLeading,
  select,
} from 'redux-saga/effects';

import types from './actionTypes';
import * as insightsActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../../../helpers/firebase';

import { toDateFirebase } from '../../../../helpers/sharedFunction';

const insightTransformer = (insight, data) => ({
  id: insight.id,
  ...data,
  averageSongDuration: data.averageSongDuration ?? 0,
  date: toDateFirebase(insight, data, 'date').toDate(),
});

function* fetchInsightsSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const insightsRef = rsf.firestore.createCollectionRefWithFilters(
      'dailyCreativeInsights',
      countryId,
      startDate,
      endDate,
      filters,
      null,
      null,
      'date',
    );

    const insightsSnap = yield call(rsf.firestore.getCollection, insightsRef);

    let insights = [];

    insightsSnap.forEach((insight) => {
      const data = insight.data();
      insights.push(insightTransformer(insight, data));
    });

    yield put(
      insightsActions.fetchInsightsSuccess(
        insights,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(insightsActions.fetchInsightsFailure(error));
  }
}

function* fetchInsightsByCreativeSaga({ creative, startDate, endDate }) {
  try {
    const insightsRef = firebase
      .firestore()
      .collection('dailyCreativeInsights')
      .where('creativeId', '==', creative.id)
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)
      .orderBy('date', 'desc');

    const insightsSnap = yield call(rsf.firestore.getCollection, insightsRef);

    let insights = [];
    insightsSnap.forEach((insight) => {
      const data = insight.data();
      insights.push(insightTransformer(insight, data));
    });
    yield put(insightsActions.fetchInsightsByCreativeSuccess(insights));
  } catch (error) {
    yield put(insightsActions.fetchInsightsByCreativeFailure(error));
  }
}

function* insightSaga() {
  yield all([
    takeEvery(types.FETCH_CREATIVE_INSIGHTS.REQUEST, fetchInsightsSaga),
    takeLeading(
      types.FETCH_CREATIVE_INSIGHTS_BY_CREATIVE.REQUEST,
      fetchInsightsByCreativeSaga,
    ),
  ]);
}

export default insightSaga;
