import React, { useEffect } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Spinner,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { withRouter, Link } from 'react-router-dom';
import * as authActions from '../../store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import DisclaimerFooter from '../../components/Common/DisclaimerFooter';

import logotype from '../../assets/images/logo-dark.png';
import logo from '../../assets/images/logo-sm-light-square.png';

const Register = (props) => {
  const loading = useSelector((state) => state.Auth.loading);
  const error = useSelector((state) => state.Auth.error);
  const success = useSelector((state) => state.Auth.success);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetMessages());
  }, []);

  const handleLoginWithGoogle = () => {
    dispatch(authActions.loginWithGoogle(props.history));
  };

  const handleValidSubmit = (event, values) => {
    const { email, password, firstName, lastName } = values;
    dispatch(
      authActions.registerWithEmail(
        email,
        password,
        firstName,
        lastName,
        props.history,
      ),
    );
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome</h5>
                        <p>Enter the magical world of AudioBoost</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logotype} alt="" className="img-fluid p-2" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <h3 className="text-center">Create Your Account</h3>
                  <div className="mt-5">
                    <button
                      className="btn btn-outline-dark btn-block waves-effect waves-light"
                      type="button"
                      onClick={handleLoginWithGoogle}
                      disabled={loading}>
                      <i className="bx bxl-google font-size-18 align-middle mr-2"></i>
                      Login with Google
                    </button>
                  </div>
                  <div className="my-4">
                    <div className="d-flex align-items-center">
                      <hr className="w-full text-gray" />
                      <div className="px-4 text-base text-gray">or</div>
                      <hr className="w-full text-gray" />
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
                      {error && <Alert color="danger">{error}</Alert>}
                      {success && <Alert color="success">{success}</Alert>}
                      <div className="form-group">
                        <AvField
                          name="firstName"
                          label="First Name"
                          type="text"
                          required
                          placeholder="Enter your first name"
                          disabled={loading}
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="lastName"
                          label="Last Name"
                          type="text"
                          required
                          placeholder="Enter your last name"
                          disabled={loading}
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          disabled={loading}
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter password"
                          disabled={loading}
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loading}>
                          {loading ? <Spinner size="sm" /> : 'Register'}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to your{' '}
                          <a
                            href="https://www.iubenda.com/terms-and-conditions/46316715"
                            target="_blank"
                            rel="noopener noreferrer">
                            Term &amp; Conditions
                          </a>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {' '}
                    Login
                  </Link>{' '}
                </p>
                <DisclaimerFooter />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);
