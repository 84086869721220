import types from './actionTypes';

const initialState = {
  loading: false,
  sites: [],
  error: '',
  success: '',
};

const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_SITE_STATE:
      return initialState;
    case types.RESET_SITE_MESSAGE:
      return {
        ...state,
        error: '',
        success: '',
      };
    case types.CREATE_SITE.REQUEST:
    case types.UPDATE_SITE.REQUEST:
    case types.SYNC_SITES.REQUEST:
    case types.IMPORT_VAST.REQUEST:
    case types.CREATE_AD_UNITS.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_SITE.SUCCESS:
    case types.UPDATE_SITE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_SITES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        sites: [...action.sites],
      };
    case types.IMPORT_VAST.SUCCESS:
    case types.CREATE_AD_UNITS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        success: action.message,
      };
    case types.CREATE_SITE.FAILURE:
    case types.UPDATE_SITE.FAILURE:
    case types.SYNC_SITES.FAILURE:
    case types.IMPORT_VAST.FAILURE:
    case types.CREATE_AD_UNITS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
        success: '',
      };
    default:
      return state;
  }
};

export default siteReducer;
