import React, { useEffect } from 'react';
import { Row, Card, CardBody, Table, Alert, Button, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { selectCampaignsWithDrafts } from '../../selectors/campaign';
import { countCreativeByCampaign } from '../../selectors/creative';
import { selectClusterByCampaign } from '../../selectors/cluster';
import * as campaignsActions from '../../store/campaigns/actions';

import { useTranslation } from 'react-i18next';
import CenterBox from '../../components/Common/CenterBox';
import { Dots } from 'react-activity';
import moment from 'moment';

const CampaignDetails = ({ campaign, results, loading }) => {
  const totalCreatives = useSelector(
    countCreativeByCampaign(campaign.id, true),
  );
  const cluster = useSelector(selectClusterByCampaign(campaign.id, true));
  const result = results.find((result) => result.campaignId === campaign.id);

  const { t } = useTranslation();

  return (
    <tr>
      <td className="align-middle border-top-0 border-bottom">
        <h6 className="font-size-14 mb-1 text-wrap">{campaign.name}</h6>
      </td>
      <td className="align-middle border-top-0 border-bottom">
        <p className="text-muted mb-1">{t('Status')}</p>
        {loading ? (
          <Spinner animation="border" variant="warning" size="sm" />
        ) : result?.status && result?.status !== 200 ? (
          <span className="badge badge-danger font-size-12">ERROR</span>
        ) : (
          <span className="badge badge-success font-size-12">READY</span>
        )}
      </td>
      <td className="align-middle border-top-0 border-bottom">
        <p className="text-muted mb-1">{t('Draft Creatives')}</p>
        <h6 className="mb-0">{totalCreatives}</h6>
      </td>
      <td className="align-middle border-top-0 border-bottom">
        <p className="text-muted mb-1">{t('Draft Cluster')}</p>
        <h6 className="mb-0">
          {cluster ? (
            <span className="badge badge-success font-size-12">YES</span>
          ) : (
            <span className="badge badge-warning font-size-12">NO</span>
          )}
        </h6>
      </td>
      <td className="align-middle border-top-0 border-bottom">
        <p className="text-muted mb-1">{t('Daily Budget')}</p>
        <h6 className="mb-0">
          {campaign.dailyBudget ? (
            `€ ${campaign.dailyBudget.toFixed(2)}`
          ) : (
            <span className="badge badge-success font-size-12">FREE</span>
          )}
        </h6>
      </td>
      <td className="align-middle border-top-0 border-bottom">
        <p className="text-muted mb-1">{t('Start Date')}</p>
        <h6 className="mb-0">
          {moment(campaign.startDate).format('DD/MM/YYYY')}
        </h6>
      </td>
      <td className="align-middle border-top-0 border-bottom">
        <p className="text-muted mb-1">{t('End Date')}</p>
        <h6 className="mb-0">
          {moment(campaign.endDate).format('DD/MM/YYYY')}
        </h6>
      </td>
    </tr>
  );
};

const PublishCampaigns = () => {
  const campaigns = useSelector(selectCampaignsWithDrafts);

  const loading = useSelector((state) => state.Campaign.loading);
  const error = useSelector((state) => state.Campaign.error);
  const results = useSelector((state) => state.Campaign.publishResults);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(campaignsActions.resetCampaignPublishResults());
  }, []);

  const handlePublishCampaigns = () => {
    dispatch(campaignsActions.publishCampaigns(campaigns));
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        {error && <Alert color="danger">{error}</Alert>}
        <div className="table-responsive mt-4">
          <Table className="mb-0">
            <tbody>
              {campaigns.map((campaign, index) => (
                <CampaignDetails
                  key={'campaign_' + index}
                  campaign={campaign}
                  results={results}
                  loading={loading}
                />
              ))}
              {!campaigns.length && !results.length && (
                <CenterBox height={100}>
                  <Dots size={21} />
                </CenterBox>
              )}
              {results
                .filter(({ status }) => status === 200)
                .map(({ name }) => (
                  <tr>
                    <td className="align-middle border-top-0 border-bottom col-3">
                      <h6 className="font-size-14 mb-1 text-wrap">{name}</h6>
                    </td>
                    <td className="align-middle border-top-0 border-bottom">
                      <p className="text-muted mb-1">{t('Upload')}</p>
                      <span className="badge badge-success font-size-12">
                        COMPLETED
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        {!!campaigns.length && (
          <Row className="mt-4 justify-content-center">
            <Button
              color="success"
              className="btn waves-effect"
              disabled={loading}
              onClick={handlePublishCampaigns}>
              {loading ? (
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
              ) : (
                <i className="bx bx-cloud-upload font-size-16 align-middle mr-2"></i>
              )}
              {t('Publish Campaigns')}
            </Button>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default PublishCampaigns;
