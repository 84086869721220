const statsTypes = {
  RESET_SITE_STATE: 'RESET_SITE_STATE',
  RESET_SITE_MESSAGE: 'RESET_SITE_MESSAGE',
  CREATE_SITE: {
    REQUEST: 'CREATE_SITE.REQUEST',
    SUCCESS: 'CREATE_SITE.SUCCESS',
    FAILURE: 'CREATE_SITE.FAILURE',
  },
  UPDATE_SITE: {
    REQUEST: 'UPDATE_SITE.REQUEST',
    SUCCESS: 'UPDATE_SITE.SUCCESS',
    FAILURE: 'UPDATE_SITE.FAILURE',
  },
  SYNC_SITES: {
    REQUEST: 'SYNC_SITES.REQUEST',
    SUCCESS: 'SYNC_SITES.SUCCESS',
    FAILURE: 'SYNC_SITES.FAILURE',
  },
  IMPORT_VAST: {
    REQUEST: 'IMPORT_VAST.REQUEST',
    SUCCESS: 'IMPORT_VAST.SUCCESS',
    FAILURE: 'IMPORT_VAST.FAILURE',
  },
  CREATE_AD_UNITS: {
    REQUEST: 'CREATE_AD_UNITS.REQUEST',
    SUCCESS: 'CREATE_AD_UNITS.SUCCESS',
    FAILURE: 'CREATE_AD_UNITS.FAILURE',
  },
};

export default statsTypes;
