import React, { useState } from 'react';
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';
import { useTranslation } from 'react-i18next';
import colors from '../CommonStyle/colors';

const DEFAULT_REMOVE_HOVER_COLOR = colors.red;
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40,
);

const styles = {
  zone: {
    alignItems: 'center',
    cursor: 'pointer',
    border: `2px dashed ${colors.gray}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: `linear-gradient(to bottom, ${colors.primary}, ${colors.primary}55)`,
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: colors.grayLight,
    borderRadius: 3,
    marginBottom: '0.5em',
    padding: 3,
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: colors.grayLight,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
    padding: 3,
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: colors.indigo,
  },
  default: {
    borderColor: colors.gray,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

const CSVUploadReader = (props) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR,
  );
  const [reset, setReset] = useState(true);

  const { t } = useTranslation();

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        console.log('---------------------------');
        console.log(results);
        console.log('---------------------------');
        props.onUploadAccepted(results);
        setZoneHover(false);
        setReset(!reset);
      }}
      onUploadRejected={props.onUploadRejected}
      onDragOver={(event) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setZoneHover(false);
      }}
      config={props.config}
      isReset={reset}
      noDrag>
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
      }) => (
        <>
          <div
            {...getRootProps()}
            style={Object.assign(
              {},
              styles.zone,
              zoneHover && styles.zoneHover,
            )}>
            {acceptedFile ? (
              <>
                <div style={styles.file}>
                  <div style={styles.info}>
                    <span style={styles.size}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span style={styles.name}>{acceptedFile.name}</span>
                  </div>
                  <div style={styles.progressBar}>
                    <ProgressBar />
                  </div>
                  <div
                    {...getRemoveFileProps()}
                    style={styles.remove}
                    onMouseOver={(event) => {
                      event.preventDefault();
                      setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                    }}
                    onMouseOut={(event) => {
                      event.preventDefault();
                      setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                    }}>
                    <Remove color={removeHoverColor} />
                  </div>
                </div>
              </>
            ) : (
              <button type="button" className="btn btn-primary  w-sm">
                <i className="bx bx-import d-block font-size-16"></i>
                {t('Upload CSV')}
              </button>
            )}
          </div>
        </>
      )}
    </CSVReader>
  );
};

export default CSVUploadReader;
