import { createSelector } from 'reselect';

export const totalMetric = (metric) =>
  createSelector(
    (state) => state.Revenue.revenues,
    (revenues) =>
      revenues
        .map((revenue) => revenue[metric] || 0)
        .reduce((a, b) => a + b, 0),
  );
