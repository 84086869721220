import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'reactstrap';
import Dropzone from 'react-dropzone';

import mp3Img from '../../assets/images/mp3-icon.png';
import closeSVG from '../../assets/images/close-square-red.svg';

const FileUpload = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      selectedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setSelectedFiles(files);
    props.handleFiles && props.handleFiles(props.name, files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const handleRemoveFile = (index) => {
    const files = [...selectedFiles];
    files.splice(index, 1);
    setSelectedFiles(files);
    props.handleRemoveFiles && props.handleRemoveFiles(props.name, index);
  };

  return (
    <React.Fragment>
      <Dropzone
        onDrop={handleAcceptedFiles}
        autoProcessQueue={false}
        {...props}>
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h6>
                {props.uploadMessage || 'Drop files here or click to upload.'}
              </h6>
            </div>
            {props.showPreview && (
              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles.map((file, index) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={index + '-file'}>
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col md="4" className="px-2 mx-auto text-center">
                            {file.type.startsWith('image/') && (
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={file.name}
                                src={file.preview}
                              />
                            )}
                            {file.type.startsWith('audio/') && (
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={file.name}
                                src={mp3Img}
                              />
                            )}
                          </Col>
                          <Col md="6" className="px-2">
                            <p className="text-muted font-size-11 mb-2">
                              {file.type}
                            </p>
                            <p className="mb-0">
                              <strong>{file.formattedSize}</strong>
                            </p>
                          </Col>
                          <Col md="2" className="px-2">
                            <div
                              onClick={() => handleRemoveFile(index)}
                              style={{
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                                backgroundImage: `url(${closeSVG})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                              }}></div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Dropzone>
    </React.Fragment>
  );
};

export default FileUpload;
