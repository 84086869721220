const statsTypes = {
  RESET_SITE_REVENUE_STATE: 'RESET_SITE_REVENUE_STATE',
  FETCH_SITE_REVENUES: {
    REQUEST: 'FETCH_SITE_REVENUES.REQUEST',
    SUCCESS: 'FETCH_SITE_REVENUES.SUCCESS',
    FAILURE: 'FETCH_SITE_REVENUES.FAILURE',
  },
};

export default statsTypes;
