import { put, all, call, takeLatest } from 'redux-saga/effects';

import types from './actionTypes';
import * as podcastAnalysesActions from './actions';

import firebase from 'firebase/app';
import rsf from '../../../helpers/firebase';

import { toDateFirebase } from '../../../helpers/sharedFunction';

const podcastAnalysisTransformer = (podcastAnalysis, data) => {
  return {
    id: podcastAnalysis.id,
    ...data,
    ...(data.createdAt && {
      createdAt: toDateFirebase(podcastAnalysis, data).toDate(),
    }),
    ...(data.updatedAt && {
      updatedAt: toDateFirebase(podcastAnalysis, data, 'updatedAt').toDate(),
    }),
  };
};

function* fetchPodcastAnalysisSaga({ podcastId }) {
  try {
    const podcastAnalysisRef = firebase
      .firestore()
      .collection('podcastAnalyses')
      .doc(podcastId);

    const podcastAnalysisDoc = yield call(
      rsf.firestore.getDocument,
      podcastAnalysisRef,
    );

    let podcastAnalysis = null;

    if (podcastAnalysisDoc.exists) {
      const data = podcastAnalysisDoc.data();
      podcastAnalysis = podcastAnalysisTransformer(podcastAnalysisDoc, data);
    }
    yield put(
      podcastAnalysesActions.fetchPodcastAnalysisSuccess(podcastAnalysis),
    );
  } catch (error) {
    yield put(podcastAnalysesActions.fetchPodcastAnalysisFailure(error));
  }
}

function* publisherSaga() {
  yield all([
    takeLatest(types.FETCH_PODCAST_ANALYSIS.REQUEST, fetchPodcastAnalysisSaga),
  ]);
}

export default publisherSaga;
