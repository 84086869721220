import React, { useState } from 'react';
import { Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import * as networksActions from '../../store/networks/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPublishersForNetworkSelect } from '../../selectors/publisher';

import { Form, Input } from '../../lib/availity/form';
import { Select } from '../../lib/availity/select';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';

const FormNetwork = (props) => {
  const editingNetwork = props.network || {};
  const publishers = useSelector(
    selectPublishersForNetworkSelect(editingNetwork),
  );

  const [active, setActive] = useState(editingNetwork.active || false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const network = {
      ...values,
      active,
    };

    dispatch(
      props.create
        ? networksActions.createNetwork({
            ...network,
          })
        : networksActions.updateNetwork(
            {
              id: editingNetwork.id,
              ...network,
            },
            editingNetwork.publisherIds,
          ),
    );
    props.closeModal();
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingNetwork, active }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ errors, isSubmitting, isValid, status, values }) => (
            <>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        defaultChecked={active}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="active"
                        onClick={(e) => {
                          setActive(!active);
                        }}>
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="publisherIds">Publisher</Label>
                    <Select
                      name="publisherIds"
                      id="publisherIds"
                      isMulti
                      options={publishers}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="headerImageUrl">Header Image</Label>
                    <Input id="headerImageUrl" name="headerImageUrl" />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton
                  disabled={!isValid || props.loading}
                  create={props.create}
                />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormNetwork;
