import types from './actionTypes';

const initialState = {
  loading: false,
  countries: [],
  error: '',
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_COUNTRY_STATE:
      return initialState;
    case types.CREATE_COUNTRY.REQUEST:
    case types.UPDATE_COUNTRY.REQUEST:
    case types.SYNC_COUNTRIES.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case types.CREATE_COUNTRY.SUCCESS:
    case types.UPDATE_COUNTRY.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case types.SYNC_COUNTRIES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        countries: [...action.countries],
      };
    case types.CREATE_COUNTRY.FAILURE:
    case types.UPDATE_COUNTRY.FAILURE:
    case types.SYNC_COUNTRIES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    default:
      return state;
  }
};

export default countryReducer;
