import React, { memo, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { drawSeriesPlayedBar } from '../../selectors/insight';
import * as insightsActions from '../../store/insights/actions';

import ReactApexChart from 'react-apexcharts';
import CenterBox from '../Common/CenterBox';
import colors from '../CommonStyle/colors';

import moment from 'moment';

const MostPlayedBar = ({ startDate, endDate, publisherId, siteIds, limit }) => {
  const loading = useSelector((state) => state.Insight.loading);
  const mostPlayedPodcasts = useSelector(
    (state) => state.Insight.playedPodcasts,
  );
  const dataBar = useSelector(drawSeriesPlayedBar());

  const dispatch = useDispatch();

  useEffect(() => {
    !mostPlayedPodcasts.length &&
      dispatch(
        insightsActions.fetchMostPlayedPodcasts(
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
          { publisherId, siteIds },
          limit,
        ),
      );
  }, []);

  const playedBar = {
    series: [
      {
        data: dataBar.series,
      },
    ],
    options: {
      colors: [
        colors.blue,
        colors.orange,
        colors.purple,
        colors.yellow,
        colors.green,
        colors.indigo,
        colors.red,
      ],
      chart: {
        type: 'bar',
        height: 270,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#fff'],
        },
      },
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: dataBar.labels,
        min: 0,
        max: Math.max(...dataBar.series),
      },
      yaxis: {
        labels: {
          show: true,
          minWidth: 200,
          maxWidth: 450,
          align: 'right',
          floating: true,
          style: {
            colors: colors.dark,
            fontSize: '12px',
            fontWeight: 500,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <div className="clearfix"></div>
        {!loading ? (
          <div className="apex-charts" dir="ltr">
            <ReactApexChart
              series={playedBar.series}
              options={playedBar.options}
              type="bar"
              height={400}
              width={700}
            />
          </div>
        ) : (
          <CenterBox height={290}>
            <i className="bx bx-hourglass bx-spin font-size-32 text-secondary"></i>
          </CenterBox>
        )}
      </CardBody>
    </Card>
  );
};

export default memo(MostPlayedBar);
