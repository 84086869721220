import '../../../lib/availity/yup';
import * as yup from 'yup';

const SPREAKER_REGEXP_URL = /^https:\/\/www\.spreaker\.com\/[/0-9A-Za-z_-]+$/;
const MEGAPHONE_REGEXP_URL =
  /^https:\/\/traffic\.megaphone\.fm\/[0-9A-Z]+\.mp3\?updated=\d+$/;

const formValidation = (creative) =>
  yup.object().shape({
    name: yup.string().min(5).required(),
    dates: yup.object().shape({
      startDate: yup.date().required(),
      endDate: yup
        .date()
        .label('end date')
        .required()
        .test('isInFuture', 'end date must be in the future', (value) => {
          if (value > new Date()) return true;
          if (
            new Date(creative?.endDate).getTime() === new Date(value).getTime()
          )
            return true; //not modified
          return false;
        }),
    }),
    title: yup.string().min(5).required(),
    brand: yup.string().min(3).required(),
    link: yup.string().url(),
    spreakerEpisodeURL: yup.string().matches(SPREAKER_REGEXP_URL, {
      message: 'Is not a Spreaker valid URL',
      excludeEmptyString: true,
    }),
    spreakerId: yup.string(),
    megaphoneEpisodeURL: yup.string().matches(MEGAPHONE_REGEXP_URL, {
      message: 'Is not a Megaphone valid URL',
      excludeEmptyString: true,
    }),
    megaphonePath: yup.string(),
    thirdPartyTrackingURLs: yup.array().of(
      yup.object({
        type: yup.string().label('utility links type').required(),
        trackingURL: yup.string().label('Third-party Tracking URL').required(),
      }),
    ),
    booster: yup.number().max(1).min(0),
  });

export default formValidation;
